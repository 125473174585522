/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import "./Dispatch.css";
import MaskedInput from "react-text-mask";
import axios from "axios";
import Highlighter from "react-highlight-words";
import moment from "moment";
import "react-multi-carousel/lib/styles.css";
import "loaders.css";
import NumberFormat from "react-number-format";
import { useTransition, animated } from "react-spring";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight, faCalendarAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useDetectClickOutside } from "react-detect-click-outside";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Calendar, CarrierList, Ltl, RatingScreen } from "./../panels";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {
    setSelectedOrder,
    setSelectedCustomer,
    setSelectedContact,
    setSelectedCarrier,
    setSelectedCarrierContact,
    setSelectedDriver as setSelectedCarrierDriver,
    setSelectedInsurance as setSelectedCarrierInsurance,

    setAdminHomePanels,
    setCompanyHomePanels,
    setAdminCarrierPanels,
    setCompanyCarrierPanels,
    setAdminCompanySetupPanels,
    setCompanyCompanySetupPanels,
    setAdminCustomerPanels,
    setCompanyCustomerPanels,
    setAdminDispatchPanels,
    setCompanyDispatchPanels,
    setAdminInvoicePanels,
    setCompanyInvoicePanels,
    setAdminLoadBoardPanels,
    setCompanyLoadBoardPanels,
    setAdminReportPanels,
    setCompanyReportPanels,

    setAvailableOrders,
    setBookedOrders,
    setInTransitOrders,
    setDeliveredNotInvoiced,
    setIsLoadingWidget
} from "./../../../actions";
import {
    ChangeCarrier,
    CustomerSearch,
    Documents,
    Routing,
    RateConf,
    Order,
    Bol,
    Modal as DispatchModal,
    OrderImport,
    RoutingMap,
    Template
} from "./../panels";

import {
    Customers,
    Carriers,
    Invoice,
    LoadBoard,
    Dispatch as DispatchPanel,
} from "./../../company";
import ModalTemplate from "./modal-template/ModalTemplate";

const Dispatch = (props) => {
    const refOrderNumber = useRef();
    const [selectedOrder, setSelectedOrder] = useState({});

    const [selectedTemplate, setSelectedTemplate] = useState({});
    const [showModalTemplate, setShowModalTemplate] = useState(false);
    const [isEditingTemplate, setIsEditingTemplate] = useState(false);
    const [isCreatingTemplate, setIsCreatingTemplate] = useState(false);
    const [selectedBillToCustomer, setSelectedBillToCustomer] = useState({});
    const [selectedBillToCustomerContact, setSelectedBillToCustomerContact] = useState({});
    const [selectedShipperCustomer, setSelectedShipperCustomer] = useState({});
    const [selectedShipperCustomerContact, setSelectedShipperCustomerContact] = useState({});
    const [selectedConsigneeCustomer, setSelectedConsigneeCustomer] = useState({});
    const [selectedConsigneeCustomerContact, setSelectedConsigneeCustomerContact,] = useState({});
    const [selectedCarrier, setSelectedCarrier] = useState({});
    const [selectedCarrierContact, setSelectedCarrierContact] = useState({});
    const [selectedCarrierDriver, setSelectedCarrierDriver] = useState({});
    const [selectedCarrierInsurance, setSelectedCarrierInsurance] = useState({});
    const [selectedOrderDocument, setSelectedOrderDocument] = useState({});
    const [selectedInternalNote, setSelectedInternalNote] = useState({});
    const [selectedNoteForCarrier, setSelectedNoteForCarrier] = useState({});
    const [selectedNoteForDriver, setSelectedNoteForDriver] = useState({});

    const refShipperContactName = useRef();
    const [shipperContactNameItems, setShipperContactNameItems] = useState([]);
    const [showShipperContactNames, setShowShipperContactNames] = useState(false);
    const refShipperContactNameDropDown = useDetectClickOutside({
        onTriggered: async () => {
            await setShowShipperContactNames(false)
        }
    });
    const refShipperContactNamePopupItems = useRef([]);

    const refShipperContactPhone = useRef();
    const [shipperContactPhoneItems, setShipperContactPhoneItems] = useState([]);
    const [showShipperContactPhones, setShowShipperContactPhones] = useState(false);
    const refShipperContactPhoneDropDown = useDetectClickOutside({
        onTriggered: async () => {
            await setShowShipperContactPhones(false)
        }
    });
    const refShipperContactPhonePopupItems = useRef([]);

    const refConsigneeContactName = useRef();
    const [consigneeContactNameItems, setConsigneeContactNameItems] = useState([]);
    const [showConsigneeContactNames, setShowConsigneeContactNames] = useState(false);
    const refConsigneeContactNameDropDown = useDetectClickOutside({
        onTriggered: async () => {
            await setShowConsigneeContactNames(false)
        }
    });
    const refConsigneeContactNamePopupItems = useRef([]);

    const refConsigneeContactPhone = useRef();
    const [consigneeContactPhoneItems, setConsigneeContactPhoneItems] = useState([]);
    const [showConsigneeContactPhones, setShowConsigneeContactPhones] = useState(false);
    const refConsigneeContactPhoneDropDown = useDetectClickOutside({
        onTriggered: async () => {
            await setShowConsigneeContactPhones(false)
        }
    });
    const refConsigneeContactPhonePopupItems = useRef([]);

    const [shipperBolNumber, setShipperBolNumber] = useState("");
    const [shipperPoNumber, setShipperPoNumber] = useState("");
    const [shipperRefNumber, setShipperRefNumber] = useState("");
    const [consigneeBolNumber, setConsigneeBolNumber] = useState("");
    const [consigneePoNumber, setConsigneePoNumber] = useState("");
    const [consigneeRefNumber, setConsigneeRefNumber] = useState("");
    const [mileageLoaderVisible, setMileageLoaderVisible] = useState(false);
    const [isShowingShipperSecondPage, setIsShowingShipperSecondPage] = useState(false);
    const [isShowingConsigneeSecondPage, setIsShowingConsigneeSecondPage] = useState(false);

    const [dispatchEvent, setDispatchEvent] = useState({});
    const [dispatchEventLocation, setDispatchEventLocation] = useState("");
    const refDispatchEventLocation = useRef();
    const [dispatchEventNotes, setDispatchEventNotes] = useState("");
    const [dispatchEventDate, setDispatchEventDate] = useState("");
    const [dispatchEventTime, setDispatchEventTime] = useState("");

    const [driverItems, setDriverItems] = useState([]);
    SwiperCore.use([Navigation]);



    useEffect(() => {
        if ((props.order_id || 0) > 0) {
            setIsLoading(true);

            axios.post(props.serverUrl + "/getOrderById", { id: props.order_id }).then((res) => {
                if (res.data.result === "OK") {
                    let order = JSON.parse(JSON.stringify(res.data.order));
                    setSelectedOrder({});
                    setSelectedOrder(order);

                    setSelectedBillToCustomer({ ...order.bill_to_company });
                    setSelectedBillToCustomerContact({ ...(order.bill_to_company?.contacts || []).find((c) => c.is_primary === 1), });

                    let pickup_id = (order.routing || []).find((r) => r.type === "pickup")?.pickup_id || 0;
                    let pickup = { ...((order.pickups || []).find((p) => p.id === pickup_id) || (order.pickups || [])[0]), };

                    setSelectedShipperCustomer(pickup.id === undefined
                        ? {}
                        : {
                            ...pickup.customer,
                            pickup_id: pickup.id,
                            contact_id: pickup.contact_id,
                            contact_name: pickup.contact_name,
                            contact_phone: pickup.contact_phone,
                            contact_primary_phone: pickup.contact_primary_phone,
                            contact_phone_ext: pickup.contact_phone_ext,
                            pu_date1: pickup.pu_date1,
                            pu_date2: pickup.pu_date2,
                            pu_time1: pickup.pu_time1,
                            pu_time2: pickup.pu_time2,
                            bol_numbers: pickup.bol_numbers,
                            po_numbers: pickup.po_numbers,
                            ref_numbers: pickup.ref_numbers,
                            seal_number: pickup.seal_number,
                            special_instructions: pickup.special_instructions,
                            type: pickup.type,
                        }
                    );
                    setSelectedShipperCustomerContact({
                        ...(pickup.contacts || []).find((c) => c.is_primary === 1),
                    });

                    let delivery_id = (order.routing || []).find((r) => r.type === "delivery")?.delivery_id || 0;
                    let delivery = {
                        ...((order.deliveries || []).find((d) => d.id === delivery_id) ||
                            (order.deliveries || [])[0]),
                    };

                    setSelectedConsigneeCustomer(
                        delivery.id === undefined
                            ? {}
                            : {
                                ...delivery.customer,
                                delivery_id: delivery.id,
                                delivery_date1: delivery.delivery_date1,
                                delivery_date2: delivery.delivery_date2,
                                delivery_time1: delivery.delivery_time1,
                                delivery_time2: delivery.delivery_time2,
                                contact_id: delivery.contact_id || "",
                                contact_name: delivery.contact_name || "",
                                contact_phone: delivery.contact_phone || "",
                                contact_primary_phone: delivery.contact_primary_phone || "work",
                                contact_phone_ext: delivery.contact_phone_ext,
                                special_instructions: delivery.special_instructions,
                                type: delivery.type,
                            }
                    );
                    setSelectedConsigneeCustomerContact({
                        ...(delivery.contacts || []).find((c) => c.is_primary === 1),
                    });

                    setSelectedCarrier({ ...order.carrier });
                    setSelectedCarrierContact(order.carrier
                        ? order.carrier_contact_id
                            ? { ...((order.carrier?.contacts || []).find((c) => c.id === order.carrier_contact_id) || {}) }
                            : { ...(order.carrier?.contacts || []).find((c) => c.is_primary === 1) }
                        : {});
                    setSelectedCarrierDriver({
                        ...order.driver,
                        name:
                            (order.driver?.first_name || "") +
                            ((order.driver?.last_name || "").trim() === ""
                                ? ""
                                : " " + (order.driver?.last_name || "")),
                    });

                    setIsLoading(false);

                    if (refDispatchEvents?.current) {
                        console.log(refDispatchEvents)
                        refDispatchEvents.current.focus();
                    }

                    if (props.isOnPanel && refOrderNumber?.current) {
                        refOrderNumber.current.focus({
                            preventScroll: true,
                        });
                    }
                }
            }).catch((e) => {
                console.log("error getting order by id", e);
                setIsLoading(false);
            });
        }

        if (props.isOnPanel && refOrderNumber?.current) {
            refOrderNumber.current.focus({
                preventScroll: true,
            });
        }

        updateSystemDateTime();
    }, []);

    useEffect(() => {
        if (props.refOrderNumber?.current) {
            props.refOrderNumber.current.focus({
                preventScroll: true,
            });
        }
    }, [props.refOrderNumber])

    useEffect(() => {
        if (props.screenFocused) {
            if (props.isOnPanel) {
                refOrderNumber.current.focus({
                    preventScroll: true,
                });
            } else {
                props.refOrderNumber.current.focus({
                    preventScroll: true,
                });
            }
        }
    }, [props.screenFocused]);

    useEffect(() => {
        if ((props.selectedOrder?.component_id || "") !== props.componentId) {
            if ((selectedOrder?.id || 0) > 0 &&
                (props.selectedOrder?.id || 0) > 0 &&
                selectedOrder.id === props.selectedOrder.id) {
                setSelectedOrder((selectedOrder) => {
                    return {
                        ...selectedOrder,
                        ...props.selectedOrder,
                    };
                });
            } else {
                if ((selectedOrder?.id || 0) > 0 &&
                    (selectedOrder?.is_cancelled || 0) === 0 &&
                    (selectedOrder?.order_invoiced || 0) === 0) {
                    if ((props.selectedOrder?.agent_code || '') !== '') {
                        if ((selectedOrder?.bill_to_company?.agent?.code || '') === props.selectedOrder.agent_code) {
                            let currentAgentCommission = selectedOrder?.agent_commission;

                            setSelectedOrder(prev => {
                                return {
                                    ...prev,
                                    bill_to_company: {
                                        ...prev.bill_to_company,
                                        agent: {
                                            ...prev.bill_to_company.agent,
                                            agent_pay_brokerage: props.selectedOrder.agent_pay_brokerage,
                                            agent_pay_et3: props.selectedOrder.agent_pay_et3
                                        }
                                    },
                                    agent_commission: (selectedOrder?.agent_code || '') === (props.selectedOrder.agent_code)
                                        ? (selectedOrder?.user_code?.code || '') === (props.selectedOrder.agent_code)
                                            ? props.selectedOrder?.agent_pay_brokerage || 0
                                            : (props.selectedOrder?.agent_pay_et3 || 0) > 0
                                                ? 100 - props.selectedOrder?.agent_pay_et3
                                                : 0
                                        : currentAgentCommission
                                }
                            })
                        }

                        window.setTimeout(() => {
                            validateOrderForSaving({ keyCode: 9 });
                        }, 10);
                    }

                }




            }
        }
    }, [props.selectedOrder]);

    useEffect(() => {
        if ((props.selectedCustomer?.component_id || "") !== props.componentId) {
            if ((selectedBillToCustomer?.id || 0) > 0 &&
                (props.selectedCustomer?.id || 0) > 0 &&
                selectedBillToCustomer.id === props.selectedCustomer.id) {
                setSelectedBillToCustomer((selectedBillToCustomer) => {
                    return {
                        ...selectedBillToCustomer,
                        ...props.selectedCustomer,
                    };
                });
            }

            if ((selectedShipperCustomer?.id || 0) > 0 &&
                (props.selectedCustomer?.id || 0) > 0 &&
                selectedShipperCustomer.id === props.selectedCustomer.id) {
                setSelectedShipperCustomer((selectedShipperCustomer) => {
                    return {
                        ...selectedShipperCustomer,
                        ...props.selectedCustomer,
                    };
                });

                setSelectedOrder((selectedOrder) => {
                    return {
                        ...selectedOrder,
                        pickups: (selectedOrder.pickups || []).map((item) => {
                            if (item.customer?.id === props.selectedCustomer.id) {
                                item.customer = props.selectedCustomer;
                            }

                            return item;
                        }),
                    };
                });
            }

            if ((selectedConsigneeCustomer?.id || 0) > 0 &&
                (props.selectedCustomer?.id || 0) > 0 &&
                selectedConsigneeCustomer.id === props.selectedCustomer.id) {
                setSelectedConsigneeCustomer((selectedConsigneeCustomer) => {
                    return {
                        ...selectedConsigneeCustomer,
                        ...props.selectedCustomer,
                    };
                });

                setSelectedOrder((selectedOrder) => {
                    return {
                        ...selectedOrder,
                        deliveries: (selectedOrder.deliveries || []).map((item) => {
                            if (item.customer?.id === props.selectedCustomer.id) {
                                item.customer = props.selectedCustomer;
                            }

                            return item;
                        }),
                    };
                });
            }
        }
    }, [props.selectedCustomer]);

    useEffect(() => {
        if ((props.selectedCustomerContact?.component_id || "") !== props.componentId) {
            if ((selectedBillToCustomerContact?.id || 0) > 0 &&
                (props.selectedCustomerContact?.id || 0) > 0 &&
                selectedBillToCustomerContact.id === props.selectedCustomerContact.id) {
                setSelectedBillToCustomerContact((selectedBillToCustomerContact) => {
                    return {
                        ...selectedBillToCustomerContact,
                        ...props.selectedCustomerContact,
                    };
                });
            }

            if ((selectedShipperCustomerContact?.id || 0) > 0 &&
                (props.selectedCustomerContact?.id || 0) > 0 &&
                selectedShipperCustomerContact.id === props.selectedCustomerContact.id) {
                setSelectedShipperCustomerContact((selectedShipperCustomerContact) => {
                    return {
                        ...selectedShipperCustomerContact,
                        ...props.selectedCustomerContact,
                    };
                });
            }

            if ((selectedConsigneeCustomerContact?.id || 0) > 0 &&
                (props.selectedCustomerContact?.id || 0) > 0 &&
                selectedConsigneeCustomerContact.id === props.selectedCustomerContact.id) {
                setSelectedConsigneeCustomerContact(
                    (selectedConsigneeCustomerContact) => {
                        return {
                            ...selectedConsigneeCustomerContact,
                            ...props.selectedCustomerContact,
                        };
                    }
                );
            }
        }
    }, [props.selectedCustomerContact]);

    useEffect(() => {
        if (props.selectedCarrier?.change_carrier || false) {
            setSelectedCarrier({
                ...props.selectedCarrier,
            });
        } else {
            if ((props.selectedCarrier?.component_id || "") !== props.componentId) {
                if (
                    (selectedCarrier?.id || 0) > 0 &&
                    (props.selectedCarrier?.id || 0) > 0 &&
                    selectedCarrier.id === props.selectedCarrier.id
                ) {
                    setSelectedCarrier((selectedCarrier) => {
                        return {
                            ...selectedCarrier,
                            ...props.selectedCarrier,
                        };
                    });
                }
            }
        }
    }, [props.selectedCarrier]);

    useEffect(() => {
        if (props.selectedCarrierContact?.change_carrier || false) {
            setSelectedCarrierContact({
                ...props.selectedCarrierContact,
            });
        } else {
            if (
                (props.selectedCarrierContact?.component_id || "") !== props.componentId
            ) {
                if (
                    (selectedCarrierContact?.id || 0) > 0 &&
                    (props.selectedCarrierContact?.id || 0) > 0 &&
                    selectedCarrierContact.id === props.selectedCarrierContact.id
                ) {
                    setSelectedCarrierContact((selectedCarrierContact) => {
                        return {
                            ...selectedCarrierContact,
                            ...props.selectedCarrierContact,
                        };
                    });
                }
            }
        }
    }, [props.selectedCarrierContact]);

    useEffect(() => {
        if (props.selectedCarrierDriver?.change_carrier || false) {
            setSelectedCarrierDriver({
                ...props.selectedCarrierDriver,
            });
        } else {
            if (
                (props.selectedCarrierDriver?.component_id || "") !== props.componentId
            ) {
                if (
                    (selectedCarrierDriver?.id || 0) > 0 &&
                    (props.selectedCarrierDriver?.id || 0) > 0 &&
                    selectedCarrierDriver.id === props.selectedCarrierDriver.id
                ) {
                    setSelectedCarrierDriver((selectedCarrierDriver) => {
                        return {
                            ...selectedCarrierDriver,
                            ...props.selectedCarrierDriver,
                        };
                    });
                }
            }
        }
    }, [props.selectedCarrierDriver]);

    const [currentSystemDateTime, setCurrentSystemDateTime] = useState(moment());

    const updateSystemDateTime = () => {
        window.setTimeout(() => {
            setCurrentSystemDateTime(moment());
            updateSystemDateTime();
        }, 1000);
    };

    const [puTime1KeyCode, setPuTime1KeyCode] = useState(0);
    const [puTime2KeyCode, setPuTime2KeyCode] = useState(0);
    const [puDate1KeyCode, setPuDate1KeyCode] = useState(0);
    const [puDate2KeyCode, setPuDate2KeyCode] = useState(0);
    const [dispatchEventTimeKeyCode, setDispatchEvebtTimeKeyCode] = useState(0);
    const [deliveryTime1KeyCode, setDeliveryTime1KeyCode] = useState(0);
    const [deliveryTime2KeyCode, setDeliveryTime2KeyCode] = useState(0);
    const [deliveryDate1KeyCode, setDeliveryDate1KeyCode] = useState(0);
    const [deliveryDate2KeyCode, setDeliveryDate2KeyCode] = useState(0);

    const [preSelectedPickupDate1, setPreSelectedPickupDate1] = useState(moment());
    const [preSelectedPickupDate2, setPreSelectedPickupDate2] = useState(moment());
    const [preSelectedDeliveryDate1, setPreSelectedDeliveryDate1] = useState(moment());
    const [preSelectedDeliveryDate2, setPreSelectedDeliveryDate2] = useState(moment());

    const [isPickupDate1CalendarShown, setIsPickupDate1CalendarShown] = useState(false);
    const [isPickupDate2CalendarShown, setIsPickupDate2CalendarShown] = useState(false);
    const [isDeliveryDate1CalendarShown, setIsDeliveryDate1CalendarShown] = useState(false);
    const [isDeliveryDate2CalendarShown, setIsDeliveryDate2CalendarShown] = useState(false);

    const refPickupDate1CalendarDropDown = useDetectClickOutside({
        onTriggered: async () => {
            await setIsPickupDate1CalendarShown(false);
        },
    });
    const refPickupDate2CalendarDropDown = useDetectClickOutside({
        onTriggered: async () => {
            await setIsPickupDate2CalendarShown(false);
        },
    });
    const refDeliveryDate1CalendarDropDown = useDetectClickOutside({
        onTriggered: async () => {
            await setIsDeliveryDate1CalendarShown(false);
        },
    });
    const refDeliveryDate2CalendarDropDown = useDetectClickOutside({
        onTriggered: async () => {
            await setIsDeliveryDate2CalendarShown(false);
        },
    });

    const refPickupDate1 = useRef();
    const refPickupDate2 = useRef();
    const refDeliveryDate1 = useRef();
    const refDeliveryDate2 = useRef();

    const refPickupTime1 = useRef();
    const refPickupTime2 = useRef();
    const refDeliveryTime1 = useRef();
    const refDeliveryTime2 = useRef();

    const refCalendarPickupDate1 = useRef();
    const refCalendarPickupDate2 = useRef();
    const refCalendarDeliveryDate1 = useRef();
    const refCalendarDeliveryDate2 = useRef();

    const refEventDate = useRef();
    const [isDispatchEventDateCalendarShown, setIsDispatchEventDateCalendarShown] = useState(false);
    const [preSelectedDispatchEventDate, setPreSelectedDispatchEventDate] = useState(moment());
    const refDispatchEventDateCalendarDropDown = useDetectClickOutside({
        onTriggered: () => {
            setIsDispatchEventDateCalendarShown(false);
        }
    });

    const [selectedOrderEvent, setSelectedOrderEvent] = useState({});
    const H = window.H;
    const platform = H !== undefined ? new H.service.Platform({
        apikey: "_aKHLFzgJTYQLzsSzVqRKyiKk8iuywH3jbtV8Mxw5Gs",
        app_id: "X4qy0Sva14BQxJCbVqXL",
    }) : undefined;

    const routingService = platform !== undefined ? platform.getRoutingService(null, 8) : undefined;

    const refBolNumbers = useRef();
    const refPoNumbers = useRef();
    const refRefNumbers = useRef();

    const refDeliveryBolNumbers = useRef();
    const refDeliveryPoNumbers = useRef();
    const refDeliveryRefNumbers = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [isSavingOrder, setIsSavingOrder] = useState(false);
    const [isSavingTemplate, setIsSavingTemplate] = useState(false);
    const [isSavingShipperCompanyInfo, setIsSavingShipperCompanyInfo] = useState(false);
    const [isSavingConsigneeCompanyInfo, setIsSavingConsigneeCompanyInfo] = useState(false);
    const [isSavingPickupId, setIsSavingPickupId] = useState(-1);
    const [isSavingDeliveryId, setIsSavingDeliveryId] = useState(-1);

    const [isSavingCarrierDriver, setIsSavingCarrierDriver] = useState(false);

    const [showingChangeCarrier, setShowingChangeCarrier] = useState(false);

    const refDivision = useRef();
    const refLoadType = useRef();
    const refAgentCode = useRef();
    const refTemplate = useRef();
    const refCarrierCode = useRef();
    const refEquipment = useRef();
    const refDriverName = useRef();
    const refDriverPhone = useRef();
    const refDispatchEvents = useRef();
    const refEventTime = useRef();

    const [divisionItems, setDivisionItems] = useState([]);
    const refDivisionDropDown = useDetectClickOutside({
        onTriggered: async () => {
            await setDivisionItems([]);
        },
    });
    const refDivisionPopupItems = useRef([]);

    const [loadTypeItems, setLoadTypeItems] = useState([]);
    const refLoadTypeDropDown = useDetectClickOutside({
        onTriggered: async () => {
            await setLoadTypeItems([]);
        },
    });
    const refLoadTypePopupItems = useRef([]);

    const refProfits = useRef()
    const [showProfitItems, setShowProfitItems] = useState(false)
    const refProfitDropDown = useDetectClickOutside({
        onTriggered: async () => {
            await setShowProfitItems(false);
        },
    });
    const refProfitPopupItems = useRef([]);

    const [agentCodeItems, setAgentCodeItems] = useState([])
    const refAgentCodeDropDown = useDetectClickOutside({
        onTriggered: async () => {
            await setAgentCodeItems([]);
        },
    });
    const refAgentCodePopupItems = useRef([]);

    const [templateItems, setTemplateItems] = useState([]);
    const refTemplateDropDown = useDetectClickOutside({
        onTriggered: async () => {
            await setTemplateItems([]);
        },
    });
    const refTemplatePopupItems = useRef([]);

    const [equipmentItems, setEquipmentItems] = useState([]);
    const refEquipmentDropDown = useDetectClickOutside({
        onTriggered: async () => {
            await setEquipmentItems([]);
        },
    });
    const refEquipmentPopupItems = useRef([]);

    const refDriverDropDown = useDetectClickOutside({
        onTriggered: async () => {
            await setDriverItems([]);
        },
    });
    const refDriverPopupItems = useRef([]);

    const refBillToCompanyCode = useRef();
    const refShipperCompanyCode = useRef();
    const refConsigneeCompanyCode = useRef();

    const [dispatchEventItems, setDispatchEventItems] = useState([]);

    const [tempDispatchEventItems, setTempDispatchEventItems] = useState([]);

    const [dispatchEventSecondPageItems, setDispatchEventSecondPageItems] = useState([]);
    const [showDispatchEventItems, setShowDispatchEventItems] = useState(false);
    const [showDispatchEventSecondPageItems, setShowDispatchEventSecondPageItems,] = useState(false);
    const refDispatchEventDropDown = useDetectClickOutside({
        onTriggered: () => {
            setDispatchEventItems([]);
        },
    });
    const refDispatchEventPopupItems = useRef([]);
    const refDispatchEventSecondPagePopupItems = useRef([]);

    const refCarrierContactName = useRef();
    const [carrierContactNameItems, setCarrierContactNameItems] = useState([]);
    const [showCarrierContactNames, setShowCarrierContactNames] = useState(false);
    const refCarrierContactNameDropDown = useDetectClickOutside({
        onTriggered: async () => {
            await setShowCarrierContactNames(false)
        }
    });
    const refCarrierContactNamePopupItems = useRef([]);

    const refCarrierContactPhone = useRef();
    const [carrierContactPhoneItems, setCarrierContactPhoneItems] = useState([]);
    const [showCarrierContactPhones, setShowCarrierContactPhones] = useState(false);
    const refCarrierContactPhoneDropDown = useDetectClickOutside({
        onTriggered: async () => {
            await setShowCarrierContactPhones(false)
        }
    });
    const refCarrierContactPhonePopupItems = useRef([]);

    const [isAddingNewShipper, setIsAddingNewShipper] = useState(false)
    const [isAddingNewConsignee, setIsAddingNewConsignee] = useState(false)

    const loadingTransition = useTransition(isLoading, {
        from: { opacity: 0, display: "block" },
        enter: { opacity: 1, display: "block" },
        leave: { opacity: 0, display: "none" },
        reverse: isLoading,
    });

    const carrierContactNamesTransition = useTransition(showCarrierContactNames, {
        from: { opacity: 0, top: 'calc(100% + 7px)' },
        enter: { opacity: 1, top: 'calc(100% + 12px)' },
        leave: { opacity: 0, top: 'calc(100% + 7px)' },
        config: { duration: 100 },
        reverse: showCarrierContactNames
    });

    const carrierContactPhonesTransition = useTransition(showCarrierContactPhones, {
        from: { opacity: 0, top: 'calc(100% + 7px)' },
        enter: { opacity: 1, top: 'calc(100% + 12px)' },
        leave: { opacity: 0, top: 'calc(100% + 7px)' },
        config: { duration: 100 },
        reverse: showCarrierContactPhones
    });

    const divisionTransition = useTransition(divisionItems.length > 0, {
        from: { opacity: 0, top: "calc(100% + 7px)" },
        enter: { opacity: 1, top: "calc(100% + 12px)" },
        leave: { opacity: 0, top: "calc(100% + 7px)" },
        config: { duration: 100 },
        reverse: divisionItems.length > 0,
    });

    const loadTypeTransition = useTransition(loadTypeItems.length > 0, {
        from: { opacity: 0, top: "calc(100% + 7px)" },
        enter: { opacity: 1, top: "calc(100% + 12px)" },
        leave: { opacity: 0, top: "calc(100% + 7px)" },
        config: { duration: 100 },
        reverse: loadTypeItems.length > 0,
    });

    const profitTransition = useTransition(showProfitItems, {
        from: { opacity: 0, top: "calc(100% + 7px)" },
        enter: { opacity: 1, top: "calc(100% + 12px)" },
        leave: { opacity: 0, top: "calc(100% + 7px)" },
        config: { duration: 100 },
        reverse: showProfitItems,
    });

    const agentCodeTransition = useTransition(agentCodeItems.length > 0, {
        from: { opacity: 0, top: "calc(100% + 7px)" },
        enter: { opacity: 1, top: "calc(100% + 12px)" },
        leave: { opacity: 0, top: "calc(100% + 7px)" },
        config: { duration: 100 },
        reverse: agentCodeItems.length > 0,
    });

    const templateTransition = useTransition(templateItems.length > 0, {
        from: { opacity: 0, top: "calc(100% + 7px)" },
        enter: { opacity: 1, top: "calc(100% + 12px)" },
        leave: { opacity: 0, top: "calc(100% + 7px)" },
        config: { duration: 100 },
        reverse: templateItems.length > 0,
    });

    const equipmentTransition = useTransition(equipmentItems.length > 0, {
        from: { opacity: 0, top: "calc(100% + 7px)" },
        enter: { opacity: 1, top: "calc(100% + 12px)" },
        leave: { opacity: 0, top: "calc(100% + 7px)" },
        config: { duration: 100 },
        reverse: equipmentItems.length > 0,
    });

    const driverTransition = useTransition(driverItems.length > 0, {
        from: { opacity: 0, top: "calc(100% + 7px)" },
        enter: { opacity: 1, top: "calc(100% + 12px)" },
        leave: { opacity: 0, top: "calc(100% + 7px)" },
        config: { duration: 100 },
        reverse: driverItems.length > 0,
    });

    const shipperFirstPageTransition = useTransition(!isShowingShipperSecondPage, {
        from: { opacity: 0, left: "0%", width: "0%" },
        enter: { opacity: 1, left: "0%", width: "100%" },
        leave: { opacity: 0, left: "0%", width: "0%" },
        config: { duration: 300 },
        reverse: !isShowingShipperSecondPage,
    });

    const shipperSecondPageTransition = useTransition(isShowingShipperSecondPage, {
        from: { opacity: 0, left: "100%", width: "0%" },
        enter: { opacity: 1, left: "0%", width: "100%" },
        leave: { opacity: 0, left: "100%", width: "0%" },
        config: { duration: 300 },
        reverse: isShowingShipperSecondPage,
    });

    const shipperContactNamesTransition = useTransition(showShipperContactNames, {
        from: { opacity: 0, top: 'calc(100% + 7px)' },
        enter: { opacity: 1, top: 'calc(100% + 12px)' },
        leave: { opacity: 0, top: 'calc(100% + 7px)' },
        config: { duration: 100 },
        reverse: showShipperContactNames
    });

    const shipperContactPhonesTransition = useTransition(showShipperContactPhones, {
        from: { opacity: 0, top: 'calc(100% + 7px)' },
        enter: { opacity: 1, top: 'calc(100% + 12px)' },
        leave: { opacity: 0, top: 'calc(100% + 7px)' },
        config: { duration: 100 },
        reverse: showShipperContactPhones
    });

    const consigneeFirstPageTransition = useTransition(!isShowingConsigneeSecondPage, {
        from: { opacity: 0, left: "0%", width: "0%" },
        enter: { opacity: 1, left: "0%", width: "100%" },
        leave: { opacity: 0, left: "0%", width: "0%" },
        config: { duration: 300 },
        reverse: !isShowingConsigneeSecondPage,
    });

    const consigneeSecondPageTransition = useTransition(isShowingConsigneeSecondPage, {
        from: { opacity: 0, left: "100%", width: "0%" },
        enter: { opacity: 1, left: "0%", width: "100%" },
        leave: { opacity: 0, left: "100%", width: "0%" },
        config: { duration: 300 },
        reverse: isShowingConsigneeSecondPage,
    });

    const consigneeContactNamesTransition = useTransition(showConsigneeContactNames, {
        from: { opacity: 0, top: 'calc(100% + 7px)' },
        enter: { opacity: 1, top: 'calc(100% + 12px)' },
        leave: { opacity: 0, top: 'calc(100% + 7px)' },
        config: { duration: 100 },
        reverse: showConsigneeContactNames
    });

    const consigneeContactPhonesTransition = useTransition(showConsigneeContactPhones, {
        from: { opacity: 0, top: 'calc(100% + 7px)' },
        enter: { opacity: 1, top: 'calc(100% + 12px)' },
        leave: { opacity: 0, top: 'calc(100% + 7px)' },
        config: { duration: 100 },
        reverse: showConsigneeContactPhones
    });

    const puDate1Transition = useTransition(isPickupDate1CalendarShown, {
        from: { opacity: 0, display: "block", top: "calc(100% + 7px)" },
        enter: { opacity: 1, display: "block", top: "calc(100% + 12px)" },
        leave: { opacity: 0, display: "none", top: "calc(100% + 7px)" },
        reverse: isPickupDate1CalendarShown,
        config: { duration: 100 },
    });

    const puDate2Transition = useTransition(isPickupDate2CalendarShown, {
        from: { opacity: 0, display: "block", top: "calc(100% + 7px)" },
        enter: { opacity: 1, display: "block", top: "calc(100% + 12px)" },
        leave: { opacity: 0, display: "none", top: "calc(100% + 7px)" },
        reverse: isPickupDate2CalendarShown,
        config: { duration: 100 },
    });

    const deliveryDate1Transition = useTransition(isDeliveryDate1CalendarShown, {
        from: { opacity: 0, display: "block", top: "calc(100% + 7px)" },
        enter: { opacity: 1, display: "block", top: "calc(100% + 12px)" },
        leave: { opacity: 0, display: "none", top: "calc(100% + 7px)" },
        reverse: isDeliveryDate1CalendarShown,
        config: { duration: 100 },
    });

    const deliveryDate2Transition = useTransition(isDeliveryDate2CalendarShown, {
        from: { opacity: 0, display: "block", top: "calc(100% + 7px)" },
        enter: { opacity: 1, display: "block", top: "calc(100% + 12px)" },
        leave: { opacity: 0, display: "none", top: "calc(100% + 7px)" },
        reverse: isDeliveryDate2CalendarShown,
        config: { duration: 100 },
    });

    const eventTransition = useTransition(dispatchEventItems.length > 0, {
        from: { opacity: 0, top: "calc(100% + 7px)" },
        enter: { opacity: 1, top: "calc(100% + 12px)" },
        leave: { opacity: 0, top: "calc(100% + 7px)" },
        config: { duration: 100 },
        reverse: dispatchEventItems.length > 0,
    });

    const dateEventTransition = useTransition(isDispatchEventDateCalendarShown, {
        from: { opacity: 0, display: "block", top: "calc(100% + 7px)" },
        enter: { opacity: 1, display: "block", top: "calc(100% + 12px)" },
        leave: { opacity: 0, display: "none", top: "calc(100% + 7px)" },
        reverse: isDispatchEventDateCalendarShown,
        config: { duration: 100 },
    });

    const changeCarrierTransition = useTransition(showingChangeCarrier, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        reverse: showingChangeCarrier,
        config: { duration: 100 },
    });

    const noteForDriverTransition = useTransition(selectedNoteForDriver?.id !== undefined, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        reverse: selectedNoteForDriver?.id !== undefined,
        config: { duration: 100 },
    });

    const noteForCarrierTransition = useTransition(selectedNoteForCarrier?.id !== undefined, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        reverse: selectedNoteForCarrier?.id !== undefined,
        config: { duration: 100 },
    });

    const internalNoteTransition = useTransition(selectedInternalNote?.id !== undefined, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        reverse: selectedInternalNote?.id !== undefined,
        config: { duration: 100 },
    });

    useEffect(() => {
        if (dispatchEventItems.length === 0) {
            setShowDispatchEventSecondPageItems(false);
            setDispatchEventSecondPageItems([]);
        }
    }, [dispatchEventItems]);

    useEffect(() => {
        let phones = [];

        if (selectedShipperCustomer?.contact_id) {
            let contact = (selectedShipperCustomer?.contacts || []).find(x => x.id === selectedShipperCustomer.contact_id);

            if ((contact?.phone_work || '') !== '') {
                phones.push({
                    id: 1,
                    type: 'work',
                    phone: contact.phone_work
                })
            }

            if ((contact?.phone_work_fax || '') !== '') {
                phones.push({
                    id: 2,
                    type: 'fax',
                    phone: contact.phone_work_fax
                })
            }

            if ((contact?.phone_mobile || '') !== '') {
                phones.push({
                    id: 3,
                    type: 'mobile',
                    phone: contact.phone_mobile
                })
            }

            if ((contact?.phone_direct || '') !== '') {
                phones.push({
                    id: 4,
                    type: 'direct',
                    phone: contact.phone_direct
                })
            }

            if ((contact?.phone_other || '') !== '') {
                phones.push({
                    id: 5,
                    type: 'other',
                    phone: contact.phone_other
                })
            }
        } else if ((selectedShipperCustomer?.contacts || []).find(x => x.is_primary === 1)) {
            let contact = (selectedShipperCustomer?.contacts || []).find(x => x.is_primary === 1);

            if ((contact?.phone_work || '') !== '') {
                phones.push({
                    id: 1,
                    type: 'work',
                    phone: contact.phone_work
                })
            }

            if ((contact?.phone_work_fax || '') !== '') {
                phones.push({
                    id: 2,
                    type: 'fax',
                    phone: contact.phone_work_fax
                })
            }

            if ((contact?.phone_mobile || '') !== '') {
                phones.push({
                    id: 3,
                    type: 'mobile',
                    phone: contact.phone_mobile
                })
            }

            if ((contact?.phone_direct || '') !== '') {
                phones.push({
                    id: 4,
                    type: 'direct',
                    phone: contact.phone_direct
                })
            }

            if ((contact?.phone_other || '') !== '') {
                phones.push({
                    id: 5,
                    type: 'other',
                    phone: contact.phone_other
                })
            }
        }

        setShipperContactPhoneItems(phones);

    }, [selectedShipperCustomer?.contact_id])

    useEffect(() => {
        let phones = [];

        if (selectedConsigneeCustomer?.contact_id) {
            let contact = (selectedConsigneeCustomer?.contacts || []).find(x => x.id === selectedConsigneeCustomer.contact_id);

            if ((contact?.phone_work || '') !== '') {
                phones.push({
                    id: 1,
                    type: 'work',
                    phone: contact.phone_work
                })
            }

            if ((contact?.phone_work_fax || '') !== '') {
                phones.push({
                    id: 2,
                    type: 'fax',
                    phone: contact.phone_work_fax
                })
            }

            if ((contact?.phone_mobile || '') !== '') {
                phones.push({
                    id: 3,
                    type: 'mobile',
                    phone: contact.phone_mobile
                })
            }

            if ((contact?.phone_direct || '') !== '') {
                phones.push({
                    id: 4,
                    type: 'direct',
                    phone: contact.phone_direct
                })
            }

            if ((contact?.phone_other || '') !== '') {
                phones.push({
                    id: 5,
                    type: 'other',
                    phone: contact.phone_other
                })
            }
        } else if ((selectedConsigneeCustomer?.contacts || []).find(x => x.is_primary === 1)) {
            let contact = (selectedConsigneeCustomer?.contacts || []).find(x => x.is_primary === 1);

            if ((contact?.phone_work || '') !== '') {
                phones.push({
                    id: 1,
                    type: 'work',
                    phone: contact.phone_work
                })
            }

            if ((contact?.phone_work_fax || '') !== '') {
                phones.push({
                    id: 2,
                    type: 'fax',
                    phone: contact.phone_work_fax
                })
            }

            if ((contact?.phone_mobile || '') !== '') {
                phones.push({
                    id: 3,
                    type: 'mobile',
                    phone: contact.phone_mobile
                })
            }

            if ((contact?.phone_direct || '') !== '') {
                phones.push({
                    id: 4,
                    type: 'direct',
                    phone: contact.phone_direct
                })
            }

            if ((contact?.phone_other || '') !== '') {
                phones.push({
                    id: 5,
                    type: 'other',
                    phone: contact.phone_other
                })
            }
        }

        setConsigneeContactPhoneItems(phones);

    }, [selectedConsigneeCustomer?.contact_id])

    useEffect(() => {
        let phones = [];
        (selectedCarrierContact?.phone_work || '') !== '' && phones.push({
            id: 1,
            type: 'work',
            phone: selectedCarrierContact.phone_work
        });
        (selectedCarrierContact?.phone_work_fax || '') !== '' && phones.push({
            id: 2,
            type: 'fax',
            phone: selectedCarrierContact.phone_work_fax
        });
        (selectedCarrierContact?.phone_mobile || '') !== '' && phones.push({
            id: 3,
            type: 'mobile',
            phone: selectedCarrierContact.phone_mobile
        });
        (selectedCarrierContact?.phone_direct || '') !== '' && phones.push({
            id: 4,
            type: 'direct',
            phone: selectedCarrierContact.phone_direct
        });
        (selectedCarrierContact?.phone_other || '') !== '' && phones.push({
            id: 5,
            type: 'other',
            phone: selectedCarrierContact.phone_other
        });

        setCarrierContactPhoneItems(phones);
    }, [
        selectedCarrierContact?.phone_work,
        selectedCarrierContact?.phone_work_fax,
        selectedCarrierContact?.phone_mobile,
        selectedCarrierContact?.phone_direct,
        selectedCarrierContact?.phone_other,
        selectedCarrierContact?.primary_phone
    ]);

    const dispatchClearBtnClick = () => {
        setSelectedOrder({});

        setSelectedBillToCustomer({});
        setSelectedBillToCustomerContact({});
        setSelectedShipperCustomer({});
        setSelectedShipperCustomerContact({});
        setSelectedConsigneeCustomer({});
        setSelectedConsigneeCustomerContact({});

        setShipperBolNumber("");
        setShipperPoNumber("");
        setShipperRefNumber("");

        setDispatchEvent({});
        setSelectedOrderEvent({});
        setDispatchEventLocation("");
        setDispatchEventNotes("");

        setSelectedNoteForCarrier({});
        setSelectedInternalNote({});
        setIsShowingShipperSecondPage(false);
        setIsShowingConsigneeSecondPage(false);

        setSelectedCarrier({});
        setSelectedCarrierDriver({});
        setSelectedCarrierInsurance({});
        setSelectedCarrierContact({});

        setIsCreatingTemplate(false);
        setIsEditingTemplate(false);

        if (props.isOnPanel) {
            if (refOrderNumber?.current) {
                refOrderNumber.current.focus({
                    preventScroll: true,
                });
            }
        } else {
            if (props.refOrderNumber?.current) {
                props.refOrderNumber.current.focus({
                    preventScroll: true,
                });
            }
        }
    };

    const getBillToCompanyByCode = (e) => {
        let key = e.keyCode || e.which;

        if (key === 9) {
            if (e.target.value !== "") {
                axios.post(props.serverUrl + "/customers", {
                    code: e.target.value.toLowerCase(),
                    user_code: (props.user?.user_code?.type || 'employee') === 'agent' ? props.user.user_code.code : ''
                }).then(async (res) => {
                    if (res.data.result === "OK") {
                        if (res.data.customers.length > 0) {
                            setSelectedBillToCustomer(res.data.customers[0]);
                            setSelectedBillToCustomerContact(
                                (res.data.customers[0].contacts || []).find(
                                    (c) => c.is_primary === 1
                                ) || {}
                            );

                            let selected_order = { ...selectedOrder } || {
                                order_number: 0,
                            };
                            selected_order.bill_to_customer_id = res.data.customers[0].id;

                            setSelectedOrder(selected_order);

                            validateOrderForSaving({ keyCode: 9 });
                        } else {
                            setSelectedBillToCustomer({});
                            setSelectedBillToCustomerContact({});
                        }
                    } else {
                        setSelectedBillToCustomer({});
                        setSelectedBillToCustomerContact({});
                    }
                }).catch((e) => {
                    console.log("error getting customers", e);
                });
            } else {
                setSelectedBillToCustomer({});
                setSelectedBillToCustomerContact({});
            }
        }
    };

    const getShipperCompanyByCode = (e) => {
        let key = e.keyCode || e.which;

        if (key === 9) {
            if (e.target.value !== "") {
                axios.post(props.serverUrl + "/customers", {
                    code: e.target.value.toLowerCase(),
                    user_code: (props.user?.user_code?.type || 'employee') === 'agent' ? props.user.user_code.code : ''
                }).then((res) => {
                    if (res.data.result === "OK") {
                        if (res.data.customers.length > 0) {
                            if ((selectedOrder?.id || 0) > 0) {
                                setSelectedOrder((selectedOrder) => {
                                    return {
                                        ...selectedOrder,
                                        pickups: (selectedOrder?.pickups || []).map((p, i) => {
                                            if (p.id === (selectedShipperCustomer?.pickup_id || 0)) {
                                                p.order_id = selectedOrder.id;
                                                p.customer = res.data.customers[0];
                                                p.customer_id = res.data.customers[0].id;

                                                setSelectedShipperCustomer(prev => {
                                                    return {
                                                        ...prev,
                                                        ...res.data.customers[0],
                                                        ...p,
                                                        customer: {},
                                                        pickup_id: p.id
                                                    }
                                                });

                                                setSelectedShipperCustomerContact((res.data.customers[0].contacts || []).find((c) => c.is_primary === 1) || {});

                                                setIsSavingPickupId(p.id);
                                            }
                                            return p;
                                        }),
                                    };
                                });

                                new Promise((resolve, reject) => {
                                    setIsShowingShipperSecondPage(true);
                                    resolve("OK");
                                }).then((response) => {
                                    refPickupDate1.current.inputElement.focus();
                                }).catch((e) => {
                                });
                            } else {
                                setSelectedOrder((selectedOrder) => {
                                    return {
                                        ...selectedOrder,
                                        pickups: (selectedOrder?.pickups || []).map((p, i) => {
                                            if (p.id === (selectedShipperCustomer?.pickup_id || 0)) {
                                                p.customer = res.data.customers[0];
                                                p.customer_id = res.data.customers[0].id;

                                                setSelectedShipperCustomer({
                                                    ...res.data.customers[0],
                                                    ...p,
                                                    customer: {},
                                                    pickup_id: p.id,
                                                });

                                                setSelectedShipperCustomerContact((res.data.customers[0].contacts || []).find((c) => c.is_primary === 1) || {});
                                            }
                                            return p;
                                        }),
                                    };
                                });

                                if ((selectedOrder?.pickups || []).length <= 1 && (selectedBillToCustomer?.id || 0) === 0) {
                                    if ((res.data.customers[0]?.bill_to_code || "") !== "") {
                                        axios.post(props.serverUrl + "/customers", {
                                            code: (res.data.customers[0]?.bill_to_code || "") + ((res.data.customers[0]?.bill_to_code_number || 0) === 0 ? "" : res.data.customers[0].bill_to_code_number),
                                            user_code: (props.user?.user_code?.type || 'employee') === 'agent' ? props.user.user_code.code : ''
                                        }).then((res) => {
                                            if (res.data.result === "OK") {
                                                if (res.data.customers.length > 0) {
                                                    setSelectedBillToCustomer(res.data.customers[0]);
                                                    setSelectedBillToCustomerContact((res.data.customers[0].contacts || []).find((c) => c.is_primary === 1) || {});

                                                    setSelectedOrder({
                                                        ...selectedOrder,
                                                        bill_to_customer_id: res.data.customers[0].id,
                                                        pickups: (selectedOrder?.pickups || []).map((pickup) => {
                                                            pickup.toSave = pickup.id === 0;
                                                            return pickup;
                                                        })
                                                    });

                                                    validateOrderForSaving({ keyCode: 9 });
                                                }

                                                refPickupDate1.current.inputElement.focus();
                                            }
                                        }).catch((e) => {
                                            console.log("error getting customers", e);
                                        });
                                    } else {
                                        refPickupDate1.current.inputElement.focus();
                                    }
                                } else {
                                    refPickupDate1.current.inputElement.focus();
                                }
                            }
                        } else {
                            setSelectedShipperCustomer({});
                            setSelectedShipperCustomerContact({});
                        }
                    } else {
                        setSelectedShipperCustomer({});
                        setSelectedShipperCustomerContact({});
                    }
                }).catch((e) => {
                    console.log("error getting customers", e);
                });
            }
        }
    };

    const getConsigneeCompanyByCode = (e) => {
        let key = e.keyCode || e.which;

        if (key === 9) {
            if (e.target.value !== "") {
                if ((selectedOrder?.id || 0) === 0) {
                    e.preventDefault();
                    window.alert("You must create or load an order first!");
                    setSelectedConsigneeCustomer({});
                    setSelectedConsigneeCustomerContact({});
                    setSelectedOrder((selectedOrder) => {
                        return { ...selectedOrder, deliveries: [] };
                    });
                    return;
                }

                setIsLoading(true);
                axios.post(props.serverUrl + "/customers", {
                    code: e.target.value.toLowerCase(),
                    user_code: (props.user?.user_code?.type || 'employee') === 'agent' ? props.user.user_code.code : ''
                }).then(async (res) => {
                    if (res.data.result === "OK") {
                        if (res.data.customers.length > 0) {
                            let delivery_id = -1;

                            setSelectedOrder({
                                ...selectedOrder,
                                deliveries: (selectedOrder?.deliveries || []).map((d, i) => {
                                    if (d.id === (selectedConsigneeCustomer?.delivery_id || 0)) {
                                        d.order_id = selectedOrder.id;
                                        d.customer = res.data.customers[0];
                                        d.customer_id = res.data.customers[0].id;

                                        let consignee = { ...res.data.customers[0] };
                                        consignee = { ...consignee, ...d };
                                        consignee.customer = {};
                                        consignee.delivery_id = d.id;

                                        setSelectedConsigneeCustomer(prev => {
                                            return {
                                                ...prev,
                                                ...consignee
                                            }
                                        });
                                        setSelectedConsigneeCustomerContact(
                                            (consignee.contacts || []).find(
                                                (c) => c.is_primary === 1
                                            ) || {}
                                        );

                                        delivery_id = d.id;
                                    }
                                    return d;
                                }),
                            });

                            setIsSavingDeliveryId(delivery_id);

                            new Promise((resolve, reject) => {
                                setIsShowingConsigneeSecondPage(true);
                                resolve(true);
                            }).then((response) => {
                                refDeliveryDate1.current.inputElement.focus();
                            }).catch((e) => {

                            });
                        } else {
                            setSelectedConsigneeCustomer({});
                            setSelectedConsigneeCustomerContact({});
                        }
                    } else {
                        setSelectedConsigneeCustomer({});
                        setSelectedConsigneeCustomerContact({});
                    }

                    setIsLoading(false);
                })
                    .catch((e) => {
                        console.log("error getting customers", e);
                        setIsLoading(false);
                    });
            }
        }
    };

    const billToCompanySearch = () => {
        let companySearch = [
            {
                field: "Code",
                data: (selectedBillToCustomer?.code || "").toLowerCase(),
            },
            {
                field: "Name",
                data: (selectedBillToCustomer?.name || "").toLowerCase(),
            },
            {
                field: "City",
                data: (selectedBillToCustomer?.city || "").toLowerCase(),
            },
            {
                field: "State",
                data: (selectedBillToCustomer?.state || "").toLowerCase(),
            },
            {
                field: "Postal Code",
                data: selectedBillToCustomer?.zip || "",
            },
            {
                field: "Contact Name",
                data: (selectedBillToCustomer?.contact_name || "").toLowerCase(),
            },
            {
                field: "Contact Phone",
                data: selectedBillToCustomer?.contact_phone || "",
            },
            {
                field: "E-Mail",
                data: (selectedBillToCustomer?.email || "").toLowerCase(),
            },
            {
                field: 'User Code',
                data: (props.user?.user_code?.type || 'employee') === 'agent' ? props.user.user_code.code : ''
            }
        ];

        let panel = {
            panelName: `${props.panelName}-customer-search`,
            component: (
                <CustomerSearch
                    title="Customer Search Results"
                    tabTimes={29000}
                    panelName={`${props.panelName}-customer-search`}
                    origin={props.origin}
                    closingCallback={() => {
                        closePanel(`${props.panelName}-customer-search`, props.origin)
                            (props.isOnPanel ? refOrderNumber : props.refOrderNumber).current.focus({ preventScroll: true });
                    }}

                    componentId={moment().format("x")}
                    customerSearch={companySearch}
                    callback={(id) => {
                        new Promise((resolve, reject) => {
                            if ((id || 0) > 0) {
                                axios.post(props.serverUrl + '/getCustomerById', { id: id }).then(res => {
                                    if (res.data.result === 'OK') {
                                        let customer = res.data.customer;

                                        if (customer) {
                                            setSelectedBillToCustomer(customer);
                                            setSelectedBillToCustomerContact((customer.contacts || []).find((c) => c.is_primary === 1) || {});

                                            let selected_order = { ...selectedOrder } || {
                                                order_number: 0,
                                            };
                                            selected_order.bill_to_customer_id = customer.id;

                                            setSelectedOrder(selected_order);

                                            setIsLoading(true);
                                            validateOrderForSaving({ keyCode: 9 });
                                            refShipperCompanyCode.current.focus();

                                            resolve("OK");
                                        } else {
                                            reject("no customer");
                                        }
                                    } else {
                                        reject("no customer");
                                    }
                                }).catch(e => {
                                    reject("no customer");
                                })
                            } else {
                                reject("no customer");
                            }
                        }).then((response) => {
                            if (response === "OK") {
                                closePanel(`${props.panelName}-customer-search`, props.origin);
                            }
                        }).catch((e) => {
                            closePanel(`${props.panelName}-customer-search`, props.origin);
                            setSelectedBillToCustomer({});
                            setSelectedBillToCustomerContact({});
                            refBillToCompanyCode.current.focus();
                        });
                    }}
                />
            ),
        };

        openPanel(panel, props.origin);
    };

    const shipperCompanySearch = () => {
        let companySearch = [
            {
                field: 'Code',
                data: (selectedShipperCustomer?.code || '').toLowerCase()
            },
            {
                field: "Name",
                data: (selectedShipperCustomer?.name || "").toLowerCase(),
            },
            {
                field: "City",
                data: (selectedShipperCustomer?.city || "").toLowerCase(),
            },
            {
                field: "State",
                data: (selectedShipperCustomer?.state || "").toLowerCase(),
            },
            {
                field: "Postal Code",
                data: selectedShipperCustomer?.zip || "",
            },
            {
                field: "Contact Name",
                data: (selectedShipperCustomer?.contact_name || "").toLowerCase(),
            },
            {
                field: "Contact Phone",
                data: selectedShipperCustomer?.contact_phone || "",
            },
            {
                field: "E-Mail",
                data: (selectedShipperCustomer?.email || "").toLowerCase(),
            },
            {
                field: 'User Code',
                data: (props.user?.user_code?.type || 'employee') === 'agent' ? props.user.user_code.code : ''
            }
        ];

        let panel = {
            panelName: `${props.panelName}-customer-search`,
            component: (
                <CustomerSearch
                    title="Customer Search Results"
                    tabTimes={29000}
                    panelName={`${props.panelName}-customer-search`}
                    origin={props.origin}
                    componentId={moment().format("x")}
                    customerSearch={companySearch}
                    callback={(id) => {
                        new Promise((resolve, reject) => {
                            if ((id || 0) > 0) {
                                axios.post(props.serverUrl + '/getCustomerById', { id: id }).then(res => {
                                    if (res.data.result === 'OK') {
                                        let customer = res.data.customer;

                                        if (customer) {
                                            if ((selectedOrder?.id || 0) > 0) {
                                                setSelectedOrder((selectedOrder) => {
                                                    return {
                                                        ...selectedOrder,
                                                        pickups: (selectedOrder?.pickups || []).map((p, i) => {
                                                            if (p.id === (selectedShipperCustomer?.pickup_id || 0)) {
                                                                p.order_id = selectedOrder.id;
                                                                p.customer = customer;
                                                                p.customer_id = customer.id;

                                                                setSelectedShipperCustomer({
                                                                    ...customer,
                                                                    ...p,
                                                                    customer: {},
                                                                    pickup_id: p.id,
                                                                });

                                                                setSelectedShipperCustomerContact((customer.contacts || []).find((c) => c.is_primary === 1) || {});
                                                                setIsSavingPickupId(p.id);
                                                            }
                                                            return p;
                                                        }),
                                                    };
                                                });

                                                setIsShowingShipperSecondPage(true);
                                            } else {
                                                if ((selectedOrder?.pickups || []).length === 0) {
                                                    setSelectedOrder((selectedOrder) => {
                                                        return {
                                                            ...selectedOrder,
                                                            pickups: [
                                                                {
                                                                    id: 0,
                                                                    customer: { ...customer },
                                                                    customer_id: customer.id,
                                                                },
                                                            ],
                                                        };
                                                    });

                                                    setSelectedShipperCustomer({
                                                        ...customer,
                                                        customer: {},
                                                        pickup_id: 0,
                                                    });

                                                    setSelectedShipperCustomerContact(
                                                        (customer.contacts || []).find(
                                                            (c) => c.is_primary === 1
                                                        ) || {}
                                                    );
                                                } else {
                                                    setSelectedOrder((selectedOrder) => {
                                                        return {
                                                            ...selectedOrder,
                                                            pickups: (selectedOrder?.pickups || []).map((p, i) => {
                                                                if (
                                                                    p.id === (selectedShipperCustomer?.pickup_id || 0)
                                                                ) {
                                                                    p.customer = customer;
                                                                    p.customer_id = customer.id;

                                                                    setSelectedShipperCustomer({
                                                                        ...customer,
                                                                        ...p,
                                                                        customer: {},
                                                                        pickup_id: p.id,
                                                                    });

                                                                    setSelectedShipperCustomerContact(
                                                                        (customer.contacts || []).find(
                                                                            (c) => c.is_primary === 1
                                                                        ) || {}
                                                                    );
                                                                }
                                                                return p;
                                                            }),
                                                        };
                                                    });
                                                }

                                                if (
                                                    (selectedOrder?.pickups || []).length <= 1 &&
                                                    (selectedBillToCustomer?.id || 0) === 0
                                                ) {
                                                    if ((customer?.bill_to_code || "") !== "") {
                                                        setIsLoading(true);
                                                        axios.post(props.serverUrl + "/customers", {
                                                            code: (customer?.bill_to_code || "") + ((customer?.bill_to_code_number || 0) === 0 ? "" : customer.bill_to_code_number),
                                                            user_code: (props.user?.user_code?.type || 'employee') === 'agent' ? props.user.user_code.code : ''
                                                        }).then((res) => {
                                                            if (res.data.result === "OK") {
                                                                if (res.data.customers.length > 0) {
                                                                    setSelectedBillToCustomer(res.data.customers[0]);
                                                                    setSelectedBillToCustomerContact((res.data.customers[0].contacts || []).find((c) => c.is_primary === 1) || {});

                                                                    setSelectedOrder((selectedOrder) => {
                                                                        return {
                                                                            ...selectedOrder,
                                                                            bill_to_customer_id: res.data.customers[0].id,
                                                                            pickups: (selectedOrder?.pickups || []).map((pickup) => {
                                                                                pickup.toSave = pickup.id === 0;
                                                                                return pickup;
                                                                            })
                                                                        };
                                                                    });

                                                                    validateOrderForSaving({ keyCode: 9 });
                                                                }

                                                                setIsShowingShipperSecondPage(true);
                                                            }
                                                            setIsLoading(false);
                                                        }).catch((e) => {
                                                            console.log("error getting customers", e);
                                                            setIsLoading(false);
                                                        });
                                                    } else {
                                                        setIsShowingShipperSecondPage(true);
                                                    }
                                                } else {
                                                    setIsShowingShipperSecondPage(true);
                                                }
                                            }

                                            resolve("OK");
                                        } else {
                                            reject("no customer");
                                        }
                                    } else {
                                        reject("no customer");
                                    }
                                }).catch(e => {
                                    reject("no customer");
                                })
                            } else {
                                reject("no customer");
                            }


                        }).then((response) => {
                            closePanel(
                                `${props.panelName}-customer-search`,
                                props.origin
                            );
                        }).catch((e) => {
                            setSelectedShipperCustomer({});
                            setSelectedShipperCustomerContact({});
                            refShipperCompanyCode.current.focus();
                        });
                    }}
                    closingCallback={() => {
                        closePanel(`${props.panelName}-customer-search`, props.origin)
                            (props.isOnPanel ? refOrderNumber : props.refOrderNumber).current.focus({ preventScroll: true });
                    }}
                />
            ),
        };

        openPanel(panel, props.origin);
    };

    const consigneeCompanySearch = () => {
        if ((selectedOrder?.id || 0) === 0) {
            window.alert("You must create or load an order first!");
            setSelectedConsigneeCustomer({});
            setSelectedConsigneeCustomerContact({});
            setSelectedOrder((selectedOrder) => {
                return { ...selectedOrder, deliveries: [] };
            });
            return;
        }

        let companySearch = [
            {
                field: "Code",
                data: (selectedConsigneeCustomer?.code || "").toLowerCase(),
            },
            {
                field: "Name",
                data: (selectedConsigneeCustomer?.name || "").toLowerCase(),
            },
            {
                field: "City",
                data: (selectedConsigneeCustomer?.city || "").toLowerCase(),
            },
            {
                field: "State",
                data: (selectedConsigneeCustomer?.state || "").toLowerCase(),
            },
            {
                field: "Postal Code",
                data: selectedConsigneeCustomer?.zip || "",
            },
            {
                field: "Contact Name",
                data: (selectedConsigneeCustomer?.contact_name || "").toLowerCase(),
            },
            {
                field: "Contact Phone",
                data: selectedConsigneeCustomer?.contact_phone || "",
            },
            {
                field: "E-Mail",
                data: (selectedConsigneeCustomer?.email || "").toLowerCase(),
            },
            {
                field: 'User Code',
                data: (props.user?.user_code?.type || 'employee') === 'agent' ? props.user.user_code.code : ''
            }
        ];

        let panel = {
            panelName: `${props.panelName}-customer-search`,
            component: (
                <CustomerSearch
                    title="Customer Search Results"
                    tabTimes={29000}
                    panelName={`${props.panelName}-customer-search`}
                    origin={props.origin}
                    closingCallback={() => {
                        closePanel(`${props.panelName}-customer-search`, props.origin)
                            (props.isOnPanel ? refOrderNumber : props.refOrderNumber).current.focus({ preventScroll: true });
                    }}

                    suborigin={"customer"}
                    componentId={moment().format("x")}
                    customerSearch={companySearch}
                    callback={(id) => {
                        new Promise((resolve, reject) => {
                            if ((id || 0) > 0) {
                                axios.post(props.serverUrl + '/getCustomerById', { id: id }).then(res => {
                                    if (res.data.result === 'OK') {
                                        let customer = res.data.customer;

                                        if (customer) {
                                            let delivery_id = -1;

                                            setSelectedOrder({
                                                ...selectedOrder,
                                                deliveries: (selectedOrder?.deliveries || []).map((d, i) => {
                                                    if (d.id === (selectedConsigneeCustomer?.delivery_id || 0)) {
                                                        d.order_id = selectedOrder.id;
                                                        d.customer = customer;
                                                        d.customer_id = customer.id;

                                                        let consignee = { ...customer };
                                                        consignee = { ...consignee, ...d };
                                                        consignee.customer = {};
                                                        consignee.delivery_id = d.id;

                                                        setSelectedConsigneeCustomer(consignee);
                                                        setSelectedConsigneeCustomerContact((consignee.contacts || []).find((c) => c.is_primary === 1) || {});

                                                        delivery_id = d.id;
                                                    }
                                                    return d;
                                                }),
                                            });

                                            setIsSavingDeliveryId(delivery_id);
                                            refDeliveryDate1.current.inputElement.focus();

                                            resolve("OK");
                                        } else {
                                            reject("no customer");
                                        }
                                    } else {
                                        reject("no customer");
                                    }
                                }).catch(e => {
                                    reject("no customer");
                                })
                            } else {
                                reject("no customer");
                            }
                        }).then((response) => {
                            if (response === "OK") {
                                closePanel(
                                    `${props.panelName}-customer-search`,
                                    props.origin
                                );
                            }
                        }).catch((e) => {
                            closePanel(
                                `${props.panelName}-customer-search`,
                                props.origin
                            );
                            setSelectedConsigneeCustomer({});
                            setSelectedConsigneeCustomerContact({});
                            refConsigneeCompanyCode.current.focus();
                        });
                    }}
                />
            ),
        };

        openPanel(panel, props.origin);
    };

    const getCarrierInfoByCode = (e) => {
        let keyCode = e.keyCode || e.which;

        if (keyCode === 9) {
            if (e.target.value.trim() !== "") {
                if ((selectedOrder?.id || 0) === 0) {
                    e.preventDefault();
                    window.alert("You must create or load an order first!");
                    setSelectedCarrier({});
                    setSelectedCarrierContact({});
                    setSelectedCarrierDriver({});
                    return;
                }

                if ((selectedOrder?.division?.id || 0) === 0) {
                    e.preventDefault();
                    window.alert("You must select a division first!");
                    setSelectedCarrier({});
                    setSelectedCarrierContact({});
                    setSelectedCarrierDriver({});
                    return;
                }

                axios.post(props.serverUrl + "/getOrderCarrierByCode", {
                    code: e.target.value.toLowerCase(),
                    division_type: selectedOrder?.division?.type
                }).then((res) => {
                    if (res.data.result === "OK") {
                        setSelectedCarrier({});
                        setSelectedCarrierDriver({});
                        setSelectedCarrierInsurance({});
                        setSelectedCarrierContact({});

                        if (res.data.carrier) {
                            const carrier = { ...res.data.carrier };

                            if ((carrier?.insurance_status || '') === 'expired') {
                                window.alert("This carrier isn't allowed to be assigned to an order because it doesn't have an active insurance status.");
                                return;
                            } else if ((carrier?.insurance_status || '') === 'warning') {
                                window.alert(`The carrier's insurance is expiring in ${carrier?.insurance_remaining_days || 0} days. Please update as soon as possible.`);
                            } else if ((carrier?.insurance_flag || 0) === 1) {
                                window.alert("This carrier isn't allowed to be assigned to an order because the insurance document must be uploaded first.");
                                return;
                            }

                            let driver = null;
                            let contact = null;

                            if (res.data.owner_type === 'carrier') {
                                if ((carrier?.contacts || []).length > 0) {
                                    contact = carrier.contacts.find(x => (x.is_primary || 0) === 1) || carrier.contacts[0];
                                }

                                if ((carrier?.drivers || []).length > 0) {
                                    if ((res.data?.driver_code || '') !== '') {
                                        driver = carrier.drivers.find(x => (x.code || '').toUpperCase() === (res.data.driver_code).toUpperCase());
                                    } else {
                                        driver = { ...carrier.drivers[0] };
                                    }
                                }
                            }

                            setSelectedCarrier(carrier);
                            setSelectedCarrierContact(contact);
                            setSelectedCarrierDriver(driver);

                            let selected_order = {
                                ...selectedOrder,
                                carrier_id: carrier?.id,
                                carrier: carrier,
                                equipment_id: driver?.tractor?.type_id,
                                equipment: driver?.tractor?.type,
                                carrier_contact_id: contact?.id,
                                carrier_contact_primary_phone: contact?.primary_phone || 'work',
                                carrier_driver_id: driver?.id,
                                driver: driver
                            };

                            setSelectedOrder(prev => {
                                return {
                                    ...prev,
                                    carrier_id: carrier?.id,
                                    carrier: carrier,
                                    equipment_id: driver?.tractor?.type_id,
                                    equipment: driver?.tractor?.type,
                                    carrier_contact_id: contact?.id,
                                    carrier_contact_primary_phone: contact?.primary_phone || 'work',
                                    carrier_driver_id: driver?.id,
                                    driver: driver
                                }
                            });

                            if (!(selected_order?.events || []).find(x => x.event_type === 'carrier assigned')) {
                                let event_notes = res.data.owner_type === 'carrier'
                                    ? 'Assigned Carrier '
                                    : res.data.owner_type === 'agent'
                                        ? 'Assigned Agent '
                                        : res.data.owner_type === 'company'
                                            ? 'Assigned Company Driver '
                                            : res.data.owner_type === 'operator'
                                                ? 'Assigned Owner Operator '
                                                : ' ';
                                let event_parameters = {
                                    order_id: selected_order.id,
                                    time: moment().format("HHmm"),
                                    event_time: moment().format("HHmm"),
                                    date: moment().format("MM/DD/YYYY"),
                                    event_date: moment().format("MM/DD/YYYY"),
                                    user_code_id: props.user.user_code.id || null,
                                    event_location: "",
                                    event_notes:
                                        event_notes +
                                        carrier.code +
                                        ((carrier?.code_number || 0) === 0
                                            ? ""
                                            : carrier.code_number) +
                                        " - " +
                                        carrier.name,
                                    event_type_id: 2,
                                    new_carrier_id: carrier.id,
                                };

                                if (!isCreatingTemplate && !isEditingTemplate) {
                                    axios.post(props.serverUrl + "/saveOrderEvent", event_parameters).then(async (res) => {
                                        if (res.data.result === "OK") {
                                            axios.post(props.serverUrl + "/saveOrder", selected_order).then((res) => {
                                                if (res.data.result === "OK") {
                                                    setSelectedOrder(res.data.order);

                                                    props.setSelectedOrder({
                                                        ...res.data.order,
                                                        component_id: props.componentId,
                                                    });
                                                }

                                                setIsSavingOrder(false);
                                            }).catch((e) => {
                                                console.log("error saving order", e);
                                                setIsSavingOrder(false);
                                            });

                                            let user_first_name = (selected_order?.user_code?.type || '') === 'agent'
                                                ? (((selected_order.user_code?.agent?.contacts || []).find(x => x.id === (selected_order.user_code?.agent_contact_id || 0))?.first_name || '')).trim()
                                                : (selected_order?.user_code?.type || '') === 'employee'
                                                    ? ((selected_order.user_code?.employee?.first_name || '')).trim()
                                                    : '';

                                            let user_last_name = (selected_order?.user_code?.type || '') === 'agent'
                                                ? (((selected_order.user_code?.agent?.contacts || []).find(x => x.id === (selected_order.user_code?.agent_contact_id || 0))?.last_name || '')).trim()
                                                : (selected_order?.user_code?.type || '') === 'employee'
                                                    ? ((selected_order.user_code?.employee?.last_name || '')).trim()
                                                    : '';

                                            let recipient_to = [];
                                            let recipient_cc = [];
                                            let recipient_bcc = [];

                                            (selected_order?.bill_to_company?.automatic_emails || []).map(item => {

                                                if ((item?.booked_load || 0) === 1) {
                                                    if ((item?.type || '') === 'to') {
                                                        recipient_to.push(item.email);
                                                    }

                                                    if ((item?.type || '') === 'cc') {
                                                        recipient_cc.push(item.email);
                                                    }

                                                    if ((item?.type || '') === 'bcc') {
                                                        recipient_bcc.push(item.email);
                                                    }
                                                }

                                                return true;
                                            });

                                            if (recipient_to.length > 0) {
                                                axios.post(props.serverUrl + '/sendBookedLoadEmail', {
                                                    order_number: selected_order?.order_number,
                                                    user_first_name,
                                                    user_last_name,
                                                    recipient_to,
                                                    recipient_cc,
                                                    recipient_bcc
                                                }).then(res => {
                                                    console.log(res.data.result);
                                                }).catch(e => {
                                                    console.log(e);
                                                })
                                            }

                                            getLoadBoardOrders()
                                        } else if (res.data.result === "ORDER ID NOT VALID") {
                                            window.alert("The order number is not valid!");
                                            refEventDate.current.inputElement.focus();
                                        }
                                    }).catch((e) => {
                                        console.log("error saving order event", e);
                                    });
                                }
                            } else {
                                if (!isSavingOrder) {
                                    setIsSavingOrder(true);
                                    if (!isCreatingTemplate && !isEditingTemplate) {
                                        axios.post(props.serverUrl + "/saveOrder", selected_order).then((res) => {
                                            if (res.data.result === "OK") {
                                                setSelectedOrder(res.data.order);

                                                props.setSelectedOrder({
                                                    ...res.data.order,
                                                    component_id: props.componentId,
                                                });
                                            }

                                            setIsSavingOrder(false);
                                        }).catch((e) => {
                                            console.log("error saving order", e);
                                            setIsSavingOrder(false);
                                        });
                                    }
                                }
                            }

                            refDriverName.current.focus();
                        } else {
                            setSelectedCarrier({});
                            setSelectedCarrierDriver({});
                            setSelectedCarrierInsurance({});
                            setSelectedCarrierContact({});
                        }
                    } else {
                        setSelectedCarrier({});
                        setSelectedCarrierDriver({});
                        setSelectedCarrierInsurance({});
                        setSelectedCarrierContact({});
                    }
                }).catch((e) => {
                    console.log("error getting carriers", e);
                });
            } else {
                setSelectedCarrier({});
                setSelectedCarrierDriver({});
                setSelectedCarrierInsurance({});
                setSelectedCarrierContact({});
            }
        }
    };

    const insuranceStatusClasses = () => {
        let classes = "input-box-container insurance-status";
        let curDate = moment().startOf("day");
        let curDate2 = moment();
        let futureMonth = curDate2.add(1, "M");
        let statusClass = "";

        (selectedCarrier.insurances || []).map((insurance, index) => {
            let expDate = moment(insurance.expiration_date, "MM/DD/YYYY");

            if (expDate < curDate) {
                statusClass = "expired";
            } else if (expDate >= curDate && expDate <= futureMonth) {
                if (statusClass !== "expired") {
                    statusClass = "warning";
                }
            } else {
                if (statusClass !== "expired" && statusClass !== "warning") {
                    statusClass = "active";
                }
            }
        });

        return (classes + " " + statusClass).trim();
    };

    const goToTabindex = (index) => {
        let elems = document.getElementsByTagName("input");

        for (var i = elems.length; i--;) {
            if (elems[i].getAttribute("tabindex") && elems[i].getAttribute("tabindex") === index) {
                elems[i].focus();
                break;
            }
        }
    };

    const getFormattedDates = (date) => {
        let formattedDate = date;

        try {
            if (moment(date.trim(), "MM/DD/YY").format("MM/DD/YY") === date.trim()) {
                formattedDate = moment(date.trim(), "MM/DD/YY").format("MM/DD/YYYY");
            }

            if (moment(date.trim(), "MM/DD/").format("MM/DD/") === date.trim()) {
                formattedDate = moment(date.trim(), "MM/DD/").format("MM/DD/YYYY");
            }

            if (moment(date.trim(), "MM/DD").format("MM/DD") === date.trim()) {
                formattedDate = moment(date.trim(), "MM/DD").format("MM/DD/YYYY");
            }

            if (moment(date.trim(), "MM/").format("MM/") === date.trim()) {
                formattedDate = moment(date.trim(), "MM/").format("MM/DD/YYYY");
            }

            if (moment(date.trim(), "MM").format("MM") === date.trim()) {
                formattedDate = moment(date.trim(), "MM").format("MM/DD/YYYY");
            }

            if (moment(date.trim(), "M/D/Y").format("M/D/Y") === date.trim()) {
                formattedDate = moment(date.trim(), "M/D/Y").format("MM/DD/YYYY");
            }

            if (moment(date.trim(), "MM/D/Y").format("MM/D/Y") === date.trim()) {
                formattedDate = moment(date.trim(), "MM/D/Y").format("MM/DD/YYYY");
            }

            if (moment(date.trim(), "MM/DD/Y").format("MM/DD/Y") === date.trim()) {
                formattedDate = moment(date.trim(), "MM/DD/Y").format("MM/DD/YYYY");
            }

            if (moment(date.trim(), "M/DD/Y").format("M/DD/Y") === date.trim()) {
                formattedDate = moment(date.trim(), "M/DD/Y").format("MM/DD/YYYY");
            }

            if (moment(date.trim(), "M/D/YY").format("M/D/YY") === date.trim()) {
                formattedDate = moment(date.trim(), "M/D/YY").format("MM/DD/YYYY");
            }

            if (moment(date.trim(), "M/D/YYYY").format("M/D/YYYY") === date.trim()) {
                formattedDate = moment(date.trim(), "M/D/YYYY").format("MM/DD/YYYY");
            }

            if (moment(date.trim(), "MM/D/YYYY").format("MM/D/YYYY") === date.trim()) {
                formattedDate = moment(date.trim(), "MM/D/YYYY").format("MM/DD/YYYY");
            }

            if (moment(date.trim(), "MM/DD/YYYY").format("MM/DD/YYYY") === date.trim()) {
                formattedDate = moment(date.trim(), "MM/DD/YYYY").format("MM/DD/YYYY");
            }

            if (moment(date.trim(), "M/DD/YYYY").format("M/DD/YYYY") === date.trim()) {
                formattedDate = moment(date.trim(), "M/DD/YYYY").format("MM/DD/YYYY");
            }

            if (moment(date.trim(), "M/D/").format("M/D/") === date.trim()) {
                formattedDate = moment(date.trim(), "M/D/").format("MM/DD/YYYY");
            }

            if (moment(date.trim(), "M/D").format("M/D") === date.trim()) {
                formattedDate = moment(date.trim(), "M/D").format("MM/DD/YYYY");
            }

            if (moment(date.trim(), "MM/D").format("MM/D") === date.trim()) {
                formattedDate = moment(date.trim(), "MM/D").format("MM/DD/YYYY");
            }

            if (moment(date.trim(), "M").format("M") === date.trim()) {
                formattedDate = moment(date.trim(), "M").format("MM/DD/YYYY");
            }
        } catch (e) {

        }

        return formattedDate;
    };

    const getFormattedHours = (hour) => {
        let formattedHour = hour;

        try {
            if (moment(hour.trim(), "HH:mm").format("HH:mm") === hour.trim()) {
                formattedHour = moment(hour.trim(), "HH:mm").format("HHmm");
            }

            if (moment(hour.trim(), "H:mm").format("H:mm") === hour.trim()) {
                formattedHour = moment(hour.trim(), "H:mm").format("HHmm");
            }

            if (moment(hour.trim(), "Hmm").format("Hmm") === hour.trim()) {
                formattedHour = moment(hour.trim(), "Hmm").format("HHmm");
            }

            if (moment(hour.trim(), "hh:mm a").format("hh:mm a") === hour.trim()) {
                formattedHour = moment(hour.trim(), "hh:mm a").format("HHmm");
            }

            if (moment(hour.trim(), "h:mm a").format("h:mm a") === hour.trim()) {
                formattedHour = moment(hour.trim(), "h:mm a").format("HHmm");
            }

            if (moment(hour.trim(), "hh:mma").format("hh:mma") === hour.trim()) {
                formattedHour = moment(hour.trim(), "hh:mma").format("HHmm");
            }

            if (moment(hour.trim(), "h:mma").format("h:mma") === hour.trim()) {
                formattedHour = moment(hour.trim(), "h:mma").format("HHmm");
            }

            if (moment(hour.trim(), "hhmm a").format("hhmm a") === hour.trim()) {
                formattedHour = moment(hour.trim(), "hhmm a").format("HHmm");
            }

            if (moment(hour.trim(), "hmm a").format("hmm a") === hour.trim()) {
                formattedHour = moment(hour.trim(), "hmm a").format("HHmm");
            }

            if (moment(hour.trim(), "hhmma").format("hhmma") === hour.trim()) {
                formattedHour = moment(hour.trim(), "hhmma").format("HHmm");
            }

            if (moment(hour.trim(), "hmma").format("hmma") === hour.trim()) {
                formattedHour = moment(hour.trim(), "hmma").format("HHmm");
            }

            if (moment(hour.trim(), "H").format("H") === hour.trim()) {
                formattedHour = moment(hour.trim(), "H").format("HHmm");
            }

            if (moment(hour.trim(), "HH").format("HH") === hour.trim()) {
                formattedHour = moment(hour.trim(), "HH").format("HHmm");
            }

            if (moment(hour.trim(), "h a").format("h a") === hour.trim()) {
                formattedHour = moment(hour.trim(), "h a").format("HHmm");
            }

            if (moment(hour.trim(), "hh a").format("hh a") === hour.trim()) {
                formattedHour = moment(hour.trim(), "hh a").format("HHmm");
            }

            if (moment(hour.trim(), "ha").format("ha") === hour.trim()) {
                formattedHour = moment(hour.trim(), "ha").format("HHmm");
            }

            if (moment(hour.trim(), "hha").format("hha") === hour.trim()) {
                formattedHour = moment(hour.trim(), "hha").format("HHmm");
            }

            if (moment(hour.trim(), "h:ma").format("h:ma") === hour.trim()) {
                formattedHour = moment(hour.trim(), "h:ma").format("HHmm");
            }

            if (moment(hour.trim(), "H:m").format("H:m") === hour.trim()) {
                formattedHour = moment(hour.trim(), "H:m").format("HHmm");
            }
        } catch (e) {

        }

        return formattedHour;
    };

    const searchCarrierBtnClick = () => {
        if ((selectedOrder?.id || 0) === 0) {
            window.alert("You must create or load an order first!");
            return;
        }

        let carrierSearch = [
            {
                field: "Name",
                data: (selectedCarrier.name || "").toLowerCase(),
            },
            {
                field: "City",
                data: (selectedCarrier.city || "").toLowerCase(),
            },
            {
                field: "State",
                data: (selectedCarrier.state || "").toLowerCase(),
            },
            {
                field: "Postal Code",
                data: selectedCarrier.zip || "",
            },
            {
                field: "Contact Name",
                data: (selectedCarrier.contact_name || "").toLowerCase(),
            },
            {
                field: "Contact Phone",
                data: selectedCarrier.contact_phone || "",
            },
            {
                field: "E-Mail",
                data: (selectedCarrier.email || "").toLowerCase(),
            },
        ];

        let panel = {
            panelName: `${props.panelName}-carrier-search`,
            component: (
                <CustomerSearch
                    title="Carrier Search Results"
                    tabTimes={69000}
                    panelName={`${props.panelName}-carrier-search`}
                    origin={props.origin}
                    closingCallback={() => {
                        closePanel(`${props.panelName}-carrier-search`, props.origin)
                            (props.isOnPanel ? refOrderNumber : props.refOrderNumber).current.focus({ preventScroll: true });
                    }}

                    suborigin={"carrier"}
                    componentId={moment().format("x")}
                    customerSearch={carrierSearch}
                    callback={(id) => {
                        new Promise((resolve, reject) => {
                            axios.post(props.serverUrl + '/getCarrierById', { id: id }).then(res => {
                                if (res.data.result === 'OK') {
                                    const carrier = res.data.carrier;

                                    if (carrier) {
                                        if ((carrier?.insurance_status || '') === 'expired') {
                                            window.alert("This carrier isn't allowed to be assigned to an order because it doesn't have an active insurance status.");
                                            return;
                                        } else if ((carrier?.insurance_status || '') === 'warning') {
                                            window.alert(`The carrier's insurance is expiring in ${carrier?.insurance_remaining_days || 0} days. Please update as soon as possible.`);
                                        } else if ((carrier?.insurance_flag || 0) === 1) {
                                            window.alert("This carrier isn't allowed to be assigned to an order because the insurance document must be uploaded first.");
                                            return;
                                        }

                                        let selected_order = { ...selectedOrder, carrier_contact_id: null } || {
                                            order_number: 0,
                                        };

                                        setSelectedCarrier({ ...carrier });

                                        if ((carrier.contacts || []).length > 0) {
                                            let carrierPrimaryContact = carrier.contacts.find(x => x.is_primary === 1);

                                            if (carrierPrimaryContact) {
                                                setSelectedCarrierContact(carrierPrimaryContact)
                                                selected_order.carrier_contact_id = carrierPrimaryContact.id;
                                                selected_order.carrier_contact_primary_phone = carrierPrimaryContact.primary_phone || 'work'
                                            } else {
                                                setSelectedCarrierContact(carrier.contacts[0])
                                                selected_order.carrier_contact_id = carrier.contacts[0].id;
                                                selected_order.carrier_contact_primary_phone = carrier.primary_phone || 'work'
                                            }
                                        }

                                        setSelectedCarrierInsurance({});

                                        selected_order.bill_to_customer_id = selectedBillToCustomer?.id || 0;
                                        selected_order.shipper_customer_id = selectedShipperCustomer?.id || 0;
                                        selected_order.consignee_customer_id = selectedConsigneeCustomer?.id || 0;
                                        selected_order.carrier_id = carrier.id;

                                        if (carrier.drivers.length > 0) {
                                            setSelectedCarrierDriver({});
                                            selected_order.carrier_driver_id = null;
                                        }

                                        if ((selected_order.events || []).find((el) => el.event_type === "carrier assigned") === undefined) {
                                            let event_parameters = {
                                                order_id: selected_order.id,
                                                time: moment().format("HHmm"),
                                                event_time: moment().format("HHmm"),
                                                date: moment().format("MM/DD/YYYY"),
                                                event_date: moment().format("MM/DD/YYYY"),
                                                user_code_id: props.user.user_code.id || null,
                                                event_location: "",
                                                event_notes:
                                                    "Assigned Carrier " +
                                                    carrier.code +
                                                    (carrier.code_number === 0 ? "" : carrier.code_number) +
                                                    " - " +
                                                    carrier.name,
                                                event_type_id: 2,
                                                new_carrier_id: carrier.id,
                                            };

                                            if (!isCreatingTemplate && !isEditingTemplate) {
                                                setIsLoading(true);

                                                axios.post(props.serverUrl + "/saveOrderEvent", event_parameters).then(async (res) => {
                                                    if (res.data.result === "OK") {
                                                        axios.post(props.serverUrl + "/saveOrder", selected_order).then((res) => {
                                                            if (res.data.result === "OK") {
                                                                setSelectedOrder(res.data.order);

                                                                props.setSelectedOrder({
                                                                    ...res.data.order,
                                                                    component_id: props.componentId,
                                                                });
                                                            }

                                                            setIsSavingOrder(false);
                                                        }).catch((e) => {
                                                            console.log("error saving order", e);
                                                            setIsSavingOrder(false);
                                                        });

                                                        let user_first_name = (selected_order?.user_code?.type || '') === 'agent'
                                                            ? (((selected_order.user_code?.agent?.contacts || []).find(x => x.id === (selected_order.user_code?.agent_contact_id || 0))?.first_name || '')).trim()
                                                            : (selected_order?.user_code?.type || '') === 'employee'
                                                                ? ((selected_order.user_code?.employee?.first_name || '')).trim()
                                                                : '';

                                                        let user_last_name = (selected_order?.user_code?.type || '') === 'agent'
                                                            ? (((selected_order.user_code?.agent?.contacts || []).find(x => x.id === (selected_order.user_code?.agent_contact_id || 0))?.last_name || '')).trim()
                                                            : (selected_order?.user_code?.type || '') === 'employee'
                                                                ? ((selected_order.user_code?.employee?.last_name || '')).trim()
                                                                : '';

                                                        let recipient_to = [];
                                                        let recipient_cc = [];
                                                        let recipient_bcc = [];

                                                        (selected_order?.bill_to_company?.automatic_emails || []).map(item => {

                                                            if ((item?.booked_load || 0) === 1) {
                                                                if ((item?.type || '') === 'to') {
                                                                    recipient_to.push(item.email);
                                                                }

                                                                if ((item?.type || '') === 'cc') {
                                                                    recipient_cc.push(item.email);
                                                                }

                                                                if ((item?.type || '') === 'bcc') {
                                                                    recipient_bcc.push(item.email);
                                                                }
                                                            }

                                                            return true;
                                                        });

                                                        if (recipient_to.length > 0) {
                                                            axios.post(props.serverUrl + '/sendBookedLoadEmail', {
                                                                order_number: selected_order?.order_number,
                                                                user_first_name,
                                                                user_last_name,
                                                                recipient_to,
                                                                recipient_cc,
                                                                recipient_bcc
                                                            }).then(res => {
                                                                console.log(res.data.result);
                                                            }).catch(e => {
                                                                console.log(e);
                                                            })
                                                        }

                                                        getLoadBoardOrders()
                                                    } else if (res.data.result === "ORDER ID NOT VALID") {
                                                        window.alert("The order number is not valid!");
                                                        refEventDate.current.inputElement.focus();
                                                    }
                                                    setIsLoading(false);
                                                }).catch((e) => {
                                                    console.log("error saving order event", e);
                                                    setIsLoading(false);
                                                });
                                            }

                                        } else {
                                            if (!isSavingOrder) {
                                                setIsSavingOrder(true);
                                                if (!isCreatingTemplate && !isEditingTemplate) {
                                                    setIsLoading(true);
                                                    axios.post(props.serverUrl + "/saveOrder", selected_order).then(async (res) => {
                                                        if (res.data.result === "OK") {
                                                            setSelectedOrder({ ...res.data.order });

                                                            props.setSelectedOrder({
                                                                ...res.data.order,
                                                                component_id: props.componentId,
                                                            });
                                                        }

                                                        setIsSavingOrder(false);
                                                        setIsLoading(false);
                                                    }).catch((e) => {
                                                        console.log("error saving order", e);
                                                        setIsSavingOrder(false);
                                                        setIsLoading(false);
                                                    });
                                                }
                                            }
                                        }
                                        resolve("OK");
                                    } else {
                                        reject("no carrier");
                                    }
                                }
                            }).catch(e => {
                                console.log('error on getting carrier', e);
                            })
                        }).then((response) => {
                            if (response === "OK") {
                                closePanel(
                                    `${props.panelName}-carrier-search`,
                                    props.origin
                                );

                                refDriverName.current.focus();
                            }
                        }).catch((e) => {
                            closePanel(
                                `${props.panelName}-carrier-search`,
                                props.origin
                            );
                            refCarrierCode.current.focus();
                        });
                    }}
                />
            ),
        };

        openPanel(panel, props.origin);
    };

    const validateOrderForSaving = (e) => {
        let key = e.keyCode || e.which;

        if (key === 9) {
            if (!isSavingOrder) {
                setIsSavingOrder(true);
            }
        }
    };

    useEffect(() => {
        if (isSavingOrder) {
            let selected_order = { ...selectedOrder } || { order_number: 0 };

            // check if there's a bill-to-company loaded
            if ((selectedBillToCustomer?.id || 0) === 0) {
                setIsSavingOrder(false);
                setMileageLoaderVisible(false);
                setIsLoading(false);
                return;
            }

            selected_order.bill_to_customer_id = (selectedBillToCustomer?.id || 0) === 0 ? null : selectedBillToCustomer.id;
            selected_order.carrier_id = (selectedCarrier?.id || 0) === 0 ? null : selectedCarrier.id;
            selected_order.carrier_driver_id = (selectedCarrierDriver?.id || 0) === 0 ? null : selectedCarrierDriver.id;

            let toSavePickup = (selected_order.pickups || []).find((p) => (p.toSave || false) === true) !== undefined;

            if (!selected_order?.user_code) {
                selected_order.user_code_id = props.user.user_code.id;
                selected_order.agent_code = props.user.user_code.type === 'agent' ? props.user.user_code.code : ''
            }

            if (!isCreatingTemplate && !isEditingTemplate) {
                axios.post(props.serverUrl + "/saveOrder", selected_order).then((res) => {
                    if (res.data.result === "OK") {
                        let newOrder = res.data.order;

                        if (newOrder?.bill_to_company?.agent) {
                            if ((newOrder?.agent_code || '') === '') {
                                newOrder.agent_code = newOrder.bill_to_company.agent.code;
                                newOrder.agent_commission = (newOrder?.user_code?.code || '') === newOrder.bill_to_company.agent.code
                                    ? newOrder?.bill_to_company.agent.agent_pay_brokerage || 0
                                    : (newOrder?.bill_to_company?.agent?.agent_pay_et3 || 0) > 0
                                        ? 100 - newOrder.bill_to_company.agent.agent_pay_et3
                                        : 0;
                            }
                        } else {
                            newOrder.agent_code = '';
                            newOrder.agent_commission = '';
                        }

                        setSelectedOrder(prev => {
                            return {
                                ...prev,
                                ...newOrder
                            }
                        });

                        if (!isEditingTemplate && !isCreatingTemplate) {
                            props.setSelectedOrder({
                                ...newOrder,
                                component_id: props.componentId,
                            });
                        }

                        if (toSavePickup) {
                            setSelectedShipperCustomer((selectedShipperCustomer) => {
                                return {
                                    ...selectedShipperCustomer,
                                    pickup_id: newOrder.pickups[0].id,
                                };
                            });
                        }

                        if ((selected_order?.id || 0) === 0 && newOrder.id > 0) {
                            if (!isAddingNewShipper && !isAddingNewConsignee) {
                                refShipperCompanyCode.current.focus();
                            }

                            getLoadBoardOrders()
                        }
                    } else {

                    }

                    setIsSavingOrder(false);
                    setIsLoading(false);
                }).catch((e) => {
                    console.log("error saving order", e);
                    setIsSavingOrder(false);
                    setIsLoading(false);
                });
            }

        }
    }, [isSavingOrder]);

    useEffect(() => {
        if (isSavingPickupId > -1) {
            if ((selectedOrder?.id || 0) > 0) {
                let pickup = (selectedOrder?.pickups || []).find((p) => p.id === isSavingPickupId);

                if (pickup !== undefined) {
                    if ((pickup.customer?.id || 0) > 0) {
                        if (!isCreatingTemplate && !isEditingTemplate) {
                            axios.post(props.serverUrl + "/saveOrderPickup", {
                                id: isSavingPickupId,
                                order_id: selectedOrder?.id || 0,
                                customer_id: pickup.customer.id,
                                contact_id: pickup.contact_id || "",
                                contact_name: pickup.contact_name || "",
                                contact_phone: pickup.contact_phone || "",
                                contact_primary_phone: pickup.contact_primary_phone || "work",
                                contact_phone_ext: pickup.contact_phone_ext || "",
                                pu_date1: pickup.pu_date1 || "",
                                pu_date2: pickup.pu_date2 || "",
                                pu_time1: pickup.pu_time1 || "",
                                pu_time2: pickup.pu_time2 || "",
                                bol_numbers: pickup.bol_numbers || "",
                                po_numbers: pickup.po_numbers || "",
                                ref_numbers: pickup.ref_numbers || "",
                                seal_number: pickup.seal_number || "",
                                special_instructions: pickup.special_instructions || "",
                                type: "pickup",
                            }).then((res) => {
                                if (res.data.result === "OK") {
                                    setSelectedOrder((prev) => {
                                        return {
                                            ...prev,
                                            pickups: (selectedOrder.pickups || []).map((p, i) => {
                                                if (p.id === isSavingPickupId) {
                                                    p = {
                                                        ...res.data.pickup,
                                                        customer: {
                                                            ...(res.data.pickup?.customer || {}),
                                                            contacts: [
                                                                ...(res.data.pickup.customer?.contacts || [])
                                                            ].sort(function (a, b) {
                                                                var aFirstChar = a.first_name.charAt(0);
                                                                var bFirstChar = b.first_name.charAt(0);
                                                                if (aFirstChar > bFirstChar) {
                                                                    return 1;
                                                                } else if (aFirstChar < bFirstChar) {
                                                                    return -1;
                                                                } else {
                                                                    var aLastChar = a.last_name.charAt(0);
                                                                    var bLastChar = b.last_name.charAt(0);
                                                                    if (aLastChar > bLastChar) {
                                                                        return 1;
                                                                    } else if (aLastChar < bLastChar) {
                                                                        return -1;
                                                                    } else {
                                                                        return 0;
                                                                    }
                                                                }
                                                            })
                                                        }
                                                    };

                                                    setSelectedShipperCustomer(prev => {
                                                        return {
                                                            ...prev,
                                                            pickup_id: res.data.pickup.id
                                                        }
                                                    });
                                                }
                                                return p;
                                            }),
                                        };
                                    });

                                    if (res.data.order.pickups.length === 1 &&
                                        res.data.order.deliveries.length === 1) {
                                        let routing = [
                                            {
                                                id: 0,
                                                pickup_id: res.data.order.pickups[0].id,
                                                delivery_id: null,
                                                type: "pickup",
                                            },
                                            {
                                                id: 0,
                                                pickup_id: null,
                                                delivery_id: res.data.order.deliveries[0].id,
                                                type: "delivery",
                                            },
                                        ];

                                        let selected_order = selectedOrder;

                                        axios.post(props.serverUrl + "/saveOrderRouting", {
                                            order_id: selectedOrder?.id || 0,
                                            routing: routing,
                                        }).then((res) => {
                                            if (res.data.result === "OK") {
                                                selected_order = res.data.order;

                                                selected_order.pickups = (selected_order.pickups || []).map(item => {
                                                    item = {
                                                        ...item,
                                                        customer: {
                                                            ...(item?.customer || {}),
                                                            contacts: [
                                                                ...(item?.customer?.contacts || [])
                                                            ].sort(function (a, b) {
                                                                var aFirstChar = a.first_name.charAt(0);
                                                                var bFirstChar = b.first_name.charAt(0);
                                                                if (aFirstChar > bFirstChar) {
                                                                    return 1;
                                                                } else if (aFirstChar < bFirstChar) {
                                                                    return -1;
                                                                } else {
                                                                    var aLastChar = a.last_name.charAt(0);
                                                                    var bLastChar = b.last_name.charAt(0);
                                                                    if (aLastChar > bLastChar) {
                                                                        return 1;
                                                                    } else if (aLastChar < bLastChar) {
                                                                        return -1;
                                                                    } else {
                                                                        return 0;
                                                                    }
                                                                }
                                                            })
                                                        }
                                                    }

                                                    return item;
                                                })

                                                selected_order.deliveries = (selected_order.deliveries || []).map(item => {
                                                    item = {
                                                        ...item,
                                                        customer: {
                                                            ...(item?.customer || {}),
                                                            contacts: [
                                                                ...(item?.customer?.contacts || [])
                                                            ].sort(function (a, b) {
                                                                var aFirstChar = a.first_name.charAt(0);
                                                                var bFirstChar = b.first_name.charAt(0);
                                                                if (aFirstChar > bFirstChar) {
                                                                    return 1;
                                                                } else if (aFirstChar < bFirstChar) {
                                                                    return -1;
                                                                } else {
                                                                    var aLastChar = a.last_name.charAt(0);
                                                                    var bLastChar = b.last_name.charAt(0);
                                                                    if (aLastChar > bLastChar) {
                                                                        return 1;
                                                                    } else if (aLastChar < bLastChar) {
                                                                        return -1;
                                                                    } else {
                                                                        return 0;
                                                                    }
                                                                }
                                                            })
                                                        }
                                                    }

                                                    return item;
                                                })

                                                setSelectedOrder(selected_order);

                                                setMileageLoaderVisible(true);

                                                let origin = null;
                                                let destination = null;

                                                let start = selected_order.routing[0];
                                                let waypoints = [];
                                                let end = selected_order.routing[selected_order.routing.length - 1];

                                                if (start.type === 'pickup') {
                                                    selected_order.pickups.map((p, i) => {
                                                        if (p.id === start.pickup_id) {
                                                            if ((p.customer?.zip_data || '') !== '') {
                                                                origin = `${p.customer.zip_data.latitude.toString()},${p.customer.zip_data.longitude.toString()}`;
                                                            }
                                                        }
                                                        return false;
                                                    })
                                                } else {
                                                    selected_order.deliveries.map((d, i) => {
                                                        if (d.id === start.delivery_id) {
                                                            if ((d.customer?.zip_data || '') !== '') {
                                                                origin = `${d.customer.zip_data.latitude.toString()},${d.customer.zip_data.longitude.toString()}`;
                                                            }
                                                        }
                                                        return false;
                                                    })
                                                }

                                                selected_order.routing.map((item, i) => {
                                                    if (i > 0 && i < (selected_order.routing.length - 1)) {
                                                        if (item.type === 'pickup') {
                                                            selected_order.pickups.map((p, i) => {
                                                                if (p.id === item.pickup_id) {
                                                                    if ((p.customer?.zip_data || '') !== '') {
                                                                        waypoints.push(`${p.customer.zip_data.latitude.toString()},${p.customer.zip_data.longitude.toString()}`);
                                                                    }
                                                                }
                                                                return false;
                                                            })
                                                        } else {
                                                            selected_order.deliveries.map((d, i) => {
                                                                if (d.id === item.delivery_id) {
                                                                    if ((d.customer?.zip_data || '') !== '') {
                                                                        waypoints.push(`${d.customer.zip_data.latitude.toString()},${d.customer.zip_data.longitude.toString()}`);
                                                                    }
                                                                }
                                                                return false;
                                                            })
                                                        }
                                                    }

                                                    return true;
                                                });

                                                if (end.type === 'pickup') {
                                                    selected_order.pickups.map((p, i) => {
                                                        if (p.id === end.pickup_id) {
                                                            if ((p.customer?.zip_data || '') !== '') {
                                                                destination = `${p.customer.zip_data.latitude.toString()},${p.customer.zip_data.longitude.toString()}`;
                                                            }
                                                        }
                                                        return false;
                                                    })
                                                } else {
                                                    selected_order.deliveries.map((d, i) => {
                                                        if (d.id === end.delivery_id) {
                                                            if ((d.customer?.zip_data || '') !== '') {
                                                                destination = `${d.customer.zip_data.latitude.toString()},${d.customer.zip_data.longitude.toString()}`;
                                                            }
                                                        }
                                                        return false;
                                                    })
                                                }

                                                let params = {
                                                    'routingMode': 'fast',
                                                    'transportMode': 'car',
                                                    'origin': origin,
                                                    'via': new H.service.Url.MultiValueQueryParameter(waypoints),
                                                    'destination': destination,
                                                    'return': 'summary'
                                                }

                                                if (routingService) {
                                                    routingService.calculateRoute(
                                                        params,
                                                        (result) => {
                                                            let miles = (result?.routes[0]?.sections || []).reduce((a, b) => {
                                                                return a + b.summary.length;
                                                            }, 0) || 0;

                                                            selected_order.miles = miles;

                                                            setSelectedOrder(selected_order);
                                                            setMileageLoaderVisible(false);

                                                            axios.post(
                                                                props.serverUrl + "/saveOrder",
                                                                selected_order
                                                            ).then((res) => {
                                                                if (res.data.result === "OK") {
                                                                    setSelectedOrder({
                                                                        ...selected_order,
                                                                        order_customer_ratings: res.data.order.order_customer_ratings,
                                                                        order_carrier_ratings: res.data.order.order_carrier_ratings,
                                                                    });

                                                                    props.setSelectedOrder({
                                                                        ...selected_order,
                                                                        order_customer_ratings: res.data.order.order_customer_ratings,
                                                                        order_carrier_ratings: res.data.order.order_carrier_ratings,
                                                                        component_id: props.componentId,
                                                                    });
                                                                }
                                                            }).catch((e) => {
                                                                console.log("error on saving order miles", e);
                                                                setMileageLoaderVisible(false);
                                                            });
                                                        },
                                                        (error) => {
                                                            console.log("error getting mileage", error);
                                                            selected_order.miles = 0;

                                                            setSelectedOrder(selected_order);
                                                            setMileageLoaderVisible(false);

                                                            axios.post(
                                                                props.serverUrl + "/saveOrder",
                                                                selected_order
                                                            ).then((res) => {
                                                                if (res.data.result === "OK") {
                                                                    setSelectedOrder({
                                                                        ...selected_order,
                                                                        order_customer_ratings: res.data.order.order_customer_ratings,
                                                                        order_carrier_ratings: res.data.order.order_carrier_ratings,
                                                                    });

                                                                    props.setSelectedOrder({
                                                                        ...selected_order,
                                                                        order_customer_ratings: res.data.order.order_customer_ratings,
                                                                        order_carrier_ratings: res.data.order.order_carrier_ratings,
                                                                        component_id: props.componentId,
                                                                    });
                                                                }
                                                            }).catch((e) => {
                                                                console.log("error on saving order miles", e);
                                                                setMileageLoaderVisible(false);
                                                            });
                                                        }
                                                    );
                                                }


                                            } else {
                                                selected_order.miles = 0;
                                                setSelectedOrder(selected_order);
                                                setMileageLoaderVisible(false);

                                                axios.post(
                                                    props.serverUrl + "/saveOrder",
                                                    selected_order
                                                ).then((res) => {
                                                    if (res.data.result === "OK") {
                                                        setSelectedOrder({
                                                            ...selected_order,
                                                            order_customer_ratings: res.data.order.order_customer_ratings,
                                                            order_carrier_ratings: res.data.order.order_carrier_ratings,
                                                        });

                                                        props.setSelectedOrder({
                                                            ...selected_order,
                                                            order_customer_ratings: res.data.order.order_customer_ratings,
                                                            order_carrier_ratings: res.data.order.order_carrier_ratings,
                                                            component_id: props.componentId,
                                                        });
                                                    }
                                                }).catch((e) => {
                                                    console.log("error on saving order miles", e);
                                                    setMileageLoaderVisible(false);
                                                });
                                            }
                                        }).catch((e) => {
                                            console.log("error on saving order routing", e);
                                            setMileageLoaderVisible(false);
                                        });
                                    }
                                } else {

                                }
                                setIsSavingPickupId(-1);
                            }).catch((e) => {
                                console.log("error on saving pickup", e);
                                setIsSavingPickupId(-1);
                            });
                        }
                    } else {
                        console.log("saving pickup customer undefined");
                        setIsSavingPickupId(-1);
                    }
                } else {
                    console.log("saving pickup undefined");
                    setIsSavingPickupId(-1);
                }
            } else {
                console.log("no order selected");
                setIsSavingPickupId(-1);
            }
        }
    }, [isSavingPickupId]);

    useEffect(() => {
        if (isSavingDeliveryId > -1) {
            if ((selectedOrder?.id || 0) > 0) {
                let delivery = (selectedOrder?.deliveries || []).find((d) => d.id === isSavingDeliveryId);

                if (delivery !== undefined) {
                    if ((delivery.customer?.id || 0) > 0) {
                        if (!isCreatingTemplate && !isEditingTemplate) {
                            axios.post(props.serverUrl + "/saveOrderDelivery", {
                                id: isSavingDeliveryId,
                                order_id: selectedOrder?.id || 0,
                                customer_id: delivery.customer.id,
                                contact_id: delivery.contact_id || null,
                                contact_name: delivery.contact_name || "",
                                contact_phone: delivery.contact_phone || "",
                                contact_primary_phone: delivery.contact_primary_phone || "work",
                                contact_phone_ext: delivery.contact_phone_ext || "",
                                delivery_date1: delivery.delivery_date1 || "",
                                delivery_date2: delivery.delivery_date2 || "",
                                delivery_time1: delivery.delivery_time1 || "",
                                delivery_time2: delivery.delivery_time2 || "",
                                bol_numbers: delivery.bol_numbers || "",
                                po_numbers: delivery.po_numbers || "",
                                ref_numbers: delivery.ref_numbers || "",
                                seal_number: delivery.seal_number || "",
                                special_instructions: delivery.special_instructions || "",
                                type: "delivery",
                            }).then((res) => {
                                if (res.data.result === "OK") {
                                    setSelectedOrder({
                                        ...selectedOrder,
                                        deliveries: (selectedOrder.deliveries || []).map((d, i) => {
                                            if (d.id === isSavingDeliveryId) {
                                                d = res.data.delivery;

                                                setSelectedConsigneeCustomer(prev => {
                                                    return {
                                                        ...prev,
                                                        delivery_id: res.data.delivery.id
                                                    }
                                                });
                                            }
                                            return d;
                                        }),
                                    });

                                    if (
                                        res.data.order.pickups.length === 1 &&
                                        res.data.order.deliveries.length === 1
                                    ) {
                                        if (!((selectedOrder?.routing || []).length >= 2 &&
                                            selectedOrder.routing[0].pickup_id === res.data.order.pickups[0].id &&
                                            selectedOrder.routing[1].delivery_id === res.data.order.deliveries[0].id)) {
                                            let routing = [
                                                {
                                                    id: 0,
                                                    pickup_id: res.data.order.pickups[0].id,
                                                    delivery_id: null,
                                                    type: "pickup",
                                                },
                                                {
                                                    id: 0,
                                                    pickup_id: null,
                                                    delivery_id: res.data.order.deliveries[0].id,
                                                    type: "delivery",
                                                },
                                            ];

                                            let selected_order = selectedOrder;

                                            axios.post(props.serverUrl + "/saveOrderRouting", {
                                                order_id: selectedOrder?.id || 0,
                                                routing: routing,
                                            }).then((res) => {
                                                if (res.data.result === "OK") {
                                                    selected_order = res.data.order;
                                                    setSelectedOrder(selected_order);

                                                    setMileageLoaderVisible(true);

                                                    let origin = null;
                                                    let destination = null;

                                                    let start = selected_order.routing[0];
                                                    let waypoints = [];
                                                    let end = selected_order.routing[selected_order.routing.length - 1];

                                                    if (start.type === 'pickup') {
                                                        selected_order.pickups.map((p, i) => {
                                                            if (p.id === start.pickup_id) {
                                                                if ((p.customer?.zip_data || '') !== '') {
                                                                    origin = `${p.customer.zip_data.latitude.toString()},${p.customer.zip_data.longitude.toString()}`;
                                                                }
                                                            }
                                                            return false;
                                                        })
                                                    } else {
                                                        selected_order.deliveries.map((d, i) => {
                                                            if (d.id === start.delivery_id) {
                                                                if ((d.customer?.zip_data || '') !== '') {
                                                                    origin = `${d.customer.zip_data.latitude.toString()},${d.customer.zip_data.longitude.toString()}`;
                                                                }
                                                            }
                                                            return false;
                                                        })
                                                    }

                                                    selected_order.routing.map((item, i) => {
                                                        if (i > 0 && i < (selected_order.routing.length - 1)) {
                                                            if (item.type === 'pickup') {
                                                                selected_order.pickups.map((p, i) => {
                                                                    if (p.id === item.pickup_id) {
                                                                        if ((p.customer?.zip_data || '') !== '') {
                                                                            waypoints.push(`${p.customer.zip_data.latitude.toString()},${p.customer.zip_data.longitude.toString()}`);
                                                                        }
                                                                    }
                                                                    return false;
                                                                })
                                                            } else {
                                                                selected_order.deliveries.map((d, i) => {
                                                                    if (d.id === item.delivery_id) {
                                                                        if ((d.customer?.zip_data || '') !== '') {
                                                                            waypoints.push(`${d.customer.zip_data.latitude.toString()},${d.customer.zip_data.longitude.toString()}`);
                                                                        }
                                                                    }
                                                                    return false;
                                                                })
                                                            }
                                                        }

                                                        return true;
                                                    });

                                                    if (end.type === 'pickup') {
                                                        selected_order.pickups.map((p, i) => {
                                                            if (p.id === end.pickup_id) {
                                                                if ((p.customer?.zip_data || '') !== '') {
                                                                    destination = `${p.customer.zip_data.latitude.toString()},${p.customer.zip_data.longitude.toString()}`;
                                                                }
                                                            }
                                                            return false;
                                                        })
                                                    } else {
                                                        selected_order.deliveries.map((d, i) => {
                                                            if (d.id === end.delivery_id) {
                                                                if ((d.customer?.zip_data || '') !== '') {
                                                                    destination = `${d.customer.zip_data.latitude.toString()},${d.customer.zip_data.longitude.toString()}`;
                                                                }
                                                            }
                                                            return false;
                                                        })
                                                    }

                                                    let params = {
                                                        'routingMode': 'fast',
                                                        'transportMode': 'car',
                                                        'origin': origin,
                                                        'via': new H.service.Url.MultiValueQueryParameter(waypoints),
                                                        'destination': destination,
                                                        'return': 'summary'
                                                    }

                                                    if (routingService) {
                                                        routingService.calculateRoute(
                                                            params,
                                                            (result) => {
                                                                let miles = (result?.routes[0]?.sections || []).reduce((a, b) => {
                                                                    return a + b.summary.length;
                                                                }, 0) || 0;

                                                                selected_order.miles = miles;

                                                                setSelectedOrder(selected_order);
                                                                setMileageLoaderVisible(false);

                                                                axios.post(
                                                                    props.serverUrl + "/saveOrder",
                                                                    selected_order
                                                                ).then((res) => {
                                                                    if (res.data.result === "OK") {
                                                                        setSelectedOrder({
                                                                            ...selected_order,
                                                                            order_customer_ratings: res.data.order.order_customer_ratings,
                                                                            order_carrier_ratings: res.data.order.order_carrier_ratings,
                                                                        });

                                                                        props.setSelectedOrder({
                                                                            ...selected_order,
                                                                            order_customer_ratings: res.data.order.order_customer_ratings,
                                                                            order_carrier_ratings: res.data.order.order_carrier_ratings,
                                                                            component_id: props.componentId,
                                                                        });
                                                                    }
                                                                }).catch((e) => {
                                                                    console.log("error on saving order miles", e);
                                                                    setMileageLoaderVisible(false);
                                                                });
                                                            },
                                                            (error) => {
                                                                console.log("error getting mileage", error);
                                                                selected_order.miles = 0;

                                                                setSelectedOrder(selected_order);
                                                                setMileageLoaderVisible(false);

                                                                axios.post(
                                                                    props.serverUrl + "/saveOrder",
                                                                    selected_order
                                                                ).then((res) => {
                                                                    if (res.data.result === "OK") {
                                                                        setSelectedOrder({
                                                                            ...selected_order,
                                                                            order_customer_ratings: res.data.order.order_customer_ratings,
                                                                            order_carrier_ratings: res.data.order.order_carrier_ratings,
                                                                        });

                                                                        props.setSelectedOrder({
                                                                            ...selected_order,
                                                                            order_customer_ratings: res.data.order.order_customer_ratings,
                                                                            order_carrier_ratings: res.data.order.order_carrier_ratings,
                                                                            component_id: props.componentId,
                                                                        });
                                                                    }
                                                                }).catch((e) => {
                                                                    console.log("error on saving order miles", e);
                                                                    setMileageLoaderVisible(false);
                                                                });
                                                            }
                                                        );
                                                    }


                                                } else {
                                                    selected_order.miles = 0;
                                                    setSelectedOrder(selected_order);
                                                    setMileageLoaderVisible(false);

                                                    axios.post(
                                                        props.serverUrl + "/saveOrder",
                                                        selected_order
                                                    ).then((res) => {
                                                        if (res.data.result === "OK") {
                                                            setSelectedOrder({
                                                                ...selected_order,
                                                                order_customer_ratings: res.data.order.order_customer_ratings,
                                                                order_carrier_ratings: res.data.order.order_carrier_ratings,
                                                            });

                                                            props.setSelectedOrder({
                                                                ...selected_order,
                                                                order_customer_ratings: res.data.order.order_customer_ratings,
                                                                order_carrier_ratings: res.data.order.order_carrier_ratings,
                                                                component_id: props.componentId,
                                                            });
                                                        }
                                                    }).catch((e) => {
                                                        console.log("error on saving order miles", e);
                                                        setMileageLoaderVisible(false);
                                                    });
                                                }
                                            }).catch((e) => {
                                                console.log("error on saving order routing", e);
                                                setMileageLoaderVisible(false);
                                            });
                                        }
                                    }
                                } else {

                                }
                                setIsSavingDeliveryId(-1);
                            }).catch((e) => {
                                console.log("error on saving delivery", e);
                                setIsSavingDeliveryId(-1);
                            });

                        }
                    } else {
                        console.log("saving delivery customer undefined");
                        setIsSavingDeliveryId(-1);
                    }
                } else {
                    console.log("saving delivery undefined");
                    setIsSavingDeliveryId(-1);
                }
            } else {
                console.log("no order selected");
                setIsSavingDeliveryId(-1);
            }
        }
    }, [isSavingDeliveryId]);

    const validateTemplateForSaving = (e) => {
        let key = e.keyCode || e.which;

        if (key === 9) {
            if (!isSavingTemplate) {
                setIsSavingTemplate(true);
            }
        }
    };

    useEffect(() => {
        if (isSavingTemplate) {
            let selectedTemplate = { ...selectedOrder };


        }
    }, [isSavingTemplate])

    const getOrderByOrderNumber = (e, action = null) => {
        let key = e.keyCode || e.which;

        if (key === 9) {
            if ((selectedOrder.order_number || "") !== "") {
                setIsLoading(true);
                axios.post(props.serverUrl + "/getOrderByOrderNumber", {
                    order_number: selectedOrder.order_number,
                    user_code: (props.user?.user_code?.type || 'employee') === 'agent' ? props.user.user_code.code : '',
                    action: action
                }).then((res) => {
                    if (res.data.result === "OK") {
                        let order = JSON.parse(JSON.stringify(res.data.order));

                        setSelectedOrder({});
                        setSelectedOrder({
                            ...order,
                            division: {
                                ...(order?.division || {}),
                                name: (order?.division?.name || '') + ' - ' + (order?.division?.type || '')
                            }
                        });
                        // await setTempRouting(order.routing);


                        setSelectedBillToCustomer({ ...order.bill_to_company });
                        setSelectedBillToCustomerContact({
                            ...(order.bill_to_company?.contacts || []).find(
                                (c) => c.is_primary === 1
                            ),
                        });

                        order.pickups = (order.pickups || []).map((p) => {
                            if (p.customer) {
                                let contacts = [
                                    ...(p.customer.contacts || [])
                                ].sort(function (a, b) {
                                    var aFirstChar = a.first_name.charAt(0);
                                    var bFirstChar = b.first_name.charAt(0);
                                    if (aFirstChar > bFirstChar) {
                                        return 1;
                                    } else if (aFirstChar < bFirstChar) {
                                        return -1;
                                    } else {
                                        var aLastChar = a.last_name.charAt(0);
                                        var bLastChar = b.last_name.charAt(0);
                                        if (aLastChar > bLastChar) {
                                            return 1;
                                        } else if (aLastChar < bLastChar) {
                                            return -1;
                                        } else {
                                            return 0;
                                        }
                                    }
                                });

                                p.customer = {
                                    ...p.customer,
                                    contacts: contacts
                                }
                            }
                            return p;
                        })

                        let pickup_id = (order.routing || []).find((r) => r.type === "pickup")?.pickup_id || 0;
                        let pickup = {
                            ...((order.pickups || []).find((p) => p.id === pickup_id) ||
                                (order.pickups || [])[0]),
                        };

                        setSelectedShipperCustomer(
                            pickup.id === undefined
                                ? {}
                                : {
                                    ...pickup.customer,
                                    pickup_id: pickup.id,
                                    contact_id: pickup.contact_id,
                                    contact_name: pickup.contact_name,
                                    contact_phone: pickup.contact_phone,
                                    contact_primary_phone: pickup.contact_primary_phone,
                                    contact_phone_ext: pickup.contact_phone_ext,
                                    pu_date1: pickup.pu_date1,
                                    pu_date2: pickup.pu_date2,
                                    pu_time1: pickup.pu_time1,
                                    pu_time2: pickup.pu_time2,
                                    bol_numbers: pickup.bol_numbers,
                                    po_numbers: pickup.po_numbers,
                                    ref_numbers: pickup.ref_numbers,
                                    seal_number: pickup.seal_number,
                                    special_instructions: pickup.special_instructions,
                                    type: pickup.type,
                                }
                        );
                        setSelectedShipperCustomerContact({
                            ...(pickup.contacts || []).find((c) => c.is_primary === 1),
                        });

                        let delivery_id = (order.routing || []).find((r) => r.type === "delivery")?.delivery_id || 0;
                        let delivery = { ...((order.deliveries || []).find((d) => d.id === delivery_id) || (order.deliveries || [])[0]), };

                        setSelectedConsigneeCustomer(
                            delivery.id === undefined
                                ? {}
                                : {
                                    ...delivery.customer,
                                    delivery_id: delivery.id,
                                    contact_id: delivery.contact_id,
                                    contact_name: delivery.contact_name,
                                    contact_phone: delivery.contact_phone,
                                    contact_primary_phone: delivery.contact_primary_phone,
                                    contact_phone_ext: delivery.contact_phone_ext,
                                    delivery_date1: delivery.delivery_date1,
                                    delivery_date2: delivery.delivery_date2,
                                    delivery_time1: delivery.delivery_time1,
                                    delivery_time2: delivery.delivery_time2,
                                    bol_numbers: delivery.bol_numbers,
                                    po_numbers: delivery.po_numbers,
                                    ref_numbers: delivery.ref_numbers,
                                    seal_number: delivery.seal_number,
                                    special_instructions: delivery.special_instructions,
                                    type: delivery.type,
                                }
                        );
                        setSelectedConsigneeCustomerContact({ ...(delivery.contacts || []).find((c) => c.is_primary === 1), });

                        setSelectedCarrier({ ...order.carrier });

                        setSelectedCarrierContact(order.carrier
                            ? order.carrier_contact_id
                                ? { ...((order.carrier?.contacts || []).find((c) => c.id === order.carrier_contact_id) || {}) }
                                : { ...(order.carrier?.contacts || []).find((c) => c.is_primary === 1) }
                            : {});

                        setSelectedCarrierDriver({
                            ...order.driver,
                            name: (order.driver?.first_name || "") +
                                ((order.driver?.last_name || "").trim() === ""
                                    ? ""
                                    : " " + (order.driver?.last_name || "")),
                        });

                        setIsLoading(false);

                        refDispatchEvents.current.focus();
                    } else {
                        setIsLoading(false);
                        dispatchClearBtnClick();
                        if (props.isOnPanel) {
                            if (refOrderNumber?.current) {
                                refOrderNumber.current.focus({
                                    preventScroll: true,
                                });
                            }
                        } else {
                            if (props.refOrderNumber?.current) {
                                props.refOrderNumber.current.focus({
                                    preventScroll: true,
                                });
                            }
                        }
                    }
                }).catch((e) => {
                    console.log("error getting order by order number", e);
                    setIsLoading(false);
                });
            }
        }
    };

    const getOrderByTripNumber = (e) => {
        let key = e.keyCode || e.which;

        if (key === 9) {
            if ((props.trip_number || "") !== "") {
                setIsLoading(true);
                axios.post(props.serverUrl + "/getOrderByTripNumber", {
                    trip_number: props.trip_number,
                    user_code: (props.user?.user_code?.type || 'employee') === 'agent' ? props.user.user_code.code : ''
                }).then((res) => {
                    if (res.data.result === "OK") {
                        let order = JSON.parse(JSON.stringify(res.data.order));

                        setSelectedOrder({});
                        setSelectedOrder(order);
                        // await setTempRouting(order.routing);

                        setSelectedBillToCustomer({ ...order.bill_to_company });

                        setSelectedBillToCustomerContact({
                            ...(order.bill_to_company?.contacts || []).find(
                                (c) => c.is_primary === 1
                            ),
                        });

                        let pickup_id = (order.routing || []).find((r) => r.type === "pickup")?.pickup_id || 0;
                        let pickup = {
                            ...((order.pickups || []).find((p) => p.id === pickup_id) ||
                                (order.pickups || [])[0]),
                        };

                        setSelectedShipperCustomer(
                            pickup.id === undefined
                                ? {}
                                : {
                                    ...pickup.customer,
                                    pickup_id: pickup.id,
                                    contact_id: pickup.contact_id,
                                    contact_name: pickup.contact_name,
                                    contact_phone: pickup.contact_phone,
                                    contact_primary_phone: pickup.contact_primary_phone,
                                    contact_phone_ext: pickup.contact_phone_ext,
                                    pu_date1: pickup.pu_date1,
                                    pu_date2: pickup.pu_date2,
                                    pu_time1: pickup.pu_time1,
                                    pu_time2: pickup.pu_time2,
                                    bol_numbers: pickup.bol_numbers,
                                    po_numbers: pickup.po_numbers,
                                    ref_numbers: pickup.ref_numbers,
                                    seal_number: pickup.seal_number,
                                    special_instructions: pickup.special_instructions,
                                    type: pickup.type,
                                }
                        );

                        setSelectedShipperCustomerContact({
                            ...(pickup.contacts || []).find((c) => c.is_primary === 1),
                        });

                        let delivery_id =
                            (order.routing || []).find((r) => r.type === "delivery")
                                ?.delivery_id || 0;
                        let delivery = {
                            ...((order.deliveries || []).find(
                                (d) => d.id === delivery_id
                            ) || (order.deliveries || [])[0]),
                        };

                        setSelectedConsigneeCustomer(
                            delivery.id === undefined
                                ? {}
                                : {
                                    ...delivery.customer,
                                    delivery_id: delivery.id,
                                    contact_id: delivery.contact_id,
                                    contact_name: delivery.contact_name,
                                    contact_phone: delivery.contact_phone,
                                    contact_primary_phone: delivery.contact_primary_phone,
                                    contact_phone_ext: delivery.contact_phone_ext,
                                    delivery_date1: delivery.delivery_date1,
                                    delivery_date2: delivery.delivery_date2,
                                    delivery_time1: delivery.delivery_time1,
                                    delivery_time2: delivery.delivery_time2,
                                    bol_numbers: delivery.bol_numbers,
                                    po_numbers: delivery.po_numbers,
                                    ref_numbers: delivery.ref_numbers,
                                    seal_number: delivery.seal_number,
                                    special_instructions: delivery.special_instructions,
                                    type: delivery.type,
                                }
                        );

                        setSelectedConsigneeCustomerContact({
                            ...(delivery.contacts || []).find((c) => c.is_primary === 1),
                        });

                        setSelectedCarrier({ ...order.carrier });
                        setSelectedCarrierContact({
                            ...(order.carrier?.contacts || []).find(
                                (c) => c.is_primary === 1
                            ),
                        });

                        setSelectedCarrierDriver({
                            ...order.driver,
                            name:
                                (order.driver?.first_name || "") +
                                ((order.driver?.last_name || "").trim() === ""
                                    ? ""
                                    : " " + (order.driver?.last_name || "")),
                        });

                        setIsLoading(false);

                        refDispatchEvents.current.focus();
                    } else {
                        setIsLoading(false);
                        dispatchClearBtnClick();
                        if (props.isOnPanel) {
                            if (refOrderNumber?.current) {
                                refOrderNumber.current.focus({
                                    preventScroll: true,
                                });
                            }
                        } else {
                            if (props.refOrderNumber?.current) {
                                props.refOrderNumber.current.focus({
                                    preventScroll: true,
                                });
                            }
                        }
                    }
                })
                    .catch((e) => {
                        console.log("error getting order by trip number", e);
                        setIsLoading(false);
                    });
            }
        }
    };

    const bolNumbersOnKeydown = async (e) => {
        let keyCode = e.keyCode || e.which;

        if (keyCode === 9 || keyCode === 13) {
            if (shipperBolNumber || "" !== "") {
                e.preventDefault();
                e.stopPropagation();
            }

            await setSelectedShipperCustomer({
                ...selectedShipperCustomer,
                bol_numbers: (
                    (selectedShipperCustomer?.bol_numbers || "") +
                    "|" +
                    shipperBolNumber
                ).trim(),
            });

            let pickups = (selectedOrder?.pickups || []).map((pu, i) => {
                if (pu.id === selectedShipperCustomer.pickup_id) {
                    pu.bol_numbers = (
                        (selectedShipperCustomer?.bol_numbers || "") +
                        "|" +
                        shipperBolNumber
                    ).trim();
                }
                return pu;
            });

            await setSelectedOrder({ ...selectedOrder, pickups: pickups });
            await setShipperBolNumber("");
            refBolNumbers.current.focus();

            // setIsSavingPickupId(selectedShipperCustomer?.pickup_id || 0);
        }
    };

    const poNumbersOnKeydown = async (e) => {
        let keyCode = e.keyCode || e.which;

        if (keyCode === 9 || keyCode === 13) {
            if (shipperPoNumber || "" !== "") {
                e.preventDefault();
                e.stopPropagation();
            }

            await setSelectedShipperCustomer({
                ...selectedShipperCustomer,
                po_numbers: (
                    (selectedShipperCustomer?.po_numbers || "") +
                    "|" +
                    shipperPoNumber
                ).trim(),
            });

            let pickups = (selectedOrder?.pickups || []).map((pu, i) => {
                if (pu.id === selectedShipperCustomer.pickup_id) {
                    pu.po_numbers = (
                        (selectedShipperCustomer?.po_numbers || "") +
                        "|" +
                        shipperPoNumber
                    ).trim();
                }
                return pu;
            });

            await setSelectedOrder({ ...selectedOrder, pickups: pickups });
            await setShipperPoNumber("");
            refPoNumbers.current.focus();

            // setIsSavingPickupId(selectedShipperCustomer?.pickup_id || 0);
        }
    };

    const refNumbersOnKeydown = async (e) => {
        let keyCode = e.keyCode || e.which;

        if (keyCode === 9 || keyCode === 13) {
            if (shipperRefNumber || "" !== "") {
                e.preventDefault();
                e.stopPropagation();
            }

            await setSelectedShipperCustomer({
                ...selectedShipperCustomer,
                ref_numbers: (
                    (selectedShipperCustomer?.ref_numbers || "") +
                    "|" +
                    shipperRefNumber
                ).trim(),
            });

            let pickups = (selectedOrder?.pickups || []).map((pu, i) => {
                if (pu.id === selectedShipperCustomer.pickup_id) {
                    pu.ref_numbers = (
                        (selectedShipperCustomer?.ref_numbers || "") +
                        "|" +
                        shipperRefNumber
                    ).trim();
                }
                return pu;
            });

            await setSelectedOrder({ ...selectedOrder, pickups: pickups });
            await setShipperRefNumber("");
            refRefNumbers.current.focus();

            // setIsSavingPickupId(selectedShipperCustomer?.pickup_id || 0);
        }
    };

    const deliveryBolNumbersOnKeydown = async (e) => {
        let keyCode = e.keyCode || e.which;

        if (keyCode === 9 || keyCode === 13) {
            if (consigneeBolNumber || "" !== "") {
                e.preventDefault();
                e.stopPropagation();
            }

            await setSelectedConsigneeCustomer({
                ...selectedConsigneeCustomer,
                bol_numbers: (
                    (selectedConsigneeCustomer?.bol_numbers || "") +
                    "|" +
                    consigneeBolNumber
                ).trim(),
            });

            let deliveries = (selectedOrder?.deliveries || []).map((delivery, i) => {
                if (delivery.id === selectedConsigneeCustomer.delivery_id) {
                    delivery.bol_numbers = (
                        (selectedConsigneeCustomer?.bol_numbers || "") +
                        "|" +
                        consigneeBolNumber
                    ).trim();
                }
                return delivery;
            });

            await setSelectedOrder({ ...selectedOrder, deliveries: deliveries });
            await setConsigneeBolNumber("");
            refDeliveryBolNumbers.current.focus();

            // setIsSavingDeliveryId(selectedConsigneeCustomer?.delivery_id || 0);
        }
    };

    const deliveryPoNumbersOnKeydown = async (e) => {
        let keyCode = e.keyCode || e.which;

        if (keyCode === 9) {
            if (consigneePoNumber || "" !== "") {
                e.preventDefault();
                e.stopPropagation();
            }

            await setSelectedConsigneeCustomer({
                ...selectedConsigneeCustomer,
                po_numbers: (
                    (selectedConsigneeCustomer?.po_numbers || "") +
                    "|" +
                    consigneePoNumber
                ).trim(),
            });

            let deliveries = (selectedOrder?.deliveries || []).map((delivery, i) => {
                if (delivery.id === selectedConsigneeCustomer.delivery_id) {
                    delivery.po_numbers = (
                        (selectedConsigneeCustomer?.po_numbers || "") +
                        "|" +
                        consigneePoNumber
                    ).trim();
                }
                return delivery;
            });

            await setSelectedOrder({ ...selectedOrder, deliveries: deliveries });
            await setConsigneePoNumber("");
            refDeliveryPoNumbers.current.focus();

            // setIsSavingDeliveryId(selectedConsigneeCustomer?.delivery_id || 0);
        }
    };

    const deliveryRefNumbersOnKeydown = async (e) => {
        let keyCode = e.keyCode || e.which;

        if (keyCode === 9 || keyCode === 13) {
            if (consigneeRefNumber || "" !== "") {
                e.preventDefault();
                e.stopPropagation();
            }

            await setSelectedConsigneeCustomer({
                ...selectedConsigneeCustomer,
                ref_numbers: (
                    (selectedConsigneeCustomer?.ref_numbers || "") +
                    "|" +
                    consigneeRefNumber
                ).trim(),
            });

            let deliveries = (selectedOrder?.deliveries || []).map((delivery, i) => {
                if (delivery.id === selectedConsigneeCustomer.delivery_id) {
                    delivery.ref_numbers = (
                        (selectedConsigneeCustomer?.ref_numbers || "") +
                        "|" +
                        consigneeRefNumber
                    ).trim();
                }
                return delivery;
            });

            await setSelectedOrder({ ...selectedOrder, deliveries: deliveries });
            await setConsigneeRefNumber("");
            refDeliveryRefNumbers.current.focus();

            // setIsSavingDeliveryId(selectedConsigneeCustomer?.delivery_id || 0);
        }
    };

    const printWindow = (data) => {
        let mywindow = window.open("", "new div", "height=400,width=600");
        mywindow.document.write("<html><head><title></title>");
        mywindow.document.write(
            '<link rel="stylesheet" href="../../css/index.css" type="text/css" media="all" />'
        );
        mywindow.document.write(
            "<style>@media print {@page {margin: 0;}body {margin:0;padding: 15mm 10mm;}}</style>"
        );
        mywindow.document.write("</head><body >");
        mywindow.document.write(data);
        mywindow.document.write("</body></html>");
        mywindow.document.close();
        mywindow.focus();
        setTimeout(function () {
            mywindow.print();
        }, 1000);

        return true;
    };

    const getPickupsOnRouting = () => {
        let pickups = [];

        try {
            (selectedOrder?.routing || []).map((r, i) => {
                if (r.type === "pickup") {
                    pickups.push(selectedOrder.pickups.find((p) => p.id === r.pickup_id));
                }
                return false;
            });
        } catch (e) {

        }

        return pickups;
    };

    const getDeliveriesOnRouting = () => {
        let deliveries = [];

        (selectedOrder?.routing || []).map((r, i) => {
            if (r.type === "delivery") {
                deliveries.push(
                    selectedOrder.deliveries.find((d) => d.id === r.delivery_id)
                );
            }
            return false;
        });

        return deliveries;
    };

    const importOrdersBtnClick = () => {
        let panel = {
            panelName: `${props.panelName}-order-import`,
            component: <OrderImport
                title='Import Orders'
                tabTimes={95000 + props.tabTimes}
                panelName={`${props.panelName}-order-import`}
                origin={props.origin}
                closingCallback={() => {
                    closePanel(`${props.panelName}-order-import`, props.origin);
                    (props.isOnPanel ? refOrderNumber : props.refOrderNumber).current.focus({ preventScroll: true });
                }}

                componentId={moment().format('x')}
            />
        }

        openPanel(panel, props.origin);
    }

    const openPanel = (panel, origin) => {
        if (origin === 'admin-home') {
            if (props.adminHomePanels.find(p => p.panelName === panel.panelName) === undefined) {
                props.setAdminHomePanels([...props.adminHomePanels, panel]);
            }
        }

        if (origin === 'admin-carrier') {
            if (props.adminCarrierPanels.find(p => p.panelName === panel.panelName) === undefined) {
                props.setAdminCarrierPanels([...props.adminCarrierPanels, panel]);
            }
        }

        if (origin === 'admin-company-setup') {
            if (props.adminCompanySetupPanels.find(p => p.panelName === panel.panelName) === undefined) {
                props.setAdminCompanySetupPanels([...props.adminCompanySetupPanels, panel]);
            }
        }

        if (origin === 'admin-customer') {
            if (props.adminCustomerPanels.find(p => p.panelName === panel.panelName) === undefined) {
                props.setAdminCustomerPanels([...props.adminCustomerPanels, panel]);
            }
        }

        if (origin === 'admin-dispatch') {
            if (props.adminDispatchPanels.find(p => p.panelName === panel.panelName) === undefined) {
                props.setAdminDispatchPanels([...props.adminDispatchPanels, panel]);
            }
        }

        if (origin === 'admin-invoice') {
            if (props.adminInvoicePanels.find(p => p.panelName === panel.panelName) === undefined) {
                props.setAdminInvoicePanels([...props.adminInvoicePanels, panel]);
            }
        }

        if (origin === 'admin-report') {
            if (props.adminReportPanels.find(p => p.panelName === panel.panelName) === undefined) {
                props.setAdminReportPanels([...props.adminReportPanels, panel]);
            }
        }

        if (origin === 'company-home') {
            if (props.companyHomePanels.find(p => p.panelName === panel.panelName) === undefined) {
                props.setCompanyHomePanels([...props.companyHomePanels, panel]);
            }
        }

        if (origin === 'company-carrier') {
            if (props.companyCarrierPanels.find(p => p.panelName === panel.panelName) === undefined) {
                props.setCompanyCarrierPanels([...props.companyCarrierPanels, panel]);
            }
        }

        if (origin === 'company-customer') {
            if (props.companyCustomerPanels.find(p => p.panelName === panel.panelName) === undefined) {
                props.setCompanyCustomerPanels([...props.companyCustomerPanels, panel]);
            }
        }

        if (origin === 'company-dispatch') {
            if (props.companyDispatchPanels.find(p => p.panelName === panel.panelName) === undefined) {
                props.setCompanyDispatchPanels([...props.companyDispatchPanels, panel]);
            }
        }

        if (origin === 'company-invoice') {
            if (props.companyInvoicePanels.find(p => p.panelName === panel.panelName) === undefined) {
                props.setCompanyInvoicePanels([...props.companyInvoicePanels, panel]);
            }
        }

        if (origin === 'company-load-board') {
            if (props.companyLoadBoardPanels.find(p => p.panelName === panel.panelName) === undefined) {
                props.setCompanyLoadBoardPanels([...props.companyLoadBoardPanels, panel]);
            }
        }

        if (origin === 'company-report') {
            if (props.companyReportPanels.find(p => p.panelName === panel.panelName) === undefined) {
                props.setCompanyReportPanels([...props.companyReportPanels, panel]);
            }
        }
    }

    const closePanel = (panelName, origin) => {
        if (origin === 'admin-home') {
            props.setAdminHomePanels(props.adminHomePanels.filter(panel => panel.panelName !== panelName));
        }

        if (origin === 'admin-carrier') {
            props.setAdminCarrierPanels(props.adminCarrierPanels.filter(panel => panel.panelName !== panelName));
        }

        if (origin === 'admin-company-setup') {
            props.setAdminCompanySetupPanels(props.adminCompanySetupPanels.filter(panel => panel.panelName !== panelName));
        }

        if (origin === 'admin-customer') {
            props.setAdminCustomerPanels(props.adminCustomerPanels.filter(panel => panel.panelName !== panelName));
        }

        if (origin === 'admin-dispatch') {
            props.setAdminDispatchPanels(props.adminDispatchPanels.filter(panel => panel.panelName !== panelName));
        }

        if (origin === 'admin-invoice') {
            props.setAdminInvoicePanels(props.adminInvoicePanels.filter(panel => panel.panelName !== panelName));
        }

        if (origin === 'admin-report') {
            props.setAdminReportPanels(props.adminReportPanels.filter(panel => panel.panelName !== panelName));
        }

        if (origin === 'company-home') {
            props.setCompanyHomePanels(props.companyHomePanels.filter(panel => panel.panelName !== panelName));
        }

        if (origin === 'company-carrier') {
            props.setCompanyCarrierPanels(props.companyCarrierPanels.filter(panel => panel.panelName !== panelName));
        }

        if (origin === 'company-customer') {
            props.setCompanyCustomerPanels(props.companyCustomerPanels.filter(panel => panel.panelName !== panelName));
        }

        if (origin === 'company-dispatch') {
            props.setCompanyDispatchPanels(props.companyDispatchPanels.filter(panel => panel.panelName !== panelName));
        }

        if (origin === 'company-invoice') {
            props.setCompanyInvoicePanels(props.companyInvoicePanels.filter(panel => panel.panelName !== panelName));
        }

        if (origin === 'company-load-board') {
            props.setCompanyLoadBoardPanels(props.companyLoadBoardPanels.filter(panel => panel.panelName !== panelName));
        }

        if (origin === 'company-report') {
            props.setCompanyReportPanels(props.companyReportPanels.filter(panel => panel.panelName !== panelName));
        }
    }

    const doUseTemplate = (template_id) => {
        if (window.confirm('Are you sure you want to proceed?')) {
            setIsLoading(true);

            axios.post(props.serverUrl + '/useTemplate', { id: template_id, user_code_id: props.user.user_code.id }).then(res => {
                if (res.data.result === 'OK') {
                    let order = JSON.parse(JSON.stringify(res.data.order));
                    setSelectedOrder({});
                    setSelectedOrder(order);

                    setSelectedBillToCustomer({ ...order.bill_to_company });
                    setSelectedBillToCustomerContact({ ...(order.bill_to_company?.contacts || []).find((c) => c.is_primary === 1), });

                    let pickup_id = (order.routing || []).find((r) => r.type === "pickup")?.pickup_id || 0;
                    let pickup = { ...((order.pickups || []).find((p) => p.id === pickup_id) || (order.pickups || [])[0]), };

                    setSelectedShipperCustomer(pickup.id === undefined
                        ? {}
                        : {
                            ...pickup.customer,
                            pickup_id: pickup.id,
                            contact_id: pickup.contact_id,
                            contact_name: pickup.contact_name,
                            contact_phone: pickup.contact_phone,
                            contact_primary_phone: pickup.contact_primary_phone,
                            contact_phone_ext: pickup.contact_phone_ext,
                            pu_date1: pickup.pu_date1,
                            pu_date2: pickup.pu_date2,
                            pu_time1: pickup.pu_time1,
                            pu_time2: pickup.pu_time2,
                            bol_numbers: pickup.bol_numbers,
                            po_numbers: pickup.po_numbers,
                            ref_numbers: pickup.ref_numbers,
                            seal_number: pickup.seal_number,
                            special_instructions: pickup.special_instructions,
                            type: pickup.type,
                        }
                    );
                    setSelectedShipperCustomerContact({
                        ...(pickup.contacts || []).find((c) => c.is_primary === 1),
                    });

                    let delivery_id = (order.routing || []).find((r) => r.type === "delivery")?.delivery_id || 0;
                    let delivery = {
                        ...((order.deliveries || []).find((d) => d.id === delivery_id) ||
                            (order.deliveries || [])[0]),
                    };

                    setSelectedConsigneeCustomer(
                        delivery.id === undefined
                            ? {}
                            : {
                                ...delivery.customer,
                                delivery_id: delivery.id,
                                contact_id: delivery.contact_id,
                                contact_name: delivery.contact_name,
                                contact_phone: delivery.contact_phone,
                                contact_primary_phone: delivery.contact_primary_phone,
                                contact_phone_ext: delivery.contact_phone_ext,
                                delivery_date1: delivery.delivery_date1,
                                delivery_date2: delivery.delivery_date2,
                                delivery_time1: delivery.delivery_time1,
                                delivery_time2: delivery.delivery_time2,
                                contact_id: delivery.contact_id || "",
                                contact_name: delivery.contact_name || "",
                                contact_phone: delivery.contact_phone || "",
                                contact_primary_phone: delivery.contact_primary_phone || "work",
                                special_instructions: delivery.special_instructions,
                                type: delivery.type,
                            }
                    );
                    setSelectedConsigneeCustomerContact({
                        ...(delivery.contacts || []).find((c) => c.is_primary === 1),
                    });

                    setSelectedCarrier({ ...order.carrier });
                    setSelectedCarrierContact(order.carrier
                        ? order.carrier_contact_id
                            ? { ...((order.carrier?.contacts || []).find((c) => c.id === order.carrier_contact_id) || {}) }
                            : { ...(order.carrier?.contacts || []).find((c) => c.is_primary === 1) }
                        : {});
                    setSelectedCarrierDriver({
                        ...order.driver,
                        name:
                            (order.driver?.first_name || "") +
                            ((order.driver?.last_name || "").trim() === ""
                                ? ""
                                : " " + (order.driver?.last_name || "")),
                    });

                    setIsLoading(false);

                    if (refDispatchEvents?.current) {
                        console.log(refDispatchEvents)
                        refDispatchEvents.current.focus();
                    }
                } else {
                    console.log(res.data.result);
                    setIsLoading(false);
                }
            }).catch(e => {
                console.log('error using template', e);
            })
        }
    }

    const getLoadBoardOrders = () => {
        props.setIsLoadingWidget(true)
        axios.post(props.serverUrl + '/getLoadBoardOrders', {
            user_code: props.user.user_code.type === 'agent' ? props.user.user_code.code : ''
        }).then(async res => {
            if (res.data.result === 'OK') {
                props.setAvailableOrders(res.data.available_orders);
                props.setBookedOrders(res.data.booked_orders);
                props.setInTransitOrders(res.data.in_transit_orders);
                props.setDeliveredNotInvoiced(res.data.not_invoiced_orders);
            }
        }).catch(e => {
            console.log('error loading orders', e)
        }).finally(() => {
            props.setIsLoadingWidget(false)
        })
    }

    return (
        <div className="dispatch-main-container" style={{
            borderRadius: props.scale === 1 ? 0 : "20px",
            background: props.isOnPanel ? "transparent" : "rgb(250, 250, 250)",
            background: props.isOnPanel ? "transparent" : "-moz-radial-gradient(center, ellipse cover, rgba(250, 250, 250, 1) 0%, rgba(200, 200, 200, 1) 100%)",
            background: props.isOnPanel ? "transparent" : "-webkit-radial-gradient(center, ellipse cover, rgba(250, 250, 250, 1) 0%, rgba(200, 200, 200, 1) 100%)",
            background: props.isOnPanel ? "transparent" : "radial-gradient(ellipse at center, rgba(250, 250, 250, 1) 0%, rgba(200, 200, 200, 1) 100%)",
            padding: props.isOnPanel ? "10px 0" : 10,
            position: props.isOnPanel ? "unset" : "relative",
        }}
            ref={props.isOnPanel ? null : props.refDispatchScreen}
            tabIndex={-1}
            onKeyDown={(e) => {
                if (e.key === "Escape") {
                    e.stopPropagation();
                    if ((selectedOrder?.id || 0) > 0) {
                        dispatchClearBtnClick();
                    } else {
                        if (props.isOnPanel) {
                            props.closingCallback();
                        }
                    }
                }

                if (e.key === "Tab") {
                    if (e.target.type === undefined) {
                        e.preventDefault();
                        if (props.isOnPanel) {
                            if (refOrderNumber?.current) {
                                (props.isOnPanel ? refOrderNumber : props.refOrderNumber).current.focus({ preventScroll: true });
                            }
                        } else {
                            if (props.refOrderNumber?.current) {
                                props.refOrderNumber.current.focus({ preventScroll: true });
                            }
                        }
                    }
                }
            }}>
            {
                (props.isOnPanel) &&
                <div className="close-btn" title="Close" onClick={e => {
                    props.closingCallback();
                }}><span className="fas fa-times"></span></div>
            }
            {
                showModalTemplate &&
                <ModalTemplate
                    name={selectedTemplate?.name || ''}
                    is_new_template={isEditingTemplate ? 0 : 1}
                    cb={(data) => {
                        setShowModalTemplate(false)
                    }}
                />
            }

            <div className="fields-container-row">
                <div
                    className="fields-container-col"
                    style={{
                        minWidth: "91%",
                        maxWidth: "91%",
                        display: "flex",
                        flexDirection: "column",
                        marginRight: 10,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginBottom: 10,
                            flexGrow: 1,
                            flexBasis: "100%",
                            alignItems: "center",
                        }}
                    >
                        <div style={{ minWidth: "38%", maxWidth: "38%", marginRight: 10 }}>
                            <div className="form-borderless-box">
                                <div className="form-row" style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div className="input-box-container"
                                        style={{
                                            // width: "9rem",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div style={{
                                            fontSize: "0.7rem",
                                            color: "rgba(0,0,0,0.7)",
                                            whiteSpace: "nowrap",
                                        }}
                                        >
                                            A/E Number:
                                        </div>
                                        <input
                                            style={{ textAlign: "right", fontWeight: "bold" }}
                                            type="text"
                                            readOnly={true}
                                            onChange={(e) => {
                                            }}
                                            value={selectedOrder?.user_code?.code || ''}
                                        />
                                    </div>
                                    <div className="form-h-sep"></div>

                                    <span className="fas fa-chevron-left" style={{
                                        fontSize: '1rem',
                                        margin: '0px 7px 0px 7px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        pointerEvents: (isEditingTemplate || isCreatingTemplate) || ((selectedOrder?.id || 0) === 0 || (selectedOrder?.order_number || '').toString().length < 5) ? 'none' : 'all',
                                        color: (selectedOrder?.id || 0) > 0 && (selectedOrder?.order_number || '').toString().length >= 5 ? '#4096ee' : 'rgba(0,0,0,0.3)'
                                    }} onClick={() => {
                                        getOrderByOrderNumber({ keyCode: 9 }, 'previous');
                                    }}></span>

                                    <div className="input-box-container" style={{
                                        // width: "9rem",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}>
                                        <div style={{
                                            fontSize: "0.7rem",
                                            color: "rgba(0,0,0,0.7)",
                                            whiteSpace: "nowrap",
                                        }} >
                                            Order Number
                                        </div>
                                        <input
                                            style={{ textAlign: "right", fontWeight: "bold" }}
                                            tabIndex={1 + props.tabTimes}
                                            type="text"
                                            ref={props.isOnPanel ? refOrderNumber : props.refOrderNumber}
                                            readOnly={isEditingTemplate || isCreatingTemplate}
                                            onKeyDown={getOrderByOrderNumber}
                                            onChange={(e) => {
                                                setSelectedOrder(prev => {
                                                    return {
                                                        ...prev,
                                                        order_number: e.target.value
                                                    }
                                                })
                                            }}
                                            value={selectedOrder?.order_number || ""}
                                        />
                                    </div>

                                    <span className="fas fa-chevron-right" style={{
                                        fontSize: '1rem',
                                        margin: '0px 7px 0px 7px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        pointerEvents: (isCreatingTemplate || isEditingTemplate) || ((selectedOrder?.id || 0) === 0 || (selectedOrder?.order_number || '').toString().length < 5) ? 'none' : 'all',
                                        color: (selectedOrder?.id || 0) > 0 && (selectedOrder?.order_number || '').toString().length >= 5 ? '#4096ee' : 'rgba(0,0,0,0.3)'
                                    }} onClick={() => {
                                        getOrderByOrderNumber({ keyCode: 9 }, 'next');
                                    }}></span>

                                    <div className="form-h-sep"></div>
                                    <div
                                        className="input-box-container"
                                        style={{
                                            // width: "9rem",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontSize: "0.7rem",
                                                color: "rgba(0,0,0,0.7)",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            Trip Number
                                        </div>
                                        <input
                                            style={{ textAlign: "right", fontWeight: "bold" }}
                                            tabIndex={2 + props.tabTimes}
                                            type="text"
                                            readOnly={isCreatingTemplate || isEditingTemplate}
                                            onKeyDown={getOrderByTripNumber}
                                            onChange={(e) => {
                                                setSelectedOrder({
                                                    ...selectedOrder,
                                                    trip_number: e.target.value,
                                                });
                                            }}
                                            value={
                                                (selectedCarrier?.id || 0) === 0
                                                    ? ""
                                                    : (selectedOrder?.trip_number || 0) === 0
                                                        ? ""
                                                        : selectedOrder?.trip_number
                                            }
                                        />
                                    </div>
                                    <div className="form-h-sep"></div>

                                    <div className="mochi-button" onClick={dispatchClearBtnClick}>
                                        <div className="mochi-button-decorator mochi-button-decorator-left">
                                            (
                                        </div>
                                        <div className="mochi-button-base">Clear</div>
                                        <div className="mochi-button-decorator mochi-button-decorator-right">
                                            )
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ minWidth: "38%", maxWidth: "38%", marginRight: 10 }}>
                            <div className="form-borderless-box">
                                <div className="form-row" style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div className="select-box-container" style={{ flexGrow: 1 }}>
                                        <div className="select-box-wrapper">
                                            <input
                                                type="text"
                                                tabIndex={3 + props.tabTimes}
                                                placeholder="Division"
                                                ref={refDivision}
                                                readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                onKeyDown={async (e) => {
                                                    if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                        let key = e.keyCode || e.which;

                                                        switch (key) {
                                                            case 37:
                                                            case 38: // arrow left | arrow up
                                                                e.preventDefault();
                                                                if (divisionItems.length > 0) {
                                                                    let selectedIndex = divisionItems.findIndex((item) => item.selected);

                                                                    if (selectedIndex === -1) {
                                                                        await setDivisionItems(
                                                                            divisionItems.map((item, index) => {
                                                                                item.selected = index === 0;
                                                                                return item;
                                                                            })
                                                                        );
                                                                    } else {
                                                                        await setDivisionItems(
                                                                            divisionItems.map((item, index) => {
                                                                                if (selectedIndex === 0) {
                                                                                    item.selected =
                                                                                        index === divisionItems.length - 1;
                                                                                } else {
                                                                                    item.selected =
                                                                                        index === selectedIndex - 1;
                                                                                }
                                                                                return item;
                                                                            })
                                                                        );
                                                                    }

                                                                    refDivisionPopupItems.current.map((r, i) => {
                                                                        if (r && r.classList.contains("selected")) {
                                                                            r.scrollIntoView({
                                                                                behavior: "auto",
                                                                                block: "center",
                                                                                inline: "nearest",
                                                                            });
                                                                        }
                                                                        return true;
                                                                    });
                                                                } else {
                                                                    axios.post(props.serverUrl + "/getDivisions").then(async (res) => {
                                                                        if (res.data.result === "OK") {
                                                                            await setDivisionItems(res.data.divisions.map((item, index) => {
                                                                                let str = item.type;
                                                                                item.name = item.name + " - " + (str.charAt(0).toUpperCase() + str.slice(1));
                                                                                item.selected = (selectedOrder?.division?.id || 0) === 0
                                                                                    ? index === 0
                                                                                    : item.id === selectedOrder.division.id;
                                                                                return item;
                                                                            }));

                                                                            refDivisionPopupItems.current.map((r, i) => {
                                                                                if (r && r.classList.contains("selected")) {
                                                                                    r.scrollIntoView({
                                                                                        behavior: "auto",
                                                                                        block: "center",
                                                                                        inline: "nearest",
                                                                                    });
                                                                                }
                                                                                return true;
                                                                            });
                                                                        }
                                                                    }).catch(async (e) => {
                                                                        console.log("error getting divisions", e);
                                                                    });
                                                                }
                                                                break;

                                                            case 39:
                                                            case 40: // arrow right | arrow down
                                                                e.preventDefault();
                                                                if (divisionItems.length > 0) {
                                                                    let selectedIndex = divisionItems.findIndex((item) => item.selected);

                                                                    if (selectedIndex === -1) {
                                                                        await setDivisionItems(divisionItems.map((item, index) => {
                                                                            item.selected = index === 0;
                                                                            return item;
                                                                        }));
                                                                    } else {
                                                                        await setDivisionItems(
                                                                            divisionItems.map((item, index) => {
                                                                                if (selectedIndex === divisionItems.length - 1) {
                                                                                    item.selected = index === 0;
                                                                                } else {
                                                                                    item.selected = index === selectedIndex + 1;
                                                                                }
                                                                                return item;
                                                                            })
                                                                        );
                                                                    }

                                                                    refDivisionPopupItems.current.map((r, i) => {
                                                                        if (r && r.classList.contains("selected")) {
                                                                            r.scrollIntoView({
                                                                                behavior: "auto",
                                                                                block: "center",
                                                                                inline: "nearest",
                                                                            });
                                                                        }
                                                                        return true;
                                                                    });
                                                                } else {
                                                                    axios.post(props.serverUrl + "/getDivisions").then(async (res) => {
                                                                        if (res.data.result === "OK") {
                                                                            await setDivisionItems(res.data.divisions.map(
                                                                                (item, index) => {
                                                                                    let str = item.type;
                                                                                    item.name = item.name + " - " + (str.charAt(0).toUpperCase() + str.slice(1));
                                                                                    item.selected = (selectedOrder?.division?.id || 0) === 0
                                                                                        ? index === 0
                                                                                        : item.id === selectedOrder.division.id;
                                                                                    return item;
                                                                                }
                                                                            ));

                                                                            refDivisionPopupItems.current.map(
                                                                                (r, i) => {
                                                                                    if (r && r.classList.contains("selected")) {
                                                                                        r.scrollIntoView({
                                                                                            behavior: "auto",
                                                                                            block: "center",
                                                                                            inline: "nearest",
                                                                                        });
                                                                                    }
                                                                                    return true;
                                                                                }
                                                                            );
                                                                        }
                                                                    })
                                                                        .catch(async (e) => {
                                                                            console.log("error getting divisions", e);
                                                                        });
                                                                }
                                                                break;

                                                            case 27: // escape
                                                                setDivisionItems([]);
                                                                break;

                                                            case 13: // enter
                                                                if (divisionItems.length > 0 && divisionItems.findIndex((item) => item.selected) > -1) {
                                                                    await setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        division: divisionItems[divisionItems.findIndex((item) => item.selected)],
                                                                        division_id: divisionItems[divisionItems.findIndex((item) => item.selected)].id,
                                                                    });

                                                                    validateOrderForSaving({ keyCode: 9 });
                                                                    setDivisionItems([]);
                                                                    // refDivision.current.focus();
                                                                    refLoadType.current.focus();
                                                                }
                                                                break;

                                                            case 9: // tab
                                                                if (divisionItems.length > 0) {
                                                                    e.preventDefault();
                                                                    await setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        division: divisionItems[divisionItems.findIndex((item) => item.selected)],
                                                                        division_id: divisionItems[divisionItems.findIndex((item) => item.selected)].id,
                                                                    });
                                                                    validateOrderForSaving({ keyCode: 9 });
                                                                    setDivisionItems([]);
                                                                    // refDivision.current.focus();
                                                                    refLoadType.current.focus();
                                                                }
                                                                break;

                                                            default:
                                                                break;
                                                        }
                                                    }
                                                }}
                                                onBlur={async () => {
                                                    if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                        if ((selectedOrder?.division?.id || 0) === 0) {
                                                            await setSelectedOrder({
                                                                ...selectedOrder,
                                                                division: {},
                                                            });
                                                        }
                                                    }
                                                }}
                                                onInput={async (e) => {
                                                    let division = selectedOrder?.division || {};
                                                    division.id = 0;
                                                    division.name = e.target.value;
                                                    await setSelectedOrder({
                                                        ...selectedOrder,
                                                        division: division,
                                                        division_id: division.id,
                                                    });

                                                    if (e.target.value.trim() === "") {
                                                        setDivisionItems([]);
                                                    } else {
                                                        axios
                                                            .post(props.serverUrl + "/getDivisions", {
                                                                name: e.target.value.trim(),
                                                            })
                                                            .then(async (res) => {
                                                                if (res.data.result === "OK") {
                                                                    await setDivisionItems(
                                                                        res.data.divisions.map((item, index) => {
                                                                            let str = item.type;
                                                                            item.name = item.name + " - " + (str.charAt(0).toUpperCase() + str.slice(1));
                                                                            item.selected = (selectedOrder?.division?.id || 0) === 0
                                                                                ? index === 0
                                                                                : item.id === selectedOrder.division.id;
                                                                            return item;
                                                                        })
                                                                    );
                                                                }
                                                            })
                                                            .catch(async (e) => {
                                                                console.log("error getting divisions", e);
                                                            });
                                                    }
                                                }}
                                                onChange={async (e) => {
                                                    let division = selectedOrder?.division || {};
                                                    division.id = 0;
                                                    division.name = e.target.value;
                                                    await setSelectedOrder({
                                                        ...selectedOrder,
                                                        division: division,
                                                        division_id: division.id,
                                                    });
                                                }}
                                                value={(selectedOrder?.division?.name || "")}
                                            />
                                            {
                                                (selectedOrder?.is_cancelled || 0) === 0 &&
                                                <FontAwesomeIcon
                                                    className="dropdown-button"
                                                    icon={faCaretDown}
                                                    onClick={() => {
                                                        if (divisionItems.length > 0) {
                                                            setDivisionItems([]);
                                                        } else {
                                                            if (
                                                                (selectedOrder?.division?.id || 0) === 0 &&
                                                                (selectedOrder?.division?.name || "") !== ""
                                                            ) {
                                                                axios.post(props.serverUrl + "/getDivisions", {
                                                                    name: selectedOrder?.division.name,
                                                                }).then(async (res) => {
                                                                    if (res.data.result === "OK") {
                                                                        await setDivisionItems(
                                                                            res.data.divisions.map((item, index) => {
                                                                                let str = item.type;
                                                                                item.name = item.name + " - " + (str.charAt(0).toUpperCase() + str.slice(1));
                                                                                item.selected = (selectedOrder?.division?.id || 0) === 0
                                                                                    ? index === 0
                                                                                    : item.id === selectedOrder.division.id;
                                                                                return item;
                                                                            })
                                                                        );

                                                                        refDivisionPopupItems.current.map(
                                                                            (r, i) => {
                                                                                if (r && r.classList.contains("selected")) {
                                                                                    r.scrollIntoView({
                                                                                        behavior: "auto",
                                                                                        block: "center",
                                                                                        inline: "nearest",
                                                                                    });
                                                                                }
                                                                                return true;
                                                                            }
                                                                        );
                                                                    }
                                                                })
                                                                    .catch(async (e) => {
                                                                        console.log("error getting divisions", e);
                                                                    });
                                                            } else {
                                                                axios.post(props.serverUrl + "/getDivisions").then(async (res) => {
                                                                    if (res.data.result === "OK") {
                                                                        await setDivisionItems(
                                                                            res.data.divisions.map((item, index) => {
                                                                                let str = item.type;
                                                                                item.name = item.name + " - " + (str.charAt(0).toUpperCase() + str.slice(1));
                                                                                item.selected = (selectedOrder?.division?.id || 0) === 0
                                                                                    ? index === 0
                                                                                    : item.id === selectedOrder.division.id;
                                                                                return item;
                                                                            })
                                                                        );

                                                                        refDivisionPopupItems.current.map(
                                                                            (r, i) => {
                                                                                if (r && r.classList.contains("selected")) {
                                                                                    r.scrollIntoView({
                                                                                        behavior: "auto",
                                                                                        block: "center",
                                                                                        inline: "nearest",
                                                                                    });
                                                                                }
                                                                                return true;
                                                                            }
                                                                        );
                                                                    }
                                                                })
                                                                    .catch(async (e) => {
                                                                        console.log("error getting divisions", e);
                                                                    });
                                                            }
                                                        }

                                                        refDivision.current.focus();
                                                    }}
                                                />
                                            }

                                        </div>

                                        {divisionTransition(
                                            (style, item) =>
                                                item && (
                                                    <animated.div
                                                        className="mochi-contextual-container"
                                                        id="mochi-contextual-container-division"
                                                        style={{
                                                            ...style,
                                                            left: "-50%",
                                                            display: "block",
                                                        }}
                                                        ref={refDivisionDropDown}
                                                    >
                                                        <div className="mochi-contextual-popup vertical below"
                                                            style={{ height: 150 }}>
                                                            <div className="mochi-contextual-popup-content">
                                                                <div className="mochi-contextual-popup-wrapper">
                                                                    {divisionItems.map((item, index) => {
                                                                        const mochiItemClasses = classnames({
                                                                            "mochi-item": true,
                                                                            selected: item.selected,
                                                                        });

                                                                        const searchValue = (selectedOrder?.division?.id || 0) === 0 &&
                                                                            (selectedOrder?.division?.name || "") !== ""
                                                                            ? selectedOrder?.division?.name
                                                                            : undefined;

                                                                        return (
                                                                            <div
                                                                                key={index}
                                                                                className={mochiItemClasses}
                                                                                id={item.id}
                                                                                onClick={() => {
                                                                                    setSelectedOrder({
                                                                                        ...selectedOrder,
                                                                                        division: item,
                                                                                        division_id: item.id,
                                                                                    });

                                                                                    window.setTimeout(() => {
                                                                                        validateOrderForSaving({
                                                                                            keyCode: 9,
                                                                                        });
                                                                                        setDivisionItems([]);
                                                                                        refLoadType.current.focus();
                                                                                    }, 0);
                                                                                }}
                                                                                ref={(ref) =>
                                                                                    refDivisionPopupItems.current.push(ref)
                                                                                }
                                                                            >
                                                                                {searchValue === undefined ? (item.name) : (
                                                                                    <Highlighter
                                                                                        highlightClassName="mochi-item-highlight-text"
                                                                                        searchWords={[searchValue]}
                                                                                        autoEscape={true}
                                                                                        textToHighlight={item.name}
                                                                                    />
                                                                                )}
                                                                                {item.selected && (
                                                                                    <FontAwesomeIcon
                                                                                        className="dropdown-selected"
                                                                                        icon={faCaretRight}
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </animated.div>
                                                )
                                        )}
                                    </div>
                                    <div className="form-h-sep"></div>
                                    <div className="select-box-container" style={{ flexGrow: 1 }}>
                                        <div className="select-box-wrapper">
                                            <input
                                                type="text"
                                                tabIndex={4 + props.tabTimes}
                                                placeholder="Load Type"
                                                ref={refLoadType}
                                                readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                onKeyDown={async (e) => {
                                                    if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                        let key = e.keyCode || e.which;

                                                        switch (key) {
                                                            case 37:
                                                            case 38: // arrow left | arrow up
                                                                e.preventDefault();
                                                                if (loadTypeItems.length > 0) {
                                                                    let selectedIndex = loadTypeItems.findIndex(
                                                                        (item) => item.selected
                                                                    );

                                                                    if (selectedIndex === -1) {
                                                                        await setLoadTypeItems(
                                                                            loadTypeItems.map((item, index) => {
                                                                                item.selected = index === 0;
                                                                                return item;
                                                                            })
                                                                        );
                                                                    } else {
                                                                        await setLoadTypeItems(
                                                                            loadTypeItems.map((item, index) => {
                                                                                if (selectedIndex === 0) {
                                                                                    item.selected =
                                                                                        index === loadTypeItems.length - 1;
                                                                                } else {
                                                                                    item.selected =
                                                                                        index === selectedIndex - 1;
                                                                                }
                                                                                return item;
                                                                            })
                                                                        );
                                                                    }

                                                                    refLoadTypePopupItems.current.map((r, i) => {
                                                                        if (r && r.classList.contains("selected")) {
                                                                            r.scrollIntoView({
                                                                                behavior: "auto",
                                                                                block: "center",
                                                                                inline: "nearest",
                                                                            });
                                                                        }
                                                                        return true;
                                                                    });
                                                                } else {
                                                                    axios.post(props.serverUrl + "/getLoadTypes").then((res) => {
                                                                        if (res.data.result === "OK") {
                                                                            setLoadTypeItems(
                                                                                res.data.load_types.map(
                                                                                    (item, index) => {
                                                                                        item.selected =
                                                                                            (selectedOrder?.load_type?.id ||
                                                                                                0) === 0
                                                                                                ? index === 0
                                                                                                : item.id ===
                                                                                                selectedOrder.load_type.id;
                                                                                        return item;
                                                                                    }
                                                                                )
                                                                            );

                                                                            refLoadTypePopupItems.current.map(
                                                                                (r, i) => {
                                                                                    if (
                                                                                        r &&
                                                                                        r.classList.contains("selected")
                                                                                    ) {
                                                                                        r.scrollIntoView({
                                                                                            behavior: "auto",
                                                                                            block: "center",
                                                                                            inline: "nearest",
                                                                                        });
                                                                                    }
                                                                                    return true;
                                                                                }
                                                                            );
                                                                        }
                                                                    })
                                                                        .catch(async (e) => {
                                                                            console.log("error getting load types", e);
                                                                        });
                                                                }
                                                                break;

                                                            case 39:
                                                            case 40: // arrow right | arrow down
                                                                e.preventDefault();
                                                                if (loadTypeItems.length > 0) {
                                                                    let selectedIndex = loadTypeItems.findIndex(
                                                                        (item) => item.selected
                                                                    );

                                                                    if (selectedIndex === -1) {
                                                                        await setLoadTypeItems(
                                                                            loadTypeItems.map((item, index) => {
                                                                                item.selected = index === 0;
                                                                                return item;
                                                                            })
                                                                        );
                                                                    } else {
                                                                        await setLoadTypeItems(
                                                                            loadTypeItems.map((item, index) => {
                                                                                if (
                                                                                    selectedIndex ===
                                                                                    loadTypeItems.length - 1
                                                                                ) {
                                                                                    item.selected = index === 0;
                                                                                } else {
                                                                                    item.selected =
                                                                                        index === selectedIndex + 1;
                                                                                }
                                                                                return item;
                                                                            })
                                                                        );
                                                                    }

                                                                    refLoadTypePopupItems.current.map((r, i) => {
                                                                        if (r && r.classList.contains("selected")) {
                                                                            r.scrollIntoView({
                                                                                behavior: "auto",
                                                                                block: "center",
                                                                                inline: "nearest",
                                                                            });
                                                                        }
                                                                        return true;
                                                                    });
                                                                } else {
                                                                    axios
                                                                        .post(props.serverUrl + "/getLoadTypes")
                                                                        .then(async (res) => {
                                                                            if (res.data.result === "OK") {
                                                                                await setLoadTypeItems(
                                                                                    res.data.load_types.map(
                                                                                        (item, index) => {
                                                                                            item.selected =
                                                                                                (selectedOrder?.load_type?.id ||
                                                                                                    0) === 0
                                                                                                    ? index === 0
                                                                                                    : item.id ===
                                                                                                    selectedOrder.load_type.id;
                                                                                            return item;
                                                                                        }
                                                                                    )
                                                                                );

                                                                                refLoadTypePopupItems.current.map(
                                                                                    (r, i) => {
                                                                                        if (
                                                                                            r &&
                                                                                            r.classList.contains("selected")
                                                                                        ) {
                                                                                            r.scrollIntoView({
                                                                                                behavior: "auto",
                                                                                                block: "center",
                                                                                                inline: "nearest",
                                                                                            });
                                                                                        }
                                                                                        return true;
                                                                                    }
                                                                                );
                                                                            }
                                                                        })
                                                                        .catch(async (e) => {
                                                                            console.log("error getting load types", e);
                                                                        });
                                                                }
                                                                break;

                                                            case 27: // escape
                                                                setLoadTypeItems([]);
                                                                break;

                                                            case 13: // enter
                                                                if (loadTypeItems.length > 0 && loadTypeItems.findIndex((item) => item.selected) > -1) {
                                                                    await setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        load_type: loadTypeItems[loadTypeItems.findIndex((item) => item.selected)],
                                                                        load_type_id: loadTypeItems[loadTypeItems.findIndex((item) => item.selected)].id,
                                                                    });
                                                                    validateOrderForSaving({ keyCode: 9 });
                                                                    setLoadTypeItems([]);
                                                                    refTemplate.current.focus();
                                                                }
                                                                break;

                                                            case 9: // tab
                                                                if (loadTypeItems.length > 0) {
                                                                    e.preventDefault();
                                                                    await setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        load_type:
                                                                            loadTypeItems[
                                                                            loadTypeItems.findIndex(
                                                                                (item) => item.selected
                                                                            )
                                                                            ],
                                                                        load_type_id:
                                                                            loadTypeItems[
                                                                                loadTypeItems.findIndex(
                                                                                    (item) => item.selected
                                                                                )
                                                                            ].id,
                                                                    });
                                                                    validateOrderForSaving({ keyCode: 9 });
                                                                    setLoadTypeItems([]);
                                                                    refTemplate.current.focus();
                                                                }
                                                                break;

                                                            default:
                                                                break;
                                                        }
                                                    }
                                                }}
                                                onBlur={async () => {
                                                    if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                        if ((selectedOrder?.load_type?.id || 0) === 0) {
                                                            await setSelectedOrder({
                                                                ...selectedOrder,
                                                                load_type: {},
                                                            });
                                                        }
                                                    }
                                                }}
                                                onInput={async (e) => {
                                                    let load_type = selectedOrder?.load_type || {};
                                                    load_type.id = 0;
                                                    load_type.name = e.target.value;
                                                    await setSelectedOrder({
                                                        ...selectedOrder,
                                                        load_type: load_type,
                                                        load_type_id: load_type.id,
                                                    });

                                                    if (e.target.value.trim() === "") {
                                                        setLoadTypeItems([]);
                                                    } else {
                                                        axios
                                                            .post(props.serverUrl + "/getLoadTypes", {
                                                                name: e.target.value.trim(),
                                                            })
                                                            .then(async (res) => {
                                                                if (res.data.result === "OK") {
                                                                    await setLoadTypeItems(
                                                                        res.data.load_types.map((item, index) => {
                                                                            item.selected =
                                                                                (selectedOrder?.load_type?.id || 0) ===
                                                                                    0
                                                                                    ? index === 0
                                                                                    : item.id ===
                                                                                    selectedOrder.load_type.id;
                                                                            return item;
                                                                        })
                                                                    );
                                                                }
                                                            })
                                                            .catch(async (e) => {
                                                                console.log("error getting load types", e);
                                                            });
                                                    }
                                                }}
                                                onChange={async (e) => {
                                                    let load_type = selectedOrder?.load_type || {};
                                                    load_type.id = 0;
                                                    load_type.name = e.target.value;
                                                    await setSelectedOrder({
                                                        ...selectedOrder,
                                                        load_type: load_type,
                                                        load_type_id: load_type.id,
                                                    });
                                                }}
                                                value={selectedOrder?.load_type?.name || ""}
                                            />
                                            {
                                                (selectedOrder?.is_cancelled || 0) === 0 &&
                                                <FontAwesomeIcon
                                                    className="dropdown-button"
                                                    icon={faCaretDown}
                                                    onClick={() => {
                                                        if (loadTypeItems.length > 0) {
                                                            setLoadTypeItems([]);
                                                        } else {
                                                            if (
                                                                (selectedOrder?.load_type?.id || 0) === 0 &&
                                                                (selectedOrder?.load_type?.name || "") !== ""
                                                            ) {
                                                                axios
                                                                    .post(props.serverUrl + "/getLoadTypes", {
                                                                        name: selectedOrder?.load_type.name,
                                                                    })
                                                                    .then(async (res) => {
                                                                        if (res.data.result === "OK") {
                                                                            await setLoadTypeItems(
                                                                                res.data.load_types.map((item, index) => {
                                                                                    item.selected =
                                                                                        (selectedOrder?.load_type?.id ||
                                                                                            0) === 0
                                                                                            ? index === 0
                                                                                            : item.id ===
                                                                                            selectedOrder.load_type.id;
                                                                                    return item;
                                                                                })
                                                                            );

                                                                            refLoadTypePopupItems.current.map(
                                                                                (r, i) => {
                                                                                    if (
                                                                                        r &&
                                                                                        r.classList.contains("selected")
                                                                                    ) {
                                                                                        r.scrollIntoView({
                                                                                            behavior: "auto",
                                                                                            block: "center",
                                                                                            inline: "nearest",
                                                                                        });
                                                                                    }
                                                                                    return true;
                                                                                }
                                                                            );
                                                                        }
                                                                    })
                                                                    .catch(async (e) => {
                                                                        console.log("error getting load types", e);
                                                                    });
                                                            } else {
                                                                axios
                                                                    .post(props.serverUrl + "/getLoadTypes")
                                                                    .then(async (res) => {
                                                                        if (res.data.result === "OK") {
                                                                            await setLoadTypeItems(
                                                                                res.data.load_types.map((item, index) => {
                                                                                    item.selected =
                                                                                        (selectedOrder?.load_type?.id ||
                                                                                            0) === 0
                                                                                            ? index === 0
                                                                                            : item.id ===
                                                                                            selectedOrder.load_type.id;
                                                                                    return item;
                                                                                })
                                                                            );

                                                                            refLoadTypePopupItems.current.map(
                                                                                (r, i) => {
                                                                                    if (
                                                                                        r &&
                                                                                        r.classList.contains("selected")
                                                                                    ) {
                                                                                        r.scrollIntoView({
                                                                                            behavior: "auto",
                                                                                            block: "center",
                                                                                            inline: "nearest",
                                                                                        });
                                                                                    }
                                                                                    return true;
                                                                                }
                                                                            );
                                                                        }
                                                                    })
                                                                    .catch(async (e) => {
                                                                        console.log("error getting load types", e);
                                                                    });
                                                            }
                                                        }

                                                        refLoadType.current.focus();
                                                    }}
                                                />
                                            }
                                        </div>

                                        {loadTypeTransition(
                                            (style, item) =>
                                                item && (
                                                    <animated.div
                                                        className="mochi-contextual-container"
                                                        id="mochi-contextual-container-load-type"
                                                        style={{
                                                            ...style,
                                                            left: "-50%",
                                                            display: "block",
                                                        }}
                                                        ref={refLoadTypeDropDown}
                                                    >
                                                        <div
                                                            className="mochi-contextual-popup vertical below"
                                                            style={{ height: 150 }}
                                                        >
                                                            <div className="mochi-contextual-popup-content">
                                                                <div className="mochi-contextual-popup-wrapper">
                                                                    {loadTypeItems.map((item, index) => {
                                                                        const mochiItemClasses = classnames({
                                                                            "mochi-item": true,
                                                                            selected: item.selected,
                                                                        });

                                                                        const searchValue =
                                                                            (selectedOrder?.load_type?.id || 0) ===
                                                                                0 &&
                                                                                (selectedOrder?.load_type?.name || "") !==
                                                                                ""
                                                                                ? selectedOrder?.load_type?.name
                                                                                : undefined;

                                                                        return (
                                                                            <div
                                                                                key={index}
                                                                                className={mochiItemClasses}
                                                                                id={item.id}
                                                                                onClick={() => {
                                                                                    setSelectedOrder({
                                                                                        ...selectedOrder,
                                                                                        load_type: item,
                                                                                        load_type_id: item.id,
                                                                                    });

                                                                                    window.setTimeout(() => {
                                                                                        validateOrderForSaving({
                                                                                            keyCode: 9,
                                                                                        });
                                                                                        setLoadTypeItems([]);
                                                                                        refTemplate.current.focus();
                                                                                    }, 0);
                                                                                }}
                                                                                ref={(ref) =>
                                                                                    refLoadTypePopupItems.current.push(
                                                                                        ref
                                                                                    )
                                                                                }
                                                                            >
                                                                                {searchValue === undefined ? (
                                                                                    item.name
                                                                                ) : (
                                                                                    <Highlighter
                                                                                        highlightClassName="mochi-item-highlight-text"
                                                                                        searchWords={[searchValue]}
                                                                                        autoEscape={true}
                                                                                        textToHighlight={item.name}
                                                                                    />
                                                                                )}
                                                                                {item.selected && (
                                                                                    <FontAwesomeIcon
                                                                                        className="dropdown-selected"
                                                                                        icon={faCaretRight}
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </animated.div>
                                                )
                                        )}
                                    </div>
                                    <div className="form-h-sep"></div>
                                    <div className="select-box-container" style={{
                                        flexGrow: 1,
                                        pointerEvents: ((selectedOrder?.id || 0) > 0 || (selectedOrder?.order_number || 0) > 0) ? 'none' : 'all'
                                    }}>
                                        <div className="select-box-wrapper">
                                            <input
                                                type="text"
                                                tabIndex={5 + props.tabTimes}
                                                placeholder="Template"
                                                style={{ textTransform: 'capitalize' }}
                                                ref={refTemplate}
                                                readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                onKeyDown={async (e) => {
                                                    if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                        let key = e.keyCode || e.which;

                                                        switch (key) {
                                                            case 37:
                                                            case 38: // arrow left | arrow up
                                                                e.preventDefault();
                                                                if (templateItems.length > 0) {
                                                                    let selectedIndex = templateItems.findIndex(
                                                                        (item) => item.selected
                                                                    );

                                                                    if (selectedIndex === -1) {
                                                                        await setTemplateItems(
                                                                            templateItems.map((item, index) => {
                                                                                item.selected = index === 0;
                                                                                return item;
                                                                            })
                                                                        );
                                                                    } else {
                                                                        await setTemplateItems(
                                                                            templateItems.map((item, index) => {
                                                                                if (selectedIndex === 0) {
                                                                                    item.selected =
                                                                                        index === templateItems.length - 1;
                                                                                } else {
                                                                                    item.selected =
                                                                                        index === selectedIndex - 1;
                                                                                }
                                                                                return item;
                                                                            })
                                                                        );
                                                                    }

                                                                    refTemplatePopupItems.current.map((r, i) => {
                                                                        if (r && r.classList.contains("selected")) {
                                                                            r.scrollIntoView({
                                                                                behavior: "auto",
                                                                                block: "center",
                                                                                inline: "nearest",
                                                                            });
                                                                        }
                                                                        return true;
                                                                    });
                                                                } else {
                                                                    axios
                                                                        .post(props.serverUrl + "/getTemplates")
                                                                        .then(async (res) => {
                                                                            if (res.data.result === "OK") {
                                                                                await setTemplateItems(
                                                                                    res.data.templates.map(
                                                                                        (item, index) => {
                                                                                            item.selected =
                                                                                                (selectedOrder?.template?.id ||
                                                                                                    0) === 0
                                                                                                    ? index === 0
                                                                                                    : item.id ===
                                                                                                    selectedOrder.template.id;
                                                                                            return item;
                                                                                        }
                                                                                    )
                                                                                );

                                                                                refTemplatePopupItems.current.map(
                                                                                    (r, i) => {
                                                                                        if (
                                                                                            r &&
                                                                                            r.classList.contains("selected")
                                                                                        ) {
                                                                                            r.scrollIntoView({
                                                                                                behavior: "auto",
                                                                                                block: "center",
                                                                                                inline: "nearest",
                                                                                            });
                                                                                        }
                                                                                        return true;
                                                                                    }
                                                                                );
                                                                            }
                                                                        })
                                                                        .catch(async (e) => {
                                                                            console.log("error getting templates", e);
                                                                        });
                                                                }
                                                                break;

                                                            case 39:
                                                            case 40: // arrow right | arrow down
                                                                e.preventDefault();
                                                                if (templateItems.length > 0) {
                                                                    let selectedIndex = templateItems.findIndex(
                                                                        (item) => item.selected
                                                                    );

                                                                    if (selectedIndex === -1) {
                                                                        await setTemplateItems(
                                                                            templateItems.map((item, index) => {
                                                                                item.selected = index === 0;
                                                                                return item;
                                                                            })
                                                                        );
                                                                    } else {
                                                                        await setTemplateItems(
                                                                            templateItems.map((item, index) => {
                                                                                if (
                                                                                    selectedIndex ===
                                                                                    templateItems.length - 1
                                                                                ) {
                                                                                    item.selected = index === 0;
                                                                                } else {
                                                                                    item.selected =
                                                                                        index === selectedIndex + 1;
                                                                                }
                                                                                return item;
                                                                            })
                                                                        );
                                                                    }

                                                                    refTemplatePopupItems.current.map((r, i) => {
                                                                        if (r && r.classList.contains("selected")) {
                                                                            r.scrollIntoView({
                                                                                behavior: "auto",
                                                                                block: "center",
                                                                                inline: "nearest",
                                                                            });
                                                                        }
                                                                        return true;
                                                                    });
                                                                } else {
                                                                    axios
                                                                        .post(props.serverUrl + "/getTemplates")
                                                                        .then(async (res) => {
                                                                            if (res.data.result === "OK") {
                                                                                await setTemplateItems(
                                                                                    res.data.templates.map(
                                                                                        (item, index) => {
                                                                                            item.selected =
                                                                                                (selectedOrder?.template?.id ||
                                                                                                    0) === 0
                                                                                                    ? index === 0
                                                                                                    : item.id ===
                                                                                                    selectedOrder.template.id;
                                                                                            return item;
                                                                                        }
                                                                                    )
                                                                                );

                                                                                refTemplatePopupItems.current.map(
                                                                                    (r, i) => {
                                                                                        if (
                                                                                            r &&
                                                                                            r.classList.contains("selected")
                                                                                        ) {
                                                                                            r.scrollIntoView({
                                                                                                behavior: "auto",
                                                                                                block: "center",
                                                                                                inline: "nearest",
                                                                                            });
                                                                                        }
                                                                                        return true;
                                                                                    }
                                                                                );
                                                                            }
                                                                        })
                                                                        .catch(async (e) => {
                                                                            console.log("error getting templates", e);
                                                                        });
                                                                }
                                                                break;

                                                            case 27: // escape
                                                                setTemplateItems([]);
                                                                break;

                                                            case 13: // enter
                                                                if (templateItems.length > 0 && templateItems.findIndex((item) => item.selected) > -1) {
                                                                    setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        template: templateItems[templateItems.findIndex((item) => item.selected)],
                                                                        template_id: templateItems[templateItems.findIndex((item) => item.selected)].id,
                                                                    });

                                                                    setTemplateItems([]);
                                                                    refTemplate.current.focus();
                                                                }
                                                                break;

                                                            case 9: // tab
                                                                if (templateItems.length > 0) {
                                                                    e.preventDefault();
                                                                    setSelectedOrder(prev => {
                                                                        return {
                                                                            ...prev,
                                                                            template: templateItems[templateItems.findIndex((item) => item.selected)],
                                                                            template_id: templateItems[templateItems.findIndex((item) => item.selected)].id,
                                                                        }
                                                                    });

                                                                    setTimeout(() => { doUseTemplate(templateItems[templateItems.findIndex((item) => item.selected)].id) }, 100);
                                                                    setTemplateItems([]);
                                                                    refTemplate.current.focus();
                                                                } else {
                                                                    if ((selectedOrder?.template_id || 0) > 0) {
                                                                        setTimeout(() => { doUseTemplate(templateItems[templateItems.findIndex((item) => item.selected)].id) }, 100);
                                                                    }
                                                                }
                                                                break;

                                                            default:
                                                                break;
                                                        }
                                                    }
                                                }}
                                                onBlur={async () => {
                                                    if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                        if ((selectedOrder?.template?.id || 0) === 0) {
                                                            await setSelectedOrder({
                                                                ...selectedOrder,
                                                                template: {},
                                                            });
                                                        }
                                                    }
                                                }}
                                                onInput={async (e) => {
                                                    let template = selectedOrder?.template || {};
                                                    template.id = 0;
                                                    template.name = e.target.value;
                                                    await setSelectedOrder({
                                                        ...selectedOrder,
                                                        template: template,
                                                        template_id: template.id,
                                                    });

                                                    if (e.target.value.trim() === "") {
                                                        setTemplateItems([]);
                                                    } else {
                                                        axios
                                                            .post(props.serverUrl + "/getTemplates", {
                                                                name: e.target.value.trim(),
                                                            })
                                                            .then(async (res) => {
                                                                if (res.data.result === "OK") {
                                                                    await setTemplateItems(
                                                                        res.data.templates.map((item, index) => {
                                                                            item.selected =
                                                                                (selectedOrder?.template?.id || 0) === 0
                                                                                    ? index === 0
                                                                                    : item.id ===
                                                                                    selectedOrder.template.id;
                                                                            return item;
                                                                        })
                                                                    );
                                                                }
                                                            })
                                                            .catch(async (e) => {
                                                                console.log("error getting templates", e);
                                                            });
                                                    }
                                                }}
                                                onChange={async (e) => {
                                                    let template = selectedOrder?.template || {};
                                                    template.id = 0;
                                                    template.name = e.target.value;
                                                    await setSelectedOrder({
                                                        ...selectedOrder,
                                                        template: template,
                                                        template_id: template.id,
                                                    });
                                                }}
                                                value={((selectedOrder?.id || 0) === 0 && (selectedOrder?.order_number || 0) === 0) ? (selectedOrder?.template?.name || "") : ''}
                                            />
                                            {
                                                (selectedOrder?.is_cancelled || 0) === 0 &&
                                                <FontAwesomeIcon
                                                    className="dropdown-button"
                                                    icon={faCaretDown}
                                                    onClick={() => {
                                                        if (templateItems.length > 0) {
                                                            setTemplateItems([]);
                                                        } else {
                                                            if (
                                                                (selectedOrder?.template?.id || 0) === 0 &&
                                                                (selectedOrder?.template?.name || "") !== ""
                                                            ) {
                                                                axios
                                                                    .post(props.serverUrl + "/getTemplates", {
                                                                        name: selectedOrder?.template.name,
                                                                    })
                                                                    .then(async (res) => {
                                                                        if (res.data.result === "OK") {
                                                                            await setTemplateItems(
                                                                                res.data.templates.map((item, index) => {
                                                                                    item.selected =
                                                                                        (selectedOrder?.template?.id || 0) ===
                                                                                            0
                                                                                            ? index === 0
                                                                                            : item.id ===
                                                                                            selectedOrder.template.id;
                                                                                    return item;
                                                                                })
                                                                            );

                                                                            refTemplatePopupItems.current.map(
                                                                                (r, i) => {
                                                                                    if (
                                                                                        r &&
                                                                                        r.classList.contains("selected")
                                                                                    ) {
                                                                                        r.scrollIntoView({
                                                                                            behavior: "auto",
                                                                                            block: "center",
                                                                                            inline: "nearest",
                                                                                        });
                                                                                    }
                                                                                    return true;
                                                                                }
                                                                            );
                                                                        }
                                                                    })
                                                                    .catch(async (e) => {
                                                                        console.log("error getting templates", e);
                                                                    });
                                                            } else {
                                                                axios
                                                                    .post(props.serverUrl + "/getTemplates")
                                                                    .then(async (res) => {
                                                                        if (res.data.result === "OK") {
                                                                            await setTemplateItems(
                                                                                res.data.templates.map((item, index) => {
                                                                                    item.selected =
                                                                                        (selectedOrder?.template?.id || 0) ===
                                                                                            0
                                                                                            ? index === 0
                                                                                            : item.id ===
                                                                                            selectedOrder.template.id;
                                                                                    return item;
                                                                                })
                                                                            );

                                                                            refTemplatePopupItems.current.map((r, i) => {
                                                                                if (r && r.classList.contains("selected")) {
                                                                                    r.scrollIntoView({
                                                                                        behavior: "auto",
                                                                                        block: "center",
                                                                                        inline: "nearest",
                                                                                    });
                                                                                }
                                                                                return true;
                                                                            }
                                                                            );
                                                                        }
                                                                    })
                                                                    .catch(async (e) => {
                                                                        console.log("error getting templates", e);
                                                                    });
                                                            }
                                                        }

                                                        refTemplate.current.focus();
                                                    }}
                                                />
                                            }
                                        </div>
                                        {templateTransition(
                                            (style, item) =>
                                                item && (
                                                    <animated.div
                                                        className="mochi-contextual-container"
                                                        id="mochi-contextual-container-template"
                                                        style={{
                                                            ...style,
                                                            left: "-50%",
                                                            display: "block",
                                                        }}
                                                        ref={refTemplateDropDown}
                                                    >
                                                        <div className="mochi-contextual-popup vertical below"
                                                            style={{ height: 150 }}>
                                                            <div className="mochi-contextual-popup-content">
                                                                <div className="mochi-contextual-popup-wrapper">
                                                                    {templateItems.map((item, index) => {
                                                                        const mochiItemClasses = classnames({
                                                                            "mochi-item": true,
                                                                            selected: item.selected,
                                                                        });

                                                                        const searchValue = (selectedOrder?.template?.id || 0) === 0 && (selectedOrder?.template?.name || "") !== ""
                                                                            ? selectedOrder?.template?.name
                                                                            : undefined;

                                                                        return (
                                                                            <div
                                                                                key={index}
                                                                                className={mochiItemClasses}
                                                                                id={item.id}
                                                                                style={{ textTransform: 'capitalize' }}
                                                                                onClick={() => {
                                                                                    setSelectedOrder({
                                                                                        ...selectedOrder,
                                                                                        template: item,
                                                                                        template_id: item.id,
                                                                                    });

                                                                                    window.setTimeout(() => {
                                                                                        validateOrderForSaving({
                                                                                            keyCode: 9,
                                                                                        });
                                                                                        setTemplateItems([]);
                                                                                        refTemplate.current.focus();
                                                                                    });
                                                                                }}
                                                                                ref={(ref) =>
                                                                                    refTemplatePopupItems.current.push(
                                                                                        ref
                                                                                    )
                                                                                }
                                                                            >
                                                                                {searchValue === undefined ? (
                                                                                    item.name
                                                                                ) : (
                                                                                    <Highlighter
                                                                                        highlightClassName="mochi-item-highlight-text"
                                                                                        searchWords={[searchValue]}
                                                                                        autoEscape={true}
                                                                                        textToHighlight={item.name}
                                                                                    />
                                                                                )}
                                                                                {item.selected && (
                                                                                    <FontAwesomeIcon
                                                                                        className="dropdown-selected"
                                                                                        icon={faCaretRight}
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </animated.div>
                                                )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ flexGrow: 1 }}>
                            <div className="form-borderless-box" style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                            }}>
                                {
                                    (selectedOrder?.id || 0) === 0 && (selectedOrder?.template_id || 0) === 0 &&
                                    <div className="mochi-button" onClick={() => {
                                        let panel = {
                                            panelName: `${props.panelName}-templates`,
                                            component: (
                                                <Template
                                                    title="Template"
                                                    tabTimes={897456 + props.tabTimes}
                                                    panelName={`${props.panelName}-templates`}
                                                    origin={props.origin}
                                                    componentId={moment().format("x")}
                                                    closingCallback={() => {
                                                        props.closePanel(`${props.panelName}-templates`, props.origin);
                                                        (props.isOnPanel ? refOrderNumber : props.refOrderNumber).current.focus({ preventScroll: true });
                                                    }}
                                                    deleteCallback={(id) => {
                                                        if ((selectedOrder?.template_id || 0) === id) {
                                                            setSelectedOrder(prev => {
                                                                return {
                                                                    ...prev,
                                                                    template_id: null,
                                                                    template: null
                                                                }
                                                            })
                                                        }
                                                    }}
                                                />
                                            ),
                                        };

                                        openPanel(panel, props.origin);
                                    }}>
                                        <div className="mochi-button-decorator mochi-button-decorator-left">(</div>
                                        <div className="mochi-button-base">New Template</div>
                                        <div className="mochi-button-decorator mochi-button-decorator-right">)</div>
                                    </div>
                                }

                                {
                                    (selectedOrder?.id || 0) === 0 && (selectedOrder?.template_id || 0) > 0 &&
                                    <div className="mochi-button" onClick={() => {
                                        doUseTemplate(selectedOrder.template_id);
                                    }}>
                                        <div className="mochi-button-decorator mochi-button-decorator-left">(</div>
                                        <div className="mochi-button-base">Use Template</div>
                                        <div className="mochi-button-decorator mochi-button-decorator-right">)</div>
                                    </div>
                                }

                                {
                                    (selectedOrder?.id || 0) === 0 && (selectedOrder?.template_id || 0) > 0 &&
                                    <div className="mochi-button" onClick={() => {

                                        let panel = {
                                            panelName: `${props.panelName}-templates`,
                                            component: (
                                                <Template
                                                    title="Template"
                                                    tabTimes={897456 + props.tabTimes}
                                                    panelName={`${props.panelName}-templates`}
                                                    origin={props.origin}
                                                    id={selectedOrder?.template_id}
                                                    componentId={moment().format("x")}
                                                    closingCallback={() => {
                                                        props.closePanel(`${props.panelName}-templates`, props.origin);
                                                        (props.isOnPanel ? refOrderNumber : props.refOrderNumber).current.focus({ preventScroll: true });
                                                    }}
                                                    deleteCallback={(id) => {
                                                        if ((selectedOrder?.template_id || 0) === id) {
                                                            setSelectedOrder(prev => {
                                                                return {
                                                                    ...prev,
                                                                    template_id: null,
                                                                    template: null
                                                                }
                                                            })
                                                        }
                                                    }}
                                                />
                                            ),
                                        };

                                        openPanel(panel, props.origin);
                                    }}>
                                        <div className="mochi-button-decorator mochi-button-decorator-left">(</div>
                                        <div className="mochi-button-base">Edit Template</div>
                                        <div className="mochi-button-decorator mochi-button-decorator-right">)</div>
                                    </div>
                                }

                                {
                                    ((selectedOrder?.id || 0) > 0 && (selectedOrder?.template_id || 0) === 0) &&
                                    <div className={`mochi-button ${(selectedOrder?.id || 0) === 0 ? 'disabled' : ''}`}
                                        style={{ marginLeft: 5 }} onClick={() => {
                                            if ((selectedOrder?.id || 0) === 0) {
                                                window.alert("You must create or load an order first!");
                                                return;
                                            }

                                            if (window.confirm("Do you wish to replicate this order?")) {
                                                setIsLoading(true);

                                                let selected_order = JSON.parse(
                                                    JSON.stringify(selectedOrder)
                                                );

                                                selected_order.id = 0;
                                                selected_order.order_number = 0;
                                                selected_order.trip_number = 0;
                                                selected_order.carrier = {};
                                                selected_order.carrier_id = null;
                                                selected_order.carrier_driver_id = null;
                                                selected_order.equipment_id = null;
                                                selected_order.equipment = {};
                                                selected_order.user_code_id = props.user.user_code.id;
                                                selected_order.customer_check_number = null;
                                                selected_order.customer_date_received = null;
                                                selected_order.invoice_received_date = null;
                                                selected_order.invoice_number = null;
                                                selected_order.term_id = null;
                                                selected_order.invoice_date_paid = null;
                                                selected_order.carrier_check_number = null;
                                                selected_order.invoice_customer_reviewed = 0;
                                                selected_order.order_invoiced = 0;
                                                selected_order.invoice_carrier_previewed = 0;
                                                selected_order.invoice_carrier_received = 0;
                                                selected_order.invoice_bol_received = 0;
                                                selected_order.invoice_rate_conf_received = 0;
                                                selected_order.invoice_carrier_approved = 0;
                                                selected_order.is_imported = 0;

                                                axios.post(props.serverUrl + "/saveOrder", selected_order).then(async (res) => {
                                                    if (res.data.result === "OK") {
                                                        await setSelectedOrder({ ...res.data.order });
                                                        await props.setSelectedOrder({
                                                            ...res.data.order,
                                                            component_id: props.componentId,
                                                        });
                                                        await setSelectedCarrier({});
                                                        await setSelectedCarrierContact({});
                                                        await setSelectedCarrierDriver({});

                                                        new Promise((resolve, reject) => {
                                                            if ((selected_order?.internal_notes || []).length > 0) {
                                                                (selected_order?.internal_notes || []).map(
                                                                    async (note, index) => {
                                                                        await axios.post(props.serverUrl + "/saveInternalNotes",
                                                                            {
                                                                                order_id: res.data.order.id,
                                                                                text: note.text,
                                                                                user_code_id: props.user.user_code.id,
                                                                            }
                                                                        ).then((res) => {
                                                                            if (index === selected_order.internal_notes.length - 1) {
                                                                                resolve("done");
                                                                            }
                                                                        });

                                                                        return false;
                                                                    }
                                                                );
                                                            } else {
                                                                resolve("done");
                                                            }
                                                        }).then((response) => {
                                                            new Promise((resolve, reject) => {
                                                                if ((selected_order?.notes_for_carrier || []).length > 0) {
                                                                    (selected_order?.notes_for_carrier || []).map(
                                                                        async (note, index) => {
                                                                            await axios.post(props.serverUrl + "/saveNotesForCarrier",
                                                                                {
                                                                                    order_id: res.data.order.id,
                                                                                    text: note.text,
                                                                                    user_code_id: props.user.user_code.id,
                                                                                }
                                                                            ).then((res) => {
                                                                                if (index === selected_order.notes_for_carrier.length - 1) {
                                                                                    resolve("done");
                                                                                }
                                                                            });

                                                                            return false;
                                                                        }
                                                                    );
                                                                } else {
                                                                    resolve("done");
                                                                }
                                                            }).then((response) => {
                                                                new Promise((resolve, reject) => {
                                                                    if ((selected_order?.order_customer_ratings || []).length > 0) {
                                                                        (selected_order?.order_customer_ratings || []).map(async (rating, index) => {
                                                                            await axios.post(props.serverUrl + "/saveOrderCustomerRating",
                                                                                {
                                                                                    order_id: res.data.order.id,
                                                                                    rate_type: rating.rate_type,
                                                                                    description: rating.description,
                                                                                    rate_subtype: rating.rate_subtype,
                                                                                    pieces: rating.pieces,
                                                                                    pieces_unit: rating.pieces_unit,
                                                                                    weight: rating.weight,
                                                                                    weight_unit: rating.weight_unit,
                                                                                    feet_required: rating.feet_required,
                                                                                    feet_required_unit:
                                                                                        rating.feet_required_unit,
                                                                                    rate: rating.rate,
                                                                                    percentage: rating.percentage,
                                                                                    days: rating.days,
                                                                                    hours: rating.hours,
                                                                                    total_charges: rating.total_charges,
                                                                                }
                                                                            ).then((res) => {
                                                                                if (index === selected_order.order_customer_ratings.length - 1) {
                                                                                    resolve("done");
                                                                                }
                                                                            });

                                                                            return false;
                                                                        });
                                                                    } else {
                                                                        resolve("done");
                                                                    }
                                                                }).then((response) => {
                                                                    new Promise((resolve, reject) => {
                                                                        if ((selected_order?.pickups || []).length > 0) {
                                                                            (selected_order?.pickups || []).map(async (pickup, index) => {
                                                                                await axios.post(props.serverUrl + "/saveOrderPickup",
                                                                                    {
                                                                                        id: 0,
                                                                                        order_id: res.data.order.id,
                                                                                        customer_id: pickup.customer.id,
                                                                                        contact_id: null,
                                                                                        contact_name: "",
                                                                                        contact_phone: "",
                                                                                        contact_primary_phone: "work",
                                                                                        contact_phone_ext: "",
                                                                                        pu_date1: "",
                                                                                        pu_date2: "",
                                                                                        pu_time1: "",
                                                                                        pu_time2: "",
                                                                                        bol_numbers: pickup.bol_numbers || "",
                                                                                        po_numbers: pickup.po_numbers || "",
                                                                                        ref_numbers: pickup.ref_numbers || "",
                                                                                        seal_number: pickup.seal_number || "",
                                                                                        special_instructions: pickup.special_instructions || "",
                                                                                        type: "pickup",
                                                                                    }
                                                                                ).then((res) => {
                                                                                    if (index === selected_order.pickups.length - 1) {
                                                                                        resolve("done");
                                                                                    }
                                                                                });

                                                                                return false;
                                                                            }
                                                                            );
                                                                        } else {
                                                                            resolve("done");
                                                                        }
                                                                    }).then((response) => {
                                                                        new Promise((resolve, reject) => {
                                                                            if ((selected_order?.deliveries || []).length > 0) {
                                                                                (selected_order?.deliveries || []).map(
                                                                                    async (delivery, index) => {
                                                                                        await axios.post(props.serverUrl + "/saveOrderDelivery",
                                                                                            {
                                                                                                id: 0,
                                                                                                order_id: res.data.order.id,
                                                                                                customer_id: delivery.customer.id,
                                                                                                contact_id: null,
                                                                                                contact_name: "",
                                                                                                contact_phone: "",
                                                                                                contact_primary_phone: "work",
                                                                                                contact_phone_ext: "",
                                                                                                delivery_date1: "",
                                                                                                delivery_date2: "",
                                                                                                delivery_time1: "",
                                                                                                delivery_time2: "",
                                                                                                bol_numbers: delivery.bol_numbers || "",
                                                                                                po_numbers: delivery.po_numbers || "",
                                                                                                ref_numbers: delivery.ref_numbers || "",
                                                                                                seal_number: delivery.seal_number || "",
                                                                                                special_instructions: delivery.special_instructions || "",
                                                                                                type: "delivery",
                                                                                            }
                                                                                        ).then((res) => {
                                                                                            if (index === selected_order.deliveries.length - 1) {
                                                                                                resolve("done");
                                                                                            }
                                                                                        });

                                                                                        return false;
                                                                                    }
                                                                                );
                                                                            } else {
                                                                                resolve("done");
                                                                            }
                                                                        }).then(async (response) => {
                                                                            await axios.post(props.serverUrl + "/getOrderByOrderNumber",
                                                                                {
                                                                                    order_number: res.data.order.order_number,
                                                                                }
                                                                            ).then(async (res) => {
                                                                                if (res.data.result === "OK") {
                                                                                    let oldPickups = selected_order.pickups || [];
                                                                                    let oldDeliveries = selected_order.deliveries || [];
                                                                                    let oldRouting = selected_order.routing || [];
                                                                                    let newPickups = res.data.order.pickups;
                                                                                    let newDeliveries = res.data.order.deliveries;
                                                                                    let newRouting = [];

                                                                                    oldRouting.map((route) => {
                                                                                        if (route.type === "pickup") {
                                                                                            let pickupIndex = oldPickups.findIndex((p) => p.id === route.pickup_id);

                                                                                            newRouting.push({
                                                                                                ...route,
                                                                                                pickup_id: newPickups[pickupIndex].id,
                                                                                            });
                                                                                        } else {
                                                                                            let deliveryIndex = oldDeliveries.findIndex((d) => d.id === route.delivery_id);

                                                                                            newRouting.push({
                                                                                                ...route,
                                                                                                delivery_id: newDeliveries[deliveryIndex].id,
                                                                                            });
                                                                                        }

                                                                                        return false;
                                                                                    });

                                                                                    await axios.post(props.serverUrl + "/saveOrderRouting",
                                                                                        {
                                                                                            order_id: res.data.order.id,
                                                                                            routing: newRouting,
                                                                                        }
                                                                                    ).then((res) => {
                                                                                        if (res.data.result === "OK") {
                                                                                            axios.post(props.serverUrl + "/getOrderByOrderNumber",
                                                                                                {
                                                                                                    order_number: res.data.order.order_number,
                                                                                                }
                                                                                            ).then((res) => {
                                                                                                if (res.data.result === "OK") {
                                                                                                    setSelectedOrder(res.data.order);

                                                                                                    let pickup_id = (res.data.order.routing || []).find((r) => r.type === "pickup")?.pickup_id || 0;
                                                                                                    let pickup = {
                                                                                                        ...((res.data.order.pickups || []).find((p) => p.id === pickup_id) || (res.data.order.pickups || [])[0]),
                                                                                                    };

                                                                                                    setSelectedShipperCustomer(
                                                                                                        pickup.id === undefined
                                                                                                            ? {}
                                                                                                            : {
                                                                                                                ...pickup.customer,
                                                                                                                pickup_id: pickup.id,
                                                                                                                pu_date1: pickup.pu_date1,
                                                                                                                pu_date2: pickup.pu_date2,
                                                                                                                pu_time1: pickup.pu_time1,
                                                                                                                pu_time2: pickup.pu_time2,
                                                                                                                bol_numbers: pickup.bol_numbers,
                                                                                                                po_numbers: pickup.po_numbers,
                                                                                                                ref_numbers: pickup.ref_numbers,
                                                                                                                seal_number: pickup.seal_number,
                                                                                                                special_instructions: pickup.special_instructions,
                                                                                                                type: pickup.type,
                                                                                                            }
                                                                                                    );
                                                                                                    setSelectedShipperCustomerContact(
                                                                                                        {
                                                                                                            ...(pickup.contacts || []).find((c) => c.is_primary === 1),
                                                                                                        }
                                                                                                    );

                                                                                                    let delivery_id = (res.data.order.routing || []).find((r) => r.type === "delivery")?.delivery_id || 0;
                                                                                                    let delivery = {
                                                                                                        ...((res.data.order.deliveries || []).find((d) => d.id === delivery_id) || (res.data.order.deliveries || [])[0]),
                                                                                                    };

                                                                                                    setSelectedConsigneeCustomer(
                                                                                                        delivery.id === undefined
                                                                                                            ? {}
                                                                                                            : {
                                                                                                                ...delivery.customer,
                                                                                                                delivery_id: delivery.id,
                                                                                                                delivery_date1: delivery.delivery_date1,
                                                                                                                delivery_date2: delivery.delivery_date2,
                                                                                                                delivery_time1: delivery.delivery_time1,
                                                                                                                delivery_time2: delivery.delivery_time2,
                                                                                                                special_instructions: delivery.special_instructions,
                                                                                                                type: delivery.type,
                                                                                                            }
                                                                                                    );
                                                                                                    setSelectedConsigneeCustomerContact(
                                                                                                        {
                                                                                                            ...(delivery.contacts || []).find((c) => c.is_primary === 1),
                                                                                                        }
                                                                                                    );
                                                                                                }
                                                                                            }).catch((e) => {
                                                                                                console.log("error saving order miles", e);
                                                                                            }).then(() => {
                                                                                                setIsLoading(false);
                                                                                                refCarrierCode.current.focus();
                                                                                            });
                                                                                        }
                                                                                    }).catch((e) => {
                                                                                        console.log("error saving order miles", e);
                                                                                    });
                                                                                }
                                                                            }).catch((e) => {
                                                                                console.log("error saving order miles", e);
                                                                            });
                                                                        });
                                                                    });
                                                                });
                                                            });
                                                        });
                                                    } else {

                                                    }
                                                }).catch((e) => {
                                                    console.log("error saving order", e);
                                                });
                                            }
                                        }}>
                                        <div className="mochi-button-decorator mochi-button-decorator-left">(</div>
                                        <div className="mochi-button-base">Replicate Order</div>
                                        <div className="mochi-button-decorator mochi-button-decorator-right">)</div>
                                    </div>
                                }

                                {
                                    (props.user?.user_code?.is_admin || 0) === 1 &&
                                    <div className="mochi-button" style={{ marginLeft: 5 }}
                                        onClick={importOrdersBtnClick}>
                                        <div className="mochi-button-decorator mochi-button-decorator-left">(</div>
                                        <div className="mochi-button-base">Import</div>
                                        <div className="mochi-button-decorator mochi-button-decorator-right">)</div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: 10,
                        flexGrow: 1,
                        flexBasis: "100%",
                    }}>
                        <div className={`form-bordered-box${((selectedOrder?.division_id || 0) === 0 || (selectedOrder?.load_type_id || 0) === 0) ? ' form-disabled' : ''}`}
                            style={{ minWidth: "38%", maxWidth: "38%", marginRight: 10 }}>
                            <div className="form-header">
                                <div className="top-border top-border-left"></div>
                                <div className="form-title">Bill To</div>
                                <div className="top-border top-border-middle"></div>
                                <div className="form-buttons">
                                    {
                                        (selectedOrder?.is_cancelled || 0) === 0 &&
                                        <div className="mochi-button" onClick={billToCompanySearch}>
                                            <div className="mochi-button-decorator mochi-button-decorator-left">(</div>
                                            <div className="mochi-button-base">Search</div>
                                            <div className="mochi-button-decorator mochi-button-decorator-right">)</div>
                                        </div>
                                    }
                                    <div className="mochi-button" onClick={() => {
                                        if ((selectedBillToCustomer.id || 0) === 0) {
                                            window.alert("You must select a customer first!");
                                            return;
                                        }

                                        let panel = {
                                            panelName: `${props.panelName}-customer`,
                                            component: (
                                                <Customers
                                                    pageName={"Customer"}
                                                    title={"Bill-To Company"}
                                                    panelName={`${props.panelName}-customer`}
                                                    tabTimes={2000 + props.tabTimes}
                                                    componentId={moment().format("x")}
                                                    isOnPanel={true}
                                                    isAdmin={props.isAdmin}
                                                    origin={props.origin}
                                                    closingCallback={() => {
                                                        props.closePanel(`${props.panelName}-customer`, props.origin);
                                                        (props.isOnPanel ? refOrderNumber : props.refOrderNumber).current.focus({ preventScroll: true });
                                                    }}

                                                    customer_id={selectedBillToCustomer.id}
                                                />
                                            ),
                                        };

                                        openPanel(panel, props.origin);
                                    }}>
                                        <div className="mochi-button-decorator mochi-button-decorator-left">(</div>
                                        <div className="mochi-button-base">Company info</div>
                                        <div className="mochi-button-decorator mochi-button-decorator-right">)</div>
                                    </div>
                                    <div className="mochi-button" onClick={() => {
                                        if ((selectedOrder?.id || 0) === 0) {
                                            window.alert("You must create or load an order first!");
                                            return;
                                        }

                                        if ((selectedOrder?.load_type_id || 0) === 0) {
                                            window.alert("You must select a load type first!");
                                            return;
                                        }

                                        let panel = {
                                            panelName: `${props.panelName}-rating`,
                                            component: (
                                                <RatingScreen
                                                    panelName={`${props.panelName}-rating`}
                                                    title="Rating Screen"
                                                    tabTimes={34000 + props.tabTimes}
                                                    componentId={moment().format("x")}
                                                    origin={props.origin}
                                                    selectedOrder={selectedOrder}
                                                    closingCallback={() => {
                                                        props.closePanel(`${props.panelName}-rating`, props.origin);
                                                        (props.isOnPanel ? refOrderNumber : props.refOrderNumber).current.focus({ preventScroll: true });
                                                    }}
                                                />
                                            ),
                                        };

                                        openPanel(panel, props.origin);
                                    }}>
                                        <div className="mochi-button-decorator mochi-button-decorator-left">(</div>
                                        <div className="mochi-button-base">Rate load</div>
                                        <div className="mochi-button-decorator mochi-button-decorator-right">)</div>
                                    </div>
                                    <div className="mochi-button" onClick={() => {
                                        setSelectedBillToCustomer({});
                                        refBillToCompanyCode.current.focus();

                                        if ((selectedOrder?.id || 0) > 0) {
                                            let order = { ...selectedOrder };

                                            setSelectedOrder(prev => {
                                                return {
                                                    ...prev,
                                                    bill_to_customer_id: null,
                                                    bill_to_company: {}
                                                }
                                            })

                                            if (!isCreatingTemplate && !isEditingTemplate) {
                                                axios.post(props.serverUrl + '/saveOrder', {
                                                    ...order,
                                                    bill_to_customer_id: null,
                                                    bill_to_company: {}
                                                }).then(res => {

                                                }).catch(e => {
                                                    console.log('error clearing bill to company')
                                                })
                                            }
                                        }
                                    }}>
                                        <div className="mochi-button-decorator mochi-button-decorator-left">(</div>
                                        <div className="mochi-button-base">Clear</div>
                                        <div className="mochi-button-decorator mochi-button-decorator-right">)</div>
                                    </div>
                                </div>
                                <div className="top-border top-border-right"></div>
                            </div>

                            <div className="form-row">
                                <div className="input-box-container input-code">
                                    <input
                                        tabIndex={6 + props.tabTimes}
                                        type="text"
                                        placeholder="Code"
                                        maxLength="8"
                                        ref={refBillToCompanyCode}
                                        readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                        onKeyDown={(e) => {
                                            if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                getBillToCompanyByCode(e)
                                            }
                                        }}
                                        onInput={(e) => {
                                            setSelectedBillToCustomer({
                                                ...selectedBillToCustomer,
                                                code: e.target.value,
                                                code_number: 0,
                                            });
                                        }}
                                        onChange={(e) => {
                                            setSelectedBillToCustomer({
                                                ...selectedBillToCustomer,
                                                code: e.target.value,
                                                code_number: 0,
                                            });
                                        }}
                                        value={
                                            (selectedBillToCustomer.code_number || 0) === 0
                                                ? selectedBillToCustomer.code || ""
                                                : selectedBillToCustomer.code +
                                                selectedBillToCustomer.code_number
                                        }
                                    />
                                </div>
                                <div className="form-h-sep"></div>
                                <div className="input-box-container grow">
                                    <input
                                        tabIndex={7 + props.tabTimes}
                                        type="text"
                                        placeholder="Name"
                                        readOnly={(selectedOrder?.is_cancelled || 0) === 0}
                                        onInput={(e) => {
                                            setSelectedBillToCustomer({
                                                ...selectedBillToCustomer,
                                                name: e.target.value,
                                            });
                                        }}
                                        onChange={(e) => {
                                            setSelectedBillToCustomer({
                                                ...selectedBillToCustomer,
                                                name: e.target.value,
                                            });
                                        }}
                                        value={selectedBillToCustomer.name || ""}
                                    />
                                </div>
                            </div>
                            <div className="form-v-sep"></div>
                            <div className="form-row">
                                <div className="input-box-container grow">
                                    <input
                                        tabIndex={8 + props.tabTimes}
                                        type="text"
                                        placeholder="Address 1"
                                        readOnly={(selectedOrder?.is_cancelled || 0) === 0}
                                        onInput={(e) => {
                                            setSelectedBillToCustomer({
                                                ...selectedBillToCustomer,
                                                address1: e.target.value,
                                            });
                                        }}
                                        onChange={(e) => {
                                            setSelectedBillToCustomer({
                                                ...selectedBillToCustomer,
                                                address1: e.target.value,
                                            });
                                        }}
                                        value={selectedBillToCustomer.address1 || ""}
                                    />
                                </div>
                            </div>
                            <div className="form-v-sep"></div>
                            <div className="form-row">
                                <div className="input-box-container grow">
                                    <input
                                        tabIndex={9 + props.tabTimes}
                                        type="text"
                                        placeholder="Address 2"
                                        readOnly={(selectedOrder?.is_cancelled || 0) === 0}
                                        onInput={(e) => {
                                            setSelectedBillToCustomer({
                                                ...selectedBillToCustomer,
                                                address2: e.target.value,
                                            });
                                        }}
                                        onChange={(e) => {
                                            setSelectedBillToCustomer({
                                                ...selectedBillToCustomer,
                                                address2: e.target.value,
                                            });
                                        }}
                                        value={selectedBillToCustomer.address2 || ""}
                                    />
                                </div>
                            </div>
                            <div className="form-v-sep"></div>
                            <div className="form-row">
                                <div className="input-box-container grow">
                                    <input
                                        tabIndex={10 + props.tabTimes}
                                        type="text"
                                        placeholder="City"
                                        readOnly={(selectedOrder?.is_cancelled || 0) === 0}
                                        onInput={(e) => {
                                            setSelectedBillToCustomer({
                                                ...selectedBillToCustomer,
                                                city: e.target.value,
                                            });
                                        }}
                                        onChange={(e) => {
                                            setSelectedBillToCustomer({
                                                ...selectedBillToCustomer,
                                                city: e.target.value,
                                            });
                                        }}
                                        value={selectedBillToCustomer.city || ""}
                                    />
                                </div>
                                <div className="form-h-sep"></div>
                                <div className="input-box-container input-state">
                                    <input
                                        tabIndex={11 + props.tabTimes}
                                        type="text"
                                        placeholder="State"
                                        maxLength="2"
                                        readOnly={(selectedOrder?.is_cancelled || 0) === 0}
                                        onInput={(e) => {
                                            setSelectedBillToCustomer({
                                                ...selectedBillToCustomer,
                                                state: e.target.value,
                                            });
                                        }}
                                        onChange={(e) => {
                                            setSelectedBillToCustomer({
                                                ...selectedBillToCustomer,
                                                state: e.target.value,
                                            });
                                        }}
                                        value={selectedBillToCustomer.state || ""}
                                    />
                                </div>
                                <div className="form-h-sep"></div>
                                <div className="input-box-container input-zip-code">
                                    <input
                                        tabIndex={12 + props.tabTimes}
                                        type="text"
                                        placeholder="Postal Code"
                                        readOnly={(selectedOrder?.is_cancelled || 0) === 0}
                                        onInput={(e) => {
                                            setSelectedBillToCustomer({
                                                ...selectedBillToCustomer,
                                                zip: e.target.value,
                                            });
                                        }}
                                        onChange={(e) => {
                                            setSelectedBillToCustomer({
                                                ...selectedBillToCustomer,
                                                zip: e.target.value,
                                            });
                                        }}
                                        value={selectedBillToCustomer.zip || ""}
                                    />
                                </div>
                            </div>
                            <div className="form-v-sep"></div>
                            <div className="form-row">
                                <div className="input-box-container grow">
                                    <input
                                        tabIndex={13 + props.tabTimes}
                                        type="text"
                                        placeholder="Contact Name"
                                        readOnly={(selectedOrder?.is_cancelled || 0) === 0}
                                        onChange={(e) => {
                                            if ((selectedBillToCustomer?.contacts || []).length === 0) {
                                                setSelectedBillToCustomer({
                                                    ...selectedBillToCustomer,
                                                    contact_name: e.target.value,
                                                });
                                            }
                                        }}
                                        onInput={(e) => {
                                            if ((selectedBillToCustomer?.contacts || []).length === 0) {
                                                setSelectedBillToCustomer({
                                                    ...selectedBillToCustomer,
                                                    contact_name: e.target.value,
                                                });
                                            }
                                        }}
                                        value={
                                            (selectedBillToCustomer?.contacts || []).find((c) => c.is_primary === 1) === undefined
                                                ? selectedBillToCustomer?.contact_name || ""
                                                : selectedBillToCustomer?.contacts.find((c) => c.is_primary === 1).first_name + " " +
                                                selectedBillToCustomer?.contacts.find((c) => c.is_primary === 1).last_name
                                        }
                                    />
                                </div>
                                <div className="form-h-sep"></div>
                                <div
                                    className="input-box-container input-phone"
                                    style={{ position: "relative" }}
                                >
                                    <MaskedInput
                                        tabIndex={14 + props.tabTimes}
                                        mask={[/[0-9]/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/,]}
                                        guide={true}
                                        type="text"
                                        placeholder="Contact Phone"
                                        readOnly={(selectedOrder?.is_cancelled || 0) === 0}
                                        onInput={(e) => {
                                            if ((selectedBillToCustomer?.contacts || []).length === 0) {
                                                setSelectedBillToCustomer({
                                                    ...selectedBillToCustomer,
                                                    contact_phone: e.target.value,
                                                });
                                            }
                                        }}
                                        onChange={(e) => {
                                            if ((selectedBillToCustomer?.contacts || []).length === 0) {
                                                setSelectedBillToCustomer({
                                                    ...selectedBillToCustomer,
                                                    contact_phone: e.target.value,
                                                });
                                            }
                                        }}
                                        value={
                                            (selectedBillToCustomer?.contacts || []).find((c) => c.is_primary === 1) === undefined
                                                ? selectedBillToCustomer?.contact_phone || ""
                                                : selectedBillToCustomer?.contacts.find((c) => c.is_primary === 1).primary_phone === "work"
                                                    ? selectedBillToCustomer?.contacts.find((c) => c.is_primary === 1).phone_work
                                                    : selectedBillToCustomer?.contacts.find((c) => c.is_primary === 1).primary_phone === "fax"
                                                        ? selectedBillToCustomer?.contacts.find((c) => c.is_primary === 1).phone_work_fax
                                                        : selectedBillToCustomer?.contacts.find((c) => c.is_primary === 1).primary_phone === "mobile"
                                                            ? selectedBillToCustomer?.contacts.find((c) => c.is_primary === 1).phone_mobile
                                                            : selectedBillToCustomer?.contacts.find((c) => c.is_primary === 1).primary_phone === "direct"
                                                                ? selectedBillToCustomer?.contacts.find((c) => c.is_primary === 1).phone_direct
                                                                : selectedBillToCustomer?.contacts.find((c) => c.is_primary === 1).primary_phone === "other"
                                                                    ? selectedBillToCustomer?.contacts.find((c) => c.is_primary === 1).phone_other
                                                                    : ""
                                        }
                                    />

                                    {(selectedBillToCustomer?.contacts || []).find((c) => c.is_primary === 1) !== undefined && (
                                        <div className={classnames({
                                            "selected-customer-contact-primary-phone": true,
                                            pushed: false,
                                        })}
                                        >
                                            {
                                                selectedBillToCustomer?.contacts.find((c) => c.is_primary === 1).primary_phone
                                            }
                                        </div>
                                    )}
                                </div>
                                <div className="form-h-sep"></div>
                                <div className="input-box-container input-phone-ext">
                                    <input
                                        tabIndex={15 + props.tabTimes}
                                        type="text"
                                        placeholder="Ext"
                                        onKeyDown={(e) => {
                                            if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                validateOrderForSaving(e)
                                            }
                                        }
                                        }
                                        onInput={(e) => {
                                            if ((selectedBillToCustomer?.contacts || []).length === 0) {
                                                setSelectedBillToCustomer({
                                                    ...selectedBillToCustomer,
                                                    ext: e.target.value,
                                                });
                                            }
                                        }}
                                        onChange={(e) => {
                                            if ((selectedBillToCustomer?.contacts || []).length === 0) {
                                                setSelectedBillToCustomer({
                                                    ...selectedBillToCustomer,
                                                    ext: e.target.value,
                                                });
                                            }
                                        }}
                                        value={
                                            (selectedBillToCustomer?.contacts || []).find((c) => c.is_primary === 1) === undefined
                                                ? selectedBillToCustomer?.ext || ""
                                                : selectedBillToCustomer?.contacts.find((c) => c.is_primary === 1).phone_ext
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={`form-bordered-box${((selectedOrder?.division_id || 0) === 0 || (selectedOrder?.load_type_id || 0) === 0) ? ' form-disabled' : ''}`}
                            style={{ minWidth: "38%", maxWidth: "38%", marginRight: 10, gap: 2 }}>
                            <div className="form-header">
                                <div className="top-border top-border-left"></div>
                                <div className="form-title">{
                                    (selectedOrder?.division?.type || 'brokerage') === 'brokerage'
                                        ? 'Carrier'
                                        : (selectedOrder?.division?.type || 'brokerage') === 'company'
                                            ? (selectedOrder?.carrier_owner_type || 'company') === 'company'
                                                ? 'Driver'
                                                : 'Owner Operator'
                                            : 'Carrier'

                                }</div>
                                <div className="top-border top-border-middle"></div>
                                <div className="form-buttons">
                                    {((selectedOrder?.is_cancelled || 0) === 0 && (selectedCarrier?.id || 0) === 0) && (
                                        <div className="mochi-button" onClick={searchCarrierBtnClick}>
                                            <div className="mochi-button-decorator mochi-button-decorator-left">(</div>
                                            <div className="mochi-button-base">Search</div>
                                            <div className="mochi-button-decorator mochi-button-decorator-right">)</div>
                                        </div>
                                    )}

                                    <div className="mochi-button" onClick={() => {
                                        if ((selectedCarrier.id || 0) === 0) {
                                            window.alert(`You must select a carrier first!`);
                                            return;
                                        }

                                        let panel = {
                                            panelName: `${props.panelName}-carrier`,
                                            component: (
                                                <Carriers
                                                    pageName={"Carrier"}
                                                    title={"Carrier"}
                                                    panelName={props.panelName + '-carrier'}
                                                    tabTimes={3000 + props.tabTimes}
                                                    screenFocused={props.carrierScreenFocused}
                                                    componentId={moment().format("x")}
                                                    isOnPanel={true}
                                                    isAdmin={props.isAdmin}
                                                    origin={props.origin}
                                                    closingCallback={() => {
                                                        props.closePanel(props.panelName + '-carrier', props.origin);
                                                        (props.isOnPanel ? refOrderNumber : props.refOrderNumber).current.focus({ preventScroll: true });
                                                    }}

                                                    carrier_id={selectedCarrier.id}
                                                />
                                            ),
                                        };

                                        openPanel(panel, props.origin);
                                    }}>
                                        <div className="mochi-button-decorator mochi-button-decorator-left">(</div>
                                        <div className="mochi-button-base">Carrier Info</div>
                                        <div className="mochi-button-decorator mochi-button-decorator-right">)</div>
                                    </div>

                                    <div className="mochi-button" onClick={() => {
                                        setSelectedCarrier({});
                                        setSelectedCarrierContact({});
                                        setSelectedCarrierDriver({});
                                        setSelectedCarrierInsurance({});

                                        refCarrierCode.current.focus();

                                        if ((selectedOrder?.id || 0) > 0) {
                                            let order = {
                                                ...selectedOrder,
                                                carrier: {},
                                                carrier_id: null,
                                                carrier_contact_id: null,
                                                carrier_contact_primary_phone: 'work',
                                                carrier_owner_type: '',
                                                carrier_driver_id: null,
                                                equipment: {},
                                                equipment_id: null,
                                                driver: {}
                                            }

                                            setSelectedOrder(prev => {
                                                return {
                                                    ...prev,
                                                    carrier: {},
                                                    carrier_id: null,
                                                    carrier_contact_id: null,
                                                    carrier_contact_primary_phone: 'work',
                                                    carrier_owner_type: '',
                                                    carrier_driver_id: null,
                                                    equipment: {},
                                                    equipment_id: null,
                                                    driver: {}
                                                }
                                            })

                                            if (!isCreatingTemplate && !isEditingTemplate) {
                                                axios.post(props.serverUrl + '/saveOrder', order).then(res => {

                                                }).catch(e => {
                                                    console.log('error clearing carrier')
                                                })
                                            }
                                        }

                                    }}>
                                        <div className="mochi-button-decorator mochi-button-decorator-left">(</div>
                                        <div className="mochi-button-base">Clear</div>
                                        <div className="mochi-button-decorator mochi-button-decorator-right">)</div>
                                    </div>
                                </div>
                                <div className="top-border top-border-right"></div>
                            </div>

                            <div className="form-row" style={{ gap: 2 }}>
                                <div className="input-box-container input-code">
                                    <input
                                        tabIndex={54 + props.tabTimes}
                                        type="text"
                                        placeholder="Code"
                                        maxLength="8"
                                        ref={refCarrierCode}
                                        readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                        onKeyDown={(e) => {
                                            if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                getCarrierInfoByCode(e);
                                            }
                                        }}
                                        onInput={(e) => {
                                            setSelectedCarrier({
                                                ...selectedCarrier,
                                                code: e.target.value,
                                                code_number: 0,
                                            });
                                        }}
                                        onChange={(e) => {
                                            setSelectedCarrier({
                                                ...selectedCarrier,
                                                code: e.target.value,
                                                code_number: 0,
                                            });
                                        }}
                                        value={
                                            (selectedCarrier.code_number || 0) === 0
                                                ? selectedCarrier.code || ""
                                                : selectedCarrier.code + selectedCarrier.code_number
                                        }
                                    />
                                </div>

                                <div className="input-box-container grow">
                                    <input
                                        tabIndex={55 + props.tabTimes}
                                        type="text"
                                        placeholder="Name"
                                        // onKeyDown={validateCarrierInfoForSaving}
                                        onInput={(e) => {
                                            setSelectedCarrier({
                                                ...selectedCarrier,
                                                name: e.target.value,
                                            });
                                        }}
                                        onChange={(e) => {
                                            setSelectedCarrier({
                                                ...selectedCarrier,
                                                name: e.target.value,
                                            });
                                        }}
                                        value={selectedCarrier?.name || ""}
                                    />
                                </div>

                                {
                                    ((selectedCarrier?.id || 0) > 0 && (selectedOrder?.carrier_owner_type || '') === 'carrier') &&
                                    <div className={insuranceStatusClasses()} style={{ width: "7rem" }}>
                                        <input type="text" placeholder="Insurance" readOnly={true} />
                                    </div>
                                }
                            </div>

                            <div className="form-row" style={{ gap: 2 }}>
                                <div className="input-box-container grow">
                                    <input
                                        tabIndex={56 + props.tabTimes}
                                        type="text"
                                        placeholder="Carrier Load - Starting City State - Destination City State"
                                        readOnly={true}
                                        value={
                                            (selectedOrder?.routing || []).length >= 2 &&
                                                (selectedOrder?.carrier?.id || 0) > 0
                                                ? selectedOrder.routing[0].type === "pickup"
                                                    ? (selectedOrder.pickups.find((p) => p.id === selectedOrder.routing[0].pickup_id)?.customer?.city || "") +
                                                    ", " +
                                                    (selectedOrder.pickups.find((p) => p.id === selectedOrder.routing[0].pickup_id)?.customer?.state || "") +
                                                    " - " +
                                                    (selectedOrder.routing[selectedOrder.routing.length - 1].type === "pickup"
                                                        ? (selectedOrder.pickups.find((p) => p.id === selectedOrder.routing[selectedOrder.routing.length - 1].pickup_id)?.customer?.city || "") +
                                                        ", " +
                                                        (selectedOrder.pickups.find((p) => p.id === selectedOrder.routing[selectedOrder.routing.length - 1].pickup_id)?.customer?.state || "")
                                                        : (selectedOrder.deliveries.find((d) => d.id === selectedOrder.routing[selectedOrder.routing.length - 1].delivery_id)?.customer?.city || "") +
                                                        ", " +
                                                        (selectedOrder.deliveries.find((d) => d.id === selectedOrder.routing[selectedOrder.routing.length - 1].delivery_id)?.customer?.state || ""))
                                                    : (selectedOrder.deliveries.find((d) => d.id === selectedOrder.routing[0].delivery_id)?.customer?.city || "") +
                                                    ", " +
                                                    (selectedOrder.deliveries.find((d) => d.id === selectedOrder.routing[0].delivery_id)?.customer?.state || "") +
                                                    " - " +
                                                    (selectedOrder.routing[selectedOrder.routing.length - 1].type === "pickup"
                                                        ? (selectedOrder.pickups.find((p) => p.id === selectedOrder.routing[selectedOrder.routing.length - 1].pickup_id)?.customer?.city || "") +
                                                        ", " +
                                                        (selectedOrder.pickups.find((p) => p.id === selectedOrder.routing[selectedOrder.routing.length - 1].pickup_id)?.customer?.state || "")
                                                        : (selectedOrder.deliveries.find((d) => d.id === selectedOrder.routing[selectedOrder.routing.length - 1].delivery_id)?.customer?.city || "") +
                                                        ", " +
                                                        (selectedOrder.deliveries.find((d) => d.id === selectedOrder.routing[selectedOrder.routing.length - 1].delivery_id)?.customer?.state || ""))
                                                : ""
                                        }
                                    />
                                </div>
                            </div>

                            <div className="form-row" style={{ gap: 2 }}>
                                <div className="select-box-container grow">
                                    <div className="select-box-wrapper">
                                        <input
                                            tabIndex={57 + props.tabTimes}
                                            type="text"
                                            placeholder="Contact Name"
                                            ref={refCarrierContactName}
                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                            onKeyDown={async (e) => {
                                                if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                    let key = e.keyCode || e.which;

                                                    switch (key) {
                                                        case 37:
                                                        case 38: // arrow left | arrow up
                                                            e.preventDefault();
                                                            if (showCarrierContactNames) {
                                                                let selectedIndex = carrierContactNameItems.findIndex(item => item.selected);

                                                                if (selectedIndex === -1) {
                                                                    await setCarrierContactNameItems(carrierContactNameItems.map((item, index) => {
                                                                        item.selected = index === 0;
                                                                        return item;
                                                                    }))
                                                                } else {
                                                                    await setCarrierContactNameItems(carrierContactNameItems.map((item, index) => {
                                                                        if (selectedIndex === 0) {
                                                                            item.selected = index === (carrierContactNameItems.length - 1);
                                                                        } else {
                                                                            item.selected = index === (selectedIndex - 1)
                                                                        }
                                                                        return item;
                                                                    }))
                                                                }

                                                                refCarrierContactNamePopupItems.current.map((r, i) => {
                                                                    if (r && r.classList.contains('selected')) {
                                                                        r.scrollIntoView({
                                                                            behavior: 'auto',
                                                                            block: 'center',
                                                                            inline: 'nearest'
                                                                        })
                                                                    }
                                                                    return true;
                                                                });
                                                            } else {
                                                                if (carrierContactNameItems.length > 1) {
                                                                    await setCarrierContactNameItems((selectedCarrier?.contacts || []).map((item, index) => {
                                                                        item.selected = (selectedCarrierContact?.id || 0) > 0 ? (selectedCarrierContact?.id || 0) === item.id : index === 0
                                                                        return item;
                                                                    }))

                                                                    setShowCarrierContactNames(true);

                                                                    refCarrierContactNamePopupItems.current.map((r, i) => {
                                                                        if (r && r.classList.contains('selected')) {
                                                                            r.scrollIntoView({
                                                                                behavior: 'auto',
                                                                                block: 'center',
                                                                                inline: 'nearest'
                                                                            })
                                                                        }
                                                                        return true;
                                                                    });
                                                                }
                                                            }
                                                            break;

                                                        case 39:
                                                        case 40: // arrow right | arrow down
                                                            e.preventDefault();
                                                            if (showCarrierContactNames) {
                                                                let selectedIndex = carrierContactNameItems.findIndex(item => item.selected);

                                                                if (selectedIndex === -1) {
                                                                    await setCarrierContactNameItems(carrierContactNameItems.map((item, index) => {
                                                                        item.selected = index === 0;
                                                                        return item;
                                                                    }))
                                                                } else {
                                                                    await setCarrierContactNameItems(carrierContactNameItems.map((item, index) => {
                                                                        if (selectedIndex === (carrierContactNameItems.length - 1)) {
                                                                            item.selected = index === 0;
                                                                        } else {
                                                                            item.selected = index === (selectedIndex + 1)
                                                                        }
                                                                        return item;
                                                                    }))
                                                                }

                                                                refCarrierContactNamePopupItems.current.map((r, i) => {
                                                                    if (r && r.classList.contains('selected')) {
                                                                        r.scrollIntoView({
                                                                            behavior: 'auto',
                                                                            block: 'center',
                                                                            inline: 'nearest'
                                                                        })
                                                                    }
                                                                    return true;
                                                                });
                                                            } else {
                                                                if (carrierContactNameItems.length > 1) {
                                                                    await setCarrierContactNameItems((selectedCarrier?.contacts || []).map((item, index) => {
                                                                        item.selected = (selectedCarrierContact?.id || 0) > 0 ? (selectedCarrierContact?.id || 0) === item.id : index === 0
                                                                        return item;
                                                                    }))

                                                                    setShowCarrierContactNames(true);

                                                                    refCarrierContactNamePopupItems.current.map((r, i) => {
                                                                        if (r && r.classList.contains('selected')) {
                                                                            r.scrollIntoView({
                                                                                behavior: 'auto',
                                                                                block: 'center',
                                                                                inline: 'nearest'
                                                                            })
                                                                        }
                                                                        return true;
                                                                    });
                                                                }
                                                            }
                                                            break;

                                                        case 27: // escape
                                                            setShowCarrierContactNames(false);
                                                            break;

                                                        case 13: // enter
                                                            if (showCarrierContactNames && carrierContactNameItems.findIndex(item => item.selected) > -1) {
                                                                if (carrierContactNameItems[carrierContactNameItems.findIndex((item) => item.selected)].id === null) {
                                                                    await setSelectedCarrierContact({ name: "" });

                                                                    if (!isCreatingTemplate && !isEditingTemplate) {
                                                                        axios.post(props.serverUrl + "/saveOrder", {
                                                                            ...selectedOrder,
                                                                            carrier_contact_id: null,
                                                                        }).then(async (res) => {
                                                                            if (res.data.result === "OK") {
                                                                                await setSelectedOrder({
                                                                                    ...res.data.order,
                                                                                });
                                                                                await props.setSelectedOrder({
                                                                                    ...res.data.order,
                                                                                    component_id: props.componentId,
                                                                                });
                                                                            } else {

                                                                            }

                                                                            setIsSavingOrder(false);
                                                                        }).catch((e) => {
                                                                            console.log("error saving order", e);
                                                                            setIsSavingOrder(false);
                                                                        });
                                                                    }

                                                                    refCarrierContactName.current.focus();
                                                                } else {
                                                                    await setSelectedCarrierContact(carrierContactNameItems[carrierContactNameItems.findIndex((item) => item.selected)]);

                                                                    await setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        carrier_contact_id: carrierContactNameItems[carrierContactNameItems.findIndex(item => item.selected)].id,
                                                                        carrier_contact_primary_phone: (carrierContactNameItems[carrierContactNameItems.findIndex(item => item.selected)].phone_work || '') !== ''
                                                                            ? 'work'
                                                                            : (carrierContactNameItems[carrierContactNameItems.findIndex(item => item.selected)].phone_work_fax || '') !== ''
                                                                                ? 'fax'
                                                                                : (carrierContactNameItems[carrierContactNameItems.findIndex(item => item.selected)].phone_mobile || '') !== ''
                                                                                    ? 'mobile'
                                                                                    : (carrierContactNameItems[carrierContactNameItems.findIndex(item => item.selected)].phone_direct || '') !== ''
                                                                                        ? 'direct'
                                                                                        : (carrierContactNameItems[carrierContactNameItems.findIndex(item => item.selected)].phone_other || '') !== ''
                                                                                            ? 'other' :
                                                                                            ''
                                                                    });

                                                                    if (!isCreatingTemplate && !isEditingTemplate) {
                                                                        axios.post(props.serverUrl + "/saveOrder", {
                                                                            ...selectedOrder,
                                                                            carrier_contact_id: carrierContactNameItems[carrierContactNameItems.findIndex(item => item.selected)].id,
                                                                            carrier_contact_primary_phone: (carrierContactNameItems[carrierContactNameItems.findIndex(item => item.selected)].phone_work || '') !== ''
                                                                                ? 'work'
                                                                                : (carrierContactNameItems[carrierContactNameItems.findIndex(item => item.selected)].phone_work_fax || '') !== ''
                                                                                    ? 'fax'
                                                                                    : (carrierContactNameItems[carrierContactNameItems.findIndex(item => item.selected)].phone_mobile || '') !== ''
                                                                                        ? 'mobile'
                                                                                        : (carrierContactNameItems[carrierContactNameItems.findIndex(item => item.selected)].phone_direct || '') !== ''
                                                                                            ? 'direct'
                                                                                            : (carrierContactNameItems[carrierContactNameItems.findIndex(item => item.selected)].phone_other || '') !== ''
                                                                                                ? 'other' :
                                                                                                'work'
                                                                        }).then(async (res) => {
                                                                            if (res.data.result === "OK") {
                                                                                await setSelectedOrder({
                                                                                    ...res.data.order,
                                                                                });
                                                                                await props.setSelectedOrder({
                                                                                    ...res.data.order,
                                                                                    component_id: props.componentId,
                                                                                });
                                                                            } else {

                                                                            }
                                                                        }).catch((e) => {
                                                                            console.log("error saving order", e);
                                                                        }).finally(() => {
                                                                            setShowCarrierContactNames(false);
                                                                            setIsSavingOrder(false);
                                                                            refCarrierContactName.current.focus();
                                                                        });
                                                                    }
                                                                }
                                                            }
                                                            break;

                                                        case 9: // tab
                                                            if (showCarrierContactNames) {
                                                                e.preventDefault();
                                                                if (carrierContactNameItems[carrierContactNameItems.findIndex((item) => item.selected)].id === null) {
                                                                    await setSelectedCarrierContact({ name: "" });

                                                                    if (!isCreatingTemplate && !isEditingTemplate) {
                                                                        axios.post(props.serverUrl + "/saveOrder", {
                                                                            ...selectedOrder,
                                                                            carrier_contact_id: null,
                                                                        }).then(async (res) => {
                                                                            if (res.data.result === "OK") {
                                                                                await setSelectedOrder({
                                                                                    ...res.data.order,
                                                                                });
                                                                                await props.setSelectedOrder({
                                                                                    ...res.data.order,
                                                                                    component_id: props.componentId,
                                                                                });
                                                                            } else {

                                                                            }

                                                                            setIsSavingOrder(false);
                                                                        }).catch((e) => {
                                                                            console.log("error saving order", e);
                                                                            setIsSavingOrder(false);
                                                                        });
                                                                    }

                                                                    refCarrierContactName.current.focus();
                                                                } else {
                                                                    await setSelectedCarrierContact(carrierContactNameItems[carrierContactNameItems.findIndex((item) => item.selected)]);

                                                                    await setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        carrier_contact_id: carrierContactNameItems[carrierContactNameItems.findIndex(item => item.selected)].id,
                                                                        carrier_contact_primary_phone: (carrierContactNameItems[carrierContactNameItems.findIndex(item => item.selected)].phone_work || '') !== ''
                                                                            ? 'work'
                                                                            : (carrierContactNameItems[carrierContactNameItems.findIndex(item => item.selected)].phone_work_fax || '') !== ''
                                                                                ? 'fax'
                                                                                : (carrierContactNameItems[carrierContactNameItems.findIndex(item => item.selected)].phone_mobile || '') !== ''
                                                                                    ? 'mobile'
                                                                                    : (carrierContactNameItems[carrierContactNameItems.findIndex(item => item.selected)].phone_direct || '') !== ''
                                                                                        ? 'direct'
                                                                                        : (carrierContactNameItems[carrierContactNameItems.findIndex(item => item.selected)].phone_other || '') !== ''
                                                                                            ? 'other' :
                                                                                            ''
                                                                    });

                                                                    if (!isCreatingTemplate && !isEditingTemplate) {
                                                                        axios.post(props.serverUrl + "/saveOrder", {
                                                                            ...selectedOrder,
                                                                            carrier_contact_id: carrierContactNameItems[carrierContactNameItems.findIndex(item => item.selected)].id,
                                                                            carrier_contact_primary_phone: (carrierContactNameItems[carrierContactNameItems.findIndex(item => item.selected)].phone_work || '') !== ''
                                                                                ? 'work'
                                                                                : (carrierContactNameItems[carrierContactNameItems.findIndex(item => item.selected)].phone_work_fax || '') !== ''
                                                                                    ? 'fax'
                                                                                    : (carrierContactNameItems[carrierContactNameItems.findIndex(item => item.selected)].phone_mobile || '') !== ''
                                                                                        ? 'mobile'
                                                                                        : (carrierContactNameItems[carrierContactNameItems.findIndex(item => item.selected)].phone_direct || '') !== ''
                                                                                            ? 'direct'
                                                                                            : (carrierContactNameItems[carrierContactNameItems.findIndex(item => item.selected)].phone_other || '') !== ''
                                                                                                ? 'other' :
                                                                                                'work'
                                                                        }).then(async (res) => {
                                                                            if (res.data.result === "OK") {
                                                                                await setSelectedOrder({
                                                                                    ...res.data.order,
                                                                                });
                                                                                await props.setSelectedOrder({
                                                                                    ...res.data.order,
                                                                                    component_id: props.componentId,
                                                                                });
                                                                            } else {

                                                                            }
                                                                        }).catch((e) => {
                                                                            console.log("error saving order", e);
                                                                        }).finally(() => {
                                                                            setShowCarrierContactNames(false);
                                                                            setIsSavingOrder(false);
                                                                        });
                                                                    }
                                                                    refCarrierContactName.current.focus();
                                                                }
                                                            } else {
                                                                // validateCarrierContactForSaving({ keyCode: 9 });
                                                            }
                                                            break;

                                                        default:
                                                            break;
                                                    }
                                                }
                                            }}
                                            onInput={(e) => {
                                            }}
                                            onChange={(e) => {
                                            }}
                                            value={((selectedCarrierContact?.first_name || '') + ' ' + (selectedCarrierContact?.last_name || '')).trim()}
                                        />

                                        {
                                            ((selectedOrder?.is_cancelled || 0) === 0 && ((selectedCarrier?.contacts || []).length > 1)) &&
                                            <FontAwesomeIcon className="dropdown-button" icon={faCaretDown}
                                                onClick={async () => {
                                                    if (showCarrierContactNames) {
                                                        setShowCarrierContactNames(false);
                                                    } else {
                                                        if ((selectedCarrier?.contacts || []).length > 1) {
                                                            await setCarrierContactNameItems((selectedCarrier?.contacts || []).map((item, index) => {
                                                                item.selected = (selectedCarrierContact?.id || 0) > 0 ? (selectedCarrierContact?.id || 0) === item.id : index === 0
                                                                return item;
                                                            }))

                                                            window.setTimeout(async () => {
                                                                await setShowCarrierContactNames(true);

                                                                refCarrierContactNamePopupItems.current.map((r, i) => {
                                                                    if (r && r.classList.contains('selected')) {
                                                                        r.scrollIntoView({
                                                                            behavior: 'auto',
                                                                            block: 'center',
                                                                            inline: 'nearest'
                                                                        })
                                                                    }
                                                                    return true;
                                                                });
                                                            }, 0)
                                                        }
                                                    }

                                                    refCarrierContactName.current.focus();
                                                }} />
                                        }
                                    </div>
                                    {
                                        carrierContactNamesTransition((style, item) => item && (
                                            <animated.div
                                                className="mochi-contextual-container"
                                                id="mochi-contextual-container-contact-names"
                                                style={{
                                                    ...style,
                                                    left: '0',
                                                    display: 'block'
                                                }}
                                                ref={refCarrierContactNameDropDown}
                                            >
                                                <div className="mochi-contextual-popup vertical below right"
                                                    style={{ height: 150 }}>
                                                    <div className="mochi-contextual-popup-content">
                                                        <div className="mochi-contextual-popup-wrapper">
                                                            {
                                                                carrierContactNameItems.map((item, index) => {
                                                                    const mochiItemClasses = classnames({
                                                                        'mochi-item': true,
                                                                        'selected': item.selected
                                                                    });

                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            className={mochiItemClasses}
                                                                            id={item.id}
                                                                            onClick={async () => {
                                                                                await setSelectedCarrierContact(item);

                                                                                await setSelectedOrder({
                                                                                    ...selectedOrder,
                                                                                    carrier_contact_id: item.id,
                                                                                    carrier_contact_primary_phone: (item.phone_work || '') !== ''
                                                                                        ? 'work'
                                                                                        : (item.phone_work_fax || '') !== ''
                                                                                            ? 'fax'
                                                                                            : (item.phone_mobile || '') !== ''
                                                                                                ? 'mobile'
                                                                                                : (item.phone_direct || '') !== ''
                                                                                                    ? 'direct'
                                                                                                    : (item.phone_other || '') !== ''
                                                                                                        ? 'other' :
                                                                                                        ''
                                                                                });

                                                                                if (!isCreatingTemplate && !isEditingTemplate) {
                                                                                    axios.post(props.serverUrl + "/saveOrder", {
                                                                                        ...selectedOrder,
                                                                                        carrier_contact_id: item.id,
                                                                                        carrier_contact_primary_phone: (item.phone_work || '') !== ''
                                                                                            ? 'work'
                                                                                            : (item.phone_work_fax || '') !== ''
                                                                                                ? 'fax'
                                                                                                : (item.phone_mobile || '') !== ''
                                                                                                    ? 'mobile'
                                                                                                    : (item.phone_direct || '') !== ''
                                                                                                        ? 'direct'
                                                                                                        : (item.phone_other || '') !== ''
                                                                                                            ? 'other' :
                                                                                                            'work'
                                                                                    }).then(async (res) => {
                                                                                        if (res.data.result === "OK") {
                                                                                            await setSelectedOrder({
                                                                                                ...res.data.order,
                                                                                            });
                                                                                            await props.setSelectedOrder({
                                                                                                ...res.data.order,
                                                                                                component_id: props.componentId,
                                                                                            });
                                                                                        } else {

                                                                                        }
                                                                                    }).catch((e) => {
                                                                                        console.log("error saving order", e);
                                                                                    }).finally(() => {
                                                                                        setShowCarrierContactNames(false);
                                                                                        setIsSavingOrder(false);
                                                                                    });
                                                                                }

                                                                                refCarrierContactName.current.focus();
                                                                            }}
                                                                            ref={ref => refCarrierContactNamePopupItems.current.push(ref)}
                                                                        >
                                                                            {
                                                                                item.first_name + ((item.last_name || '') === '' ? '' : ' ' + item.last_name)
                                                                            }

                                                                            {
                                                                                item.selected &&
                                                                                <FontAwesomeIcon
                                                                                    className="dropdown-selected"
                                                                                    icon={faCaretRight} />
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </animated.div>
                                        ))
                                    }
                                </div>

                                <div className="select-box-container input-phone">
                                    <div className="select-box-wrapper">
                                        <MaskedInput tabIndex={58 + props.tabTimes}
                                            mask={[/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                            guide={true}
                                            type="text"
                                            placeholder="Contact Phone"
                                            ref={refCarrierContactPhone}
                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                            onKeyDown={async (e) => {
                                                if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                    let key = e.keyCode || e.which;

                                                    switch (key) {
                                                        case 37:
                                                        case 38: // arrow left | arrow up
                                                            e.preventDefault();
                                                            if (showCarrierContactPhones) {
                                                                let selectedIndex = carrierContactPhoneItems.findIndex(item => item.selected);

                                                                if (selectedIndex === -1) {
                                                                    await setCarrierContactPhoneItems(carrierContactPhoneItems.map((item, index) => {
                                                                        item.selected = index === 0;
                                                                        return item;
                                                                    }))
                                                                } else {
                                                                    await setCarrierContactPhoneItems(carrierContactPhoneItems.map((item, index) => {
                                                                        if (selectedIndex === 0) {
                                                                            item.selected = index === (carrierContactPhoneItems.length - 1);
                                                                        } else {
                                                                            item.selected = index === (selectedIndex - 1)
                                                                        }
                                                                        return item;
                                                                    }))
                                                                }

                                                                refCarrierContactPhonePopupItems.current.map((r, i) => {
                                                                    if (r && r.classList.contains('selected')) {
                                                                        r.scrollIntoView({
                                                                            behavior: 'auto',
                                                                            block: 'center',
                                                                            inline: 'nearest'
                                                                        })
                                                                    }
                                                                    return true;
                                                                });
                                                            } else {
                                                                if (carrierContactPhoneItems.length > 1) {
                                                                    await setCarrierContactPhoneItems(carrierContactPhoneItems.map((item, index) => {
                                                                        item.selected = (selectedOrder?.carrier_contact_primary_phone || '') === '' ? index === 0 : selectedOrder.carrier_contact_primary_phone === item.type;
                                                                        return item;
                                                                    }))

                                                                    setShowCarrierContactPhones(true);

                                                                    refCarrierContactPhonePopupItems.current.map((r, i) => {
                                                                        if (r && r.classList.contains('selected')) {
                                                                            r.scrollIntoView({
                                                                                behavior: 'auto',
                                                                                block: 'center',
                                                                                inline: 'nearest'
                                                                            })
                                                                        }
                                                                        return true;
                                                                    });
                                                                }
                                                            }
                                                            break;

                                                        case 39:
                                                        case 40: // arrow right | arrow down
                                                            e.preventDefault();
                                                            if (showCarrierContactPhones) {
                                                                let selectedIndex = carrierContactPhoneItems.findIndex(item => item.selected);

                                                                if (selectedIndex === -1) {
                                                                    await setCarrierContactPhoneItems(carrierContactPhoneItems.map((item, index) => {
                                                                        item.selected = index === 0;
                                                                        return item;
                                                                    }))
                                                                } else {
                                                                    await setCarrierContactPhoneItems(carrierContactPhoneItems.map((item, index) => {
                                                                        if (selectedIndex === (carrierContactPhoneItems.length - 1)) {
                                                                            item.selected = index === 0;
                                                                        } else {
                                                                            item.selected = index === (selectedIndex + 1)
                                                                        }
                                                                        return item;
                                                                    }))
                                                                }

                                                                refCarrierContactPhonePopupItems.current.map((r, i) => {
                                                                    if (r && r.classList.contains('selected')) {
                                                                        r.scrollIntoView({
                                                                            behavior: 'auto',
                                                                            block: 'center',
                                                                            inline: 'nearest'
                                                                        })
                                                                    }
                                                                    return true;
                                                                });
                                                            } else {
                                                                if (carrierContactPhoneItems.length > 1) {
                                                                    await setCarrierContactPhoneItems(carrierContactPhoneItems.map((item, index) => {
                                                                        item.selected = (selectedOrder?.carrier_contact_primary_phone || '') === '' ? index === 0 : selectedOrder.carrier_contact_primary_phone === item.type;
                                                                        return item;
                                                                    }))

                                                                    setShowCarrierContactPhones(true);

                                                                    refCarrierContactPhonePopupItems.current.map((r, i) => {
                                                                        if (r && r.classList.contains('selected')) {
                                                                            r.scrollIntoView({
                                                                                behavior: 'auto',
                                                                                block: 'center',
                                                                                inline: 'nearest'
                                                                            })
                                                                        }
                                                                        return true;
                                                                    });
                                                                }
                                                            }
                                                            break;

                                                        case 27: // escape
                                                            setShowCarrierContactPhones(false);
                                                            break;

                                                        case 13: // enter
                                                            if (showCarrierContactPhones && carrierContactPhoneItems.findIndex(item => item.selected) > -1) {
                                                                await setSelectedOrder({
                                                                    ...selectedOrder,
                                                                    carrier_contact_primary_phone: carrierContactPhoneItems[carrierContactPhoneItems.findIndex(item => item.selected)].type
                                                                })

                                                                if (!isCreatingTemplate && !isEditingTemplate) {
                                                                    axios.post(props.serverUrl + "/saveOrder", {
                                                                        ...selectedOrder,
                                                                        carrier_contact_primary_phone: carrierContactPhoneItems[carrierContactPhoneItems.findIndex(item => item.selected)].type
                                                                    }).then(async (res) => {
                                                                        if (res.data.result === "OK") {
                                                                            await setSelectedOrder({
                                                                                ...res.data.order,
                                                                            });
                                                                            await props.setSelectedOrder({
                                                                                ...res.data.order,
                                                                                component_id: props.componentId,
                                                                            });
                                                                        } else {

                                                                        }
                                                                    }).catch((e) => {
                                                                        console.log("error saving order", e);
                                                                    }).finally(() => {
                                                                        setShowCarrierContactPhones(false);
                                                                        setIsSavingOrder(false);
                                                                    });
                                                                }
                                                                refCarrierContactPhone.current.inputElement.focus();

                                                            }
                                                            break;

                                                        case 9: // tab
                                                            if (showCarrierContactPhones) {
                                                                e.preventDefault();
                                                                await setSelectedOrder({
                                                                    ...selectedOrder,
                                                                    carrier_contact_primary_phone: carrierContactPhoneItems[carrierContactPhoneItems.findIndex(item => item.selected)].type
                                                                })

                                                                if (!isCreatingTemplate && !isEditingTemplate) {
                                                                    axios.post(props.serverUrl + "/saveOrder", {
                                                                        ...selectedOrder,
                                                                        carrier_contact_primary_phone: carrierContactPhoneItems[carrierContactPhoneItems.findIndex(item => item.selected)].type
                                                                    }).then(async (res) => {
                                                                        if (res.data.result === "OK") {
                                                                            await setSelectedOrder({
                                                                                ...res.data.order,
                                                                            });
                                                                            await props.setSelectedOrder({
                                                                                ...res.data.order,
                                                                                component_id: props.componentId,
                                                                            });
                                                                        } else {

                                                                        }
                                                                    }).catch((e) => {
                                                                        console.log("error saving order", e);
                                                                    }).finally(() => {
                                                                        setShowCarrierContactPhones(false);
                                                                        setIsSavingOrder(false);
                                                                    });
                                                                }
                                                                refCarrierContactPhone.current.inputElement.focus();
                                                            } else {
                                                                // validateCarrierAddressForSaving({ keyCode: 9 });
                                                            }
                                                            break;

                                                        default:
                                                            break;
                                                    }
                                                }
                                            }}
                                            onInput={(e) => {
                                            }}
                                            onChange={(e) => {
                                            }}
                                            value={
                                                (selectedOrder?.carrier_contact_primary_phone || '') === 'work'
                                                    ? (selectedCarrierContact?.phone_work || '')
                                                    : (selectedOrder?.carrier_contact_primary_phone || '') === 'fax'
                                                        ? (selectedCarrierContact?.phone_work_fax || '')
                                                        : (selectedOrder?.carrier_contact_primary_phone || '') === 'mobile'
                                                            ? (selectedCarrierContact?.phone_mobile || '')
                                                            : (selectedOrder?.carrier_contact_primary_phone || '') === 'direct'
                                                                ? (selectedCarrierContact?.phone_direct || '')
                                                                : (selectedOrder?.carrier_contact_primary_phone || '') === 'other'
                                                                    ? (selectedCarrierContact?.phone_other || '')
                                                                    : ''
                                            }
                                        />

                                        {
                                            (selectedCarrierContact?.id || 0) > 0 &&
                                            <div
                                                className={classnames({
                                                    'selected-carrier-contact-primary-phone': true,
                                                    'pushed': (carrierContactPhoneItems.length > 1)
                                                })}>
                                                {selectedOrder?.carrier_contact_primary_phone || ''}
                                            </div>
                                        }

                                        {
                                            ((selectedOrder?.is_cancelled || 0) === 0 && carrierContactPhoneItems.length > 1) &&
                                            <FontAwesomeIcon className="dropdown-button" icon={faCaretDown}
                                                onClick={async () => {
                                                    if (showCarrierContactPhones) {
                                                        setShowCarrierContactPhones(false);
                                                    } else {
                                                        if (carrierContactPhoneItems.length > 1) {
                                                            await setCarrierContactPhoneItems(carrierContactPhoneItems.map((item, index) => {
                                                                item.selected = (selectedOrder?.carrier_contact_primary_phone || '') === '' ? index === 0 : selectedOrder.carrier_contact_primary_phone === item.type;
                                                                return item;
                                                            }))

                                                            window.setTimeout(async () => {
                                                                await setShowCarrierContactPhones(true);

                                                                refCarrierContactPhonePopupItems.current.map((r, i) => {
                                                                    if (r && r.classList.contains('selected')) {
                                                                        r.scrollIntoView({
                                                                            behavior: 'auto',
                                                                            block: 'center',
                                                                            inline: 'nearest'
                                                                        })
                                                                    }
                                                                    return true;
                                                                });
                                                            }, 0)
                                                        }
                                                    }

                                                    refCarrierContactPhone.current.inputElement.focus();
                                                }} />
                                        }
                                    </div>
                                    {
                                        carrierContactPhonesTransition((style, item) => item && (
                                            <animated.div
                                                className="mochi-contextual-container"
                                                id="mochi-contextual-container-contact-phone"
                                                style={{
                                                    ...style,
                                                    left: '0',
                                                    display: 'block'
                                                }}
                                                ref={refCarrierContactPhoneDropDown}
                                            >
                                                <div className="mochi-contextual-popup vertical below right"
                                                    style={{ height: 150 }}>
                                                    <div className="mochi-contextual-popup-content">
                                                        <div className="mochi-contextual-popup-wrapper">
                                                            {
                                                                carrierContactPhoneItems.map((item, index) => {
                                                                    const mochiItemClasses = classnames({
                                                                        'mochi-item': true,
                                                                        'selected': item.selected
                                                                    });

                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            className={mochiItemClasses}
                                                                            id={item.id}
                                                                            onClick={async () => {
                                                                                await setSelectedOrder({
                                                                                    ...selectedOrder,
                                                                                    carrier_contact_primary_phone: item.type
                                                                                });

                                                                                if (!isCreatingTemplate && !isEditingTemplate) {
                                                                                    axios.post(props.serverUrl + "/saveOrder", {
                                                                                        ...selectedOrder,
                                                                                        carrier_contact_primary_phone: item.type
                                                                                    }).then(async (res) => {
                                                                                        if (res.data.result === "OK") {
                                                                                            await setSelectedOrder({
                                                                                                ...res.data.order,
                                                                                            });
                                                                                            await props.setSelectedOrder({
                                                                                                ...res.data.order,
                                                                                                component_id: props.componentId,
                                                                                            });
                                                                                        } else {

                                                                                        }
                                                                                    }).catch((e) => {
                                                                                        console.log("error saving order", e);
                                                                                    }).finally(() => {
                                                                                        setShowCarrierContactPhones(false);
                                                                                        setIsSavingOrder(false);
                                                                                    });
                                                                                }
                                                                                refCarrierContactPhone.current.inputElement.focus();
                                                                            }}
                                                                            ref={ref => refCarrierContactPhonePopupItems.current.push(ref)}
                                                                        >
                                                                            {
                                                                                item.type === 'work' ? `Phone Work `
                                                                                    : item.type === 'fax' ? `Phone Work Fax `
                                                                                        : item.type === 'mobile' ? `Phone Mobile `
                                                                                            : item.type === 'direct' ? `Phone Direct `
                                                                                                : item.type === 'other' ? `Phone Other ` : ''
                                                                            }

                                                                            (<b>
                                                                                {
                                                                                    item.type === 'work' ? item.phone
                                                                                        : item.type === 'fax' ? item.phone
                                                                                            : item.type === 'mobile' ? item.phone
                                                                                                : item.type === 'direct' ? item.phone
                                                                                                    : item.type === 'other' ? item.phone : ''
                                                                                }
                                                                            </b>)

                                                                            {
                                                                                item.selected &&
                                                                                <FontAwesomeIcon
                                                                                    className="dropdown-selected"
                                                                                    icon={faCaretRight} />
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </animated.div>
                                        ))
                                    }
                                </div>

                                <div className="input-box-container input-phone-ext">
                                    <input
                                        tabIndex={59 + props.tabTimes}
                                        type="text"
                                        placeholder="Ext"
                                        readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                        onChange={(e) => {
                                            if ((selectedOrder?.carrier_contact_primary_phone || '') === 'work') {
                                                setSelectedCarrierContact(prev => {
                                                    return {
                                                        ...prev,
                                                        phone_ext: e.target.value
                                                    }
                                                })
                                            }
                                        }}
                                        value={
                                            (selectedOrder?.carrier_contact_primary_phone || '') === 'work'
                                                ? selectedCarrierContact?.phone_ext
                                                : ''
                                        }
                                    />
                                </div>

                                <div className="select-box-container" style={{ flexGrow: 1 }}>
                                    <div className="select-box-wrapper">
                                        <input
                                            type="text"
                                            tabIndex={60 + props.tabTimes}
                                            placeholder="Equipment"
                                            ref={refEquipment}
                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                            onKeyDown={(e) => {
                                                if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                    let key = e.keyCode || e.which;

                                                    switch (key) {
                                                        case 37:
                                                        case 38: // arrow left | arrow up
                                                            e.preventDefault();
                                                            if (equipmentItems.length > 0) {
                                                                let selectedIndex = equipmentItems.findIndex(
                                                                    (item) => item.selected
                                                                );

                                                                if (selectedIndex === -1) {
                                                                    setEquipmentItems(
                                                                        equipmentItems.map((item, index) => {
                                                                            item.selected = index === 0;
                                                                            return item;
                                                                        })
                                                                    );
                                                                } else {
                                                                    setEquipmentItems(
                                                                        equipmentItems.map((item, index) => {
                                                                            if (selectedIndex === 0) {
                                                                                item.selected =
                                                                                    index === equipmentItems.length - 1;
                                                                            } else {
                                                                                item.selected =
                                                                                    index === selectedIndex - 1;
                                                                            }
                                                                            return item;
                                                                        })
                                                                    );
                                                                }

                                                                refEquipmentPopupItems.current.map((r, i) => {
                                                                    if (r && r.classList.contains("selected")) {
                                                                        r.scrollIntoView({
                                                                            behavior: "auto",
                                                                            block: "center",
                                                                            inline: "nearest",
                                                                        });
                                                                    }
                                                                    return true;
                                                                });
                                                            } else {
                                                                axios
                                                                    .post(props.serverUrl + "/getEquipments")
                                                                    .then((res) => {
                                                                        if (res.data.result === "OK") {
                                                                            setEquipmentItems(res.data.equipments.map((item, index) => {
                                                                                item.selected = (selectedOrder?.equipment?.id || 0) === 0
                                                                                    ? index === 0
                                                                                    : item.id === selectedOrder.equipment.id;
                                                                                return item;
                                                                            })
                                                                            );

                                                                            refEquipmentPopupItems.current.map(
                                                                                (r, i) => {
                                                                                    if (r && r.classList.contains("selected")) {
                                                                                        r.scrollIntoView({
                                                                                            behavior: "auto",
                                                                                            block: "center",
                                                                                            inline: "nearest",
                                                                                        });
                                                                                    }
                                                                                    return true;
                                                                                }
                                                                            );
                                                                        }
                                                                    })
                                                                    .catch(async (e) => {
                                                                        console.log("error getting equipments", e);
                                                                    });
                                                            }
                                                            break;

                                                        case 39:
                                                        case 40: // arrow right | arrow down
                                                            e.preventDefault();
                                                            if (equipmentItems.length > 0) {
                                                                let selectedIndex = equipmentItems.findIndex(
                                                                    (item) => item.selected
                                                                );

                                                                if (selectedIndex === -1) {
                                                                    setEquipmentItems(equipmentItems.map((item, index) => {
                                                                        item.selected = index === 0;
                                                                        return item;
                                                                    })
                                                                    );
                                                                } else {
                                                                    setEquipmentItems(
                                                                        equipmentItems.map((item, index) => {
                                                                            if (selectedIndex === equipmentItems.length - 1) {
                                                                                item.selected = index === 0;
                                                                            } else {
                                                                                item.selected = index === selectedIndex + 1;
                                                                            }
                                                                            return item;
                                                                        })
                                                                    );
                                                                }

                                                                refEquipmentPopupItems.current.map((r, i) => {
                                                                    if (r && r.classList.contains("selected")) {
                                                                        r.scrollIntoView({
                                                                            behavior: "auto",
                                                                            block: "center",
                                                                            inline: "nearest",
                                                                        });
                                                                    }
                                                                    return true;
                                                                });
                                                            } else {
                                                                axios
                                                                    .post(props.serverUrl + "/getEquipments")
                                                                    .then((res) => {
                                                                        if (res.data.result === "OK") {
                                                                            setEquipmentItems(
                                                                                res.data.equipments.map((item, index) => {
                                                                                    item.selected = (selectedOrder?.equipment?.id || 0) === 0
                                                                                        ? index === 0
                                                                                        : item.id === selectedOrder.equipment.id;
                                                                                    return item;
                                                                                })
                                                                            );

                                                                            refEquipmentPopupItems.current.map(
                                                                                (r, i) => {
                                                                                    if (r && r.classList.contains("selected")) {
                                                                                        r.scrollIntoView({
                                                                                            behavior: "auto",
                                                                                            block: "center",
                                                                                            inline: "nearest",
                                                                                        });
                                                                                    }
                                                                                    return true;
                                                                                }
                                                                            );
                                                                        }
                                                                    })
                                                                    .catch((e) => {
                                                                        console.log("error getting equipments", e);
                                                                    });
                                                            }
                                                            break;

                                                        case 27: // escape
                                                            setEquipmentItems([]);
                                                            break;

                                                        case 13: // enter
                                                            if (equipmentItems.length > 0 && equipmentItems.findIndex((item) => item.selected) > -1) {
                                                                new Promise((resolve, reject) => {
                                                                    setSelectedOrder((selectedOrder) => {
                                                                        return {
                                                                            ...selectedOrder,
                                                                            equipment: equipmentItems[equipmentItems.findIndex((item) => item.selected)],
                                                                            equipment_id: equipmentItems[equipmentItems.findIndex((item) => item.selected)].id,
                                                                        };
                                                                    });

                                                                    resolve("OK");
                                                                }).then((response) => {
                                                                    validateOrderForSaving({ keyCode: 9 });
                                                                    setEquipmentItems([]);
                                                                    refDriverName.current.focus();
                                                                }).catch((e) => {
                                                                });
                                                            }
                                                            break;

                                                        case 9: // tab
                                                            if (equipmentItems.length > 0 && equipmentItems.findIndex((item) => item.selected) > -1) {
                                                                e.preventDefault();

                                                                new Promise((resolve, reject) => {
                                                                    setSelectedOrder((selectedOrder) => {
                                                                        return {
                                                                            ...selectedOrder,
                                                                            equipment: equipmentItems[equipmentItems.findIndex((item) => item.selected)],
                                                                            equipment_id: equipmentItems[equipmentItems.findIndex((item) => item.selected)].id,
                                                                        };
                                                                    });

                                                                    resolve("OK");
                                                                }).then((response) => {
                                                                    validateOrderForSaving({ keyCode: 9 });
                                                                    setEquipmentItems([]);
                                                                    refDriverName.current.focus();
                                                                }).catch((e) => {
                                                                });
                                                            }
                                                            break;
                                                        default:
                                                            break;
                                                    }
                                                }
                                            }}
                                            onBlur={() => {
                                                if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                    if ((selectedOrder?.equipment?.id || 0) === 0) {
                                                        setSelectedOrder((selectedOrder) => {
                                                            return {
                                                                ...selectedOrder,
                                                                equipment_id: null,
                                                                equipment: {},
                                                            };
                                                        });
                                                    }
                                                }
                                            }}
                                            onInput={(e) => {
                                                let equipment = selectedOrder?.equipment || {};
                                                equipment.id = 0;
                                                equipment.name = e.target.value;
                                                setSelectedOrder((selectedOrder) => {
                                                    return {
                                                        ...selectedOrder,
                                                        equipment: equipment,
                                                        equipment_id: equipment.id,
                                                    };
                                                });

                                                if (e.target.value.trim() === "") {
                                                    setEquipmentItems([]);
                                                } else {
                                                    axios.post(props.serverUrl + "/getEquipments", { name: e.target.value.trim() }).then((res) => {
                                                        if (res.data.result === "OK") {
                                                            setEquipmentItems(
                                                                res.data.equipments.map((item, index) => {
                                                                    item.selected = (selectedOrder?.equipment?.id || 0) === 0
                                                                        ? index === 0
                                                                        : item.id === selectedOrder.equipment.id;
                                                                    return item;
                                                                })
                                                            );
                                                        }
                                                    }).catch((e) => {
                                                        console.log("error getting equipments", e);
                                                    });
                                                }
                                            }}
                                            onChange={(e) => {
                                                let equipment = selectedOrder?.equipment || {};
                                                equipment.id = 0;
                                                equipment.name = e.target.value;
                                                setSelectedOrder((selectedOrder) => {
                                                    return {
                                                        ...selectedOrder,
                                                        equipment: equipment,
                                                        equipment_id: equipment.id,
                                                    };
                                                });
                                            }}
                                            value={selectedOrder?.equipment?.name || ""}
                                        />
                                        {
                                            (selectedOrder?.is_cancelled || 0) === 0 &&
                                            <FontAwesomeIcon
                                                className="dropdown-button"
                                                icon={faCaretDown}
                                                onClick={() => {
                                                    if (equipmentItems.length > 0) {
                                                        setEquipmentItems([]);
                                                    } else {
                                                        if ((selectedOrder?.equipment?.id || 0) === 0 && (selectedOrder?.equipment?.name || "") !== "") {
                                                            axios.post(props.serverUrl + "/getEquipments", {
                                                                name: selectedOrder?.equipment.name,
                                                            }).then((res) => {
                                                                if (res.data.result === "OK") {
                                                                    setEquipmentItems(
                                                                        res.data.equipments.map((item, index) => {
                                                                            item.selected = (selectedOrder?.equipment?.id || 0) === 0
                                                                                ? index === 0
                                                                                : item.id === selectedOrder.equipment.id;
                                                                            return item;
                                                                        })
                                                                    );

                                                                    refEquipmentPopupItems.current.map((r, i) => {
                                                                        if (r && r.classList.contains("selected")) {
                                                                            r.scrollIntoView({
                                                                                behavior: "auto",
                                                                                block: "center",
                                                                                inline: "nearest",
                                                                            });
                                                                        }
                                                                        return true;
                                                                    });
                                                                }
                                                            }).catch((e) => {
                                                                console.log("error getting equipments", e);
                                                            });
                                                        } else {
                                                            axios.post(props.serverUrl + "/getEquipments").then((res) => {
                                                                if (res.data.result === "OK") {
                                                                    setEquipmentItems(
                                                                        res.data.equipments.map((item, index) => {
                                                                            item.selected = (selectedOrder?.equipment?.id || 0) === 0
                                                                                ? index === 0
                                                                                : item.id === selectedOrder.equipment.id;
                                                                            return item;
                                                                        })
                                                                    );

                                                                    refEquipmentPopupItems.current.map((r, i) => {
                                                                        if (r && r.classList.contains("selected")) {
                                                                            r.scrollIntoView({
                                                                                behavior: "auto",
                                                                                block: "center",
                                                                                inline: "nearest",
                                                                            });
                                                                        }
                                                                        return true;
                                                                    });
                                                                }
                                                            }).catch(async (e) => {
                                                                console.log("error getting equipments", e);
                                                            });
                                                        }
                                                    }

                                                    refEquipment.current.focus();
                                                }}
                                            />
                                        }
                                    </div>
                                    {equipmentTransition(
                                        (style, item) =>
                                            item && (
                                                <animated.div
                                                    className="mochi-contextual-container"
                                                    id="mochi-contextual-container-equipment"
                                                    style={{
                                                        ...style,
                                                        left: "-50%",
                                                        display: "block",
                                                    }}
                                                    ref={refEquipmentDropDown}
                                                >
                                                    <div className="mochi-contextual-popup vertical below"
                                                        style={{ height: 150 }}>
                                                        <div className="mochi-contextual-popup-content">
                                                            <div className="mochi-contextual-popup-wrapper">
                                                                {equipmentItems.map((item, index) => {
                                                                    const mochiItemClasses = classnames({
                                                                        "mochi-item": true,
                                                                        selected: item.selected,
                                                                    });

                                                                    const searchValue = (selectedOrder?.equipment?.id || 0) === 0 && (selectedOrder?.equipment?.name || "") !== ""
                                                                        ? selectedOrder?.equipment?.name
                                                                        : undefined;

                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            className={mochiItemClasses}
                                                                            id={item.id}
                                                                            onClick={() => {
                                                                                new Promise((resolve, reject) => {
                                                                                    setSelectedOrder((selectedOrder) => {
                                                                                        return {
                                                                                            ...selectedOrder,
                                                                                            equipment: item,
                                                                                            equipment_id: item.id,
                                                                                        };
                                                                                    });

                                                                                    resolve("OK");
                                                                                }).then((response) => {
                                                                                    validateOrderForSaving({ keyCode: 9 });
                                                                                    setEquipmentItems([]);
                                                                                    refDriverName.current.focus();
                                                                                }).catch((e) => {
                                                                                });
                                                                            }}
                                                                            ref={(ref) =>
                                                                                refEquipmentPopupItems.current.push(ref)
                                                                            }
                                                                        >
                                                                            {searchValue === undefined
                                                                                ? item.name
                                                                                : (
                                                                                    <Highlighter
                                                                                        highlightClassName="mochi-item-highlight-text"
                                                                                        searchWords={[searchValue]}
                                                                                        autoEscape={true}
                                                                                        textToHighlight={item.name}
                                                                                    />
                                                                                )}
                                                                            {item.selected && (
                                                                                <FontAwesomeIcon
                                                                                    className="dropdown-selected"
                                                                                    icon={faCaretRight}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </animated.div>
                                            )
                                    )}
                                </div>
                            </div>

                            <div className="form-row" style={{ gap: 2 }}>
                                <div className="select-box-container" style={{ width: "9rem" }}>
                                    <div className="select-box-wrapper">
                                        <input
                                            type="text"
                                            tabIndex={61 + props.tabTimes}
                                            placeholder="Driver Name"
                                            ref={refDriverName}
                                            readOnly={
                                                ((selectedOrder?.is_cancelled || 0) === 1) ||
                                                !((selectedOrder?.carrier_owner_type || '') === 'carrier' || (selectedOrder?.carrier_owner_type || '') === 'agent')
                                            }
                                            onKeyDown={async (e) => {
                                                if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                    let key = e.keyCode || e.which;

                                                    if ((selectedOrder?.carrier_owner_type || '') === 'carrier' || (selectedOrder?.carrier_owner_type || '') === 'agent') {
                                                        switch (key) {
                                                            case 37:
                                                            case 38: // arrow left | arrow up
                                                                e.preventDefault();
                                                                if (driverItems.length > 0) {
                                                                    let selectedIndex = driverItems.findIndex(
                                                                        (item) => item.selected
                                                                    );

                                                                    if (selectedIndex === -1) {
                                                                        await setDriverItems(
                                                                            driverItems.map((item, index) => {
                                                                                item.selected = index === 0;
                                                                                return item;
                                                                            })
                                                                        );
                                                                    } else {
                                                                        await setDriverItems(
                                                                            driverItems.map((item, index) => {
                                                                                if (selectedIndex === 0) {
                                                                                    item.selected = index === driverItems.length - 1;
                                                                                } else {
                                                                                    item.selected = index === selectedIndex - 1;
                                                                                }
                                                                                return item;
                                                                            })
                                                                        );
                                                                    }

                                                                    refDriverPopupItems.current.map((r, i) => {
                                                                        if (r && r.classList.contains("selected")) {
                                                                            r.scrollIntoView({
                                                                                behavior: "auto",
                                                                                block: "center",
                                                                                inline: "nearest",
                                                                            });
                                                                        }
                                                                        return true;
                                                                    });
                                                                } else {
                                                                    if ((selectedCarrier?.id || 0) > 0) {
                                                                        axios.post(props.serverUrl + "/getDriversByCarrierId",
                                                                            {
                                                                                carrier_id: selectedCarrier.id,
                                                                                agent_id: selectedCarrier.id,
                                                                                owner_type: selectedOrder?.carrier_owner_type,
                                                                                name: selectedCarrierDriver?.name
                                                                            }
                                                                        ).then(async (res) => {
                                                                            if (res.data.result === "OK") {
                                                                                if (res.data.count > 0) {
                                                                                    await setDriverItems([
                                                                                        ...[{
                                                                                            name: "Clear",
                                                                                            id: null,
                                                                                        }],
                                                                                        ...res.data.drivers.map((item, index) => {
                                                                                            item.selected = (selectedCarrierDriver?.id || 0) === 0
                                                                                                ? index === 0
                                                                                                : item.id === selectedCarrierDriver.id;
                                                                                            return item;
                                                                                        }),
                                                                                    ]);
                                                                                } else {
                                                                                    await setDriverItems([
                                                                                        ...[{
                                                                                            name: "Clear",
                                                                                            id: null,
                                                                                        }],
                                                                                    ]);
                                                                                }

                                                                                refDriverPopupItems.current.map(
                                                                                    (r, i) => {
                                                                                        if (r && r.classList.contains("selected")) {
                                                                                            r.scrollIntoView({
                                                                                                behavior: "auto",
                                                                                                block: "center",
                                                                                                inline: "nearest",
                                                                                            });
                                                                                        }
                                                                                        return true;
                                                                                    }
                                                                                );
                                                                            }
                                                                        })
                                                                            .catch(async (e) => {
                                                                                console.log("error getting carrier drivers", e);
                                                                            });
                                                                    }
                                                                }
                                                                break;

                                                            case 39:
                                                            case 40: // arrow right | arrow down
                                                                e.preventDefault();
                                                                if (driverItems.length > 0) {
                                                                    let selectedIndex = driverItems.findIndex(
                                                                        (item) => item.selected
                                                                    );

                                                                    if (selectedIndex === -1) {
                                                                        await setDriverItems(
                                                                            driverItems.map((item, index) => {
                                                                                item.selected = index === 0;
                                                                                return item;
                                                                            })
                                                                        );
                                                                    } else {
                                                                        await setDriverItems(
                                                                            driverItems.map((item, index) => {
                                                                                if (selectedIndex === driverItems.length - 1) {
                                                                                    item.selected = index === 0;
                                                                                } else {
                                                                                    item.selected = index === selectedIndex + 1;
                                                                                }
                                                                                return item;
                                                                            })
                                                                        );
                                                                    }

                                                                    refDriverPopupItems.current.map((r, i) => {
                                                                        if (r && r.classList.contains("selected")) {
                                                                            r.scrollIntoView({
                                                                                behavior: "auto",
                                                                                block: "center",
                                                                                inline: "nearest",
                                                                            });
                                                                        }
                                                                        return true;
                                                                    });
                                                                } else {
                                                                    if ((selectedCarrier?.id || 0) > 0) {
                                                                        axios.post(props.serverUrl + "/getDriversByCarrierId",
                                                                            {
                                                                                carrier_id: selectedCarrier.id,
                                                                                agent_id: selectedCarrier.id,
                                                                                owner_type: selectedOrder?.carrier_owner_type,
                                                                                name: selectedCarrierDriver?.name
                                                                            }
                                                                        ).then(async (res) => {
                                                                            if (res.data.result === "OK") {
                                                                                if (res.data.count > 0) {
                                                                                    await setDriverItems([
                                                                                        ...[{
                                                                                            name: "Clear",
                                                                                            id: null,
                                                                                        }],
                                                                                        ...res.data.drivers.map(
                                                                                            (item, index) => {
                                                                                                item.selected = (selectedCarrierDriver?.id || 0) === 0
                                                                                                    ? index === 0
                                                                                                    : item.id === selectedCarrierDriver.id;
                                                                                                return item;
                                                                                            }
                                                                                        ),
                                                                                    ]);
                                                                                } else {
                                                                                    await setDriverItems([
                                                                                        ...[{
                                                                                            name: "Clear",
                                                                                            id: null,
                                                                                        }],
                                                                                    ]);
                                                                                }

                                                                                refDriverPopupItems.current.map(
                                                                                    (r, i) => {
                                                                                        if (r && r.classList.contains("selected")) {
                                                                                            r.scrollIntoView({
                                                                                                behavior: "auto",
                                                                                                block: "center",
                                                                                                inline: "nearest",
                                                                                            });
                                                                                        }
                                                                                        return true;
                                                                                    }
                                                                                );
                                                                            }
                                                                        }).catch(async (e) => {
                                                                            console.log("error getting carrier drivers", e);
                                                                        });
                                                                    }
                                                                }
                                                                break;

                                                            case 27: // escape
                                                                setDriverItems([]);
                                                                break;

                                                            case 13: // enter
                                                                if (driverItems.length > 0 && driverItems.findIndex((item) => item.selected) > -1) {
                                                                    if (driverItems[driverItems.findIndex((item) => item.selected)].id === null) {
                                                                        await setSelectedCarrierDriver({ name: "" });

                                                                        if (!isCreatingTemplate && !isEditingTemplate) {
                                                                            axios.post(props.serverUrl + "/saveOrder", {
                                                                                ...selectedOrder,
                                                                                carrier_driver_id: null,
                                                                            }).then(async (res) => {
                                                                                if (res.data.result === "OK") {
                                                                                    await setSelectedOrder({
                                                                                        ...res.data.order,
                                                                                    });
                                                                                    await props.setSelectedOrder({
                                                                                        ...res.data.order,
                                                                                        component_id: props.componentId,
                                                                                    });
                                                                                } else {

                                                                                }

                                                                                setIsSavingOrder(false);
                                                                            }).catch((e) => {
                                                                                console.log("error saving order", e);
                                                                                setIsSavingOrder(false);
                                                                            });
                                                                        }

                                                                        refDriverPhone.current.inputElement.focus();
                                                                    } else {
                                                                        await setSelectedCarrierDriver(driverItems[driverItems.findIndex((item) => item.selected)]);
                                                                        await setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            carrier_driver_id: driverItems[driverItems.findIndex((item) => item.selected)].id,
                                                                            equipment: driverItems[driverItems.findIndex((item) => item.selected)]?.tractor?.type,
                                                                            equipment_id: driverItems[driverItems.findIndex((item) => item.selected)]?.tractor?.type_id
                                                                        })

                                                                        if (!isCreatingTemplate && !isEditingTemplate) {
                                                                            axios.post(props.serverUrl + "/saveOrder", {
                                                                                ...selectedOrder,
                                                                                carrier_driver_id: driverItems[driverItems.findIndex((item) => item.selected)].id,
                                                                                equipment: driverItems[driverItems.findIndex((item) => item.selected)]?.tractor?.type,
                                                                                equipment_id: driverItems[driverItems.findIndex((item) => item.selected)]?.tractor?.type_id
                                                                            }).then(async (res) => {
                                                                                if (res.data.result === "OK") {
                                                                                    await props.setSelectedOrder({
                                                                                        ...res.data.order,
                                                                                        component_id: props.componentId,
                                                                                    });
                                                                                } else {

                                                                                }

                                                                                setIsSavingOrder(false);
                                                                            }).catch((e) => {
                                                                                console.log("error saving order", e);
                                                                                setIsSavingOrder(false);
                                                                            });
                                                                        }

                                                                        refDriverPhone.current.inputElement.focus();
                                                                    }

                                                                    setDriverItems([]);
                                                                }
                                                                break;

                                                            case 9: // tab
                                                                if (driverItems.length > 0) {
                                                                    e.preventDefault();
                                                                    if (driverItems[driverItems.findIndex((item) => item.selected)].id === null) {
                                                                        await setSelectedCarrierDriver({ name: "" });

                                                                        if (!isCreatingTemplate && !isEditingTemplate) {
                                                                            axios.post(props.serverUrl + "/saveOrder", {
                                                                                ...selectedOrder,
                                                                                carrier_driver_id: null,
                                                                            }).then(async (res) => {
                                                                                if (res.data.result === "OK") {
                                                                                    await setSelectedOrder({
                                                                                        ...res.data.order,
                                                                                    });

                                                                                    await props.setSelectedOrder({
                                                                                        ...res.data.order,
                                                                                        component_id: props.componentId,
                                                                                    });
                                                                                } else {

                                                                                }

                                                                                setIsSavingOrder(false);
                                                                            }).catch((e) => {
                                                                                console.log("error saving order", e);
                                                                                setIsSavingOrder(false);
                                                                            });
                                                                        }

                                                                        refDriverPhone.current.inputElement.focus();
                                                                    } else {
                                                                        await setSelectedCarrierDriver(driverItems[driverItems.findIndex((item) => item.selected)]);

                                                                        await setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            carrier_driver_id: driverItems[driverItems.findIndex((item) => item.selected)].id,
                                                                            equipment: driverItems[driverItems.findIndex((item) => item.selected)]?.tractor?.type,
                                                                            equipment_id: driverItems[driverItems.findIndex((item) => item.selected)]?.tractor?.type_id
                                                                        })

                                                                        if (!isCreatingTemplate && !isEditingTemplate) {
                                                                            axios.post(props.serverUrl + "/saveOrder", {
                                                                                ...selectedOrder,
                                                                                carrier_driver_id: driverItems[driverItems.findIndex((item) => item.selected)].id,
                                                                                equipment: driverItems[driverItems.findIndex((item) => item.selected)]?.tractor?.type,
                                                                                equipment_id: driverItems[driverItems.findIndex((item) => item.selected)]?.tractor?.type_id
                                                                            }).then(async (res) => {
                                                                                if (res.data.result === "OK") {
                                                                                    await props.setSelectedOrder({
                                                                                        ...res.data.order,
                                                                                        component_id: props.componentId,
                                                                                    });
                                                                                } else {

                                                                                }

                                                                                setIsSavingOrder(false);
                                                                            }).catch((e) => {
                                                                                console.log("error saving order", e);
                                                                                setIsSavingOrder(false);
                                                                            });
                                                                        }

                                                                        refDriverPhone.current.inputElement.focus();
                                                                    }

                                                                    setDriverItems([]);
                                                                }
                                                                break;

                                                            default:
                                                                break;
                                                        }
                                                        ;
                                                    }
                                                }
                                            }}
                                            onBlur={(e) => {
                                                if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                    if ((selectedCarrierDriver?.id || 0) > 0) {
                                                        if (e.target.value.trim() === "") {
                                                            setSelectedCarrierDriver({ name: "" });
                                                            setSelectedOrder(prev => {
                                                                return {
                                                                    ...prev,
                                                                    carrier_driver_id: null
                                                                }
                                                            })
                                                        }
                                                    }
                                                }
                                            }}
                                            onInput={async (e) => {
                                                setSelectedCarrierDriver(prev => {
                                                    return {
                                                        ...prev,
                                                        name: e.target.value
                                                    }
                                                });

                                                if ((selectedCarrier?.id || '') > 0) {
                                                    axios.post(props.serverUrl + "/getDriversByCarrierId",
                                                        {
                                                            carrier_id: selectedCarrier.id,
                                                            agent_id: selectedCarrier.id,
                                                            owner_type: selectedOrder?.carrier_owner_type,
                                                            name: selectedCarrierDriver?.name
                                                        }
                                                    ).then(async (res) => {
                                                        if (res.data.result === "OK") {
                                                            if (res.data.count > 0) {
                                                                await setDriverItems([
                                                                    ...[{
                                                                        name: "Clear",
                                                                        id: null,
                                                                    }],
                                                                    ...res.data.drivers.map(
                                                                        (item, index) => {
                                                                            item.selected = (selectedCarrierDriver?.id || 0) === 0
                                                                                ? index === 0
                                                                                : item.id === selectedCarrierDriver.id;
                                                                            return item;
                                                                        }
                                                                    ),
                                                                ]);
                                                            } else {
                                                                await setDriverItems([
                                                                    ...[{
                                                                        name: "Clear",
                                                                        id: null,
                                                                    }],
                                                                ]);
                                                            }

                                                            refDriverPopupItems.current.map(
                                                                (r, i) => {
                                                                    if (r && r.classList.contains("selected")) {
                                                                        r.scrollIntoView({
                                                                            behavior: "auto",
                                                                            block: "center",
                                                                            inline: "nearest",
                                                                        });
                                                                    }
                                                                    return true;
                                                                }
                                                            );
                                                        }
                                                    }).catch(async (e) => {
                                                        console.log("error getting carrier drivers", e);
                                                    });
                                                }
                                            }}
                                            onChange={async (e) => {
                                                setSelectedCarrierDriver(prev => {
                                                    return {
                                                        ...prev,
                                                        name: e.target.value
                                                    }
                                                });
                                            }}
                                            value={selectedCarrierDriver?.name || ""}
                                        />
                                        {
                                            ((selectedOrder?.is_cancelled || 0) === 0 &&
                                                ((selectedOrder?.carrier_owner_type || '') === 'carrier' || (selectedOrder?.carrier_owner_type || '') === 'agent') &&
                                                ((selectedCarrier?.drivers || []).length > 0)) && (
                                                <FontAwesomeIcon
                                                    className="dropdown-button"
                                                    icon={faCaretDown}
                                                    onClick={() => {
                                                        if (driverItems.length > 0) {
                                                            setDriverItems([]);
                                                        } else {
                                                            window.setTimeout(async () => {
                                                                if ((selectedCarrier?.id || 0) > 0) {
                                                                    axios.post(props.serverUrl + "/getDriversByCarrierId",
                                                                        {
                                                                            carrier_id: selectedCarrier.id,
                                                                            agent_id: selectedCarrier.id,
                                                                            owner_type: selectedOrder?.carrier_owner_type,
                                                                            name: selectedCarrierDriver?.name
                                                                        }
                                                                    ).then(async (res) => {
                                                                        if (res.data.result === "OK") {
                                                                            if (res.data.count > 0) {
                                                                                await setDriverItems([
                                                                                    ...[{
                                                                                        name: "Clear",
                                                                                        id: null,
                                                                                    }],
                                                                                    ...res.data.drivers.map((item, index) => {
                                                                                        item.selected = (selectedCarrierDriver?.id || 0) === 0
                                                                                            ? index === 0
                                                                                            : item.id === selectedCarrierDriver.id;
                                                                                        return item;
                                                                                    }),
                                                                                ]);
                                                                            } else {
                                                                                await setDriverItems([
                                                                                    ...[{
                                                                                        name: "Clear",
                                                                                        id: null,
                                                                                    }],
                                                                                ]);
                                                                            }

                                                                            refDriverPopupItems.current.map(
                                                                                (r, i) => {
                                                                                    if (r && r.classList.contains("selected")) {
                                                                                        r.scrollIntoView({
                                                                                            behavior: "auto",
                                                                                            block: "center",
                                                                                            inline: "nearest",
                                                                                        });
                                                                                    }
                                                                                    return true;
                                                                                }
                                                                            );
                                                                        }
                                                                    }).catch(async (e) => {
                                                                        console.log("error getting carrier drivers", e);
                                                                    });
                                                                }

                                                                refDriverName.current.focus();
                                                            }, 0);
                                                        }
                                                    }}
                                                />
                                            )}
                                    </div>

                                    {driverTransition(
                                        (style, item) =>
                                            item && (
                                                <animated.div
                                                    className="mochi-contextual-container"
                                                    id="mochi-contextual-container-driver-name"
                                                    style={{
                                                        ...style,
                                                        left: "-50%",
                                                        display: "block",
                                                    }}
                                                    ref={refDriverDropDown}
                                                >
                                                    <div className="mochi-contextual-popup vertical below"
                                                        style={{ height: 150 }}>
                                                        <div className="mochi-contextual-popup-content">
                                                            <div className="mochi-contextual-popup-wrapper">
                                                                {driverItems.map((item, index) => {
                                                                    const mochiItemClasses = classnames({
                                                                        "mochi-item": true,
                                                                        selected: item.selected,
                                                                    });

                                                                    // const searchValue = (selectedCarrierDriver?.name || '').trim();

                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            className={mochiItemClasses}
                                                                            id={item.id}
                                                                            onClick={() => {
                                                                                if (item.id === null) {
                                                                                    setSelectedCarrierDriver({
                                                                                        name: "",
                                                                                    });

                                                                                    if (!isCreatingTemplate && !isEditingTemplate) {
                                                                                        axios.post(props.serverUrl + "/saveOrder",
                                                                                            {
                                                                                                ...selectedOrder,
                                                                                                carrier_driver_id: null,
                                                                                            }
                                                                                        ).then((res) => {
                                                                                            if (res.data.result === "OK") {
                                                                                                setSelectedOrder({
                                                                                                    ...res.data.order,
                                                                                                });
                                                                                                props.setSelectedOrder({
                                                                                                    ...res.data.order,
                                                                                                    component_id: props.componentId,
                                                                                                });
                                                                                            } else {

                                                                                            }

                                                                                            setIsSavingOrder(false);
                                                                                        }).catch((e) => {
                                                                                            console.log("error saving order", e);
                                                                                            setIsSavingOrder(false);
                                                                                        });
                                                                                    }

                                                                                    refDriverPhone.current.inputElement.focus();
                                                                                } else {
                                                                                    setSelectedCarrierDriver(item);

                                                                                    setSelectedOrder({
                                                                                        ...selectedOrder,
                                                                                        carrier_driver_id: item.id,
                                                                                        equipment: item?.tractor?.type,
                                                                                        equipment_id: item?.tractor?.type_id
                                                                                    })

                                                                                    if (!isCreatingTemplate && !isEditingTemplate) {
                                                                                        axios.post(props.serverUrl + "/saveOrder", {
                                                                                            ...selectedOrder,
                                                                                            carrier_driver_id: item.id,
                                                                                            equipment: item?.tractor?.type,
                                                                                            equipment_id: item?.tractor?.type_id
                                                                                        }).then(async (res) => {
                                                                                            if (res.data.result === "OK") {
                                                                                                await props.setSelectedOrder({
                                                                                                    ...res.data.order,
                                                                                                    component_id: props.componentId,
                                                                                                });
                                                                                            } else {

                                                                                            }

                                                                                            setIsSavingOrder(false);
                                                                                        }).catch((e) => {
                                                                                            console.log("error saving order", e);
                                                                                            setIsSavingOrder(false);
                                                                                        });
                                                                                    }

                                                                                    refDriverPhone.current.inputElement.focus();
                                                                                }

                                                                                setDriverItems([]);
                                                                            }}
                                                                            ref={(ref) => refDriverPopupItems.current.push(ref)}>
                                                                            {item.name}
                                                                            {item.selected && (
                                                                                <FontAwesomeIcon
                                                                                    className="dropdown-selected"
                                                                                    icon={faCaretRight}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </animated.div>
                                            )
                                    )}
                                </div>

                                <div className="input-box-container grow">
                                    <MaskedInput
                                        tabIndex={62 + props.tabTimes}
                                        ref={refDriverPhone}
                                        mask={[/[0-9]/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/,]}
                                        guide={true}
                                        type="text"
                                        placeholder="Driver Phone"
                                        // readOnly={
                                        //     (selectedOrder?.is_cancelled || 0) === 1 ||
                                        //     !((selectedOrder?.carrier_owner_type || '') === 'carrier' || (selectedOrder?.carrier_owner_type || '') === 'agent')
                                        // }
                                        readOnly={true}
                                        onKeyDown={(e) => {
                                            if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                // validateCarrierDriverForSaving(e)
                                            }
                                        }}
                                        onInput={(e) => {
                                            setSelectedCarrierDriver({
                                                ...selectedCarrierDriver,
                                                contact_phone: e.target.value,
                                            });
                                        }}
                                        onChange={(e) => {
                                            setSelectedCarrierDriver({
                                                ...selectedCarrierDriver,
                                                contact_phone: e.target.value,
                                            });
                                        }}
                                        value={selectedCarrierDriver?.contact_phone || ""}
                                    />
                                </div>

                                <div className="input-box-container" style={{ maxWidth: "5.8rem", minWidth: "5.8rem" }}>
                                    <input
                                        tabIndex={63 + props.tabTimes}
                                        type="text"
                                        placeholder="Unit Number"
                                        // readOnly={
                                        //     (selectedOrder?.is_cancelled || 0) === 1 ||
                                        //     !((selectedOrder?.carrier_owner_type || '') === 'carrier' || (selectedOrder?.carrier_owner_type || '') === 'agent')
                                        // }
                                        readOnly={true}
                                        onKeyDown={(e) => {
                                            if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                // validateCarrierDriverForSaving(e);
                                            }
                                        }}
                                        onInput={(e) => {
                                            setSelectedCarrierDriver({
                                                ...selectedCarrierDriver,
                                                truck: e.target.value,
                                            });
                                        }}
                                        onChange={(e) => {
                                            setSelectedCarrierDriver({
                                                ...selectedCarrierDriver,
                                                truck: e.target.value,
                                            });
                                        }}
                                        value={selectedCarrierDriver?.tractor?.number || ""}
                                    />
                                </div>

                                <div className="input-box-container" style={{ maxWidth: "5.8rem", minWidth: "5.8rem" }}>
                                    <input
                                        tabIndex={64 + props.tabTimes}
                                        type="text"
                                        placeholder="Trailer Number"
                                        // readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                        readOnly={true}
                                        onKeyDown={(e) => {
                                            if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                e.preventDefault();
                                                // validateCarrierInfoForSaving({ keyCode: 9 });

                                                if ((selectedOrder?.id || 0) === 0) {
                                                    // window.alert('You must create or load an order first!');
                                                    return;
                                                }

                                                if ((selectedOrder?.load_type_id || 0) === 0) {
                                                    // window.alert('You must select a load type first!');
                                                    return;
                                                }

                                                let panel = {
                                                    panelName: `${props.panelName}-rating`,
                                                    component: (
                                                        <RatingScreen
                                                            panelName={`${props.panelName}-rating`}
                                                            title="Rating Screen"
                                                            tabTimes={34000 + props.tabTimes}
                                                            componentId={moment().format("x")}
                                                            origin={props.origin}
                                                            selectedOrder={selectedOrder}
                                                            closingCallback={() => {
                                                                closePanel(`${props.panelName}-rating`, props.origin);
                                                                (props.isOnPanel ? refOrderNumber : props.refOrderNumber).current.focus({ preventScroll: true });
                                                            }}
                                                        />
                                                    ),
                                                };

                                                openPanel(panel, props.origin);
                                            }
                                        }}
                                        onInput={(e) => {
                                            setSelectedCarrierDriver({
                                                ...selectedCarrierDriver,
                                                trailer: e.target.value,
                                            });
                                        }}
                                        onChange={(e) => {
                                            setSelectedCarrierDriver({
                                                ...selectedCarrierDriver,
                                                trailer: e.target.value,
                                            });
                                        }}
                                        value={selectedCarrierDriver?.trailer?.number || ""}
                                    />
                                </div>
                            </div>

                            <div className="form-row" style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                flexGrow: 1,
                                alignItems: "flex-end",
                            }}>
                                <div className="mochi-button" style={{ fontSize: "1rem" }} onClick={() => {
                                    if ((selectedOrder?.id || 0) === 0) {
                                        window.alert("You must create or load an order first!");
                                        return;
                                    }

                                    let panel = {
                                        panelName: `${props.panelName}-rate-conf`,
                                        component: (
                                            <RateConf
                                                title="Rate Conf"
                                                tabTimes={41000 + props.tabTimes}
                                                panelName={`${props.panelName}-rate-conf`}
                                                origin={props.origin}
                                                componentId={moment().format("x")}
                                                selectedOrderId={selectedOrder?.id || 0}
                                                closingCallback={() => {
                                                    closePanel(`${props.panelName}-rate-conf`, props.origin);
                                                    (props.isOnPanel ? refOrderNumber : props.refOrderNumber).current.focus({ preventScroll: true });
                                                }}
                                            />
                                        ),
                                    };

                                    openPanel(panel, props.origin);
                                }}>
                                    <div className="mochi-button-decorator mochi-button-decorator-left"> (</div>
                                    <div className="mochi-button-base">Rate Confirmation</div>
                                    <div className="mochi-button-decorator mochi-button-decorator-right"> )</div>
                                </div>
                                <div className="mochi-button" style={{ fontSize: "1rem" }} onClick={() => {
                                    if ((selectedOrder?.id || 0) === 0) {
                                        window.alert("You must create or load an order first!");
                                        return;
                                    }

                                    if ((selectedOrder?.load_type_id || 0) === 0) {
                                        window.alert("You must select a load type first!");
                                        return;
                                    }

                                    let panel = {
                                        panelName: `${props.panelName}-rating`,
                                        component: (
                                            <RatingScreen
                                                panelName={`${props.panelName}-rating`}
                                                title="Rating Screen"
                                                tabTimes={34000 + props.tabTimes}
                                                componentId={moment().format("x")}
                                                origin={props.origin}
                                                selectedOrder={selectedOrder}
                                                closingCallback={() => {
                                                    closePanel(`${props.panelName}-rating`, props.origin);
                                                    (props.isOnPanel ? refOrderNumber : props.refOrderNumber).current.focus({ preventScroll: true });
                                                }}
                                            />
                                        ),
                                    };

                                    openPanel(panel, props.origin);
                                }}>
                                    <div className="mochi-button-decorator mochi-button-decorator-left">
                                        (
                                    </div>
                                    <div className="mochi-button-base">Adjust Rate</div>
                                    <div className="mochi-button-decorator mochi-button-decorator-right">
                                        )
                                    </div>
                                </div>
                                <div className="mochi-button" style={{
                                    fontSize: "1rem"
                                }} onClick={(e) => {
                                    if ((selectedOrder?.id || 0) === 0) {
                                        window.alert("You must create or load an order first!");
                                        return;
                                    }

                                    if ((selectedOrder?.carrier?.id || 0) === 0) {
                                        window.alert("You must select a carrier first!");
                                        return;
                                    }

                                    setShowingChangeCarrier(true);
                                }}>
                                    <div className="mochi-button-decorator mochi-button-decorator-left">(</div>
                                    <div className="mochi-button-base" style={{
                                        color: (selectedOrder?.is_cancelled || 0) === 0 ? 'black' : 'rgba(0,0,0,0.4)'
                                    }}>Change Carrier
                                    </div>
                                    <div className="mochi-button-decorator mochi-button-decorator-right">)</div>
                                </div>
                                <div className="mochi-button" style={{ fontSize: "1rem" }} onClick={() => {
                                    if ((selectedOrder?.order_number || 0) === 0) {
                                        window.alert("You must select or create an order first!");
                                        return;
                                    }

                                    setSelectedNoteForDriver({ id: 0 });
                                }}>
                                    <div className="mochi-button-decorator mochi-button-decorator-left">(</div>
                                    <div className="mochi-button-base" style={{
                                        color: (selectedOrder?.is_cancelled || 0) === 0 ? 'black' : 'rgba(0,0,0,0.4)'
                                    }}>Notes for Driver
                                    </div>
                                    <div className="mochi-button-decorator mochi-button-decorator-right">)</div>
                                </div>
                            </div>
                        </div>

                        <div className={`form-borderless-box${((selectedOrder?.division_id || 0) === 0 || (selectedOrder?.load_type_id || 0) === 0) ? ' form-disabled' : ''}`}
                            style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
                            <div
                                className="form-row"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginBottom: 10,
                                }}
                            >
                                <div className="input-toggle-container">
                                    <input
                                        type="checkbox"
                                        id="cbox-dispatch-hazmat-btn"

                                        onChange={async (e) => {
                                            await setSelectedOrder({
                                                ...selectedOrder,
                                                haz_mat: e.target.checked ? 1 : 0,
                                            });

                                            validateOrderForSaving({ keyCode: 9 });
                                        }}
                                        checked={(selectedOrder?.haz_mat || 0) === 1}
                                    />
                                    <label htmlFor="cbox-dispatch-hazmat-btn">
                                        <div className="label-text">HazMat</div>
                                        <div className="input-toggle-btn"></div>
                                    </label>
                                </div>
                                <div className="input-toggle-container">
                                    <input
                                        type="checkbox"
                                        id="cbox-dispatch-expedited-btn"
                                        onChange={async (e) => {
                                            await setSelectedOrder({
                                                ...selectedOrder,
                                                expedited: e.target.checked ? 1 : 0,
                                            });

                                            validateOrderForSaving({ keyCode: 9 });
                                        }}
                                        checked={(selectedOrder?.expedited || 0) === 1}
                                    />
                                    <label htmlFor="cbox-dispatch-expedited-btn">
                                        <div className="label-text">Expedited</div>
                                        <div className="input-toggle-btn"></div>
                                    </label>
                                </div>
                            </div>
                            <div className="form-row" style={{ flexGrow: 1, display: "flex" }}>
                                <div className={`form-bordered-box${((selectedOrder?.division_id || 0) === 0 || (selectedOrder?.load_type_id || 0) === 0) ? ' form-disabled' : ''}`}>
                                    <div className="form-header">
                                        <div className="top-border top-border-left"></div>
                                        <div className="form-title">Notes for Carrier on Rate Conf</div>
                                        <div className="top-border top-border-middle"></div>
                                        <div className="form-buttons">
                                            <div className="mochi-button" onClick={() => {
                                                if ((selectedOrder?.order_number || 0) === 0) {
                                                    window.alert("You must select or create an order first!");
                                                    return;
                                                }

                                                setSelectedNoteForCarrier({ id: 0 });
                                            }}>
                                                <div className="mochi-button-decorator mochi-button-decorator-left">(</div>
                                                <div className="mochi-button-base">Add note</div>
                                                <div className="mochi-button-decorator mochi-button-decorator-right">)</div>
                                            </div>
                                        </div>
                                        <div className="top-border top-border-right"></div>
                                    </div>

                                    <div className="notes-for-carrier-container">
                                        <div className="notes-for-carrier-wrapper">
                                            {(selectedOrder?.notes_for_carrier || []).map(
                                                (note, index) => {
                                                    return (
                                                        <div
                                                            className="notes-for-carrier-item"
                                                            key={index}
                                                            onClick={() => setSelectedNoteForCarrier(note)}
                                                        >
                                                            {note.text}
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`fields-container-col${((selectedOrder?.division_id || 0) === 0 || (selectedOrder?.load_type_id || 0) === 0) ? ' form-disabled' : ''}`}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "10%",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                        padding: "2px 0 10px 0",
                    }}
                >
                    <div
                        className="mochi-button"
                        onClick={() => {
                            if ((selectedOrder?.id || 0) === 0) {
                                window.alert("You must create or load an order first!");
                                return;
                            }

                            let panel = {
                                panelName: `${props.panelName}-rate-conf`,
                                component: (
                                    <RateConf
                                        title="Rate Conf"
                                        tabTimes={41000 + props.tabTimes}
                                        panelName={`${props.panelName}-rate-conf`}
                                        origin={props.origin}
                                        componentId={moment().format("x")}
                                        selectedOrderId={selectedOrder?.id || 0}
                                        closingCallback={() => {
                                            closePanel(`${props.panelName}-rate-conf`, props.origin);
                                            (props.isOnPanel ? refOrderNumber : props.refOrderNumber).current.focus({ preventScroll: true });
                                        }}
                                    />
                                ),
                            };

                            openPanel(panel, props.origin);
                        }}
                    >
                        <div className="mochi-button-decorator mochi-button-decorator-left">
                            (
                        </div>
                        <div className="mochi-button-base">Rate Conf</div>
                        <div className="mochi-button-decorator mochi-button-decorator-right">
                            )
                        </div>
                    </div>
                    <div
                        className="mochi-button"
                        onClick={() => {
                            if ((selectedOrder?.id || 0) === 0) {
                                window.alert("You must create or load an order first!");
                                return;
                            }

                            let panel = {
                                panelName: `${props.panelName}-order`,
                                component: (
                                    <Order
                                        title="Order"
                                        tabTimes={37000 + props.tabTimes}
                                        panelName={`${props.panelName}-order`}
                                        origin={props.origin}
                                        closingCallback={() => {
                                            closePanel(`${props.panelName}-order`, props.origin);
                                            (props.isOnPanel ? refOrderNumber : props.refOrderNumber).current.focus({ preventScroll: true });
                                        }}

                                        componentId={moment().format("x")}
                                        selectedOrderId={selectedOrder?.id || 0}
                                    />
                                ),
                            };

                            openPanel(panel, props.origin);
                        }}
                    >
                        <div className="mochi-button-decorator mochi-button-decorator-left">
                            (
                        </div>
                        <div className="mochi-button-base">Print Order</div>
                        <div className="mochi-button-decorator mochi-button-decorator-right">
                            )
                        </div>
                    </div>
                    <div
                        className="mochi-button"
                        onClick={() => {
                            if ((selectedOrder?.id || 0) === 0) {
                                window.alert("You must create or load an order first!");
                                return;
                            }

                            let panel = {
                                panelName: `${props.panelName}-bol`,
                                component: (
                                    <Bol
                                        title="Bol"
                                        tabTimes={40000 + props.tabTimes}
                                        panelName={`${props.panelName}-bol`}
                                        origin={props.origin}
                                        closingCallback={() => {
                                            closePanel(`${props.panelName}-bol`, props.origin);
                                            (props.isOnPanel ? refOrderNumber : props.refOrderNumber).current.focus({ preventScroll: true });
                                        }}

                                        componentId={moment().format("x")}
                                        selectedOrder={selectedOrder}
                                    />
                                ),
                            };

                            openPanel(panel, props.origin);
                        }}
                    >
                        <div className="mochi-button-decorator mochi-button-decorator-left">
                            (
                        </div>
                        <div className="mochi-button-base">Print BOL</div>
                        <div className="mochi-button-decorator mochi-button-decorator-right">
                            )
                        </div>
                    </div>
                    <div
                        className={classnames({
                            "mochi-button": true,
                            disabled: false,
                        })}
                        onClick={() => {
                            if ((selectedOrder?.id || 0) === 0) {
                                window.alert("You must create or load an order first!");
                                return;
                            }

                            let panel = {
                                panelName: `${props.panelName}-documents`,
                                component: (
                                    <Documents
                                        title="Documents"
                                        tabTimes={90000 + props.tabTimes}
                                        panelName={`${props.panelName}-documents`}
                                        origin={props.origin}
                                        suborigin={"order"}
                                        closingCallback={() => {
                                            closePanel(`${props.panelName}-documents`, props.origin);
                                            (props.isOnPanel ? refOrderNumber : props.refOrderNumber).current.focus({ preventScroll: true });
                                        }}

                                        componentId={moment().format("x")}
                                        selectedOwner={{ ...selectedOrder }}
                                        selectedOwnerDocument={{
                                            id: 0,
                                            user_id: Math.floor(Math.random() * (15 - 1)) + 1,
                                            date_entered: moment().format("MM/DD/YYYY"),
                                        }}
                                        savingDocumentUrl="/saveOrderDocument"
                                        deletingDocumentUrl="/deleteOrderDocument"
                                        savingDocumentNoteUrl="/saveOrderDocumentNote"
                                        deletingDocumentNoteUrl="/deleteOrderDocumentNote"
                                        serverDocumentsFolder="/order-documents/"
                                        permissionName='dispatch documents'
                                    />
                                ),
                            };

                            openPanel(panel, props.origin);
                        }}
                    >
                        <div className="mochi-button-decorator mochi-button-decorator-left">
                            (
                        </div>
                        <div className="mochi-button-base">Documents</div>
                        <div className="mochi-button-decorator mochi-button-decorator-right">
                            )
                        </div>
                    </div>
                    <div
                        className="mochi-button"
                        onClick={async () => {
                            let panel = {
                                panelName: `${props.panelName}-load-board`,
                                component: (
                                    <LoadBoard
                                        pageName={"Load Board"}
                                        title={"Load Board"}
                                        panelName={"load-board"}
                                        tabTimes={4000 + props.tabTimes}
                                        screenFocused={props.loadBoardScreenFocused}
                                        componentId={moment().format("x")}
                                        isOnPanel={true}
                                        isAdmin={props.isAdmin}
                                        origin={props.origin}
                                        closingCallback={() => {
                                            closePanel(`${props.panelName}-load-board`, props.origin);
                                            (props.isOnPanel ? refOrderNumber : props.refOrderNumber).current.focus({ preventScroll: true });
                                        }}

                                    />
                                ),
                            };

                            openPanel(panel, props.origin);
                        }}
                    >
                        <div className="mochi-button-decorator mochi-button-decorator-left">
                            (
                        </div>
                        <div className="mochi-button-base">Load Board</div>
                        <div className="mochi-button-decorator mochi-button-decorator-right">
                            )
                        </div>
                    </div>
                    <div
                        className="mochi-button"
                        onClick={() => {
                            if ((selectedOrder?.id || 0) === 0) {
                                window.alert("You must create or load an order first!");
                                return;
                            }

                            if ((selectedOrder?.load_type_id || 0) === 0) {
                                window.alert("You must select a load type first!");
                                return;
                            }

                            let panel = {
                                panelName: `${props.panelName}-rating`,
                                component: (
                                    <RatingScreen
                                        panelName={`${props.panelName}-rating`}
                                        title="Rating Screen"
                                        tabTimes={34000 + props.tabTimes}
                                        componentId={moment().format("x")}
                                        origin={props.origin}
                                        selectedOrder={selectedOrder}
                                        closingCallback={() => {
                                            closePanel(`${props.panelName}-rating`, props.origin);
                                            (props.isOnPanel ? refOrderNumber : props.refOrderNumber).current.focus({ preventScroll: true });
                                        }}
                                    />
                                ),
                            };

                            openPanel(panel, props.origin);
                        }}
                    >
                        <div className="mochi-button-decorator mochi-button-decorator-left">
                            (
                        </div>
                        <div className="mochi-button-base">Rate Load</div>
                        <div className="mochi-button-decorator mochi-button-decorator-right">
                            )
                        </div>
                    </div>
                </div>
            </div>

            <div className="fields-container-row" style={{
                display: "flex",
                alignSelf: "flex-start",
                minWidth: "70%",
                maxWidth: "100%",
                alignItems: "center",
            }}>

                <div className="fields-container-row routing" style={{
                    display: "flex",
                    alignSelf: "flex-start",
                    minWidth: "70%",
                    maxWidth: "69%",
                    alignItems: "center",
                }}>
                    <div className="pickups-container" style={{ display: "flex", flexDirection: "row" }}>
                        <div className="swiper-pickup-prev-btn">
                            <span className="fas fa-chevron-left"></span>
                        </div>

                        <Swiper
                            slidesPerView={5}
                            navigation={{
                                prevEl: ".swiper-pickup-prev-btn",
                                nextEl: ".swiper-pickup-next-btn",
                            }}
                        >
                            {[
                                ...getPickupsOnRouting(),
                                ...(selectedOrder?.pickups || []).filter((p) => (selectedOrder?.routing || []).find((r) => r.pickup_id === p.id) === undefined),
                            ].map((pickup, index) => {
                                if (pickup === undefined) {
                                    return "";
                                } else {
                                    let fulDateTime1 = (pickup.pu_date1 || "") + " " + (pickup.pu_time1 || "");
                                    let fulDateTime2 = (pickup.pu_date2 || "") + " " + (pickup.pu_time2 || "");
                                    let puDateTime = undefined;
                                    let statusClass = "active";
                                    let curDateTime = currentSystemDateTime;

                                    if (moment(fulDateTime2, "MM/DD/YYYY HHmm").format("MM/DD/YYYY HHmm") === fulDateTime2) {
                                        puDateTime = moment(fulDateTime2, "MM/DD/YYYY HHmm");
                                    } else if (moment(fulDateTime1, "MM/DD/YYYY HHmm").format("MM/DD/YYYY HHmm") === fulDateTime1) {
                                        puDateTime = moment(fulDateTime1, "MM/DD/YYYY HHmm");
                                    }

                                    if (puDateTime !== undefined) {
                                        let pastHour = puDateTime.clone().subtract(1, "hours");

                                        if (curDateTime < pastHour) {
                                            statusClass = "active";
                                        } else if (curDateTime >= pastHour && curDateTime <= puDateTime) {
                                            statusClass = "warning";
                                        } else {
                                            statusClass = "expired";
                                        }

                                        if ((selectedOrder?.events || []).length > 0) {
                                            selectedOrder.events.map((item) => {
                                                if ((item.event_type?.name || "").toLowerCase() === "loaded" && item.shipper_id === pickup.customer_id) {
                                                    curDateTime = moment(item.event_date + " " + item.event_time, "MM/DD/YYYY HHmm");

                                                    if (curDateTime <= puDateTime) {
                                                        statusClass = "active";
                                                    } else {
                                                        statusClass = "expired";
                                                    }
                                                }
                                                return true;
                                            });
                                        }
                                    }

                                    let classes = classnames({
                                        "order-pickup": true,
                                        selected: selectedShipperCustomer?.pickup_id === pickup.id,
                                        active: statusClass === "active",
                                        warning: statusClass === "warning",
                                        expired: statusClass === "expired",
                                        unsaved: pickup.id === 0,
                                    });

                                    return (
                                        <SwiperSlide
                                            className={classes}
                                            key={index}
                                            onClick={() => {
                                                setSelectedShipperCustomer({
                                                    ...pickup.customer,
                                                    pickup_id: pickup.id,
                                                    pu_date1: pickup.pu_date1,
                                                    pu_date2: pickup.pu_date2,
                                                    pu_time1: pickup.pu_time1,
                                                    pu_time2: pickup.pu_time2,
                                                    bol_numbers: pickup.bol_numbers,
                                                    po_numbers: pickup.po_numbers,
                                                    ref_numbers: pickup.ref_numbers,
                                                    seal_number: pickup.seal_number,
                                                    special_instructions: pickup.special_instructions,
                                                    contact_id: pickup.contact_id,
                                                    contact_name: pickup.contact_name,
                                                    contact_phone: pickup.contact_phone,
                                                    contact_phone_ext: pickup.contact_phone_ext,
                                                    contact_primary_phone: pickup.contact_primary_phone,
                                                    type: pickup.type,
                                                });

                                                setSelectedShipperCustomerContact((pickup.customer?.contacts || []).find((c) => c.is_primary === 1) || {});

                                                refShipperCompanyCode.current.focus();
                                            }}
                                        >
                                            <div>PU {index + 1}</div>
                                            {
                                                (selectedOrder?.is_cancelled || 0) === 0 &&
                                                <div
                                                    className="pu-remove-btn"
                                                    title="Remove this pickup"
                                                    onClick={async (e) => {
                                                        e.stopPropagation();

                                                        let selected_order = JSON.parse(JSON.stringify(selectedOrder));

                                                        selected_order.pickups = (selected_order?.pickups || []).filter((pu, i) => {
                                                            return pu.id !== pickup.id;
                                                        });

                                                        await setSelectedOrder(selected_order);

                                                        if (selected_order.pickups.length > 0) {
                                                            await setSelectedShipperCustomer({
                                                                ...(selected_order.pickups[0].customer || {}),
                                                                ...selected_order.pickups[0],
                                                                customer: {},
                                                                pickup_id: selected_order.pickups[0].id,
                                                            });

                                                            await setSelectedShipperCustomerContact(
                                                                (
                                                                    selected_order.pickups[0].customer?.contacts || []
                                                                ).find((c) => c.is_primary === 1) || {}
                                                            );
                                                        } else {
                                                            await setSelectedShipperCustomer({});
                                                            await setSelectedShipperCustomerContact({});
                                                        }

                                                        await axios.post(props.serverUrl + "/removeOrderPickup", {
                                                            id: pickup.id,
                                                            order_id: selected_order?.id || 0,
                                                        }).then((res) => {
                                                            if (res.data.result === "OK") {
                                                                selected_order = res.data.order;

                                                                // validar routing
                                                                // 1. VALIDAR PICKUPS AND DELIVERIES = 1a111
                                                                if ((selected_order?.pickups || []).length > 0 && selected_order.deliveries.length > 0) {
                                                                    if (selected_order.pickups.length === 1 && selected_order.deliveries.length === 1) {
                                                                        let routing = [
                                                                            {
                                                                                order_id: selected_order?.id || 0,
                                                                                pickup_id: selected_order.pickups[0].id,
                                                                                delivery_id: null,
                                                                                type: "pickup",
                                                                            },
                                                                            {
                                                                                order_id: selected_order?.id || 0,
                                                                                pickup_id: null,
                                                                                delivery_id: selected_order.deliveries[0].id,
                                                                                type: "delivery",
                                                                            },
                                                                        ];

                                                                        axios.post(props.serverUrl + "/saveOrderRouting", {
                                                                            order_id: selected_order?.id || 0,
                                                                            routing: routing,
                                                                        }).then((res) => {
                                                                            if (res.data.result === "OK") {
                                                                                selected_order = res.data.order;
                                                                                setSelectedOrder(selected_order);

                                                                                setMileageLoaderVisible(true);

                                                                                let origin = null;
                                                                                let destination = null;

                                                                                let start = selected_order.routing[0];
                                                                                let waypoints = [];
                                                                                let end = selected_order.routing[selected_order.routing.length - 1];

                                                                                if (start.type === 'pickup') {
                                                                                    selected_order.pickups.map((p, i) => {
                                                                                        if (p.id === start.pickup_id) {
                                                                                            if ((p.customer?.zip_data || '') !== '') {
                                                                                                origin = `${p.customer.zip_data.latitude.toString()},${p.customer.zip_data.longitude.toString()}`;
                                                                                            }
                                                                                        }
                                                                                        return false;
                                                                                    })
                                                                                } else {
                                                                                    selected_order.deliveries.map((d, i) => {
                                                                                        if (d.id === start.delivery_id) {
                                                                                            if ((d.customer?.zip_data || '') !== '') {
                                                                                                origin = `${d.customer.zip_data.latitude.toString()},${d.customer.zip_data.longitude.toString()}`;
                                                                                            }
                                                                                        }
                                                                                        return false;
                                                                                    })
                                                                                }

                                                                                selected_order.routing.map((item, i) => {
                                                                                    if (i > 0 && i < (selected_order.routing.length - 1)) {
                                                                                        if (item.type === 'pickup') {
                                                                                            selected_order.pickups.map((p, i) => {
                                                                                                if (p.id === item.pickup_id) {
                                                                                                    if ((p.customer?.zip_data || '') !== '') {
                                                                                                        waypoints.push(`${p.customer.zip_data.latitude.toString()},${p.customer.zip_data.longitude.toString()}`);
                                                                                                    }
                                                                                                }
                                                                                                return false;
                                                                                            })
                                                                                        } else {
                                                                                            selected_order.deliveries.map((d, i) => {
                                                                                                if (d.id === item.delivery_id) {
                                                                                                    if ((d.customer?.zip_data || '') !== '') {
                                                                                                        waypoints.push(`${d.customer.zip_data.latitude.toString()},${d.customer.zip_data.longitude.toString()}`);
                                                                                                    }
                                                                                                }
                                                                                                return false;
                                                                                            })
                                                                                        }
                                                                                    }

                                                                                    return true;
                                                                                });

                                                                                if (end.type === 'pickup') {
                                                                                    selected_order.pickups.map((p, i) => {
                                                                                        if (p.id === end.pickup_id) {
                                                                                            if ((p.customer?.zip_data || '') !== '') {
                                                                                                destination = `${p.customer.zip_data.latitude.toString()},${p.customer.zip_data.longitude.toString()}`;
                                                                                            }
                                                                                        }
                                                                                        return false;
                                                                                    })
                                                                                } else {
                                                                                    selected_order.deliveries.map((d, i) => {
                                                                                        if (d.id === end.delivery_id) {
                                                                                            if ((d.customer?.zip_data || '') !== '') {
                                                                                                destination = `${d.customer.zip_data.latitude.toString()},${d.customer.zip_data.longitude.toString()}`;
                                                                                            }
                                                                                        }
                                                                                        return false;
                                                                                    })
                                                                                }

                                                                                let params = {
                                                                                    'routingMode': 'fast',
                                                                                    'transportMode': 'car',
                                                                                    'origin': origin,
                                                                                    'via': new H.service.Url.MultiValueQueryParameter(waypoints),
                                                                                    'destination': destination,
                                                                                    'return': 'summary'
                                                                                }

                                                                                if (routingService) {
                                                                                    routingService.calculateRoute(
                                                                                        params,
                                                                                        (result) => {
                                                                                            let miles = (result?.routes[0]?.sections || []).reduce((a, b) => {
                                                                                                return a + b.summary.length;
                                                                                            }, 0) || 0;

                                                                                            selected_order.miles = miles;

                                                                                            setSelectedOrder(selected_order);
                                                                                            setMileageLoaderVisible(false);

                                                                                            axios.post(props.serverUrl + "/saveOrder", selected_order).then(async (res) => {
                                                                                                if (res.data.result === "OK") {
                                                                                                    setSelectedOrder({
                                                                                                        ...selected_order,
                                                                                                        order_customer_ratings: res.data.order.order_customer_ratings,
                                                                                                        order_carrier_ratings: res.data.order.order_carrier_ratings,
                                                                                                    });

                                                                                                    props.setSelectedOrder({
                                                                                                        ...selected_order,
                                                                                                        order_customer_ratings: res.data.order.order_customer_ratings,
                                                                                                        order_carrier_ratings: res.data.order.order_carrier_ratings,
                                                                                                        component_id: props.componentId,
                                                                                                    });
                                                                                                }
                                                                                            }).catch((e) => {
                                                                                                console.log("error on saving order miles", e);
                                                                                                setMileageLoaderVisible(false);
                                                                                            });
                                                                                        },
                                                                                        (error) => {
                                                                                            console.log("error getting mileage", error);
                                                                                            selected_order.miles = 0;

                                                                                            setSelectedOrder(selected_order);
                                                                                            setMileageLoaderVisible(false);

                                                                                            axios.post(props.serverUrl + "/saveOrder", selected_order).then(async (res) => {
                                                                                                if (res.data.result === "OK") {
                                                                                                    setSelectedOrder({
                                                                                                        ...selected_order,
                                                                                                        order_customer_ratings: res.data.order.order_customer_ratings,
                                                                                                        order_carrier_ratings: res.data.order.order_carrier_ratings,
                                                                                                    });

                                                                                                    props.setSelectedOrder({
                                                                                                        ...selected_order,
                                                                                                        order_customer_ratings: res.data.order.order_customer_ratings,
                                                                                                        order_carrier_ratings: res.data.order.order_carrier_ratings,
                                                                                                        component_id: props.componentId,
                                                                                                    });
                                                                                                }
                                                                                            }).catch((e) => {
                                                                                                console.log("error on saving order miles", e);
                                                                                                setMileageLoaderVisible(false);
                                                                                            });
                                                                                        }
                                                                                    );
                                                                                }
                                                                            } else {
                                                                                selected_order.miles = 0;
                                                                                setSelectedOrder(selected_order);
                                                                                setMileageLoaderVisible(false);

                                                                                axios.post(props.serverUrl + "/saveOrder", selected_order).then(async (res) => {
                                                                                    if (res.data.result === "OK") {
                                                                                        setSelectedOrder({
                                                                                            ...selected_order,
                                                                                            order_customer_ratings: res.data.order.order_customer_ratings,
                                                                                            order_carrier_ratings: res.data.order.order_carrier_ratings,
                                                                                        });

                                                                                        props.setSelectedOrder({
                                                                                            ...selected_order,
                                                                                            order_customer_ratings: res.data.order.order_customer_ratings,
                                                                                            order_carrier_ratings: res.data.order.order_carrier_ratings,
                                                                                            component_id: props.componentId,
                                                                                        });
                                                                                    }
                                                                                }).catch((e) => {
                                                                                    console.log("error on saving order miles", e);
                                                                                    setMileageLoaderVisible(false);
                                                                                });
                                                                            }
                                                                        }).catch((e) => {
                                                                            console.log("error saving order miles", e);
                                                                            setMileageLoaderVisible(false);
                                                                        });
                                                                    } else {
                                                                        if (selected_order.routing.length >= 2) {
                                                                            setMileageLoaderVisible(true);

                                                                            let origin = null;
                                                                            let destination = null;

                                                                            let start = selected_order.routing[0];
                                                                            let waypoints = [];
                                                                            let end = selected_order.routing[selected_order.routing.length - 1];

                                                                            if (start.type === 'pickup') {
                                                                                selected_order.pickups.map((p, i) => {
                                                                                    if (p.id === start.pickup_id) {
                                                                                        if ((p.customer?.zip_data || '') !== '') {
                                                                                            origin = `${p.customer.zip_data.latitude.toString()},${p.customer.zip_data.longitude.toString()}`;
                                                                                        }
                                                                                    }
                                                                                    return false;
                                                                                })
                                                                            } else {
                                                                                selected_order.deliveries.map((d, i) => {
                                                                                    if (d.id === start.delivery_id) {
                                                                                        if ((d.customer?.zip_data || '') !== '') {
                                                                                            origin = `${d.customer.zip_data.latitude.toString()},${d.customer.zip_data.longitude.toString()}`;
                                                                                        }
                                                                                    }
                                                                                    return false;
                                                                                })
                                                                            }

                                                                            selected_order.routing.map((item, i) => {
                                                                                if (i > 0 && i < (selected_order.routing.length - 1)) {
                                                                                    if (item.type === 'pickup') {
                                                                                        selected_order.pickups.map((p, i) => {
                                                                                            if (p.id === item.pickup_id) {
                                                                                                if ((p.customer?.zip_data || '') !== '') {
                                                                                                    waypoints.push(`${p.customer.zip_data.latitude.toString()},${p.customer.zip_data.longitude.toString()}`);
                                                                                                }
                                                                                            }
                                                                                            return false;
                                                                                        })
                                                                                    } else {
                                                                                        selected_order.deliveries.map((d, i) => {
                                                                                            if (d.id === item.delivery_id) {
                                                                                                if ((d.customer?.zip_data || '') !== '') {
                                                                                                    waypoints.push(`${d.customer.zip_data.latitude.toString()},${d.customer.zip_data.longitude.toString()}`);
                                                                                                }
                                                                                            }
                                                                                            return false;
                                                                                        })
                                                                                    }
                                                                                }

                                                                                return true;
                                                                            });

                                                                            if (end.type === 'pickup') {
                                                                                selected_order.pickups.map((p, i) => {
                                                                                    if (p.id === end.pickup_id) {
                                                                                        if ((p.customer?.zip_data || '') !== '') {
                                                                                            destination = `${p.customer.zip_data.latitude.toString()},${p.customer.zip_data.longitude.toString()}`;
                                                                                        }
                                                                                    }
                                                                                    return false;
                                                                                })
                                                                            } else {
                                                                                selected_order.deliveries.map((d, i) => {
                                                                                    if (d.id === end.delivery_id) {
                                                                                        if ((d.customer?.zip_data || '') !== '') {
                                                                                            destination = `${d.customer.zip_data.latitude.toString()},${d.customer.zip_data.longitude.toString()}`;
                                                                                        }
                                                                                    }
                                                                                    return false;
                                                                                })
                                                                            }

                                                                            let params = {
                                                                                'routingMode': 'fast',
                                                                                'transportMode': 'car',
                                                                                'origin': origin,
                                                                                'via': new H.service.Url.MultiValueQueryParameter(waypoints),
                                                                                'destination': destination,
                                                                                'return': 'summary'
                                                                            }

                                                                            if (routingService) {
                                                                                routingService.calculateRoute(
                                                                                    params,
                                                                                    (result) => {
                                                                                        let miles = (result?.routes[0]?.sections || []).reduce((a, b) => {
                                                                                            return a + b.summary.length;
                                                                                        }, 0) || 0;

                                                                                        selected_order.miles = miles;

                                                                                        setSelectedOrder(selected_order);
                                                                                        setMileageLoaderVisible(false);

                                                                                        axios.post(props.serverUrl + "/saveOrder", selected_order).then(async (res) => {
                                                                                            if (res.data.result === "OK") {
                                                                                                setSelectedOrder({
                                                                                                    ...selected_order,
                                                                                                    order_customer_ratings: res.data.order.order_customer_ratings,
                                                                                                    order_carrier_ratings: res.data.order.order_carrier_ratings,
                                                                                                });

                                                                                                props.setSelectedOrder({
                                                                                                    ...selected_order,
                                                                                                    order_customer_ratings: res.data.order.order_customer_ratings,
                                                                                                    order_carrier_ratings: res.data.order.order_carrier_ratings,
                                                                                                    component_id: props.componentId,
                                                                                                });
                                                                                            }
                                                                                        }).catch((e) => {
                                                                                            setMileageLoaderVisible(false);
                                                                                            console.log("error on saving order miles", e);
                                                                                        });
                                                                                    },
                                                                                    (error) => {
                                                                                        console.log("error getting mileage", error);
                                                                                        selected_order.miles = 0;
                                                                                        setSelectedOrder(selected_order);
                                                                                        setMileageLoaderVisible(false);

                                                                                        axios.post(props.serverUrl + "/saveOrder", selected_order).then(async (res) => {
                                                                                            if (res.data.result === "OK") {
                                                                                                setSelectedOrder({
                                                                                                    ...selected_order,
                                                                                                    order_customer_ratings: res.data.order.order_customer_ratings,
                                                                                                    order_carrier_ratings: res.data.order.order_carrier_ratings,
                                                                                                });

                                                                                                props.setSelectedOrder({
                                                                                                    ...selected_order,
                                                                                                    order_customer_ratings: res.data.order.order_customer_ratings,
                                                                                                    order_carrier_ratings: res.data.order.order_carrier_ratings,
                                                                                                    component_id: props.componentId,
                                                                                                });
                                                                                            }
                                                                                        }).catch((e) => {
                                                                                            setMileageLoaderVisible(false);
                                                                                            console.log("error on saving order miles", e);
                                                                                        });
                                                                                    }
                                                                                );
                                                                            }
                                                                        } else {
                                                                            selected_order.miles = 0;
                                                                            setSelectedOrder(selected_order);
                                                                            setMileageLoaderVisible(false);

                                                                            axios.post(props.serverUrl + "/saveOrder", selected_order).then(async (res) => {
                                                                                if (res.data.result === "OK") {
                                                                                    setSelectedOrder({
                                                                                        ...selected_order,
                                                                                        order_customer_ratings: res.data.order.order_customer_ratings,
                                                                                        order_carrier_ratings: res.data.order.order_carrier_ratings,
                                                                                    });

                                                                                    props.setSelectedOrder({
                                                                                        ...selected_order,
                                                                                        order_customer_ratings: res.data.order.order_customer_ratings,
                                                                                        order_carrier_ratings: res.data.order.order_carrier_ratings,
                                                                                        component_id: props.componentId,
                                                                                    });
                                                                                }
                                                                            }).catch((e) => {
                                                                                setMileageLoaderVisible(false);
                                                                                console.log("error on saving order miles", e);
                                                                            });
                                                                        }
                                                                    }
                                                                } else {
                                                                    // GUARDAR ORDEN CON MILES = 0;
                                                                    selected_order.miles = 0;
                                                                    setSelectedOrder(selected_order);
                                                                    setMileageLoaderVisible(false);

                                                                    axios.post(props.serverUrl + "/saveOrder", selected_order).then(async (res) => {
                                                                        if (res.data.result === "OK") {
                                                                            setSelectedOrder({
                                                                                ...selected_order,
                                                                                order_customer_ratings: res.data.order.order_customer_ratings,
                                                                                order_carrier_ratings: res.data.order.order_carrier_ratings,
                                                                            });

                                                                            props.setSelectedOrder({
                                                                                ...selected_order,
                                                                                order_customer_ratings: res.data.order.order_customer_ratings,
                                                                                order_carrier_ratings: res.data.order.order_carrier_ratings,
                                                                                component_id: props.componentId,
                                                                            });
                                                                        }
                                                                    }).catch((e) => {
                                                                        setMileageLoaderVisible(false);
                                                                        console.log("error on saving order miles", e);
                                                                    });
                                                                }
                                                            } else {
                                                                setMileageLoaderVisible(false);
                                                            }
                                                        }).catch((e) => {
                                                            setMileageLoaderVisible(false);
                                                            console.log("error on removing order pickup", e);
                                                        });
                                                    }}
                                                >
                                                    <span className="fas fa-times"></span>
                                                </div>
                                            }
                                        </SwiperSlide>
                                    );
                                }
                            })}

                            {
                                ((selectedOrder?.pickups || []).find((p) => p.id === 0) === undefined && (selectedOrder?.is_cancelled || 0) === 0) &&
                                (<SwiperSlide
                                    className="order-pickup adding"
                                    title="Add new pickup"
                                    onClick={() => {
                                        let pickups = selectedOrder?.pickups || [];
                                        pickups.push({ id: 0 });
                                        setSelectedShipperCustomer({ id: 0, pickup_id: 0 });
                                        setSelectedShipperCustomerContact({});
                                        setSelectedOrder({ ...selectedOrder, pickups: pickups });

                                        refShipperCompanyCode.current.focus();
                                    }}
                                >
                                    <div>
                                        <span className="fas fa-plus"></span>
                                    </div>
                                </SwiperSlide>)
                            }
                        </Swiper>

                        <div className="swiper-pickup-next-btn">
                            <span className="fas fa-chevron-right"></span>
                        </div>
                    </div>

                    <div className="form-h-sep"></div>
                    <div
                        className="mochi-button"
                        onClick={() => {
                            if ((selectedOrder?.id || 0) === 0) {
                                window.alert("You must create or load an order first!");
                                return;
                            }

                            let panel = {
                                panelName: `${props.panelName}-routing`,
                                component: (
                                    <Routing
                                        panelName={`${props.panelName}-routing`}
                                        title="Routing"
                                        tabTimes={39000 + props.tabTimes}
                                        origin={props.origin}
                                        closingCallback={() => {
                                            closePanel(`${props.panelName}-routing`, props.origin);
                                            (props.isOnPanel ? refOrderNumber : props.refOrderNumber).current.focus({ preventScroll: true });
                                        }}

                                        componentId={moment().format("x")}
                                        selectedOrder={selectedOrder}
                                        isAdmin={props.isAdmin}
                                    />
                                ),
                            };

                            openPanel(panel, props.origin);
                        }}
                    >
                        <div className="mochi-button-decorator mochi-button-decorator-left"> (</div>
                        <div className="mochi-button-base">Routing</div>
                        <div className="mochi-button-decorator mochi-button-decorator-right"> )</div>
                    </div>
                    <div className="form-h-sep"></div>
                    <div className="deliveries-container" style={{ display: "flex", flexDirection: "row" }}>
                        <div className="swiper-delivery-prev-btn">
                            <span className="fas fa-chevron-left"></span>
                        </div>

                        <Swiper slidesPerView={5}
                            navigation={{ prevEl: ".swiper-delivery-prev-btn", nextEl: ".swiper-delivery-next-btn" }}>
                            {[
                                ...getDeliveriesOnRouting(),
                                ...(selectedOrder?.deliveries || []).filter((d) => (selectedOrder?.routing || []).find((r) => r.delivery_id === d.id) === undefined),
                            ].map((delivery, index) => {
                                if (delivery === undefined) {
                                    return "";
                                } else {
                                    let fulDateTime1 = (delivery.delivery_date1 || "") + " " + (delivery.delivery_time1 || "");
                                    let fulDateTime2 = (delivery.delivery_date2 || "") + " " + (delivery.delivery_time2 || "");
                                    let deliveryDateTime = undefined;
                                    let statusClass = "active";
                                    let curDateTime = currentSystemDateTime;

                                    if (moment(fulDateTime2, "MM/DD/YYYY HHmm").format("MM/DD/YYYY HHmm") === fulDateTime2) {
                                        deliveryDateTime = moment(fulDateTime2, "MM/DD/YYYY HHmm");
                                    } else if (moment(fulDateTime1, "MM/DD/YYYY HHmm").format("MM/DD/YYYY HHmm") === fulDateTime1) {
                                        deliveryDateTime = moment(fulDateTime1, "MM/DD/YYYY HHmm");
                                    }

                                    if (deliveryDateTime !== undefined) {
                                        let pastHour = deliveryDateTime.clone().subtract(1, "hours");

                                        if (curDateTime < pastHour) {
                                            statusClass = "active";
                                        } else if (curDateTime >= pastHour && curDateTime <= deliveryDateTime) {
                                            statusClass = "warning";
                                        } else {
                                            statusClass = "expired";
                                        }

                                        if ((selectedOrder?.events || []).length > 0) {
                                            selectedOrder.events.map((item) => {
                                                if ((item.event_type?.name || "").toLowerCase() === "delivered" && item.consignee_id === delivery.customer_id) {
                                                    curDateTime = moment(item.event_date + " " + item.event_time, "MM/DD/YYYY HHmm");

                                                    if (curDateTime <= deliveryDateTime) {
                                                        statusClass = "active";
                                                    } else {
                                                        statusClass = "expired";
                                                    }
                                                }
                                                return true;
                                            });
                                        }
                                    }

                                    let classes = classnames({
                                        "order-delivery": true,
                                        selected: selectedConsigneeCustomer?.delivery_id === delivery.id,
                                        active: statusClass === "active",
                                        warning: statusClass === "warning",
                                        expired: statusClass === "expired",
                                        unsaved: delivery.id === 0,
                                    });

                                    return (
                                        <SwiperSlide
                                            className={classes}
                                            key={index}
                                            onClick={() => {
                                                setSelectedConsigneeCustomer({
                                                    ...delivery.customer,
                                                    delivery_id: delivery.id,
                                                    delivery_date1: delivery.delivery_date1,
                                                    delivery_date2: delivery.delivery_date2,
                                                    delivery_time1: delivery.delivery_time1,
                                                    delivery_time2: delivery.delivery_time2,
                                                    bol_numbers: delivery.bol_numbers,
                                                    po_numbers: delivery.po_numbers,
                                                    ref_numbers: delivery.ref_numbers,
                                                    seal_number: delivery.seal_number,
                                                    special_instructions: delivery.special_instructions,
                                                    type: delivery.type,
                                                });

                                                setSelectedConsigneeCustomerContact((delivery.customer?.contacts || []).find((c) => c.is_primary === 1) || {});

                                                refConsigneeCompanyCode.current.focus();
                                            }}
                                        >
                                            <div>Delivery {index + 1}</div>
                                            {
                                                (selectedOrder?.is_cancelled || 0) === 0 &&
                                                <div
                                                    className="delivery-remove-btn"
                                                    title="Remove this delivery"
                                                    onClick={async (e) => {
                                                        e.stopPropagation();

                                                        let selected_order = JSON.parse(JSON.stringify(selectedOrder));

                                                        selected_order.deliveries = (selected_order?.deliveries || []).filter((del, i) => {
                                                            return del.id !== delivery.id;
                                                        });

                                                        await setSelectedOrder(selected_order);

                                                        if (selected_order.deliveries.length > 0) {
                                                            await setSelectedConsigneeCustomer({
                                                                ...(selected_order.deliveries[0].customer || {}),
                                                                ...selected_order.deliveries[0],
                                                                customer: {},
                                                                delivery_id: selected_order.deliveries[0].id,
                                                            });

                                                            await setSelectedConsigneeCustomerContact((selected_order.deliveries[0].customer?.contacts || []).find((c) => c.is_primary === 1) || {});
                                                        } else {
                                                            await setSelectedConsigneeCustomer({});
                                                            await setSelectedConsigneeCustomerContact({});
                                                        }

                                                        await axios.post(props.serverUrl + "/removeOrderDelivery", {
                                                            id: delivery.id,
                                                            order_id: selected_order?.id || 0,
                                                        }).then((res) => {
                                                            if (res.data.result === "OK") {
                                                                selected_order = res.data.order;

                                                                if ((selected_order?.pickups || []).length > 0 && selected_order.deliveries.length > 0) {
                                                                    if (selected_order.pickups.length === 1 && selected_order.deliveries.length === 1) {
                                                                        let routing = [
                                                                            {
                                                                                order_id: selected_order?.id || 0,
                                                                                pickup_id: selected_order.pickups[0].id,
                                                                                delivery_id: null,
                                                                                type: "pickup",
                                                                            },
                                                                            {
                                                                                order_id: selected_order?.id || 0,
                                                                                pickup_id: null,
                                                                                delivery_id: selected_order.deliveries[0].id,
                                                                                type: "delivery",
                                                                            },
                                                                        ];

                                                                        axios.post(props.serverUrl + "/saveOrderRouting", {
                                                                            order_id: selected_order?.id || 0,
                                                                            routing: routing,
                                                                        }
                                                                        ).then((res) => {
                                                                            if (res.data.result === "OK") {
                                                                                selected_order = res.data.order;
                                                                                setSelectedOrder(selected_order);

                                                                                setMileageLoaderVisible(true);

                                                                                let origin = null;
                                                                                let destination = null;

                                                                                let start = selected_order.routing[0];
                                                                                let waypoints = [];
                                                                                let end = selected_order.routing[selected_order.routing.length - 1];

                                                                                if (start.type === 'pickup') {
                                                                                    selected_order.pickups.map((p, i) => {
                                                                                        if (p.id === start.pickup_id) {
                                                                                            if ((p.customer?.zip_data || '') !== '') {
                                                                                                origin = `${p.customer.zip_data.latitude.toString()},${p.customer.zip_data.longitude.toString()}`;
                                                                                            }
                                                                                        }
                                                                                        return false;
                                                                                    })
                                                                                } else {
                                                                                    selected_order.deliveries.map((d, i) => {
                                                                                        if (d.id === start.delivery_id) {
                                                                                            if ((d.customer?.zip_data || '') !== '') {
                                                                                                origin = `${d.customer.zip_data.latitude.toString()},${d.customer.zip_data.longitude.toString()}`;
                                                                                            }
                                                                                        }
                                                                                        return false;
                                                                                    })
                                                                                }

                                                                                selected_order.routing.map((item, i) => {
                                                                                    if (i > 0 && i < (selected_order.routing.length - 1)) {
                                                                                        if (item.type === 'pickup') {
                                                                                            selected_order.pickups.map((p, i) => {
                                                                                                if (p.id === item.pickup_id) {
                                                                                                    if ((p.customer?.zip_data || '') !== '') {
                                                                                                        waypoints.push(`${p.customer.zip_data.latitude.toString()},${p.customer.zip_data.longitude.toString()}`);
                                                                                                    }
                                                                                                }
                                                                                                return false;
                                                                                            })
                                                                                        } else {
                                                                                            selected_order.deliveries.map((d, i) => {
                                                                                                if (d.id === item.delivery_id) {
                                                                                                    if ((d.customer?.zip_data || '') !== '') {
                                                                                                        waypoints.push(`${d.customer.zip_data.latitude.toString()},${d.customer.zip_data.longitude.toString()}`);
                                                                                                    }
                                                                                                }
                                                                                                return false;
                                                                                            })
                                                                                        }
                                                                                    }

                                                                                    return true;
                                                                                });

                                                                                if (end.type === 'pickup') {
                                                                                    selected_order.pickups.map((p, i) => {
                                                                                        if (p.id === end.pickup_id) {
                                                                                            if ((p.customer?.zip_data || '') !== '') {
                                                                                                destination = `${p.customer.zip_data.latitude.toString()},${p.customer.zip_data.longitude.toString()}`;
                                                                                            }
                                                                                        }
                                                                                        return false;
                                                                                    })
                                                                                } else {
                                                                                    selected_order.deliveries.map((d, i) => {
                                                                                        if (d.id === end.delivery_id) {
                                                                                            if ((d.customer?.zip_data || '') !== '') {
                                                                                                destination = `${d.customer.zip_data.latitude.toString()},${d.customer.zip_data.longitude.toString()}`;
                                                                                            }
                                                                                        }
                                                                                        return false;
                                                                                    })
                                                                                }

                                                                                let params = {
                                                                                    'routingMode': 'fast',
                                                                                    'transportMode': 'car',
                                                                                    'origin': origin,
                                                                                    'via': new H.service.Url.MultiValueQueryParameter(waypoints),
                                                                                    'destination': destination,
                                                                                    'return': 'summary'
                                                                                }

                                                                                if (routingService) {
                                                                                    routingService.calculateRoute(
                                                                                        params,
                                                                                        (result) => {
                                                                                            let miles = (result?.routes[0]?.sections || []).reduce((a, b) => {
                                                                                                return a + b.summary.length;
                                                                                            }, 0) || 0;

                                                                                            selected_order.miles = miles;

                                                                                            setSelectedOrder(selected_order);
                                                                                            setMileageLoaderVisible(false);

                                                                                            axios
                                                                                                .post(
                                                                                                    props.serverUrl + "/saveOrder",
                                                                                                    selected_order
                                                                                                )
                                                                                                .then(async (res) => {
                                                                                                    if (res.data.result === "OK") {
                                                                                                        setSelectedOrder({
                                                                                                            ...selected_order,
                                                                                                            order_customer_ratings:
                                                                                                                res.data.order
                                                                                                                    .order_customer_ratings,
                                                                                                            order_carrier_ratings:
                                                                                                                res.data.order
                                                                                                                    .order_carrier_ratings,
                                                                                                        });

                                                                                                        props.setSelectedOrder({
                                                                                                            ...selected_order,
                                                                                                            order_customer_ratings:
                                                                                                                res.data.order
                                                                                                                    .order_customer_ratings,
                                                                                                            order_carrier_ratings:
                                                                                                                res.data.order
                                                                                                                    .order_carrier_ratings,
                                                                                                            component_id:
                                                                                                                props.componentId,
                                                                                                        });
                                                                                                    }
                                                                                                })
                                                                                                .catch((e) => {
                                                                                                    console.log("error on saving order miles", e);
                                                                                                    setMileageLoaderVisible(false);
                                                                                                });
                                                                                        },
                                                                                        (error) => {
                                                                                            console.log("error getting mileage", error);
                                                                                            selected_order.miles = 0;

                                                                                            setSelectedOrder(selected_order);
                                                                                            setMileageLoaderVisible(false);

                                                                                            axios.post(props.serverUrl + "/saveOrder", selected_order).then(async (res) => {
                                                                                                if (res.data.result === "OK") {
                                                                                                    setSelectedOrder({
                                                                                                        ...selected_order,
                                                                                                        order_customer_ratings: res.data.order.order_customer_ratings,
                                                                                                        order_carrier_ratings: res.data.order.order_carrier_ratings,
                                                                                                    });

                                                                                                    props.setSelectedOrder({
                                                                                                        ...selected_order,
                                                                                                        order_customer_ratings: res.data.order.order_customer_ratings,
                                                                                                        order_carrier_ratings: res.data.order.order_carrier_ratings,
                                                                                                        component_id: props.componentId,
                                                                                                    });
                                                                                                }
                                                                                            })
                                                                                                .catch((e) => {
                                                                                                    console.log("error on saving order miles", e);
                                                                                                    setMileageLoaderVisible(false);
                                                                                                });
                                                                                        }
                                                                                    );
                                                                                }


                                                                            } else {
                                                                                selected_order.miles = 0;
                                                                                setSelectedOrder(selected_order);
                                                                                setMileageLoaderVisible(false);

                                                                                axios
                                                                                    .post(
                                                                                        props.serverUrl + "/saveOrder",
                                                                                        selected_order
                                                                                    )
                                                                                    .then(async (res) => {
                                                                                        if (res.data.result === "OK") {
                                                                                            setSelectedOrder({
                                                                                                ...selected_order,
                                                                                                order_customer_ratings:
                                                                                                    res.data.order
                                                                                                        .order_customer_ratings,
                                                                                                order_carrier_ratings:
                                                                                                    res.data.order
                                                                                                        .order_carrier_ratings,
                                                                                            });

                                                                                            props.setSelectedOrder({
                                                                                                ...selected_order,
                                                                                                order_customer_ratings:
                                                                                                    res.data.order
                                                                                                        .order_customer_ratings,
                                                                                                order_carrier_ratings:
                                                                                                    res.data.order
                                                                                                        .order_carrier_ratings,
                                                                                                component_id: props.componentId,
                                                                                            });
                                                                                        }
                                                                                    })
                                                                                    .catch((e) => {
                                                                                        console.log("error on saving order miles", e);
                                                                                        setMileageLoaderVisible(false);
                                                                                    });
                                                                            }
                                                                        })
                                                                            .catch((e) => {
                                                                                setMileageLoaderVisible(false);
                                                                                console.log("error saving order miles", e);
                                                                            });
                                                                    } else {
                                                                        if (selected_order.routing.length >= 2) {
                                                                            setMileageLoaderVisible(true);

                                                                            let origin = null;
                                                                            let destination = null;

                                                                            let start = selected_order.routing[0];
                                                                            let waypoints = [];
                                                                            let end = selected_order.routing[selected_order.routing.length - 1];

                                                                            if (start.type === 'pickup') {
                                                                                selected_order.pickups.map((p, i) => {
                                                                                    if (p.id === start.pickup_id) {
                                                                                        if ((p.customer?.zip_data || '') !== '') {
                                                                                            origin = `${p.customer.zip_data.latitude.toString()},${p.customer.zip_data.longitude.toString()}`;
                                                                                        }
                                                                                    }
                                                                                    return false;
                                                                                })
                                                                            } else {
                                                                                selected_order.deliveries.map((d, i) => {
                                                                                    if (d.id === start.delivery_id) {
                                                                                        if ((d.customer?.zip_data || '') !== '') {
                                                                                            origin = `${d.customer.zip_data.latitude.toString()},${d.customer.zip_data.longitude.toString()}`;
                                                                                        }
                                                                                    }
                                                                                    return false;
                                                                                })
                                                                            }

                                                                            selected_order.routing.map((item, i) => {
                                                                                if (i > 0 && i < (selected_order.routing.length - 1)) {
                                                                                    if (item.type === 'pickup') {
                                                                                        selected_order.pickups.map((p, i) => {
                                                                                            if (p.id === item.pickup_id) {
                                                                                                if ((p.customer?.zip_data || '') !== '') {
                                                                                                    waypoints.push(`${p.customer.zip_data.latitude.toString()},${p.customer.zip_data.longitude.toString()}`);
                                                                                                }
                                                                                            }
                                                                                            return false;
                                                                                        })
                                                                                    } else {
                                                                                        selected_order.deliveries.map((d, i) => {
                                                                                            if (d.id === item.delivery_id) {
                                                                                                if ((d.customer?.zip_data || '') !== '') {
                                                                                                    waypoints.push(`${d.customer.zip_data.latitude.toString()},${d.customer.zip_data.longitude.toString()}`);
                                                                                                }
                                                                                            }
                                                                                            return false;
                                                                                        })
                                                                                    }
                                                                                }

                                                                                return true;
                                                                            });

                                                                            if (end.type === 'pickup') {
                                                                                selected_order.pickups.map((p, i) => {
                                                                                    if (p.id === end.pickup_id) {
                                                                                        if ((p.customer?.zip_data || '') !== '') {
                                                                                            destination = `${p.customer.zip_data.latitude.toString()},${p.customer.zip_data.longitude.toString()}`;
                                                                                        }
                                                                                    }
                                                                                    return false;
                                                                                })
                                                                            } else {
                                                                                selected_order.deliveries.map((d, i) => {
                                                                                    if (d.id === end.delivery_id) {
                                                                                        if ((d.customer?.zip_data || '') !== '') {
                                                                                            destination = `${d.customer.zip_data.latitude.toString()},${d.customer.zip_data.longitude.toString()}`;
                                                                                        }
                                                                                    }
                                                                                    return false;
                                                                                })
                                                                            }

                                                                            let params = {
                                                                                'routingMode': 'fast',
                                                                                'transportMode': 'car',
                                                                                'origin': origin,
                                                                                'via': new H.service.Url.MultiValueQueryParameter(waypoints),
                                                                                'destination': destination,
                                                                                'return': 'summary'
                                                                            }

                                                                            if (routingService) {
                                                                                routingService.calculateRoute(
                                                                                    params,
                                                                                    (result) => {
                                                                                        let miles = (result?.routes[0]?.sections || []).reduce((a, b) => {
                                                                                            return a + b.summary.length;
                                                                                        }, 0) || 0;

                                                                                        selected_order.miles = miles;

                                                                                        setSelectedOrder(selected_order);
                                                                                        setMileageLoaderVisible(false);

                                                                                        axios.post(props.serverUrl + "/saveOrder", selected_order).then(async (res) => {
                                                                                            if (res.data.result === "OK") {
                                                                                                setSelectedOrder({
                                                                                                    ...selected_order,
                                                                                                    order_customer_ratings: res.data.order.order_customer_ratings,
                                                                                                    order_carrier_ratings: res.data.order.order_carrier_ratings,
                                                                                                });

                                                                                                props.setSelectedOrder({
                                                                                                    ...selected_order,
                                                                                                    order_customer_ratings: res.data.order.order_customer_ratings,
                                                                                                    order_carrier_ratings: res.data.order.order_carrier_ratings,
                                                                                                    component_id: props.componentId,
                                                                                                });
                                                                                            }
                                                                                        }).catch((e) => {
                                                                                            console.log("error on saving order miles", e);
                                                                                            setMileageLoaderVisible(false);
                                                                                        });
                                                                                    },
                                                                                    (error) => {
                                                                                        console.log("error getting mileage", error);
                                                                                        selected_order.miles = 0;
                                                                                        setSelectedOrder(selected_order);
                                                                                        setMileageLoaderVisible(false);

                                                                                        axios.post(props.serverUrl + "/saveOrder", selected_order).then(async (res) => {
                                                                                            if (res.data.result === "OK") {
                                                                                                setSelectedOrder({
                                                                                                    ...selected_order,
                                                                                                    order_customer_ratings: res.data.order.order_customer_ratings,
                                                                                                    order_carrier_ratings: res.data.order.order_carrier_ratings,
                                                                                                });

                                                                                                props.setSelectedOrder({
                                                                                                    ...selected_order,
                                                                                                    order_customer_ratings: res.data.order.order_customer_ratings,
                                                                                                    order_carrier_ratings: res.data.order.order_carrier_ratings,
                                                                                                    component_id: props.componentId,
                                                                                                });
                                                                                            }
                                                                                        }).catch((e) => {
                                                                                            console.log("error on saving order miles", e);
                                                                                            setMileageLoaderVisible(false);
                                                                                        });
                                                                                    }
                                                                                );
                                                                            }
                                                                        } else {
                                                                            selected_order.miles = 0;
                                                                            setSelectedOrder(selected_order);
                                                                            setMileageLoaderVisible(false);

                                                                            axios
                                                                                .post(
                                                                                    props.serverUrl + "/saveOrder",
                                                                                    selected_order
                                                                                )
                                                                                .then(async (res) => {
                                                                                    if (res.data.result === "OK") {
                                                                                        props.setSelectedOrder({
                                                                                            ...selected_order,
                                                                                            order_customer_ratings:
                                                                                                res.data.order
                                                                                                    .order_customer_ratings,
                                                                                            order_carrier_ratings:
                                                                                                res.data.order
                                                                                                    .order_carrier_ratings,
                                                                                            component_id: props.componentId,
                                                                                        });
                                                                                    }
                                                                                })
                                                                                .catch((e) => {
                                                                                    console.log("error on saving order miles", e);
                                                                                    setMileageLoaderVisible(false);
                                                                                });
                                                                        }
                                                                    }
                                                                } else {
                                                                    // GUARDAR ORDEN CON MILES = 0;
                                                                    selected_order.miles = 0;
                                                                    setSelectedOrder(selected_order);
                                                                    setMileageLoaderVisible(false);

                                                                    axios
                                                                        .post(
                                                                            props.serverUrl + "/saveOrder",
                                                                            selected_order
                                                                        )
                                                                        .then(async (res) => {
                                                                            if (res.data.result === "OK") {
                                                                                props.setSelectedOrder({
                                                                                    ...selected_order,
                                                                                    order_customer_ratings:
                                                                                        res.data.order.order_customer_ratings,
                                                                                    order_carrier_ratings:
                                                                                        res.data.order.order_carrier_ratings,
                                                                                    component_id: props.componentId,
                                                                                });
                                                                            }
                                                                        })
                                                                        .catch((e) => {
                                                                            console.log("error on saving order miles", e);
                                                                            setMileageLoaderVisible(false);
                                                                        });
                                                                }
                                                            } else {

                                                            }
                                                        })
                                                            .catch((e) => {
                                                                setMileageLoaderVisible(false);
                                                                console.log("error on removing order delivery", e);
                                                            });
                                                    }}
                                                >
                                                    <span className="fas fa-times"></span>
                                                </div>
                                            }
                                        </SwiperSlide>
                                    );
                                }
                            })}

                            {
                                ((selectedOrder?.deliveries || []).find((d) => d.id === 0) === undefined && (selectedOrder?.is_cancelled || 0) === 0) &&
                                (<SwiperSlide
                                    className="order-delivery adding"
                                    title="Add new delivery"
                                    onClick={() => {
                                        if ((selectedOrder?.id || 0) === 0) {
                                            window.alert("You must create or load an order first!");
                                            setSelectedConsigneeCustomer({});
                                            setSelectedConsigneeCustomerContact({});
                                            return;
                                        }

                                        let deliveries = selectedOrder?.deliveries || [];
                                        deliveries.push({ id: 0 });
                                        setSelectedConsigneeCustomer({ id: 0, delivery_id: 0 });
                                        setSelectedConsigneeCustomerContact({});
                                        setSelectedOrder({
                                            ...selectedOrder,
                                            deliveries: deliveries,
                                        });

                                        refConsigneeCompanyCode.current.focus();
                                    }}
                                >
                                    <div>
                                        <span className="fas fa-plus"></span>
                                    </div>
                                </SwiperSlide>)
                            }
                        </Swiper>

                        <div className="swiper-delivery-next-btn">
                            <span className="fas fa-chevron-right"></span>
                        </div>
                    </div>
                </div>

                {(selectedOrder?.id || 0) > 0
                    ? (selectedOrder?.order_invoiced || 0) === 1
                        ? <div className="order-invoiced-btn" onClick={() => {
                            let panel = {
                                panelName: `${props.panelName}-invoice`,
                                component: <Invoice
                                    pageName={'Invoice'}
                                    title={'Invoice'}
                                    panelName={`${props.panelName}-invoice`}
                                    tabTimes={5000 + props.tabTimes}
                                    screenFocused={props.invoiceScreenFocused}
                                    componentId={moment().format('x')}
                                    isOnPanel={true}
                                    origin={props.origin}
                                    closingCallback={() => {
                                        closePanel(`${props.panelName}-invoice`, props.origin);
                                        (props.isOnPanel ? refOrderNumber : props.refOrderNumber).current.focus({ preventScroll: true });
                                    }}

                                    order_id={(selectedOrder?.id || 0)}
                                />
                            }

                            openPanel(panel, props.origin);
                        }}>Invoiced</div>
                        : (selectedOrder?.is_cancelled || 0) === 0
                            ? <div className="mochi-button" style={{ marginLeft: 80 }} onClick={() => {
                                if (window.confirm("Are you sure you want to proceed?")) {
                                    axios.post(props.serverUrl + '/saveOrder', {
                                        ...selectedOrder,
                                        is_cancelled: 1
                                    }).then(res => {
                                        if (res.data.result === 'OK') {
                                            setSelectedOrder(prev => {
                                                return {
                                                    ...prev,
                                                    is_cancelled: res.data?.order?.is_cancelled || 0
                                                }
                                            })
                                        }
                                    }).catch((e) => {
                                        console.log(e);
                                    })
                                }
                            }}>
                                <div className="mochi-button-decorator mochi-button-decorator-left">(</div>
                                <div className="mochi-button-base">Cancel Order</div>
                                <div className="mochi-button-decorator mochi-button-decorator-right">)</div>
                            </div>
                            : (selectedOrder?.is_cancelled || 0) === 1
                                ? <div className="order-cancelled-btn" onClick={() => {
                                }}>Cancelled</div>
                                : ''
                    : ''
                }

            </div>

            <div className="fields-container-row" style={{ marginTop: 10 }}>
                <div
                    className="fields-container-col"
                    style={{
                        minWidth: "91%",
                        maxWidth: "91%",
                        display: "flex",
                        flexDirection: "column",
                        marginRight: 10,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginBottom: 10,
                            flexGrow: 1,
                            flexBasis: "100%",
                        }}
                    >
                        <div className="form-bordered-box" style={{
                            minWidth: "38%",
                            maxWidth: "38%",
                            marginRight: 10,
                            height: "9rem",
                            position: "relative",
                        }}>
                            <div className="form-header">
                                <div className="top-border top-border-left"></div>
                                <div className="form-title" style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 10,
                                    alignItems: 'center'
                                }}>
                                    <span>Shipper</span>
                                    <FontAwesomeIcon icon={faPlus} style={{ cursor: 'pointer' }} onClick={() => {
                                        if ((selectedOrder?.pickups || []).find(x => x.id === 0)) {
                                            const newPickups = (selectedOrder?.pickups || []).filter(x => x.id !== 0);

                                            setSelectedOrder(prev => {
                                                return {
                                                    ...prev,
                                                    pickups: newPickups
                                                }
                                            })

                                            if (newPickups.length > 0) {
                                                setSelectedShipperCustomer({
                                                    ...newPickups[0].customer,
                                                    pickup_id: newPickups[0].id
                                                })

                                                setSelectedShipperCustomerContact((newPickups[0].customer?.contacts || []).find(c => c.is_primary === 1) || {})
                                            }
                                        }

                                        let panel = {
                                            panelName: `${props.panelName}-customer`,
                                            component: (
                                                <Customers
                                                    pageName={"Customer"}
                                                    title={"Shipper Company"}
                                                    panelName={`${props.panelName}-customer`}
                                                    tabTimes={3000 + props.tabTimes}
                                                    componentId={moment().format("x")}
                                                    isOnPanel={true}
                                                    isAdmin={props.isAdmin}
                                                    origin={props.origin}
                                                    suborigin='dispatch shipper'
                                                    onCustomerChangeCallback={(customer) => {
                                                        // checking if the order is already created
                                                        if ((selectedOrder?.id || 0) > 0) {
                                                            // checking if the customer is already selected as shipper
                                                            if ((selectedShipperCustomer?.id || 0) === customer.id) {
                                                                setSelectedOrder(prev => {
                                                                    return {
                                                                        ...prev,
                                                                        pickups: (prev?.pickups || []).map((p, i) => {
                                                                            if (p.id === (selectedShipperCustomer?.pickup_id || 0)) {
                                                                                p.customer = customer;
                                                                                p.customer_id = customer.id;

                                                                                setSelectedShipperCustomer(prev => {
                                                                                    return {
                                                                                        ...prev,
                                                                                        ...customer,
                                                                                        customer: {}
                                                                                    }
                                                                                })

                                                                                setSelectedShipperCustomerContact((customer.contacts || []).find((c) => c.is_primary === 1) || {})
                                                                            }
                                                                            return p;
                                                                        })
                                                                    }
                                                                })

                                                                setIsShowingShipperSecondPage(true);
                                                            } else { // if the customer is not selected as shipper, is added as new pickup and selected as shipper
                                                                setSelectedOrder((prev) => {
                                                                    return {
                                                                        ...prev,
                                                                        pickups: [
                                                                            ...(prev?.pickups || []),
                                                                            {
                                                                                id: 0,
                                                                                order_id: prev.id,
                                                                                customer: { ...customer },
                                                                                customer_id: customer.id
                                                                            }
                                                                        ]
                                                                    };
                                                                });

                                                                setSelectedShipperCustomer({
                                                                    ...customer,
                                                                    customer: {},
                                                                    pickup_id: 0
                                                                })

                                                                setSelectedShipperCustomerContact((customer?.contacts || []).find((c) => c.is_primary === 1) || {})
                                                                setIsShowingShipperSecondPage(true)
                                                                setIsSavingPickupId(0)
                                                            }
                                                        } else {
                                                            // if the order is not created yet, check if the customer has the Bill To Code filled
                                                            // and proceed to create the order with the selected customer as bill to customer and shipper
                                                            if ((customer?.bill_to_code || '') !== '') {
                                                                setIsLoading(true);
                                                                const billToCode = (customer?.bill_to_code || '') + ((customer?.bill_to_code_number || 0) === 0 ? '' : customer.bill_to_code_number)

                                                                axios.post(props.serverUrl + "/getCustomerByCode", {
                                                                    code: billToCode,
                                                                    user_code: (props.user?.user_code?.type || 'employee') === 'agent' ? props.user.user_code.code : ''
                                                                }).then((res) => {
                                                                    if (res.data.result === "OK") {
                                                                        setSelectedBillToCustomer(res.data.customer);
                                                                        setSelectedBillToCustomerContact((res.data.customer.contacts || []).find((c) => c.is_primary === 1) || {});

                                                                        setSelectedShipperCustomer({
                                                                            ...customer,
                                                                            customer: {},
                                                                            pickup_id: 0
                                                                        })

                                                                        setSelectedShipperCustomerContact((customer?.contacts || []).find((c) => c.is_primary === 1) || {})

                                                                        setSelectedOrder((selectedOrder) => {
                                                                            return {
                                                                                ...selectedOrder,
                                                                                bill_to_customer_id: res.data.customer.id,
                                                                                pickups: [
                                                                                    {
                                                                                        toSave: true,
                                                                                        id: 0,
                                                                                        customer: { ...customer },
                                                                                        customer_id: customer.id
                                                                                    }
                                                                                ]
                                                                            };
                                                                        });

                                                                        validateOrderForSaving({ keyCode: 9 });

                                                                        setIsShowingShipperSecondPage(true);
                                                                    }
                                                                    setIsLoading(false);
                                                                }).catch((e) => {
                                                                    console.log("error getting customers", e);
                                                                    setIsLoading(false);
                                                                });
                                                            }
                                                        }
                                                    }}
                                                    closingCallback={() => {
                                                        closePanel(`${props.panelName}-customer`, props.origin);
                                                        (props.isOnPanel ? refOrderNumber : props.refOrderNumber).current.focus({ preventScroll: true });
                                                    }}
                                                />
                                            ),
                                        };

                                        openPanel(panel, props.origin);
                                    }} />
                                </div>
                                <div className="top-border top-border-middle"></div>
                                <div className="form-buttons">
                                    {
                                        ((selectedOrder?.id || 0) > 0 && (selectedOrder?.load_type_id || 0) === 2) &&
                                        <div className="mochi-button" onClick={() => {
                                            let panel = {
                                                panelName: `${props.panelName}-ltl-units`,
                                                component: (
                                                    <Ltl
                                                        title="LTL Units & Accessorials"
                                                        tabTimes={1546484 + props.tabTimes}
                                                        panelName={`${props.panelName}-ltl`}
                                                        origin={props.origin}
                                                        componentId={moment().format("x")}
                                                        selectedOrderId={selectedOrder.id}
                                                        setSelectedOrder={setSelectedOrder}
                                                        onEscCallback={() => {
                                                            closePanel(`${props.panelName}-ltl-units`, props.origin);
                                                            refConsigneeCompanyCode.current.focus();
                                                            setIsShowingShipperSecondPage(false);
                                                        }}
                                                    />
                                                ),
                                            };

                                            openPanel(panel, props.origin);
                                        }}>
                                            <div className="mochi-button-decorator mochi-button-decorator-left">(</div>
                                            <div className="mochi-button-base">LTL Units</div>
                                            <div className="mochi-button-decorator mochi-button-decorator-right">)</div>
                                        </div>
                                    }
                                    <div className="mochi-button" onClick={shipperCompanySearch} style={{
                                        pointerEvents: (selectedOrder?.is_cancelled || 0) === 0 ? 'all' : 'none'
                                    }}>
                                        <div className="mochi-button-decorator mochi-button-decorator-left">(</div>
                                        <div className="mochi-button-base" style={{
                                            color: (selectedOrder?.is_cancelled || 0) === 0 ? 'black' : 'rgba(0,0,0,0.4)'
                                        }}>Search
                                        </div>
                                        <div className="mochi-button-decorator mochi-button-decorator-right">)</div>
                                    </div>

                                    <div className="mochi-button" onClick={() => {
                                        if ((selectedShipperCustomer.id || 0) === 0) {
                                            window.alert("You must select a customer first!");
                                            return;
                                        }

                                        let panel = {
                                            panelName: `${props.panelName}-customer`,
                                            component: (
                                                <Customers
                                                    pageName={"Customer"}
                                                    title={"Shipper Company"}
                                                    panelName={`${props.panelName}-customer`}
                                                    tabTimes={3000 + props.tabTimes}
                                                    componentId={moment().format("x")}
                                                    isOnPanel={true}
                                                    isAdmin={props.isAdmin}
                                                    origin={props.origin}
                                                    closingCallback={() => {
                                                        closePanel(`${props.panelName}-customer`, props.origin);
                                                        (props.isOnPanel ? refOrderNumber : props.refOrderNumber).current.focus({ preventScroll: true });
                                                    }}

                                                    customer_id={selectedShipperCustomer.id}
                                                />
                                            ),
                                        };

                                        openPanel(panel, props.origin);
                                    }}>
                                        <div className="mochi-button-decorator mochi-button-decorator-left">(</div>
                                        <div className="mochi-button-base">Company info</div>
                                        <div className="mochi-button-decorator mochi-button-decorator-right">)</div>
                                    </div>
                                    {isShowingShipperSecondPage && (
                                        <div className="mochi-button" onClick={() => {
                                            setIsShowingShipperSecondPage(false)
                                        }}>
                                            <div className="mochi-button-decorator mochi-button-decorator-left">(</div>
                                            <div className="mochi-button-base">1st Page</div>
                                            <div className="mochi-button-decorator mochi-button-decorator-right">)</div>
                                        </div>
                                    )}
                                    {!isShowingShipperSecondPage && (
                                        <div className="mochi-button" onClick={() => {
                                            setIsShowingShipperSecondPage(true)
                                        }}>
                                            <div className="mochi-button-decorator mochi-button-decorator-left">(</div>
                                            <div className="mochi-button-base">2nd Page</div>
                                            <div className="mochi-button-decorator mochi-button-decorator-right">)</div>
                                        </div>
                                    )}
                                </div>
                                <div className="top-border top-border-right"></div>
                            </div>

                            <div className="form-wrapper" style={{ overflowX: "hidden", overflowY: "visible" }}>
                                <div className="form-row">
                                    <div className="input-box-container input-code">
                                        <input
                                            tabIndex={16 + props.tabTimes}
                                            type="text"
                                            placeholder="Code"
                                            maxLength="8"
                                            ref={refShipperCompanyCode}
                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                            onKeyDown={(e) => {
                                                if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                    getShipperCompanyByCode(e);
                                                }
                                            }}
                                            onInput={(e) => {
                                                if ((selectedShipperCustomer?.id || 0) === 0) {
                                                    if ((selectedOrder?.pickups || []).find((p) => p.id === 0) === undefined) {
                                                        setSelectedOrder({
                                                            ...selectedOrder,
                                                            pickups: [
                                                                ...(selectedOrder?.pickups || []),
                                                                {
                                                                    id: 0,
                                                                    customer: {
                                                                        id: 0,
                                                                        code: e.target.value.toUpperCase(),
                                                                    },
                                                                },
                                                            ],
                                                        });
                                                    } else {
                                                        setSelectedOrder({
                                                            ...selectedOrder,
                                                            pickups: (selectedOrder?.pickups || []).map(
                                                                (p, i) => {
                                                                    if (p.id === 0) {
                                                                        p.customer = {
                                                                            ...p.customer,
                                                                            code: e.target.value.toUpperCase(),
                                                                        };
                                                                    }
                                                                    return p;
                                                                }
                                                            ),
                                                        });
                                                    }

                                                    setSelectedShipperCustomer({
                                                        ...selectedShipperCustomer,
                                                        code: e.target.value.toUpperCase(),
                                                        code_number: 0,
                                                    });
                                                } else {
                                                    setSelectedOrder({
                                                        ...selectedOrder,
                                                        pickups: (selectedOrder?.pickups || []).map(
                                                            (p, i) => {
                                                                if (p.id === selectedShipperCustomer?.id) {
                                                                    p.customer = {
                                                                        ...p.customer,
                                                                        code: e.target.value.toUpperCase(),
                                                                    };
                                                                }
                                                                return p;
                                                            }
                                                        ),
                                                    });

                                                    setSelectedShipperCustomer({
                                                        ...selectedShipperCustomer,
                                                        code: e.target.value.toUpperCase(),
                                                        code_number: 0,
                                                    });
                                                }
                                            }}
                                            onChange={(e) => {
                                                if ((selectedShipperCustomer?.id || 0) === 0) {
                                                    if (
                                                        (selectedOrder?.pickups || []).find(
                                                            (p) => p.id === 0
                                                        ) === undefined
                                                    ) {
                                                        setSelectedOrder({
                                                            ...selectedOrder,
                                                            pickups: [
                                                                ...(selectedOrder?.pickups || []),
                                                                {
                                                                    id: 0,
                                                                    customer: {
                                                                        id: 0,
                                                                        code: e.target.value.toUpperCase(),
                                                                    },
                                                                },
                                                            ],
                                                        });
                                                    } else {
                                                        setSelectedOrder({
                                                            ...selectedOrder,
                                                            pickups: (selectedOrder?.pickups || []).map(
                                                                (p, i) => {
                                                                    if (p.id === 0) {
                                                                        p.customer = {
                                                                            ...p.customer,
                                                                            code: e.target.value.toUpperCase(),
                                                                        };
                                                                    }
                                                                    return p;
                                                                }
                                                            ),
                                                        });
                                                    }

                                                    setSelectedShipperCustomer({
                                                        ...selectedShipperCustomer,
                                                        code: e.target.value.toUpperCase(),
                                                        code_number: 0,
                                                    });
                                                } else {
                                                    setSelectedOrder({
                                                        ...selectedOrder,
                                                        pickups: (selectedOrder?.pickups || []).map(
                                                            (p, i) => {
                                                                if (p.id === selectedShipperCustomer?.id) {
                                                                    p.customer = {
                                                                        ...p.customer,
                                                                        code: e.target.value.toUpperCase(),
                                                                    };
                                                                }
                                                                return p;
                                                            }
                                                        ),
                                                    });

                                                    setSelectedShipperCustomer({
                                                        ...selectedShipperCustomer,
                                                        code: e.target.value.toUpperCase(),
                                                        code_number: 0,
                                                    });
                                                }
                                            }}
                                            value={
                                                (selectedShipperCustomer.code_number || 0) === 0
                                                    ? selectedShipperCustomer.code || ""
                                                    : selectedShipperCustomer.code +
                                                    selectedShipperCustomer.code_number
                                            }
                                        />
                                    </div>
                                    <div className="form-h-sep"></div>
                                    <div className="input-box-container grow">
                                        <input
                                            tabIndex={17 + props.tabTimes}
                                            type="text"
                                            placeholder="Name"
                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                            onInput={(e) => {
                                                if ((selectedShipperCustomer?.id || 0) === 0) {
                                                    if (
                                                        (selectedOrder?.pickups || []).find(
                                                            (p) => p.id === 0
                                                        ) === undefined
                                                    ) {
                                                        setSelectedOrder({
                                                            ...selectedOrder,
                                                            pickups: [
                                                                ...(selectedOrder?.pickups || []),
                                                                {
                                                                    id: 0,
                                                                    customer: {
                                                                        id: 0,
                                                                        name: e.target.value,
                                                                    },
                                                                },
                                                            ],
                                                        });
                                                    } else {
                                                        setSelectedOrder({
                                                            ...selectedOrder,
                                                            pickups: (selectedOrder?.pickups || []).map(
                                                                (p, i) => {
                                                                    if (p.id === 0) {
                                                                        p.customer = {
                                                                            ...p.customer,
                                                                            name: e.target.value,
                                                                        };
                                                                    }
                                                                    return p;
                                                                }
                                                            ),
                                                        });
                                                    }

                                                    setSelectedShipperCustomer({
                                                        ...selectedShipperCustomer,
                                                        id: 0,
                                                        name: e.target.value,
                                                    });
                                                } else {
                                                    setSelectedOrder({
                                                        ...selectedOrder,
                                                        pickups: (selectedOrder?.pickups || []).map(
                                                            (p, i) => {
                                                                if (p.id === selectedShipperCustomer?.id) {
                                                                    p.customer = {
                                                                        ...p.customer,
                                                                        name: e.target.value,
                                                                    };
                                                                }
                                                                return p;
                                                            }
                                                        ),
                                                    });

                                                    setSelectedShipperCustomer({
                                                        ...selectedShipperCustomer,
                                                        name: e.target.value,
                                                    });
                                                }
                                            }}
                                            onChange={(e) => {
                                                if ((selectedShipperCustomer?.id || 0) === 0) {
                                                    if (
                                                        (selectedOrder?.pickups || []).find(
                                                            (p) => p.id === 0
                                                        ) === undefined
                                                    ) {
                                                        setSelectedOrder({
                                                            ...selectedOrder,
                                                            pickups: [
                                                                ...(selectedOrder?.pickups || []),
                                                                {
                                                                    id: 0,
                                                                    customer: {
                                                                        id: 0,
                                                                        name: e.target.value,
                                                                    },
                                                                },
                                                            ],
                                                        });
                                                    } else {
                                                        setSelectedOrder({
                                                            ...selectedOrder,
                                                            pickups: (selectedOrder?.pickups || []).map(
                                                                (p, i) => {
                                                                    if (p.id === 0) {
                                                                        p.customer = {
                                                                            ...p.customer,
                                                                            name: e.target.value,
                                                                        };
                                                                    }
                                                                    return p;
                                                                }
                                                            ),
                                                        });
                                                    }

                                                    setSelectedShipperCustomer({
                                                        ...selectedShipperCustomer,
                                                        id: 0,
                                                        name: e.target.value,
                                                    });
                                                } else {
                                                    setSelectedOrder({
                                                        ...selectedOrder,
                                                        pickups: (selectedOrder?.pickups || []).map(
                                                            (p, i) => {
                                                                if (p.id === selectedShipperCustomer.id) {
                                                                    p.customer = {
                                                                        ...p.customer,
                                                                        name: e.target.value,
                                                                    };
                                                                }
                                                                return p;
                                                            }
                                                        ),
                                                    });

                                                    setSelectedShipperCustomer({
                                                        ...selectedShipperCustomer,
                                                        name: e.target.value,
                                                    });
                                                }
                                            }}
                                            value={selectedShipperCustomer?.name || ""}
                                        />
                                    </div>
                                </div>

                                {shipperFirstPageTransition(
                                    (style, item) =>
                                        item && (
                                            <animated.div
                                                className="form-page first-page shipper-first-page"
                                                style={{
                                                    ...style,
                                                }}
                                            >
                                                <div className="form-row">
                                                    <div className="input-box-container grow">
                                                        <input
                                                            tabIndex={18 + props.tabTimes}
                                                            type="text"
                                                            placeholder="Address 1"
                                                            style={{ textTransform: "capitalize" }}
                                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                            onInput={(e) => {
                                                                if ((selectedShipperCustomer?.id || 0) === 0) {
                                                                    if (
                                                                        (selectedOrder?.pickups || []).find(
                                                                            (p) => p.id === 0
                                                                        ) === undefined
                                                                    ) {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            pickups: [
                                                                                ...(selectedOrder?.pickups || []),
                                                                                {
                                                                                    id: 0,
                                                                                    customer: {
                                                                                        id: 0,
                                                                                        address1: e.target.value,
                                                                                    },
                                                                                },
                                                                            ],
                                                                        });
                                                                    } else {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            pickups: (
                                                                                selectedOrder?.pickups || []
                                                                            ).map((p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.customer = {
                                                                                        ...p.customer,
                                                                                        address1: e.target.value,
                                                                                    };
                                                                                }
                                                                                return p;
                                                                            }),
                                                                        });
                                                                    }

                                                                    setSelectedShipperCustomer({
                                                                        ...selectedShipperCustomer,
                                                                        id: 0,
                                                                        address1: e.target.value,
                                                                    });
                                                                } else {
                                                                    setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        pickups: (selectedOrder?.pickups || []).map(
                                                                            (p, i) => {
                                                                                if (
                                                                                    p.id === selectedShipperCustomer?.id
                                                                                ) {
                                                                                    p.customer = {
                                                                                        ...p.customer,
                                                                                        address1: e.target.value,
                                                                                    };
                                                                                }
                                                                                return p;
                                                                            }
                                                                        ),
                                                                    });

                                                                    setSelectedShipperCustomer({
                                                                        ...selectedShipperCustomer,
                                                                        address1: e.target.value,
                                                                    });
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                if ((selectedShipperCustomer?.id || 0) === 0) {
                                                                    if (
                                                                        (selectedOrder?.pickups || []).find(
                                                                            (p) => p.id === 0
                                                                        ) === undefined
                                                                    ) {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            pickups: [
                                                                                ...(selectedOrder?.pickups || []),
                                                                                {
                                                                                    id: 0,
                                                                                    customer: {
                                                                                        id: 0,
                                                                                        address1: e.target.value,
                                                                                    },
                                                                                },
                                                                            ],
                                                                        });
                                                                    } else {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            pickups: (
                                                                                selectedOrder?.pickups || []
                                                                            ).map((p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.customer = {
                                                                                        ...p.customer,
                                                                                        address1: e.target.value,
                                                                                    };
                                                                                }
                                                                                return p;
                                                                            }),
                                                                        });
                                                                    }

                                                                    setSelectedShipperCustomer({
                                                                        ...selectedShipperCustomer,
                                                                        id: 0,
                                                                        address1: e.target.value,
                                                                    });
                                                                } else {
                                                                    setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        pickups: (selectedOrder?.pickups || []).map(
                                                                            (p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.customer = {
                                                                                        ...p.customer,
                                                                                        address1: e.target.value,
                                                                                    };
                                                                                }
                                                                                return p;
                                                                            }
                                                                        ),
                                                                    });

                                                                    setSelectedShipperCustomer({
                                                                        ...selectedShipperCustomer,
                                                                        address1: e.target.value,
                                                                    });
                                                                }
                                                            }}
                                                            value={selectedShipperCustomer?.address1 || ""}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="input-box-container grow">
                                                        <input
                                                            tabIndex={19 + props.tabTimes}
                                                            type="text"
                                                            placeholder="Address 2"
                                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                            onInput={(e) => {
                                                                if ((selectedShipperCustomer?.id || 0) === 0) {
                                                                    if (
                                                                        (selectedOrder?.pickups || []).find(
                                                                            (p) => p.id === 0
                                                                        ) === undefined
                                                                    ) {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            pickups: [
                                                                                ...(selectedOrder?.pickups || []),
                                                                                {
                                                                                    id: 0,
                                                                                    customer: {
                                                                                        id: 0,
                                                                                        address2: e.target.value,
                                                                                    },
                                                                                },
                                                                            ],
                                                                        });
                                                                    } else {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            pickups: (
                                                                                selectedOrder?.pickups || []
                                                                            ).map((p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.customer = {
                                                                                        ...p.customer,
                                                                                        address2: e.target.value,
                                                                                    };
                                                                                }
                                                                                return p;
                                                                            }),
                                                                        });
                                                                    }

                                                                    setSelectedShipperCustomer({
                                                                        ...selectedShipperCustomer,
                                                                        id: 0,
                                                                        address2: e.target.value,
                                                                    });
                                                                } else {
                                                                    setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        pickups: (selectedOrder?.pickups || []).map(
                                                                            (p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.customer = {
                                                                                        ...p.customer,
                                                                                        address2: e.target.value,
                                                                                    };
                                                                                }
                                                                                return p;
                                                                            }
                                                                        ),
                                                                    });

                                                                    setSelectedShipperCustomer({
                                                                        ...selectedShipperCustomer,
                                                                        address2: e.target.value,
                                                                    });
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                if ((selectedShipperCustomer?.id || 0) === 0) {
                                                                    if (
                                                                        (selectedOrder?.pickups || []).find(
                                                                            (p) => p.id === 0
                                                                        ) === undefined
                                                                    ) {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            pickups: [
                                                                                ...(selectedOrder?.pickups || []),
                                                                                {
                                                                                    id: 0,
                                                                                    customer: {
                                                                                        id: 0,
                                                                                        address2: e.target.value,
                                                                                    },
                                                                                },
                                                                            ],
                                                                        });
                                                                    } else {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            pickups: (
                                                                                selectedOrder?.pickups || []
                                                                            ).map((p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.customer = {
                                                                                        ...p.customer,
                                                                                        address2: e.target.value,
                                                                                    };
                                                                                }
                                                                                return p;
                                                                            }),
                                                                        });
                                                                    }

                                                                    setSelectedShipperCustomer({
                                                                        ...selectedShipperCustomer,
                                                                        id: 0,
                                                                        address2: e.target.value,
                                                                    });
                                                                } else {
                                                                    setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        pickups: (selectedOrder?.pickups || []).map(
                                                                            (p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.customer = {
                                                                                        ...p.customer,
                                                                                        address2: e.target.value,
                                                                                    };
                                                                                }
                                                                                return p;
                                                                            }
                                                                        ),
                                                                    });

                                                                    setSelectedShipperCustomer({
                                                                        ...selectedShipperCustomer,
                                                                        address2: e.target.value,
                                                                    });
                                                                }
                                                            }}
                                                            value={selectedShipperCustomer?.address2 || ""}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="input-box-container grow">
                                                        <input
                                                            tabIndex={20 + props.tabTimes}
                                                            type="text"
                                                            placeholder="City"
                                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                            onInput={(e) => {
                                                                if ((selectedShipperCustomer?.id || 0) === 0) {
                                                                    if (
                                                                        (selectedOrder?.pickups || []).find(
                                                                            (p) => p.id === 0
                                                                        ) === undefined
                                                                    ) {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            pickups: [
                                                                                ...(selectedOrder?.pickups || []),
                                                                                {
                                                                                    id: 0,
                                                                                    customer: {
                                                                                        id: 0,
                                                                                        city: e.target.value,
                                                                                    },
                                                                                },
                                                                            ],
                                                                        });
                                                                    } else {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            pickups: (
                                                                                selectedOrder?.pickups || []
                                                                            ).map((p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.customer = {
                                                                                        ...p.customer,
                                                                                        city: e.target.value,
                                                                                    };
                                                                                }
                                                                                return p;
                                                                            }),
                                                                        });
                                                                    }

                                                                    setSelectedShipperCustomer({
                                                                        ...selectedShipperCustomer,
                                                                        id: 0,
                                                                        city: e.target.value,
                                                                    });
                                                                } else {
                                                                    setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        pickups: (selectedOrder?.pickups || []).map(
                                                                            (p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.customer = {
                                                                                        ...p.customer,
                                                                                        city: e.target.value,
                                                                                    };
                                                                                }
                                                                                return p;
                                                                            }
                                                                        ),
                                                                    });

                                                                    setSelectedShipperCustomer({
                                                                        ...selectedShipperCustomer,
                                                                        city: e.target.value,
                                                                    });
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                if ((selectedShipperCustomer?.id || 0) === 0) {
                                                                    if (
                                                                        (selectedOrder?.pickups || []).find(
                                                                            (p) => p.id === 0
                                                                        ) === undefined
                                                                    ) {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            pickups: [
                                                                                ...(selectedOrder?.pickups || []),
                                                                                {
                                                                                    id: 0,
                                                                                    customer: {
                                                                                        id: 0,
                                                                                        city: e.target.value,
                                                                                    },
                                                                                },
                                                                            ],
                                                                        });
                                                                    } else {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            pickups: (
                                                                                selectedOrder?.pickups || []
                                                                            ).map((p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.customer = {
                                                                                        ...p.customer,
                                                                                        city: e.target.value,
                                                                                    };
                                                                                }
                                                                                return p;
                                                                            }),
                                                                        });
                                                                    }

                                                                    setSelectedShipperCustomer({
                                                                        ...selectedShipperCustomer,
                                                                        id: 0,
                                                                        city: e.target.value,
                                                                    });
                                                                } else {
                                                                    setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        pickups: (selectedOrder?.pickups || []).map(
                                                                            (p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.customer = {
                                                                                        ...p.customer,
                                                                                        city: e.target.value,
                                                                                    };
                                                                                }
                                                                                return p;
                                                                            }
                                                                        ),
                                                                    });

                                                                    setSelectedShipperCustomer({
                                                                        ...selectedShipperCustomer,
                                                                        city: e.target.value,
                                                                    });
                                                                }
                                                            }}
                                                            value={selectedShipperCustomer?.city || ""}
                                                        />
                                                    </div>
                                                    <div className="form-h-sep"></div>
                                                    <div className="input-box-container input-state">
                                                        <input
                                                            tabIndex={21 + props.tabTimes}
                                                            type="text"
                                                            placeholder="State"
                                                            maxLength="2"
                                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                            onInput={(e) => {
                                                                if ((selectedShipperCustomer?.id || 0) === 0) {
                                                                    if (
                                                                        (selectedOrder?.pickups || []).find(
                                                                            (p) => p.id === 0
                                                                        ) === undefined
                                                                    ) {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            pickups: [
                                                                                ...(selectedOrder?.pickups || []),
                                                                                {
                                                                                    id: 0,
                                                                                    customer: {
                                                                                        id: 0,
                                                                                        state: e.target.value.toUpperCase(),
                                                                                    },
                                                                                },
                                                                            ],
                                                                        });
                                                                    } else {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            pickups: (
                                                                                selectedOrder?.pickups || []
                                                                            ).map((p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.customer = {
                                                                                        ...p.customer,
                                                                                        state: e.target.value.toUpperCase(),
                                                                                    };
                                                                                }
                                                                                return p;
                                                                            }),
                                                                        });
                                                                    }

                                                                    setSelectedShipperCustomer({
                                                                        ...selectedShipperCustomer,
                                                                        id: 0,
                                                                        state: e.target.value.toUpperCase(),
                                                                    });
                                                                } else {
                                                                    setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        pickups: (selectedOrder?.pickups || []).map(
                                                                            (p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.customer = {
                                                                                        ...p.customer,
                                                                                        state: e.target.value.toUpperCase(),
                                                                                    };
                                                                                }
                                                                                return p;
                                                                            }
                                                                        ),
                                                                    });

                                                                    setSelectedShipperCustomer({
                                                                        ...selectedShipperCustomer,
                                                                        state: e.target.value.toUpperCase(),
                                                                    });
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                if ((selectedShipperCustomer?.id || 0) === 0) {
                                                                    if (
                                                                        (selectedOrder?.pickups || []).find(
                                                                            (p) => p.id === 0
                                                                        ) === undefined
                                                                    ) {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            pickups: [
                                                                                ...(selectedOrder?.pickups || []),
                                                                                {
                                                                                    id: 0,
                                                                                    customer: {
                                                                                        id: 0,
                                                                                        state: e.target.value.toUpperCase(),
                                                                                    },
                                                                                },
                                                                            ],
                                                                        });
                                                                    } else {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            pickups: (
                                                                                selectedOrder?.pickups || []
                                                                            ).map((p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.customer = {
                                                                                        ...p.customer,
                                                                                        state: e.target.value.toUpperCase(),
                                                                                    };
                                                                                }
                                                                                return p;
                                                                            }),
                                                                        });
                                                                    }

                                                                    setSelectedShipperCustomer({
                                                                        ...selectedShipperCustomer,
                                                                        id: 0,
                                                                        state: e.target.value.toUpperCase(),
                                                                    });
                                                                } else {
                                                                    setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        pickups: (selectedOrder?.pickups || []).map(
                                                                            (p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.customer = {
                                                                                        ...p.customer,
                                                                                        state: e.target.value.toUpperCase(),
                                                                                    };
                                                                                }
                                                                                return p;
                                                                            }
                                                                        ),
                                                                    });

                                                                    setSelectedShipperCustomer({
                                                                        ...selectedShipperCustomer,
                                                                        state: e.target.value.toUpperCase(),
                                                                    });
                                                                }
                                                            }}
                                                            value={selectedShipperCustomer?.state || ""}
                                                        />
                                                    </div>
                                                    <div className="form-h-sep"></div>
                                                    <div className="input-box-container input-zip-code">
                                                        <input
                                                            tabIndex={22 + props.tabTimes}
                                                            type="text"
                                                            placeholder="Postal Code"
                                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                            onInput={(e) => {
                                                                if ((selectedShipperCustomer?.id || 0) === 0) {
                                                                    if (
                                                                        (selectedOrder?.pickups || []).find(
                                                                            (p) => p.id === 0
                                                                        ) === undefined
                                                                    ) {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            pickups: [
                                                                                ...(selectedOrder?.pickups || []),
                                                                                {
                                                                                    id: 0,
                                                                                    customer: {
                                                                                        id: 0,
                                                                                        zip: e.target.value,
                                                                                    },
                                                                                },
                                                                            ],
                                                                        });
                                                                    } else {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            pickups: (
                                                                                selectedOrder?.pickups || []
                                                                            ).map((p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.customer = {
                                                                                        ...p.customer,
                                                                                        zip: e.target.value,
                                                                                    };
                                                                                }
                                                                                return p;
                                                                            }),
                                                                        });
                                                                    }

                                                                    setSelectedShipperCustomer({
                                                                        ...selectedShipperCustomer,
                                                                        id: 0,
                                                                        zip: e.target.value,
                                                                    });
                                                                } else {
                                                                    setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        pickups: (selectedOrder?.pickups || []).map(
                                                                            (p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.customer = {
                                                                                        ...p.customer,
                                                                                        zip: e.target.value,
                                                                                    };
                                                                                }
                                                                                return p;
                                                                            }
                                                                        ),
                                                                    });

                                                                    setSelectedShipperCustomer({
                                                                        ...selectedShipperCustomer,
                                                                        zip: e.target.value,
                                                                    });
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                if ((selectedShipperCustomer?.id || 0) === 0) {
                                                                    if (
                                                                        (selectedOrder?.pickups || []).find(
                                                                            (p) => p.id === 0
                                                                        ) === undefined
                                                                    ) {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            pickups: [
                                                                                ...(selectedOrder?.pickups || []),
                                                                                {
                                                                                    id: 0,
                                                                                    customer: {
                                                                                        id: 0,
                                                                                        zip: e.target.value,
                                                                                    },
                                                                                },
                                                                            ],
                                                                        });
                                                                    } else {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            pickups: (
                                                                                selectedOrder?.pickups || []
                                                                            ).map((p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.customer = {
                                                                                        ...p.customer,
                                                                                        zip: e.target.value,
                                                                                    };
                                                                                }
                                                                                return p;
                                                                            }),
                                                                        });
                                                                    }

                                                                    setSelectedShipperCustomer({
                                                                        ...selectedShipperCustomer,
                                                                        id: 0,
                                                                        zip: e.target.value,
                                                                    });
                                                                } else {
                                                                    setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        pickups: (selectedOrder?.pickups || []).map(
                                                                            (p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.customer = {
                                                                                        ...p.customer,
                                                                                        zip: e.target.value,
                                                                                    };
                                                                                }
                                                                                return p;
                                                                            }
                                                                        ),
                                                                    });

                                                                    setSelectedShipperCustomer({
                                                                        ...selectedShipperCustomer,
                                                                        zip: e.target.value,
                                                                    });
                                                                }
                                                            }}
                                                            value={selectedShipperCustomer?.zip || ""}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="select-box-container" style={{ flexGrow: 1 }}>
                                                        <div className="select-box-wrapper">
                                                            <input
                                                                tabIndex={23 + props.tabTimes}
                                                                type="text"
                                                                placeholder="Contact Name"
                                                                ref={refShipperContactName}
                                                                readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                                onKeyDown={async (e) => {
                                                                    if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                        let key = e.keyCode || e.which;

                                                                        switch (key) {
                                                                            case 37:
                                                                            case 38: // arrow left | arrow up
                                                                                if (showShipperContactNames) {
                                                                                    let selectedIndex = shipperContactNameItems.findIndex(item => item.selected);

                                                                                    if (selectedIndex === -1) {
                                                                                        await setShipperContactNameItems(shipperContactNameItems.map((item, index) => {
                                                                                            item.selected = index === 0;
                                                                                            return item;
                                                                                        }))
                                                                                    } else {
                                                                                        await setShipperContactNameItems(shipperContactNameItems.map((item, index) => {
                                                                                            if (selectedIndex === 0) {
                                                                                                item.selected = index === (shipperContactNameItems.length - 1);
                                                                                            } else {
                                                                                                item.selected = index === (selectedIndex - 1)
                                                                                            }
                                                                                            return item;
                                                                                        }))
                                                                                    }

                                                                                    refShipperContactNamePopupItems.current.map((r, i) => {
                                                                                        if (r && r.classList.contains('selected')) {
                                                                                            r.scrollIntoView({
                                                                                                behavior: 'auto',
                                                                                                block: 'center',
                                                                                                inline: 'nearest'
                                                                                            })
                                                                                        }
                                                                                        return true;
                                                                                    });
                                                                                } else {
                                                                                    if ((selectedShipperCustomer?.contacts || []).length > 0) {
                                                                                        await setShipperContactNameItems((selectedShipperCustomer?.contacts || []).map((item, index) => {
                                                                                            item.selected = index === 0
                                                                                            return item;
                                                                                        }))

                                                                                        setShowShipperContactNames(true);

                                                                                        refShipperContactNamePopupItems.current.map((r, i) => {
                                                                                            if (r && r.classList.contains('selected')) {
                                                                                                r.scrollIntoView({
                                                                                                    behavior: 'auto',
                                                                                                    block: 'center',
                                                                                                    inline: 'nearest'
                                                                                                })
                                                                                            }
                                                                                            return true;
                                                                                        });
                                                                                    }
                                                                                }
                                                                                break;

                                                                            case 39:
                                                                            case 40: // arrow right | arrow down

                                                                                if (showShipperContactNames) {
                                                                                    let selectedIndex = shipperContactNameItems.findIndex(item => item.selected);

                                                                                    if (selectedIndex === -1) {
                                                                                        await setShipperContactNameItems(shipperContactNameItems.map((item, index) => {
                                                                                            item.selected = index === 0;
                                                                                            return item;
                                                                                        }))
                                                                                    } else {
                                                                                        await setShipperContactNameItems(shipperContactNameItems.map((item, index) => {
                                                                                            if (selectedIndex === (shipperContactNameItems.length - 1)) {
                                                                                                item.selected = index === 0;
                                                                                            } else {
                                                                                                item.selected = index === (selectedIndex + 1)
                                                                                            }
                                                                                            return item;
                                                                                        }))
                                                                                    }

                                                                                    refShipperContactNamePopupItems.current.map((r, i) => {
                                                                                        if (r && r.classList.contains('selected')) {
                                                                                            r.scrollIntoView({
                                                                                                behavior: 'auto',
                                                                                                block: 'center',
                                                                                                inline: 'nearest'
                                                                                            })
                                                                                        }
                                                                                        return true;
                                                                                    });
                                                                                } else {
                                                                                    if ((selectedShipperCustomer?.contacts || []).length > 0) {
                                                                                        await setShipperContactNameItems((selectedShipperCustomer?.contacts || []).map((item, index) => {
                                                                                            item.selected = index === 0
                                                                                            return item;
                                                                                        }))

                                                                                        setShowShipperContactNames(true);

                                                                                        refShipperContactNamePopupItems.current.map((r, i) => {
                                                                                            if (r && r.classList.contains('selected')) {
                                                                                                r.scrollIntoView({
                                                                                                    behavior: 'auto',
                                                                                                    block: 'center',
                                                                                                    inline: 'nearest'
                                                                                                })
                                                                                            }
                                                                                            return true;
                                                                                        });
                                                                                    }
                                                                                }
                                                                                break;

                                                                            case 27: // escape
                                                                                setShowShipperContactNames(false);
                                                                                break;

                                                                            case 13: // enter
                                                                                if (showShipperContactNames && shipperContactNameItems.findIndex(item => item.selected) > -1) {
                                                                                    let _contact_id = shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].id;
                                                                                    let _contact_name = ((shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)]?.first_name || '')
                                                                                        + ' '
                                                                                        + (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)]?.last_name || '')).trim();

                                                                                    let _contact_phone = (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].primary_phone || '') === 'work'
                                                                                        ? (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].phone_work || '')
                                                                                        : (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].primary_phone || '') === 'fax'
                                                                                            ? (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].phone_work_fax || '')
                                                                                            : (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].primary_phone || '') === 'mobile'
                                                                                                ? (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].phone_mobile || '')
                                                                                                : (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].primary_phone || '') === 'direct'
                                                                                                    ? (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].phone_direct || '')
                                                                                                    : (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].primary_phone || '') === 'other'
                                                                                                        ? (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].phone_other || '')
                                                                                                        : '';
                                                                                    let _contact_phone_ext = (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].phone_ext || '');

                                                                                    let _contact_primary_phone = (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].phone_work || '') !== ''
                                                                                        ? 'work'
                                                                                        : (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].phone_work_fax || '') !== ''
                                                                                            ? 'fax'
                                                                                            : (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].phone_mobile || '') !== ''
                                                                                                ? 'mobile'
                                                                                                : (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].phone_direct || '') !== ''
                                                                                                    ? 'direct'
                                                                                                    : (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].phone_other || '') !== ''
                                                                                                        ? 'other' :
                                                                                                        ''

                                                                                    if ((selectedShipperCustomer?.id || 0) === 0) {
                                                                                        if ((selectedOrder?.pickups || []).find((p) => p.id === 0) === undefined) {
                                                                                            setSelectedOrder({
                                                                                                ...selectedOrder,
                                                                                                pickups: [
                                                                                                    ...(selectedOrder?.pickups || []),
                                                                                                    {
                                                                                                        id: 0,
                                                                                                        contact_id: _contact_id,
                                                                                                        contact_name: _contact_name,
                                                                                                        contact_phone: _contact_phone,
                                                                                                        contact_phone_ext: _contact_phone_ext,
                                                                                                        contact_primary_phone: _contact_primary_phone
                                                                                                    },
                                                                                                ],
                                                                                            });
                                                                                        } else {
                                                                                            setSelectedOrder({
                                                                                                ...selectedOrder,
                                                                                                pickups: (selectedOrder?.pickups || []).map((p, i) => {
                                                                                                    if (p.id === 0) {
                                                                                                        p.contact_id = _contact_id;
                                                                                                        p.contact_name = _contact_name;
                                                                                                        p.contact_phone = _contact_phone;
                                                                                                        p.contact_phone_ext = _contact_phone_ext;
                                                                                                        p.contact_primary_phone = _contact_primary_phone;
                                                                                                    }
                                                                                                    return p;
                                                                                                }),
                                                                                            });
                                                                                        }
                                                                                    } else {
                                                                                        setSelectedOrder(_selectedOrder => {
                                                                                            return {
                                                                                                ..._selectedOrder,
                                                                                                pickups: (_selectedOrder?.pickups || []).map((p, i) => {
                                                                                                    if (p.id === selectedShipperCustomer.pickup_id) {
                                                                                                        p.contact_id = _contact_id;
                                                                                                        p.contact_name = _contact_name;
                                                                                                        p.contact_phone = _contact_phone;
                                                                                                        p.contact_phone_ext = _contact_phone_ext;
                                                                                                        p.contact_primary_phone = _contact_primary_phone;
                                                                                                    }
                                                                                                    return p;
                                                                                                }),
                                                                                            }
                                                                                        });
                                                                                    }

                                                                                    setSelectedShipperCustomer({
                                                                                        ...selectedShipperCustomer,
                                                                                        contact_id: _contact_id,
                                                                                        contact_name: _contact_name,
                                                                                        contact_phone: _contact_phone,
                                                                                        contact_phone_ext: _contact_phone_ext,
                                                                                        contact_primary_phone: _contact_primary_phone
                                                                                    });

                                                                                    setShowShipperContactNames(false);
                                                                                    refShipperContactPhone.current.inputElement.focus();
                                                                                }
                                                                                break;

                                                                            case 9: // tab
                                                                                if (showShipperContactNames && shipperContactNameItems.findIndex(item => item.selected) > -1) {
                                                                                    e.preventDefault();
                                                                                    let _contact_id = shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].id;
                                                                                    let _contact_name = ((shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].first_name || '')
                                                                                        + ' '
                                                                                        + (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].last_name || '')).trim();
                                                                                    let _contact_phone = (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].primary_phone || '') === 'work'
                                                                                        ? (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].phone_work || '')
                                                                                        : (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].primary_phone || '') === 'fax'
                                                                                            ? (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].phone_work_fax || '')
                                                                                            : (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].primary_phone || '') === 'mobile'
                                                                                                ? (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].phone_mobile || '')
                                                                                                : (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].primary_phone || '') === 'direct'
                                                                                                    ? (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].phone_direct || '')
                                                                                                    : (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].primary_phone || '') === 'other'
                                                                                                        ? (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].phone_other || '')
                                                                                                        : '';
                                                                                    let _contact_phone_ext = (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].phone_ext || '');
                                                                                    let _email = (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].primary_email || '') === 'work'
                                                                                        ? (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].email_work || '')
                                                                                        : (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].primary_email || '') === 'personal'
                                                                                            ? (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].email_personal || '')
                                                                                            : (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].primary_email || '') === 'other'
                                                                                                ? (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].email_other || '')
                                                                                                : '';
                                                                                    let _contact_primary_phone = (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].phone_work || '') !== ''
                                                                                        ? 'work'
                                                                                        : (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].phone_work_fax || '') !== ''
                                                                                            ? 'fax'
                                                                                            : (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].phone_mobile || '') !== ''
                                                                                                ? 'mobile'
                                                                                                : (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].phone_direct || '') !== ''
                                                                                                    ? 'direct'
                                                                                                    : (shipperContactNameItems[shipperContactNameItems.findIndex(item => item.selected)].phone_other || '') !== ''
                                                                                                        ? 'other' :
                                                                                                        ''


                                                                                    if ((selectedShipperCustomer?.id || 0) === 0) {
                                                                                        if ((selectedOrder?.pickups || []).find((p) => p.id === 0) === undefined) {
                                                                                            setSelectedOrder({
                                                                                                ...selectedOrder,
                                                                                                pickups: [
                                                                                                    ...(selectedOrder?.pickups || []),
                                                                                                    {
                                                                                                        id: 0,
                                                                                                        contact_id: _contact_id,
                                                                                                        contact_name: _contact_name,
                                                                                                        contact_phone: _contact_phone,
                                                                                                        contact_phone_ext: _contact_phone_ext,
                                                                                                        contact_primary_phone: _contact_primary_phone
                                                                                                    },
                                                                                                ],
                                                                                            });
                                                                                        } else {
                                                                                            setSelectedOrder({
                                                                                                ...selectedOrder,
                                                                                                pickups: (selectedOrder?.pickups || []).map((p, i) => {
                                                                                                    if (p.id === 0) {
                                                                                                        p.contact_id = _contact_id;
                                                                                                        p.contact_name = _contact_name;
                                                                                                        p.contact_phone = _contact_phone;
                                                                                                        p.contact_phone_ext = _contact_phone_ext;
                                                                                                        p.contact_primary_phone = _contact_primary_phone;
                                                                                                    }
                                                                                                    return p;
                                                                                                }),
                                                                                            });
                                                                                        }
                                                                                    } else {
                                                                                        setSelectedOrder(_selectedOrder => {
                                                                                            return {
                                                                                                ..._selectedOrder,
                                                                                                pickups: (_selectedOrder?.pickups || []).map((p, i) => {
                                                                                                    if (p.id === selectedShipperCustomer.pickup_id) {
                                                                                                        p.contact_id = _contact_id;
                                                                                                        p.contact_name = _contact_name;
                                                                                                        p.contact_phone = _contact_phone;
                                                                                                        p.contact_phone_ext = _contact_phone_ext;
                                                                                                        p.contact_primary_phone = _contact_primary_phone;
                                                                                                    }
                                                                                                    return p;
                                                                                                }),
                                                                                            }
                                                                                        });
                                                                                    }

                                                                                    setSelectedShipperCustomer({
                                                                                        ...selectedShipperCustomer,
                                                                                        contact_id: _contact_id,
                                                                                        contact_name: _contact_name,
                                                                                        contact_phone: _contact_phone,
                                                                                        contact_phone_ext: _contact_phone_ext,
                                                                                        contact_primary_phone: _contact_primary_phone
                                                                                    });

                                                                                    setShowShipperContactNames(false);
                                                                                    refShipperContactPhone.current.inputElement.focus();
                                                                                } else {

                                                                                }
                                                                                break;
                                                                            default:
                                                                                break;
                                                                        }
                                                                    }
                                                                }}
                                                                onBlur={e => {
                                                                    if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                        let contact = (selectedShipperCustomer?.contacts || []).find(x => (x.first_name + ' ' + x.last_name).toLowerCase() === e.target.value.toLowerCase());

                                                                        if (contact) {
                                                                            if ((selectedShipperCustomer?.id || 0) === 0) {
                                                                                if ((selectedOrder?.pickups || []).find((p) => p.id === 0) === undefined) {
                                                                                    setSelectedOrder({
                                                                                        ...selectedOrder,
                                                                                        pickups: [
                                                                                            ...(selectedOrder?.pickups || []),
                                                                                            {
                                                                                                id: 0,
                                                                                                contact_id: contact.id,
                                                                                                contact_phone: (contact.primary_phone || '') === 'work'
                                                                                                    ? (contact.phone_work || '')
                                                                                                    : (contact.primary_phone || '') === 'fax'
                                                                                                        ? (contact.phone_work_fax || '')
                                                                                                        : (contact.primary_phone || '') === 'mobile'
                                                                                                            ? (contact.phone_mobile || '')
                                                                                                            : (contact.primary_phone || '') === 'direct'
                                                                                                                ? (contact.phone_direct || '')
                                                                                                                : (contact.primary_phone || '') === 'other'
                                                                                                                    ? (contact.phone_other || '')
                                                                                                                    : '',
                                                                                                contact_primary_phone: contact.primary_phone || 'work',
                                                                                                contact_phone_ext: (contact.phone_ext || '')
                                                                                            }
                                                                                        ],
                                                                                    });
                                                                                } else {
                                                                                    setSelectedOrder({
                                                                                        ...selectedOrder,
                                                                                        pickups: (selectedOrder?.pickups || []).map((p, i) => {
                                                                                            if (p.id === 0) {
                                                                                                p.contact_id = contact.id;
                                                                                                p.contact_phone = (contact.primary_phone || '') === 'work'
                                                                                                    ? (contact.phone_work || '')
                                                                                                    : (contact.primary_phone || '') === 'fax'
                                                                                                        ? (contact.phone_work_fax || '')
                                                                                                        : (contact.primary_phone || '') === 'mobile'
                                                                                                            ? (contact.phone_mobile || '')
                                                                                                            : (contact.primary_phone || '') === 'direct'
                                                                                                                ? (contact.phone_direct || '')
                                                                                                                : (contact.primary_phone || '') === 'other'
                                                                                                                    ? (contact.phone_other || '')
                                                                                                                    : '';
                                                                                                p.contact_primary_phone = contact.primary_phone || 'work';
                                                                                                p.contact_phone_ext = (contact.phone_ext || '');
                                                                                            }
                                                                                            return p;
                                                                                        }),
                                                                                    });
                                                                                }
                                                                            } else {
                                                                                setSelectedOrder(_selectedOrder => {
                                                                                    return {
                                                                                        ..._selectedOrder,
                                                                                        pickups: (_selectedOrder?.pickups || []).map((p, i) => {
                                                                                            if (p.id === selectedShipperCustomer.pickup_id) {
                                                                                                p.contact_id = contact.id;
                                                                                                p.contact_phone = (contact.primary_phone || '') === 'work'
                                                                                                    ? (contact.phone_work || '')
                                                                                                    : (contact.primary_phone || '') === 'fax'
                                                                                                        ? (contact.phone_work_fax || '')
                                                                                                        : (contact.primary_phone || '') === 'mobile'
                                                                                                            ? (contact.phone_mobile || '')
                                                                                                            : (contact.primary_phone || '') === 'direct'
                                                                                                                ? (contact.phone_direct || '')
                                                                                                                : (contact.primary_phone || '') === 'other'
                                                                                                                    ? (contact.phone_other || '')
                                                                                                                    : '';
                                                                                                p.contact_primary_phone = contact.primary_phone || 'work';
                                                                                                p.contact_phone_ext = (contact.phone_ext || '');
                                                                                            }
                                                                                            return p;
                                                                                        }),
                                                                                    }
                                                                                });
                                                                            }

                                                                            setSelectedShipperCustomer(_selectedShipperCustomer => {
                                                                                return {
                                                                                    ..._selectedShipperCustomer,
                                                                                    contact_id: contact.id,
                                                                                    contact_phone: (contact.primary_phone || '') === 'work'
                                                                                        ? (contact.phone_work || '')
                                                                                        : (contact.primary_phone || '') === 'fax'
                                                                                            ? (contact.phone_work_fax || '')
                                                                                            : (contact.primary_phone || '') === 'mobile'
                                                                                                ? (contact.phone_mobile || '')
                                                                                                : (contact.primary_phone || '') === 'direct'
                                                                                                    ? (contact.phone_direct || '')
                                                                                                    : (contact.primary_phone || '') === 'other'
                                                                                                        ? (contact.phone_other || '')
                                                                                                        : '',
                                                                                    contact_primary_phone: contact.primary_phone || 'work',
                                                                                    contact_phone_ext: (contact.phone_ext || '')
                                                                                }
                                                                            })
                                                                        } else {
                                                                            if ((selectedShipperCustomer?.id || 0) === 0) {
                                                                                if ((selectedOrder?.pickups || []).find((p) => p.id === 0) === undefined) {
                                                                                    setSelectedOrder({
                                                                                        ...selectedOrder,
                                                                                        pickups: [
                                                                                            ...(selectedOrder?.pickups || []),
                                                                                            {
                                                                                                id: 0,
                                                                                                contact_id: null,
                                                                                                contact_primary_phone: ''
                                                                                            }
                                                                                        ],
                                                                                    });
                                                                                } else {
                                                                                    setSelectedOrder({
                                                                                        ...selectedOrder,
                                                                                        pickups: (selectedOrder?.pickups || []).map((p, i) => {
                                                                                            if (p.id === 0) {
                                                                                                p.contact_id = null;
                                                                                                p.contact_primary_phone = '';
                                                                                            }
                                                                                            return p;
                                                                                        }),
                                                                                    });
                                                                                }
                                                                            } else {
                                                                                setSelectedOrder(_selectedOrder => {
                                                                                    return {
                                                                                        ..._selectedOrder,
                                                                                        pickups: (_selectedOrder?.pickups || []).map((p, i) => {
                                                                                            if (p.id === selectedShipperCustomer.pickup_id) {
                                                                                                p.contact_id = null;
                                                                                                p.contact_primary_phone = '';
                                                                                            }
                                                                                            return p;
                                                                                        }),
                                                                                    }
                                                                                });
                                                                            }

                                                                            setSelectedShipperCustomer(_selectedShipperCustomer => {
                                                                                return {
                                                                                    ..._selectedShipperCustomer,
                                                                                    contact_id: null,
                                                                                    contact_primary_phone: ''
                                                                                }
                                                                            })
                                                                        }
                                                                    }
                                                                }}
                                                                onChange={(e) => {
                                                                    if ((selectedShipperCustomer?.id || 0) === 0) {
                                                                        if ((selectedOrder?.pickups || []).find((p) => p.id === 0) === undefined) {
                                                                            setSelectedOrder({
                                                                                ...selectedOrder,
                                                                                pickups: [
                                                                                    ...(selectedOrder?.pickups || []),
                                                                                    {
                                                                                        id: 0,
                                                                                        contact_name: e.target.value,
                                                                                    },
                                                                                ],
                                                                            });
                                                                        } else {
                                                                            setSelectedOrder({
                                                                                ...selectedOrder,
                                                                                pickups: (selectedOrder?.pickups || []).map((p, i) => {
                                                                                    if (p.id === 0) {
                                                                                        p.contact_name = e.target.value
                                                                                    }
                                                                                    return p;
                                                                                }),
                                                                            });
                                                                        }
                                                                    } else {
                                                                        setSelectedOrder(_selectedOrder => {
                                                                            return {
                                                                                ..._selectedOrder,
                                                                                pickups: (_selectedOrder?.pickups || []).map((p, i) => {
                                                                                    if (p.id === selectedShipperCustomer.pickup_id) {

                                                                                        p.contact_name = e.target.value
                                                                                    }
                                                                                    return p;
                                                                                }),
                                                                            }
                                                                        });
                                                                    }

                                                                    setSelectedShipperCustomer({
                                                                        ...selectedShipperCustomer,
                                                                        contact_name: e.target.value,
                                                                    });
                                                                }}
                                                                onInput={(e) => {
                                                                    if ((selectedShipperCustomer?.id || 0) === 0) {
                                                                        if ((selectedOrder?.pickups || []).find((p) => p.id === 0) === undefined) {
                                                                            setSelectedOrder({
                                                                                ...selectedOrder,
                                                                                pickups: [
                                                                                    ...(selectedOrder?.pickups || []),
                                                                                    {
                                                                                        id: 0,
                                                                                        contact_name: e.target.value,
                                                                                    },
                                                                                ],
                                                                            });
                                                                        } else {
                                                                            setSelectedOrder({
                                                                                ...selectedOrder,
                                                                                pickups: (selectedOrder?.pickups || []).map((p, i) => {
                                                                                    if (p.id === 0) {
                                                                                        p.contact_name = e.target.value
                                                                                    }
                                                                                    return p;
                                                                                }),
                                                                            });
                                                                        }
                                                                    } else {
                                                                        setSelectedOrder(_selectedOrder => {
                                                                            return {
                                                                                ..._selectedOrder,
                                                                                pickups: (_selectedOrder?.pickups || []).map((p, i) => {
                                                                                    if (p.id === selectedShipperCustomer.pickup_id) {

                                                                                        p.contact_name = e.target.value
                                                                                    }
                                                                                    return p;
                                                                                }),
                                                                            }
                                                                        });
                                                                    }

                                                                    setSelectedShipperCustomer({
                                                                        ...selectedShipperCustomer,
                                                                        contact_name: e.target.value,
                                                                    });
                                                                }}
                                                                value={(selectedShipperCustomer?.contact_id || 0) > 0
                                                                    ? (((selectedShipperCustomer?.contacts || []).find(x => x.id === selectedShipperCustomer.contact_id)?.first_name || '') + ' ' +
                                                                        ((selectedShipperCustomer?.contacts || []).find(x => x.id === selectedShipperCustomer.contact_id)?.last_name || '')).trim()
                                                                    : (selectedShipperCustomer?.contacts || []).find(x => x.is_primary === 1)
                                                                        ? ((selectedShipperCustomer.contacts.find(x => x.is_primary === 1)?.first_name || '') + ' ' +
                                                                            (selectedShipperCustomer.contacts.find(x => x.is_primary === 1)?.last_name || '')).trim()
                                                                        : (selectedShipperCustomer?.contact_name || '')
                                                                }
                                                            />

                                                            {
                                                                (selectedOrder?.is_cancelled || 0) === 0 &&
                                                                <FontAwesomeIcon className="dropdown-button"
                                                                    icon={faCaretDown}
                                                                    onClick={async () => {
                                                                        if (showShipperContactNames) {
                                                                            setShowShipperContactNames(false);
                                                                        } else {
                                                                            if ((selectedShipperCustomer?.contacts || []).length > 0) {
                                                                                await setShipperContactNameItems((selectedShipperCustomer?.contacts || []).map((item, index) => {
                                                                                    item.selected = index === 0
                                                                                    return item;
                                                                                }))

                                                                                window.setTimeout(async () => {
                                                                                    await setShowShipperContactNames(true);

                                                                                    refShipperContactNamePopupItems.current.map((r, i) => {
                                                                                        if (r && r.classList.contains('selected')) {
                                                                                            r.scrollIntoView({
                                                                                                behavior: 'auto',
                                                                                                block: 'center',
                                                                                                inline: 'nearest'
                                                                                            })
                                                                                        }
                                                                                        return true;
                                                                                    });
                                                                                }, 0)
                                                                            }
                                                                        }

                                                                        refShipperContactName.current.focus();
                                                                    }} />
                                                            }
                                                        </div>
                                                        {
                                                            shipperContactNamesTransition((style, item) => item && (
                                                                <animated.div
                                                                    className="mochi-contextual-container"
                                                                    id="mochi-contextual-container-contact-names"
                                                                    style={{
                                                                        ...style,
                                                                        left: '0',
                                                                        display: 'block'
                                                                    }}
                                                                    ref={refShipperContactNameDropDown}
                                                                >
                                                                    <div
                                                                        className="mochi-contextual-popup vertical below right"
                                                                        style={{ height: 150 }}>
                                                                        <div className="mochi-contextual-popup-content">
                                                                            <div className="mochi-contextual-popup-wrapper">
                                                                                {
                                                                                    shipperContactNameItems.map((item, index) => {
                                                                                        const mochiItemClasses = classnames({
                                                                                            'mochi-item': true,
                                                                                            'selected': item.selected
                                                                                        });

                                                                                        return (
                                                                                            <div
                                                                                                key={index}
                                                                                                className={mochiItemClasses}
                                                                                                id={item.id}
                                                                                                onClick={() => {
                                                                                                    let _contact_id = (item.id)
                                                                                                    let _contact_name = ((item.first_name || '')
                                                                                                        + ' '
                                                                                                        + (item.last_name || '')).trim();

                                                                                                    let _contact_phone = (item.primary_phone || '') === 'work'
                                                                                                        ? (item.phone_work || '')
                                                                                                        : (item.primary_phone || '') === 'fax'
                                                                                                            ? (item.phone_work_fax || '')
                                                                                                            : (item.primary_phone || '') === 'mobile'
                                                                                                                ? (item.phone_mobile || '')
                                                                                                                : (item.primary_phone || '') === 'direct'
                                                                                                                    ? (item.phone_direct || '')
                                                                                                                    : (item.primary_phone || '') === 'other'
                                                                                                                        ? (item.phone_other || '')
                                                                                                                        : '';
                                                                                                    let _contact_phone_ext = (item.phone_ext || '');

                                                                                                    let _contact_primary_phone = (item.phone_work || '') !== ''
                                                                                                        ? 'work'
                                                                                                        : (item.phone_work_fax || '') !== ''
                                                                                                            ? 'fax'
                                                                                                            : (item.phone_mobile || '') !== ''
                                                                                                                ? 'mobile'
                                                                                                                : (item.phone_direct || '') !== ''
                                                                                                                    ? 'direct'
                                                                                                                    : (item.phone_other || '') !== ''
                                                                                                                        ? 'other' :
                                                                                                                        ''


                                                                                                    if ((selectedShipperCustomer?.id || 0) === 0) {
                                                                                                        if ((selectedOrder?.pickups || []).find((p) => p.id === 0) === undefined) {
                                                                                                            setSelectedOrder({
                                                                                                                ...selectedOrder,
                                                                                                                pickups: [
                                                                                                                    ...(selectedOrder?.pickups || []),
                                                                                                                    {
                                                                                                                        id: 0,
                                                                                                                        contact_id: _contact_id,
                                                                                                                        contact_name: _contact_name,
                                                                                                                        contact_phone: _contact_phone,
                                                                                                                        contact_phone_ext: _contact_phone_ext,
                                                                                                                        contact_primary_phone: _contact_primary_phone
                                                                                                                    },
                                                                                                                ],
                                                                                                            });
                                                                                                        } else {
                                                                                                            setSelectedOrder({
                                                                                                                ...selectedOrder,
                                                                                                                pickups: (selectedOrder?.pickups || []).map((p, i) => {
                                                                                                                    if (p.id === 0) {
                                                                                                                        p.contact_id = _contact_id;
                                                                                                                        p.contact_name = _contact_name;
                                                                                                                        p.contact_phone = _contact_phone;
                                                                                                                        p.contact_phone_ext = _contact_phone_ext;
                                                                                                                        p.contact_primary_phone = _contact_primary_phone;
                                                                                                                    }
                                                                                                                    return p;
                                                                                                                }),
                                                                                                            });
                                                                                                        }
                                                                                                    } else {
                                                                                                        setSelectedOrder(_selectedOrder => {
                                                                                                            return {
                                                                                                                ..._selectedOrder,
                                                                                                                pickups: (_selectedOrder?.pickups || []).map((p, i) => {
                                                                                                                    if (p.id === selectedShipperCustomer.pickup_id) {
                                                                                                                        p.contact_id = _contact_id;
                                                                                                                        p.contact_name = _contact_name;
                                                                                                                        p.contact_phone = _contact_phone;
                                                                                                                        p.contact_phone_ext = _contact_phone_ext;
                                                                                                                        p.contact_primary_phone = _contact_primary_phone;
                                                                                                                    }
                                                                                                                    return p;
                                                                                                                }),
                                                                                                            }
                                                                                                        });
                                                                                                    }

                                                                                                    setSelectedShipperCustomer({
                                                                                                        ...selectedShipperCustomer,
                                                                                                        contact_id: _contact_id,
                                                                                                        contact_name: _contact_name,
                                                                                                        contact_phone: _contact_phone,
                                                                                                        contact_phone_ext: _contact_phone_ext,
                                                                                                        contact_primary_phone: _contact_primary_phone
                                                                                                    });

                                                                                                    setShowShipperContactNames(false);
                                                                                                    refShipperContactPhone.current.inputElement.focus();

                                                                                                }}
                                                                                                ref={ref => refShipperContactNamePopupItems.current.push(ref)}
                                                                                            >
                                                                                                {
                                                                                                    item.first_name + ((item.last_name || '') === '' ? '' : ' ' + item.last_name)
                                                                                                }

                                                                                                {
                                                                                                    item.selected &&
                                                                                                    <FontAwesomeIcon
                                                                                                        className="dropdown-selected"
                                                                                                        icon={faCaretRight} />
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </animated.div>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className="form-h-sep"></div>
                                                    <div className="select-box-container input-phone"
                                                        style={{ width: '10.3rem' }}>
                                                        <div className="select-box-wrapper">
                                                            <MaskedInput
                                                                tabIndex={24 + props.tabTimes}
                                                                mask={[/[0-9]/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/,]}
                                                                guide={true}
                                                                type="text"
                                                                placeholder="Contact Phone"
                                                                ref={refShipperContactPhone}
                                                                readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                                onKeyDown={async (e) => {
                                                                    if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                        let key = e.keyCode || e.which;

                                                                        switch (key) {
                                                                            case 37:
                                                                            case 38: // arrow left | arrow up
                                                                                if (showShipperContactPhones) {
                                                                                    let selectedIndex = shipperContactPhoneItems.findIndex(item => item.selected);

                                                                                    if (selectedIndex === -1) {
                                                                                        await setShipperContactPhoneItems(shipperContactPhoneItems.map((item, index) => {
                                                                                            item.selected = index === 0;
                                                                                            return item;
                                                                                        }))
                                                                                    } else {
                                                                                        await setShipperContactPhoneItems(shipperContactPhoneItems.map((item, index) => {
                                                                                            if (selectedIndex === 0) {
                                                                                                item.selected = index === (shipperContactPhoneItems.length - 1);
                                                                                            } else {
                                                                                                item.selected = index === (selectedIndex - 1)
                                                                                            }
                                                                                            return item;
                                                                                        }))
                                                                                    }

                                                                                    refShipperContactPhonePopupItems.current.map((r, i) => {
                                                                                        if (r && r.classList.contains('selected')) {
                                                                                            r.scrollIntoView({
                                                                                                behavior: 'auto',
                                                                                                block: 'center',
                                                                                                inline: 'nearest'
                                                                                            })
                                                                                        }
                                                                                        return true;
                                                                                    });
                                                                                } else {
                                                                                    if (shipperContactPhoneItems.length > 0) {
                                                                                        await setShipperContactPhoneItems(shipperContactPhoneItems.map((item, index) => {
                                                                                            item.selected = index === 0;
                                                                                            return item;
                                                                                        }))

                                                                                        setShowShipperContactPhones(true);

                                                                                        refShipperContactPhonePopupItems.current.map((r, i) => {
                                                                                            if (r && r.classList.contains('selected')) {
                                                                                                r.scrollIntoView({
                                                                                                    behavior: 'auto',
                                                                                                    block: 'center',
                                                                                                    inline: 'nearest'
                                                                                                })
                                                                                            }
                                                                                            return true;
                                                                                        });
                                                                                    }
                                                                                }
                                                                                break;

                                                                            case 39:
                                                                            case 40: // arrow right | arrow down
                                                                                if (showShipperContactPhones) {
                                                                                    let selectedIndex = shipperContactPhoneItems.findIndex(item => item.selected);

                                                                                    if (selectedIndex === -1) {
                                                                                        await setShipperContactPhoneItems(shipperContactPhoneItems.map((item, index) => {
                                                                                            item.selected = index === 0;
                                                                                            return item;
                                                                                        }))
                                                                                    } else {
                                                                                        await setShipperContactPhoneItems(shipperContactPhoneItems.map((item, index) => {
                                                                                            if (selectedIndex === (shipperContactPhoneItems.length - 1)) {
                                                                                                item.selected = index === 0;
                                                                                            } else {
                                                                                                item.selected = index === (selectedIndex + 1)
                                                                                            }
                                                                                            return item;
                                                                                        }))
                                                                                    }

                                                                                    refShipperContactPhonePopupItems.current.map((r, i) => {
                                                                                        if (r && r.classList.contains('selected')) {
                                                                                            r.scrollIntoView({
                                                                                                behavior: 'auto',
                                                                                                block: 'center',
                                                                                                inline: 'nearest'
                                                                                            })
                                                                                        }
                                                                                        return true;
                                                                                    });
                                                                                } else {
                                                                                    if (shipperContactPhoneItems.length > 0) {
                                                                                        await setShipperContactPhoneItems(shipperContactPhoneItems.map((item, index) => {
                                                                                            item.selected = index === 0;
                                                                                            return item;
                                                                                        }))

                                                                                        setShowShipperContactPhones(true);

                                                                                        refShipperContactPhonePopupItems.current.map((r, i) => {
                                                                                            if (r && r.classList.contains('selected')) {
                                                                                                r.scrollIntoView({
                                                                                                    behavior: 'auto',
                                                                                                    block: 'center',
                                                                                                    inline: 'nearest'
                                                                                                })
                                                                                            }
                                                                                            return true;
                                                                                        });
                                                                                    }
                                                                                }
                                                                                break;

                                                                            case 27: // escape
                                                                                setShowShipperContactPhones(false);
                                                                                break;

                                                                            case 13: // enter
                                                                                if (showShipperContactPhones && shipperContactPhoneItems.findIndex(item => item.selected) > -1) {
                                                                                    let _contact_phone = shipperContactPhoneItems[shipperContactPhoneItems.findIndex(item => item.selected)].phone;
                                                                                    let _contact_primary_phone = shipperContactPhoneItems[shipperContactPhoneItems.findIndex(item => item.selected)].type;

                                                                                    if ((selectedShipperCustomer?.id || 0) === 0) {
                                                                                        if ((selectedOrder?.pickups || []).find((p) => p.id === 0) === undefined) {
                                                                                            setSelectedOrder({
                                                                                                ...selectedOrder,
                                                                                                pickups: [
                                                                                                    ...(selectedOrder?.pickups || []),
                                                                                                    {
                                                                                                        id: 0,
                                                                                                        contact_phone: _contact_phone,
                                                                                                        contact_primary_phone: _contact_primary_phone
                                                                                                    },
                                                                                                ],
                                                                                            });
                                                                                        } else {
                                                                                            setSelectedOrder({
                                                                                                ...selectedOrder,
                                                                                                pickups: (selectedOrder?.pickups || []).map((p, i) => {
                                                                                                    if (p.id === 0) {
                                                                                                        p.contact_phone = _contact_phone;
                                                                                                        p.contact_primary_phone = _contact_primary_phone;
                                                                                                    }
                                                                                                    return p;
                                                                                                }),
                                                                                            });
                                                                                        }
                                                                                    } else {
                                                                                        setSelectedOrder(_selectedOrder => {
                                                                                            return {
                                                                                                ..._selectedOrder,
                                                                                                pickups: (_selectedOrder?.pickups || []).map((p, i) => {
                                                                                                    if (p.id === selectedShipperCustomer.pickup_id) {
                                                                                                        p.contact_phone = _contact_phone;
                                                                                                        p.contact_primary_phone = _contact_primary_phone;
                                                                                                    }
                                                                                                    return p;
                                                                                                }),
                                                                                            }
                                                                                        });
                                                                                    }

                                                                                    setSelectedShipperCustomer({
                                                                                        ...selectedShipperCustomer,
                                                                                        contact_phone: _contact_phone,
                                                                                        contact_primary_phone: _contact_primary_phone
                                                                                    });

                                                                                    setShowShipperContactPhones(false);
                                                                                    refShipperContactPhone.current.inputElement.focus();
                                                                                }
                                                                                break;

                                                                            case 9: // tab
                                                                                if (showShipperContactPhones && shipperContactPhoneItems.findIndex(item => item.selected) > -1) {
                                                                                    e.preventDefault();
                                                                                    let _contact_phone = shipperContactPhoneItems[shipperContactPhoneItems.findIndex(item => item.selected)].phone;
                                                                                    let _contact_primary_phone = shipperContactPhoneItems[shipperContactPhoneItems.findIndex(item => item.selected)].type;

                                                                                    if ((selectedShipperCustomer?.id || 0) === 0) {
                                                                                        if ((selectedOrder?.pickups || []).find((p) => p.id === 0) === undefined) {
                                                                                            setSelectedOrder({
                                                                                                ...selectedOrder,
                                                                                                pickups: [
                                                                                                    ...(selectedOrder?.pickups || []),
                                                                                                    {
                                                                                                        id: 0,
                                                                                                        contact_phone: _contact_phone,
                                                                                                        contact_primary_phone: _contact_primary_phone
                                                                                                    },
                                                                                                ],
                                                                                            });
                                                                                        } else {
                                                                                            setSelectedOrder({
                                                                                                ...selectedOrder,
                                                                                                pickups: (selectedOrder?.pickups || []).map((p, i) => {
                                                                                                    if (p.id === 0) {
                                                                                                        p.contact_phone = _contact_phone;
                                                                                                        p.contact_primary_phone = _contact_primary_phone;
                                                                                                    }
                                                                                                    return p;
                                                                                                }),
                                                                                            });
                                                                                        }
                                                                                    } else {
                                                                                        setSelectedOrder(_selectedOrder => {
                                                                                            return {
                                                                                                ..._selectedOrder,
                                                                                                pickups: (_selectedOrder?.pickups || []).map((p, i) => {
                                                                                                    if (p.id === selectedShipperCustomer.pickup_id) {
                                                                                                        p.contact_phone = _contact_phone;
                                                                                                        p.contact_primary_phone = _contact_primary_phone;
                                                                                                    }
                                                                                                    return p;
                                                                                                }),
                                                                                            }
                                                                                        });
                                                                                    }

                                                                                    setSelectedShipperCustomer({
                                                                                        ...selectedShipperCustomer,
                                                                                        contact_phone: _contact_phone,
                                                                                        contact_primary_phone: _contact_primary_phone
                                                                                    });

                                                                                    setShowShipperContactPhones(false);
                                                                                    refShipperContactPhone.current.inputElement.focus();
                                                                                }
                                                                                break;
                                                                            default:
                                                                                break;
                                                                        }
                                                                    }
                                                                }}
                                                                onInput={(e) => {
                                                                    if ((selectedShipperCustomer?.id || 0) === 0) {
                                                                        if ((selectedOrder?.pickups || []).find((p) => p.id === 0) === undefined) {
                                                                            setSelectedOrder({
                                                                                ...selectedOrder,
                                                                                pickups: [
                                                                                    ...(selectedOrder?.pickups || []),
                                                                                    {
                                                                                        id: 0,
                                                                                        contact_phone: e.target.value
                                                                                    },
                                                                                ],
                                                                            });
                                                                        } else {
                                                                            setSelectedOrder({
                                                                                ...selectedOrder,
                                                                                pickups: (selectedOrder?.pickups || []).map((p, i) => {
                                                                                    if (p.id === 0) {
                                                                                        p.contact_phone = e.target.value;
                                                                                    }
                                                                                    return p;
                                                                                }),
                                                                            });
                                                                        }
                                                                    } else {
                                                                        setSelectedOrder(_selectedOrder => {
                                                                            return {
                                                                                ..._selectedOrder,
                                                                                pickups: (_selectedOrder?.pickups || []).map((p, i) => {
                                                                                    if (p.id === selectedShipperCustomer.pickup_id) {
                                                                                        p.contact_phone = e.target.value;
                                                                                    }
                                                                                    return p;
                                                                                }),
                                                                            }
                                                                        });
                                                                    }

                                                                    setSelectedShipperCustomer(_selectedShipperCustomer => {
                                                                        return {
                                                                            ..._selectedShipperCustomer,
                                                                            contact_phone: e.target.value
                                                                        }
                                                                    })
                                                                }}
                                                                onChange={(e) => {
                                                                    if ((selectedShipperCustomer?.id || 0) === 0) {
                                                                        if ((selectedOrder?.pickups || []).find((p) => p.id === 0) === undefined) {
                                                                            setSelectedOrder({
                                                                                ...selectedOrder,
                                                                                pickups: [
                                                                                    ...(selectedOrder?.pickups || []),
                                                                                    {
                                                                                        id: 0,
                                                                                        contact_phone: e.target.value
                                                                                    },
                                                                                ],
                                                                            });
                                                                        } else {
                                                                            setSelectedOrder({
                                                                                ...selectedOrder,
                                                                                pickups: (selectedOrder?.pickups || []).map((p, i) => {
                                                                                    if (p.id === 0) {
                                                                                        p.contact_phone = e.target.value;
                                                                                    }
                                                                                    return p;
                                                                                }),
                                                                            });
                                                                        }
                                                                    } else {
                                                                        setSelectedOrder(_selectedOrder => {
                                                                            return {
                                                                                ..._selectedOrder,
                                                                                pickups: (_selectedOrder?.pickups || []).map((p, i) => {
                                                                                    if (p.id === selectedShipperCustomer.pickup_id) {
                                                                                        p.contact_phone = e.target.value;
                                                                                    }
                                                                                    return p;
                                                                                }),
                                                                            }
                                                                        });
                                                                    }

                                                                    setSelectedShipperCustomer(_selectedShipperCustomer => {
                                                                        return {
                                                                            ..._selectedShipperCustomer,
                                                                            contact_phone: e.target.value
                                                                        }
                                                                    })
                                                                }}
                                                                value={(selectedShipperCustomer?.contact_id || 0) > 0
                                                                    ? (selectedShipperCustomer?.contact_primary_phone || 'work') === 'work'
                                                                        ? ((selectedShipperCustomer?.contacts || []).find(x => x.id === selectedShipperCustomer.contact_id)?.phone_work || '')
                                                                        : (selectedShipperCustomer?.contact_primary_phone || 'work') === 'fax'
                                                                            ? ((selectedShipperCustomer?.contacts || []).find(x => x.id === selectedShipperCustomer.contact_id)?.phone_work_fax || '')
                                                                            : (selectedShipperCustomer?.contact_primary_phone || 'work') === 'mobile'
                                                                                ? ((selectedShipperCustomer?.contacts || []).find(x => x.id === selectedShipperCustomer.contact_id)?.phone_mobile || '')
                                                                                : (selectedShipperCustomer?.contact_primary_phone || 'work') === 'direct'
                                                                                    ? ((selectedShipperCustomer?.contacts || []).find(x => x.id === selectedShipperCustomer.contact_id)?.phone_direct || '')
                                                                                    : (selectedShipperCustomer?.contact_primary_phone || 'work') === 'other'
                                                                                        ? ((selectedShipperCustomer?.contacts || []).find(x => x.id === selectedShipperCustomer.contact_id)?.phone_other || '')
                                                                                        : ''
                                                                    : (selectedShipperCustomer?.contacts || []).find(x => x.is_primary === 1)
                                                                        ? (selectedShipperCustomer?.contact_primary_phone || 'work') === 'work'
                                                                            ? (selectedShipperCustomer.contacts.find(x => x.is_primary === 1)?.phone_work || '')
                                                                            : (selectedShipperCustomer?.contact_primary_phone || 'work') === 'fax'
                                                                                ? (selectedShipperCustomer.contacts.find(x => x.is_primary === 1)?.phone_work_fax || '')
                                                                                : (selectedShipperCustomer?.contact_primary_phone || 'work') === 'mobile'
                                                                                    ? (selectedShipperCustomer.contacts.find(x => x.is_primary === 1)?.phone_mobile || '')
                                                                                    : (selectedShipperCustomer?.contact_primary_phone || 'work') === 'direct'
                                                                                        ? (selectedShipperCustomer.contacts.find(x => x.is_primary === 1)?.phone_direct || '')
                                                                                        : (selectedShipperCustomer?.contact_primary_phone || 'work') === 'other'
                                                                                            ? (selectedShipperCustomer.contacts.find(x => x.is_primary === 1)?.phone_other || '')
                                                                                            : ''
                                                                        : (selectedShipperCustomer?.contact_phone || '')
                                                                }
                                                            />
                                                            {((selectedShipperCustomer?.id || 0) > 0 && (selectedShipperCustomer?.id !== undefined)) && (
                                                                <div className={classnames({
                                                                    "selected-customer-contact-primary-phone": true,
                                                                    'pushed': (shipperContactPhoneItems.length > 0)
                                                                })}>
                                                                    {selectedShipperCustomer?.contact_primary_phone || ''}
                                                                </div>
                                                            )}

                                                            {
                                                                (shipperContactPhoneItems.length > 0 && (selectedOrder?.is_cancelled || 0) === 0) && (
                                                                    <FontAwesomeIcon
                                                                        className="dropdown-button" icon={faCaretDown}
                                                                        onClick={async () => {
                                                                            if (showShipperContactPhones) {
                                                                                setShowShipperContactPhones(false);
                                                                            } else {
                                                                                if (shipperContactPhoneItems.length > 0) {
                                                                                    await setShipperContactPhoneItems(shipperContactPhoneItems.map((item, index) => {
                                                                                        item.selected = index === 0;
                                                                                        return item;
                                                                                    }))

                                                                                    window.setTimeout(async () => {
                                                                                        await setShowShipperContactPhones(true);

                                                                                        refShipperContactPhonePopupItems.current.map((r, i) => {
                                                                                            if (r && r.classList.contains('selected')) {
                                                                                                r.scrollIntoView({
                                                                                                    behavior: 'auto',
                                                                                                    block: 'center',
                                                                                                    inline: 'nearest'
                                                                                                })
                                                                                            }
                                                                                            return true;
                                                                                        });
                                                                                    }, 0)
                                                                                }
                                                                            }

                                                                            refShipperContactPhone.current.inputElement.focus();
                                                                        }} />
                                                                )
                                                            }
                                                        </div>
                                                        {
                                                            shipperContactPhonesTransition((style, item) => item && (
                                                                <animated.div
                                                                    className="mochi-contextual-container"
                                                                    id="mochi-contextual-container-contact-phone"
                                                                    style={{
                                                                        ...style,
                                                                        left: '0',
                                                                        display: 'block'
                                                                    }}
                                                                    ref={refShipperContactPhoneDropDown}
                                                                >
                                                                    <div
                                                                        className="mochi-contextual-popup vertical below right"
                                                                        style={{ height: 150 }}>
                                                                        <div className="mochi-contextual-popup-content">
                                                                            <div className="mochi-contextual-popup-wrapper">
                                                                                {
                                                                                    shipperContactPhoneItems.map((item, index) => {
                                                                                        const mochiItemClasses = classnames({
                                                                                            'mochi-item': true,
                                                                                            'selected': item.selected
                                                                                        });

                                                                                        return (
                                                                                            <div
                                                                                                key={index}
                                                                                                className={mochiItemClasses}
                                                                                                id={item.id}
                                                                                                onClick={() => {
                                                                                                    let _contact_phone = item.phone;
                                                                                                    let _contact_primary_phone = item.type;

                                                                                                    if ((selectedShipperCustomer?.id || 0) === 0) {
                                                                                                        if ((selectedOrder?.pickups || []).find((p) => p.id === 0) === undefined) {
                                                                                                            setSelectedOrder({
                                                                                                                ...selectedOrder,
                                                                                                                pickups: [
                                                                                                                    ...(selectedOrder?.pickups || []),
                                                                                                                    {
                                                                                                                        id: 0,
                                                                                                                        contact_phone: _contact_phone,
                                                                                                                        contact_primary_phone: _contact_primary_phone
                                                                                                                    },
                                                                                                                ],
                                                                                                            });
                                                                                                        } else {
                                                                                                            setSelectedOrder({
                                                                                                                ...selectedOrder,
                                                                                                                pickups: (selectedOrder?.pickups || []).map((p, i) => {
                                                                                                                    if (p.id === 0) {
                                                                                                                        p.contact_phone = _contact_phone;
                                                                                                                        p.contact_primary_phone = _contact_primary_phone;
                                                                                                                    }
                                                                                                                    return p;
                                                                                                                }),
                                                                                                            });
                                                                                                        }
                                                                                                    } else {
                                                                                                        setSelectedOrder(_selectedOrder => {
                                                                                                            return {
                                                                                                                ..._selectedOrder,
                                                                                                                pickups: (_selectedOrder?.pickups || []).map((p, i) => {
                                                                                                                    if (p.id === selectedShipperCustomer.pickup_id) {
                                                                                                                        p.contact_phone = _contact_phone;
                                                                                                                        p.contact_primary_phone = _contact_primary_phone;
                                                                                                                    }
                                                                                                                    return p;
                                                                                                                }),
                                                                                                            }
                                                                                                        });
                                                                                                    }

                                                                                                    setSelectedShipperCustomer({
                                                                                                        ...selectedShipperCustomer,
                                                                                                        contact_phone: _contact_phone,
                                                                                                        contact_primary_phone: _contact_primary_phone
                                                                                                    });

                                                                                                    setShowShipperContactPhones(false);
                                                                                                    refShipperContactPhone.current.inputElement.focus();
                                                                                                }}
                                                                                                ref={ref => refShipperContactPhonePopupItems.current.push(ref)}
                                                                                            >
                                                                                                {
                                                                                                    item.type === 'work' ? `Phone Work `
                                                                                                        : item.type === 'fax' ? `Phone Work Fax `
                                                                                                            : item.type === 'mobile' ? `Phone Mobile `
                                                                                                                : item.type === 'direct' ? `Phone Direct `
                                                                                                                    : item.type === 'other' ? `Phone Other ` : ''
                                                                                                }

                                                                                                (<b>
                                                                                                    {
                                                                                                        item.type === 'work' ? item.phone
                                                                                                            : item.type === 'fax' ? item.phone
                                                                                                                : item.type === 'mobile' ? item.phone
                                                                                                                    : item.type === 'direct' ? item.phone
                                                                                                                        : item.type === 'other' ? item.phone : ''
                                                                                                    }
                                                                                                </b>)

                                                                                                {
                                                                                                    item.selected &&
                                                                                                    <FontAwesomeIcon
                                                                                                        className="dropdown-selected"
                                                                                                        icon={faCaretRight} />
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </animated.div>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className="form-h-sep"></div>
                                                    <div className="input-box-container input-phone-ext">
                                                        <input
                                                            tabIndex={25 + props.tabTimes}
                                                            type="text"
                                                            placeholder="Ext"
                                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                            onKeyDown={e => {
                                                                if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                    let key = e.keyCode || e.which;

                                                                    if (key === 9) {
                                                                        setIsSavingPickupId(selectedShipperCustomer?.pickup_id || 0);
                                                                    }
                                                                }
                                                            }}
                                                            onInput={e => {
                                                                if ((selectedShipperCustomer?.id || 0) === 0) {
                                                                    if ((selectedOrder?.pickups || []).find((p) => p.id === 0) === undefined) {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            pickups: [
                                                                                ...(selectedOrder?.pickups || []),
                                                                                {
                                                                                    id: 0,
                                                                                    contact_phone_ext: e.target.value
                                                                                },
                                                                            ],
                                                                        });
                                                                    } else {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            pickups: (selectedOrder?.pickups || []).map((p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.contact_phone_ext = e.target.value;
                                                                                }
                                                                                return p;
                                                                            }),
                                                                        });
                                                                    }
                                                                } else {
                                                                    setSelectedOrder(_selectedOrder => {
                                                                        return {
                                                                            ..._selectedOrder,
                                                                            pickups: (_selectedOrder?.pickups || []).map((p, i) => {
                                                                                if (p.id === selectedShipperCustomer.pickup_id) {
                                                                                    p.contact_phone_ext = e.target.value;
                                                                                }
                                                                                return p;
                                                                            }),
                                                                        }
                                                                    });
                                                                }

                                                                setSelectedShipperCustomer(_selectedShipperCustomer => {
                                                                    return {
                                                                        ..._selectedShipperCustomer,
                                                                        contact_phone_ext: e.target.value
                                                                    }
                                                                })
                                                            }}
                                                            onChange={e => {
                                                                if ((selectedShipperCustomer?.id || 0) === 0) {
                                                                    if ((selectedOrder?.pickups || []).find((p) => p.id === 0) === undefined) {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            pickups: [
                                                                                ...(selectedOrder?.pickups || []),
                                                                                {
                                                                                    id: 0,
                                                                                    contact_phone_ext: e.target.value
                                                                                },
                                                                            ],
                                                                        });
                                                                    } else {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            pickups: (selectedOrder?.pickups || []).map((p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.contact_phone_ext = e.target.value;
                                                                                }
                                                                                return p;
                                                                            }),
                                                                        });
                                                                    }
                                                                } else {
                                                                    setSelectedOrder(_selectedOrder => {
                                                                        return {
                                                                            ..._selectedOrder,
                                                                            pickups: (_selectedOrder?.pickups || []).map((p, i) => {
                                                                                if (p.id === selectedShipperCustomer.pickup_id) {
                                                                                    p.contact_phone_ext = e.target.value;
                                                                                }
                                                                                return p;
                                                                            }),
                                                                        }
                                                                    });
                                                                }

                                                                setSelectedShipperCustomer(_selectedShipperCustomer => {
                                                                    return {
                                                                        ..._selectedShipperCustomer,
                                                                        contact_phone_ext: e.target.value
                                                                    }
                                                                })
                                                            }}
                                                            value={
                                                                (selectedShipperCustomer?.contact_id || 0) > 0
                                                                    ? (selectedShipperCustomer?.contact_primary_phone || '') === 'work'
                                                                        ? (selectedShipperCustomer?.contacts || []).find(x => x.id === selectedShipperCustomer.contact_id)?.phone_ext || ''
                                                                        : ''
                                                                    : (selectedShipperCustomer?.contact_primary_phone || '') === 'work'
                                                                        ? (selectedShipperCustomer?.contacts || []).find(x => x.is_primary === 1)
                                                                            ? selectedShipperCustomer.contacts.find(x => x.is_primary === 1)?.phone_ext || ''
                                                                            : selectedShipperCustomer?.contact_phone_ext || ''
                                                                        : ''
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </animated.div>
                                        )
                                )}

                                {shipperSecondPageTransition(
                                    (style, item) =>
                                        item && (
                                            <animated.div className="form-page second-page shipper-second-page"
                                                style={{ ...style }}>
                                                <div className="form-row" style={{ alignItems: "center" }}>
                                                    <div className="select-box-container"
                                                        style={{ flexGrow: 1, position: "relative" }}>
                                                        <div className="select-box-wrapper">
                                                            <MaskedInput
                                                                tabIndex={26 + props.tabTimes}
                                                                ref={refPickupDate1}
                                                                mask={[/[0-9]/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/,]}
                                                                guide={false}
                                                                type="text"
                                                                placeholder="PU Date 1"
                                                                autoFocus={true}
                                                                readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                                onKeyDown={async (e) => {
                                                                    if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                        e.stopPropagation();
                                                                        let key = e.keyCode || e.which;
                                                                        await setPuDate1KeyCode(key);

                                                                        let puDate1 = e.target.value.trim() === ""
                                                                            ? moment()
                                                                            : moment(getFormattedDates(selectedShipperCustomer?.pu_date1 || ""), "MM/DD/YYYY");
                                                                        await setPreSelectedPickupDate1(puDate1);

                                                                        if (key === 13) {
                                                                            if (isPickupDate1CalendarShown) {
                                                                                await setSelectedShipperCustomer({
                                                                                    ...selectedShipperCustomer,
                                                                                    pu_date1: preSelectedPickupDate1.clone().format("MM/DD/YYYY"),
                                                                                    pu_date2: preSelectedPickupDate1.clone().format("MM/DD/YYYY"),
                                                                                });

                                                                                await setSelectedOrder({
                                                                                    ...selectedOrder,
                                                                                    pickups: (selectedOrder?.pickups || []).map((pu, i) => {
                                                                                        if (pu.id === (selectedShipperCustomer?.pickup_id || 0)) {
                                                                                            pu.pu_date1 = preSelectedPickupDate1.clone().format("MM/DD/YYYY");
                                                                                            pu.pu_date2 = preSelectedPickupDate1.clone().format("MM/DD/YYYY");
                                                                                            // setIsSavingPickupId(-1);
                                                                                            // setIsSavingPickupId(pu.id);
                                                                                        }
                                                                                        return pu;
                                                                                    }),
                                                                                });

                                                                                await setIsPickupDate1CalendarShown(false);
                                                                                await setIsPickupDate2CalendarShown(false);
                                                                                await setIsDeliveryDate1CalendarShown(false);
                                                                                await setIsDeliveryDate2CalendarShown(false);

                                                                                refPickupTime1.current.focus();
                                                                            }
                                                                        }

                                                                        if (key >= 37 && key <= 40) {
                                                                            if (isPickupDate1CalendarShown) {
                                                                                e.preventDefault();

                                                                                if (key === 37) {
                                                                                    // left - minus 1
                                                                                    setPreSelectedPickupDate1(preSelectedPickupDate1.clone().subtract(1, "day"));
                                                                                }

                                                                                if (key === 38) {
                                                                                    // up - minus 7
                                                                                    setPreSelectedPickupDate1(preSelectedPickupDate1.clone().subtract(7, "day"));
                                                                                }

                                                                                if (key === 39) {
                                                                                    // right - plus 1
                                                                                    setPreSelectedPickupDate1(preSelectedPickupDate1.clone().add(1, "day"));
                                                                                }

                                                                                if (key === 40) {
                                                                                    // down - plus 7
                                                                                    setPreSelectedPickupDate1(preSelectedPickupDate1.clone().add(7, "day"));
                                                                                }

                                                                                await setSelectedOrder({
                                                                                    ...selectedOrder,
                                                                                    pickups: (selectedOrder?.pickups || []).map((pu, i) => {
                                                                                        if (pu.id === (selectedShipperCustomer?.pickup_id || 0)) {
                                                                                            pu.pu_date1 = preSelectedPickupDate1.clone().format("MM/DD/YYYY");
                                                                                        }
                                                                                        return pu;
                                                                                    }),
                                                                                });
                                                                                // await validateOrderForSaving({ keyCode: 9 });
                                                                            } else {
                                                                                if (key === 38 || key === 40) {
                                                                                    setIsPickupDate1CalendarShown(true);
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }}
                                                                onBlur={async (e) => {
                                                                    if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                        if (puDate1KeyCode === 9) {
                                                                            let formatted = getFormattedDates(
                                                                                e.target.value
                                                                            );

                                                                            await setSelectedShipperCustomer({
                                                                                ...selectedShipperCustomer,
                                                                                pu_date1: formatted,
                                                                                pu_date2: formatted,
                                                                            });

                                                                            let pickups = (selectedOrder?.pickups || []).map((pu, i) => {
                                                                                if (pu.id === (selectedShipperCustomer?.pickup_id || 0)) {
                                                                                    pu.pu_date1 = formatted;
                                                                                    pu.pu_date2 = formatted;
                                                                                    // setIsSavingPickupId(-1);
                                                                                    // setIsSavingPickupId(pu.id);
                                                                                }
                                                                                return pu;
                                                                            });

                                                                            await setSelectedOrder({
                                                                                ...selectedOrder,
                                                                                pickups: pickups,
                                                                            });

                                                                            // await setIsPickupDate1CalendarShown(false);
                                                                            // await setIsPickupDate2CalendarShown(false);
                                                                            // await setIsDeliveryDate1CalendarShown(false);
                                                                            // await setIsDeliveryDate2CalendarShown(false);
                                                                        }
                                                                    }
                                                                }}
                                                                onInput={(e) => {
                                                                    setSelectedShipperCustomer({
                                                                        ...selectedShipperCustomer,
                                                                        pu_date1: e.target.value,
                                                                    });

                                                                    setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        pickups: (selectedOrder?.pickups || []).map((pu, i) => {
                                                                            if (pu.id === (selectedShipperCustomer?.pickup_id || 0)) {
                                                                                pu.pu_date1 = e.target.value;
                                                                            }
                                                                            return pu;
                                                                        }
                                                                        ),
                                                                    });
                                                                }}
                                                                onChange={(e) => {
                                                                    setSelectedShipperCustomer({
                                                                        ...selectedShipperCustomer,
                                                                        pu_date1: e.target.value,
                                                                    });

                                                                    setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        pickups: (selectedOrder?.pickups || []).map((pu, i) => {
                                                                            if (pu.id === (selectedShipperCustomer?.pickup_id || 0)) {
                                                                                pu.pu_date1 = e.target.value;
                                                                            }
                                                                            return pu;
                                                                        }
                                                                        ),
                                                                    });
                                                                }}
                                                                value={selectedShipperCustomer?.pu_date1 || ""}
                                                            />

                                                            {
                                                                (selectedOrder?.is_cancelled || 0) === 0 &&
                                                                <FontAwesomeIcon
                                                                    className="dropdown-button calendar"
                                                                    icon={faCalendarAlt}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setIsPickupDate2CalendarShown(false);
                                                                        setIsDeliveryDate1CalendarShown(false);
                                                                        setIsDeliveryDate2CalendarShown(false);

                                                                        if (moment((selectedShipperCustomer?.pu_date1 || "").trim(), "MM/DD/YYYY").format("MM/DD/YYYY") === (selectedShipperCustomer?.pu_date1 || "").trim()) {
                                                                            setPreSelectedPickupDate1(moment(selectedShipperCustomer?.pu_date1, "MM/DD/YYYY"));
                                                                        } else {
                                                                            setPreSelectedPickupDate1(moment());
                                                                        }

                                                                        window.setTimeout(async () => {
                                                                            await setIsPickupDate1CalendarShown(true);
                                                                            refPickupDate1.current.inputElement.focus();
                                                                        }, 0);
                                                                    }}
                                                                />
                                                            }
                                                        </div>
                                                        {puDate1Transition(
                                                            (style, item) =>
                                                                item && (
                                                                    <animated.div
                                                                        className="mochi-contextual-container"
                                                                        id="mochi-contextual-container-pickup-date1"
                                                                        style={{
                                                                            ...style,
                                                                            left: "0%",
                                                                            display: "block",
                                                                        }}
                                                                        ref={refPickupDate1CalendarDropDown}
                                                                    >
                                                                        <div
                                                                            className="mochi-contextual-popup vertical below right"
                                                                            style={{ height: 275 }}
                                                                        >
                                                                            <div className="mochi-contextual-popup-content">
                                                                                <div className="mochi-contextual-popup-wrapper">
                                                                                    <Calendar
                                                                                        value={moment((selectedShipperCustomer?.pu_date1 || "").trim(), "MM/DD/YYYY").format("MM/DD/YYYY") === (selectedShipperCustomer?.pu_date1 || "").trim()
                                                                                            ? moment(selectedShipperCustomer?.pu_date1, "MM/DD/YYYY")
                                                                                            : moment()
                                                                                        }
                                                                                        onChange={(day) => {
                                                                                            setSelectedShipperCustomer(
                                                                                                (selectedShipperCustomer) => {
                                                                                                    return {
                                                                                                        ...selectedShipperCustomer,
                                                                                                        pu_date1: day.format("MM/DD/YYYY"),
                                                                                                        pu_date2: day.format("MM/DD/YYYY"),
                                                                                                    };
                                                                                                }
                                                                                            );

                                                                                            setSelectedOrder(
                                                                                                (selectedOrder) => {
                                                                                                    return {
                                                                                                        ...selectedOrder,
                                                                                                        pickups: (selectedOrder?.pickups || []).map((pu, i) => {
                                                                                                            if (pu.id === (selectedShipperCustomer?.pickup_id || 0)) {
                                                                                                                pu.pu_date1 = day.format("MM/DD/YYYY");
                                                                                                                pu.pu_date2 = day.format("MM/DD/YYYY");
                                                                                                                // setIsSavingPickupId(-1);
                                                                                                                // setIsSavingPickupId(pu.id);
                                                                                                            }
                                                                                                            return pu;
                                                                                                        }),
                                                                                                    };
                                                                                                }
                                                                                            );

                                                                                            setIsPickupDate1CalendarShown(false);
                                                                                            setIsPickupDate2CalendarShown(false);
                                                                                            setIsDeliveryDate1CalendarShown(false);
                                                                                            setIsDeliveryDate2CalendarShown(false);

                                                                                            refPickupTime1.current.focus();
                                                                                        }}
                                                                                        closeCalendar={() => {
                                                                                            setIsPickupDate1CalendarShown(false);
                                                                                        }}
                                                                                        preDay={preSelectedPickupDate1}
                                                                                        onChangePreDay={(preDay) => {
                                                                                            setPreSelectedPickupDate1(preDay);
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </animated.div>
                                                                )
                                                        )}
                                                    </div>
                                                    <div className="form-h-sep"></div>
                                                    <div className="input-box-container grow">
                                                        <input
                                                            tabIndex={27 + props.tabTimes}
                                                            type="text"
                                                            placeholder="PU Time 1"
                                                            maxLength={4}
                                                            ref={refPickupTime1}
                                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                            onKeyDown={(e) => {
                                                                if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                    e.stopPropagation();
                                                                    setPuTime1KeyCode(e.keyCode || e.which);
                                                                }
                                                            }}
                                                            onBlur={async (e) => {
                                                                if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                    if (puTime1KeyCode === 9) {
                                                                        let formatted = getFormattedHours(e.target.value);

                                                                        await setSelectedShipperCustomer({
                                                                            ...selectedShipperCustomer,
                                                                            pu_time1: formatted,
                                                                        });

                                                                        let pickups = (selectedOrder?.pickups || []).map((pu, i) => {
                                                                            if (pu.id === (selectedShipperCustomer?.pickup_id || 0)) {
                                                                                pu.pu_time1 = formatted;
                                                                                // setIsSavingPickupId(-1);
                                                                                // setIsSavingPickupId(pu.id);
                                                                            }
                                                                            return pu;
                                                                        });

                                                                        await setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            pickups: pickups,
                                                                        });
                                                                    }
                                                                }
                                                            }}
                                                            onInput={(e) => {
                                                                setSelectedShipperCustomer({
                                                                    ...selectedShipperCustomer,
                                                                    pu_time1: e.target.value,
                                                                });

                                                                setSelectedOrder({
                                                                    ...selectedOrder,
                                                                    pickups: (selectedOrder?.pickups || []).map((pu, i) => {
                                                                        if (pu.id === (selectedShipperCustomer?.pickup_id || 0)) {
                                                                            pu.pu_time1 = e.target.value;
                                                                        }
                                                                        return pu;
                                                                    }),
                                                                });
                                                            }}
                                                            onChange={(e) => {
                                                                setSelectedShipperCustomer({
                                                                    ...selectedShipperCustomer,
                                                                    pu_time1: e.target.value,
                                                                });

                                                                setSelectedOrder({
                                                                    ...selectedOrder,
                                                                    pickups: (selectedOrder?.pickups || []).map((pu, i) => {
                                                                        if (pu.id === (selectedShipperCustomer?.pickup_id || 0)) {
                                                                            pu.pu_time1 = e.target.value;
                                                                        }
                                                                        return pu;
                                                                    }),
                                                                });
                                                            }}
                                                            value={selectedShipperCustomer?.pu_time1 || ""}
                                                        />
                                                    </div>
                                                    <div
                                                        style={{
                                                            minWidth: "1.8rem",
                                                            fontSize: "1rem",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        To
                                                    </div>
                                                    <div className="select-box-container"
                                                        style={{ flexGrow: 1, position: "relative" }}>
                                                        <div className="select-box-wrapper">
                                                            <MaskedInput
                                                                tabIndex={28 + props.tabTimes}
                                                                ref={refPickupDate2}
                                                                mask={[/[0-9]/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/,]}
                                                                guide={false}
                                                                type="text"
                                                                placeholder="PU Date 2"
                                                                readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                                onKeyDown={async (e) => {
                                                                    if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                        e.stopPropagation();
                                                                        let key = e.keyCode || e.which;
                                                                        await setPuDate2KeyCode(key);

                                                                        let puDate2 = e.target.value.trim() === ""
                                                                            ? moment()
                                                                            : moment(getFormattedDates(selectedShipperCustomer?.pu_date2 || ""), "MM/DD/YYYY");
                                                                        await setPreSelectedPickupDate2(puDate2);

                                                                        if (key === 13) {
                                                                            if (isPickupDate2CalendarShown) {
                                                                                await setSelectedShipperCustomer({
                                                                                    ...selectedShipperCustomer,
                                                                                    pu_date2: preSelectedPickupDate2.clone().format("MM/DD/YYYY")
                                                                                });

                                                                                await setSelectedOrder({
                                                                                    ...selectedOrder,
                                                                                    pickups: (
                                                                                        selectedOrder?.pickups || []
                                                                                    ).map((pu, i) => {
                                                                                        if (pu.id === (selectedShipperCustomer?.pickup_id || 0)) {
                                                                                            pu.pu_date2 = preSelectedPickupDate2.clone().format("MM/DD/YYYY");
                                                                                            // setIsSavingPickupId(-1);
                                                                                            // setIsSavingPickupId(pu.id);
                                                                                        }
                                                                                        return pu;
                                                                                    }),
                                                                                });

                                                                                await setIsPickupDate1CalendarShown(false);
                                                                                await setIsPickupDate2CalendarShown(false);
                                                                                await setIsDeliveryDate1CalendarShown(false);
                                                                                await setIsDeliveryDate2CalendarShown(false);

                                                                                refPickupTime2.current.focus();
                                                                            }
                                                                        }

                                                                        if (key >= 37 && key <= 40) {
                                                                            if (isPickupDate2CalendarShown) {
                                                                                e.preventDefault();

                                                                                if (key === 37) {
                                                                                    // left - minus 1
                                                                                    setPreSelectedPickupDate2(preSelectedPickupDate2.clone().subtract(1, "day"));
                                                                                }

                                                                                if (key === 38) {
                                                                                    // up - minus 7
                                                                                    setPreSelectedPickupDate2(preSelectedPickupDate2.clone().subtract(7, "day"));
                                                                                }

                                                                                if (key === 39) {
                                                                                    // right - plus 1
                                                                                    setPreSelectedPickupDate2(preSelectedPickupDate2.clone().add(1, "day"));
                                                                                }

                                                                                if (key === 40) {
                                                                                    // down - plus 7
                                                                                    setPreSelectedPickupDate2(preSelectedPickupDate2.clone().add(7, "day"));
                                                                                }

                                                                                await setSelectedOrder({
                                                                                    ...selectedOrder,
                                                                                    pickups: (selectedOrder?.pickups || []).map((pu, i) => {
                                                                                        if (pu.id === (selectedShipperCustomer?.pickup_id || 0)) {
                                                                                            pu.pu_date2 = preSelectedPickupDate2.clone().format("MM/DD/YYYY");
                                                                                        }
                                                                                        return pu;
                                                                                    }),
                                                                                });
                                                                            } else {
                                                                                if (key === 38 || key === 40) {
                                                                                    setIsPickupDate2CalendarShown(true);
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }}
                                                                onBlur={async (e) => {
                                                                    if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                        if (puDate2KeyCode === 9) {
                                                                            let formatted = getFormattedDates(
                                                                                e.target.value
                                                                            );

                                                                            await setSelectedShipperCustomer({
                                                                                ...selectedShipperCustomer,
                                                                                pu_date2: formatted,
                                                                            });

                                                                            let pickups = (selectedOrder?.pickups || []).map((pu, i) => {
                                                                                if (pu.id === (selectedShipperCustomer?.pickup_id || 0)) {
                                                                                    pu.pu_date2 = formatted;
                                                                                    // setIsSavingPickupId(-1);
                                                                                    // setIsSavingPickupId(pu.id);
                                                                                }
                                                                                return pu;
                                                                            });

                                                                            await setSelectedOrder({
                                                                                ...selectedOrder,
                                                                                pickups: pickups,
                                                                            });

                                                                            // await setIsPickupDate1CalendarShown(false);
                                                                            // await setIsPickupDate2CalendarShown(false);
                                                                            // await setIsDeliveryDate1CalendarShown(false);
                                                                            // await setIsDeliveryDate2CalendarShown(false);
                                                                        }
                                                                    }
                                                                }}
                                                                onInput={(e) => {
                                                                    setSelectedShipperCustomer({
                                                                        ...selectedShipperCustomer,
                                                                        pu_date2: e.target.value
                                                                    });

                                                                    setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        pickups: (selectedOrder?.pickups || []).map((pu, i) => {
                                                                            if (pu.id === (selectedShipperCustomer?.pickup_id || 0)) {
                                                                                pu.pu_date2 = e.target.value;
                                                                            }
                                                                            return pu;
                                                                        })
                                                                    });
                                                                }}
                                                                onChange={(e) => {
                                                                    setSelectedShipperCustomer({
                                                                        ...selectedShipperCustomer,
                                                                        pu_date2: e.target.value,
                                                                    });

                                                                    setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        pickups: (selectedOrder?.pickups || []).map((pu, i) => {
                                                                            if (pu.id === (selectedShipperCustomer?.pickup_id || 0)) {
                                                                                pu.pu_date2 = e.target.value;
                                                                            }
                                                                            return pu;
                                                                        })
                                                                    });
                                                                }}
                                                                value={selectedShipperCustomer?.pu_date2 || ""}
                                                            />
                                                            {
                                                                (selectedOrder?.is_cancelled || 0) === 0 &&
                                                                <FontAwesomeIcon
                                                                    className="dropdown-button calendar"
                                                                    icon={faCalendarAlt}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setIsPickupDate1CalendarShown(false);
                                                                        setIsDeliveryDate1CalendarShown(false);
                                                                        setIsDeliveryDate2CalendarShown(false);

                                                                        if (moment((selectedShipperCustomer?.pu_date2 || "").trim(), "MM/DD/YYYY").format("MM/DD/YYYY") === (selectedShipperCustomer?.pu_date2 || "").trim()) {
                                                                            setPreSelectedPickupDate2(moment(selectedShipperCustomer?.pu_date2, "MM/DD/YYYY"));
                                                                        } else {
                                                                            setPreSelectedPickupDate2(moment());
                                                                        }

                                                                        window.setTimeout(async () => {
                                                                            await setIsPickupDate2CalendarShown(true);
                                                                            refPickupDate2.current.inputElement.focus();
                                                                        }, 0);
                                                                    }}
                                                                />
                                                            }
                                                        </div>
                                                        {puDate2Transition(
                                                            (style, item) =>
                                                                item && (
                                                                    <animated.div
                                                                        className="mochi-contextual-container"
                                                                        id="mochi-contextual-container-pickup-date2"
                                                                        style={{
                                                                            ...style,
                                                                            left: "0%",
                                                                            display: "block",
                                                                        }}
                                                                        ref={refPickupDate2CalendarDropDown}
                                                                    >
                                                                        <div
                                                                            className="mochi-contextual-popup vertical below right"
                                                                            style={{ height: 275 }}>
                                                                            <div className="mochi-contextual-popup-content">
                                                                                <div className="mochi-contextual-popup-wrapper">
                                                                                    <Calendar
                                                                                        value={
                                                                                            moment((selectedShipperCustomer?.pu_date2 || "").trim(), "MM/DD/YYYY").format("MM/DD/YYYY") === (selectedShipperCustomer?.pu_date2 || "").trim()
                                                                                                ? moment(selectedShipperCustomer?.pu_date2, "MM/DD/YYYY")
                                                                                                : moment()
                                                                                        }
                                                                                        onChange={(day) => {
                                                                                            setSelectedShipperCustomer(
                                                                                                (selectedShipperCustomer) => {
                                                                                                    return {
                                                                                                        ...selectedShipperCustomer,
                                                                                                        pu_date2:
                                                                                                            day.format("MM/DD/YYYY"),
                                                                                                    };
                                                                                                }
                                                                                            );

                                                                                            setSelectedOrder((selectedOrder) => {
                                                                                                return {
                                                                                                    ...selectedOrder,
                                                                                                    pickups: (selectedOrder?.pickups || []).map((pu, i) => {
                                                                                                        if (pu.id === (selectedShipperCustomer?.pickup_id || 0)) {
                                                                                                            pu.pu_date2 = day.format("MM/DD/YYYY");
                                                                                                            // setIsSavingPickupId(-1);
                                                                                                            // setIsSavingPickupId(pu.id);
                                                                                                        }
                                                                                                        return pu;
                                                                                                    }),
                                                                                                };
                                                                                            }
                                                                                            );

                                                                                            setIsPickupDate1CalendarShown(false);
                                                                                            setIsPickupDate2CalendarShown(false);
                                                                                            setIsDeliveryDate1CalendarShown(false);
                                                                                            setIsDeliveryDate2CalendarShown(false);

                                                                                            refPickupTime2.current.focus();
                                                                                        }}
                                                                                        closeCalendar={() => {
                                                                                            setIsPickupDate2CalendarShown(false);
                                                                                        }}
                                                                                        preDay={preSelectedPickupDate2}
                                                                                        onChangePreDay={(preDay) => {
                                                                                            setPreSelectedPickupDate2(preDay);
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </animated.div>
                                                                )
                                                        )}
                                                    </div>
                                                    <div className="form-h-sep"></div>
                                                    <div className="input-box-container grow">
                                                        <input
                                                            tabIndex={29 + props.tabTimes}
                                                            type="text"
                                                            placeholder="PU Time 2"
                                                            maxLength={4}
                                                            ref={refPickupTime2}
                                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                            onKeyDown={(e) => {
                                                                if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                    e.stopPropagation();
                                                                    setPuTime2KeyCode(e.keyCode || e.which);
                                                                }
                                                            }}
                                                            onBlur={async (e) => {
                                                                if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                    if (puTime2KeyCode === 9) {
                                                                        let formatted = getFormattedHours(
                                                                            e.target.value
                                                                        );

                                                                        await setSelectedShipperCustomer({
                                                                            ...selectedShipperCustomer,
                                                                            pu_time2: formatted,
                                                                        });

                                                                        let pickups = (
                                                                            selectedOrder?.pickups || []
                                                                        ).map((pu, i) => {
                                                                            if (
                                                                                pu.id ===
                                                                                (selectedShipperCustomer?.pickup_id || 0)
                                                                            ) {
                                                                                pu.pu_time2 = formatted;
                                                                                // setIsSavingPickupId(-1);
                                                                                // setIsSavingPickupId(pu.id);
                                                                            }
                                                                            return pu;
                                                                        });

                                                                        await setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            pickups: pickups,
                                                                        });
                                                                    }
                                                                }
                                                            }}
                                                            onInput={(e) => {
                                                                setSelectedShipperCustomer({
                                                                    ...selectedShipperCustomer,
                                                                    pu_time2: e.target.value,
                                                                });

                                                                setSelectedOrder({
                                                                    ...selectedOrder,
                                                                    pickups: (selectedOrder?.pickups || []).map(
                                                                        (pu, i) => {
                                                                            if (
                                                                                pu.id ===
                                                                                (selectedShipperCustomer?.pickup_id ||
                                                                                    0)
                                                                            ) {
                                                                                pu.pu_time2 = e.target.value;
                                                                            }
                                                                            return pu;
                                                                        }
                                                                    ),
                                                                });
                                                            }}
                                                            onChange={(e) => {
                                                                setSelectedShipperCustomer({
                                                                    ...selectedShipperCustomer,
                                                                    pu_time2: e.target.value,
                                                                });

                                                                setSelectedOrder({
                                                                    ...selectedOrder,
                                                                    pickups: (selectedOrder?.pickups || []).map(
                                                                        (pu, i) => {
                                                                            if (
                                                                                pu.id ===
                                                                                (selectedShipperCustomer?.pickup_id ||
                                                                                    0)
                                                                            ) {
                                                                                pu.pu_time2 = e.target.value;
                                                                            }
                                                                            return pu;
                                                                        }
                                                                    ),
                                                                });
                                                            }}
                                                            value={selectedShipperCustomer?.pu_time2 || ""}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-row" style={{ alignItems: "center" }}>
                                                    <div className="input-box-container grow"
                                                        style={{ flexGrow: 1, flexBasis: "100%" }}>
                                                        {(selectedShipperCustomer?.bol_numbers || "").split("|").map((item, index) => {
                                                            if (item.trim() !== "") {
                                                                return (
                                                                    <div key={index} style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        fontSize: "0.7rem",
                                                                        backgroundColor: "rgba(0,0,0,0.2)",
                                                                        padding: "2px 10px",
                                                                        borderRadius: "10px",
                                                                        marginRight: "2px",
                                                                        cursor: "default",
                                                                    }}
                                                                        title={item}
                                                                    >
                                                                        {
                                                                            (selectedOrder?.is_cancelled || 0) === 0 &&
                                                                            <span className="fas fa-trash-alt" style={{
                                                                                marginRight: "5px",
                                                                                cursor: "pointer"
                                                                            }} onClick={() => {
                                                                                setSelectedShipperCustomer({
                                                                                    ...selectedShipperCustomer,
                                                                                    bol_numbers: (selectedShipperCustomer?.bol_numbers || "").replace(item, "").trim(),
                                                                                });

                                                                                setSelectedOrder({
                                                                                    ...selectedOrder,
                                                                                    pickups: (selectedOrder?.pickups || []).map((pu, i) => {
                                                                                        if (pu.id === (selectedShipperCustomer?.pickup_id || 0)) {
                                                                                            pu.bol_numbers = (selectedShipperCustomer?.bol_numbers || "").replace(item, "").trim();
                                                                                        }
                                                                                        return pu;
                                                                                    }),
                                                                                });

                                                                                setIsSavingPickupId(selectedShipperCustomer?.pickup_id || 0);
                                                                            }}
                                                                            ></span>
                                                                        }

                                                                        <span
                                                                            className="automatic-email-inputted"
                                                                            style={{ whiteSpace: "nowrap" }}
                                                                        >
                                                                            {item}
                                                                        </span>
                                                                    </div>
                                                                );
                                                            } else {
                                                                return false;
                                                            }
                                                        })}

                                                        <input
                                                            tabIndex={30 + props.tabTimes}
                                                            type="text"
                                                            placeholder="BOL Numbers"
                                                            ref={refBolNumbers}
                                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                            onKeyDown={(e) => {
                                                                if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                    bolNumbersOnKeydown(e);
                                                                }
                                                            }}
                                                            onInput={(e) => {
                                                                setShipperBolNumber(e.target.value);
                                                            }}
                                                            onChange={(e) => {
                                                                setShipperBolNumber(e.target.value);
                                                            }}
                                                            value={shipperBolNumber || ""}
                                                        />
                                                    </div>
                                                    <div
                                                        style={{
                                                            minWidth: "1.8rem",
                                                            fontSize: "1rem",
                                                            textAlign: "center",
                                                        }}
                                                    ></div>
                                                    <div
                                                        className="input-box-container grow"
                                                        style={{ flexGrow: 1, flexBasis: "100%" }}
                                                    >
                                                        {(selectedShipperCustomer?.po_numbers || "").split("|").map((item, index) => {
                                                            if (item.trim() !== "") {
                                                                return (
                                                                    <div
                                                                        key={index}
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            fontSize: "0.7rem",
                                                                            backgroundColor: "rgba(0,0,0,0.2)",
                                                                            padding: "2px 10px",
                                                                            borderRadius: "10px",
                                                                            marginRight: "2px",
                                                                            cursor: "default",
                                                                        }}
                                                                        title={item}
                                                                    >
                                                                        {
                                                                            (selectedOrder?.is_cancelled || 0) === 0 &&
                                                                            <span className="fas fa-trash-alt"
                                                                                style={{
                                                                                    marginRight: "5px",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                                onClick={() => {
                                                                                    setSelectedShipperCustomer({
                                                                                        ...selectedShipperCustomer,
                                                                                        po_numbers: (selectedShipperCustomer?.po_numbers || "").replace(item, "").trim()
                                                                                    });

                                                                                    setSelectedOrder({
                                                                                        ...selectedOrder,
                                                                                        pickups: (selectedOrder?.pickups || []).map((pu, i) => {
                                                                                            if (pu.id === (selectedShipperCustomer?.pickup_id || 0)) {
                                                                                                pu.po_numbers = (selectedShipperCustomer?.po_numbers || "").replace(item, "").trim();
                                                                                            }
                                                                                            return pu;
                                                                                        }),
                                                                                    });

                                                                                    setIsSavingPickupId(selectedShipperCustomer?.pickup_id || 0);
                                                                                }}
                                                                            ></span>
                                                                        }

                                                                        <span className="automatic-email-inputted"
                                                                            style={{ whiteSpace: "nowrap" }}>{item}</span>
                                                                    </div>
                                                                );
                                                            } else {
                                                                return false;
                                                            }
                                                        })}
                                                        <input
                                                            tabIndex={31 + props.tabTimes}
                                                            type="text"
                                                            placeholder="PO Numbers"
                                                            ref={refPoNumbers}
                                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                            onKeyDown={(e) => {
                                                                if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                    poNumbersOnKeydown(e);
                                                                }
                                                            }}
                                                            onInput={(e) => {
                                                                setShipperPoNumber(e.target.value);
                                                            }}
                                                            onChange={(e) => {
                                                                setShipperPoNumber(e.target.value);
                                                            }}
                                                            value={shipperPoNumber || ""}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-row" style={{ alignItems: "center" }}>
                                                    <div className="input-box-container grow"
                                                        style={{ flexGrow: 1, flexBasis: "100%" }}>
                                                        {(selectedShipperCustomer?.ref_numbers || "").split("|").map((item, index) => {
                                                            if (item.trim() !== "") {
                                                                return (
                                                                    <div key={index} style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        fontSize: "0.7rem",
                                                                        backgroundColor: "rgba(0,0,0,0.2)",
                                                                        padding: "2px 10px",
                                                                        borderRadius: "10px",
                                                                        marginRight: "2px",
                                                                        cursor: "default",
                                                                    }}
                                                                        title={item}
                                                                    >
                                                                        {
                                                                            (selectedOrder?.is_cancelled || 0) === 0 &&
                                                                            <span className="fas fa-trash-alt" style={{
                                                                                marginRight: "5px",
                                                                                cursor: "pointer"
                                                                            }}
                                                                                onClick={() => {
                                                                                    setSelectedShipperCustomer({
                                                                                        ...selectedShipperCustomer,
                                                                                        ref_numbers: (selectedShipperCustomer?.ref_numbers || "").replace(item, "").trim()
                                                                                    });

                                                                                    setSelectedOrder({
                                                                                        ...selectedOrder,
                                                                                        pickups: (selectedOrder?.pickups || []).map((pu, i) => {
                                                                                            if (pu.id === selectedShipperCustomer.pickup_id) {
                                                                                                pu.ref_numbers = (selectedShipperCustomer?.ref_numbers || "").replace(item, "").trim();
                                                                                            }
                                                                                            return pu;
                                                                                        }),
                                                                                    });

                                                                                    setIsSavingPickupId(selectedShipperCustomer?.pickup_id || 0);
                                                                                }}
                                                                            ></span>
                                                                        }

                                                                        <span className="automatic-email-inputted"
                                                                            style={{ whiteSpace: "nowrap" }}>{item}</span>
                                                                    </div>
                                                                );
                                                            } else {
                                                                return false;
                                                            }
                                                        })}
                                                        <input
                                                            tabIndex={32 + props.tabTimes}
                                                            type="text"
                                                            placeholder="REF Numbers"
                                                            ref={refRefNumbers}
                                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                            onKeyDown={(e) => {
                                                                if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                    refNumbersOnKeydown(e);
                                                                }
                                                            }}
                                                            onInput={(e) => {
                                                                setShipperRefNumber(e.target.value);
                                                            }}
                                                            onChange={(e) => {
                                                                setShipperRefNumber(e.target.value);
                                                            }}
                                                            value={shipperRefNumber || ""}
                                                        />
                                                    </div>
                                                    <div
                                                        style={{
                                                            minWidth: "1.8rem",
                                                            fontSize: "1rem",
                                                            textAlign: "center",
                                                        }}
                                                    ></div>
                                                    <div
                                                        className="input-box-container grow"
                                                        style={{ flexGrow: 1, flexBasis: "100%" }}
                                                    >
                                                        <input
                                                            tabIndex={33 + props.tabTimes}
                                                            type="text"
                                                            placeholder="SEAL Number"
                                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                            onKeyDown={(e) => {
                                                                if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                    let key = e.keyCode || e.which;

                                                                    if (key === 9) {
                                                                        setIsSavingPickupId(
                                                                            selectedShipperCustomer?.pickup_id || 0
                                                                        );
                                                                    }
                                                                }
                                                            }}
                                                            onInput={(e) => {
                                                                setSelectedOrder({
                                                                    ...selectedOrder,
                                                                    pickups: (selectedOrder?.pickups || []).map((pu, i) => {
                                                                        if (pu.id === (selectedShipperCustomer?.pickup_id || 0)) {
                                                                            pu.seal_number = e.target.value;
                                                                        }
                                                                        return pu;
                                                                    }),
                                                                });

                                                                setSelectedShipperCustomer({
                                                                    ...selectedShipperCustomer,
                                                                    seal_number: e.target.value,
                                                                });
                                                            }}
                                                            onChange={(e) => {
                                                                setSelectedOrder({
                                                                    ...selectedOrder,
                                                                    pickups: (selectedOrder?.pickups || []).map(
                                                                        (pu, i) => {
                                                                            if (pu.id === (selectedShipperCustomer?.pickup_id || 0)) {
                                                                                pu.seal_number = e.target.value;
                                                                            }
                                                                            return pu;
                                                                        }
                                                                    ),
                                                                });

                                                                setSelectedShipperCustomer({
                                                                    ...selectedShipperCustomer,
                                                                    seal_number: e.target.value,
                                                                });
                                                            }}
                                                            value={selectedShipperCustomer?.seal_number || ""}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-row" style={{ alignItems: "center" }}>
                                                    <div className="input-box-container grow">
                                                        <input
                                                            tabIndex={34 + props.tabTimes}
                                                            type="text"
                                                            placeholder="Special Instructions"
                                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                            onKeyDown={(e) => {
                                                                if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                    let key = e.keyCode || e.which;

                                                                    if (key === 9) {
                                                                        e.preventDefault();
                                                                        setIsSavingPickupId(selectedShipperCustomer?.pickup_id || 0);

                                                                        if ((selectedOrder?.id || 0) === 0) {
                                                                            window.alert("You must create or load an order first!");
                                                                            return;
                                                                        }

                                                                        if ((selectedOrder?.load_type_id || 0) === 2) {
                                                                            let panel = {
                                                                                panelName: `${props.panelName}-ltl-units`,
                                                                                component: (
                                                                                    <Ltl
                                                                                        title="LTL Units & Accessorials"
                                                                                        tabTimes={1546484 + props.tabTimes}
                                                                                        panelName={`${props.panelName}-ltl-units`}
                                                                                        origin={props.origin}
                                                                                        componentId={moment().format("x")}
                                                                                        selectedOrderId={selectedOrder.id}
                                                                                        setSelectedOrder={setSelectedOrder}
                                                                                        onEscCallback={() => {
                                                                                            closePanel(`${props.panelName}-ltl-units`, props.origin);
                                                                                            refConsigneeCompanyCode.current.focus();
                                                                                            setIsShowingShipperSecondPage(false);
                                                                                        }}
                                                                                    />
                                                                                ),
                                                                            };

                                                                            openPanel(panel, props.origin);
                                                                        } else {
                                                                            refConsigneeCompanyCode.current.focus();
                                                                            setIsShowingShipperSecondPage(false);
                                                                        }


                                                                    }
                                                                }
                                                            }}
                                                            onInput={(e) => {
                                                                setSelectedOrder({
                                                                    ...selectedOrder,
                                                                    pickups: (selectedOrder?.pickups || []).map(
                                                                        (pu, i) => {
                                                                            if (
                                                                                pu.id ===
                                                                                (selectedShipperCustomer?.pickup_id ||
                                                                                    0)
                                                                            ) {
                                                                                pu.special_instructions =
                                                                                    e.target.value;
                                                                            }
                                                                            return pu;
                                                                        }
                                                                    ),
                                                                });

                                                                setSelectedShipperCustomer({
                                                                    ...selectedShipperCustomer,
                                                                    special_instructions: e.target.value,
                                                                });
                                                            }}
                                                            onChange={(e) => {
                                                                setSelectedOrder({
                                                                    ...selectedOrder,
                                                                    pickups: (selectedOrder?.pickups || []).map(
                                                                        (pu, i) => {
                                                                            if (
                                                                                pu.id ===
                                                                                (selectedShipperCustomer?.pickup_id ||
                                                                                    0)
                                                                            ) {
                                                                                pu.special_instructions =
                                                                                    e.target.value;
                                                                            }
                                                                            return pu;
                                                                        }
                                                                    ),
                                                                });

                                                                setSelectedShipperCustomer({
                                                                    ...selectedShipperCustomer,
                                                                    special_instructions: e.target.value,
                                                                });
                                                            }}
                                                            value={
                                                                selectedShipperCustomer?.special_instructions ||
                                                                ""
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                            </animated.div>
                                        )
                                )}
                            </div>
                        </div>

                        <div
                            className="form-bordered-box"
                            style={{
                                minWidth: "38%",
                                maxWidth: "38%",
                                marginRight: 10,
                                height: "9rem",
                                position: "relative",
                            }}
                        >
                            <div className="form-header">
                                <div className="top-border top-border-left"></div>
                                <div className="form-title" style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 10,
                                    alignItems: 'center'
                                }}>
                                    <span>Consignee</span>
                                    <FontAwesomeIcon icon={faPlus} style={{ cursor: 'pointer' }} onClick={() => {
                                        if ((selectedOrder?.deliveries || []).find(x => x.id === 0)) {
                                            const newDeliveries = (selectedOrder?.deliveries || []).filter(x => x.id !== 0);

                                            setSelectedOrder(prev => {
                                                return {
                                                    ...prev,
                                                    deliveries: newDeliveries
                                                }
                                            })

                                            if (newDeliveries.length > 0) {
                                                setSelectedConsigneeCustomer({
                                                    ...newDeliveries[0].customer,
                                                    delivery_id: newDeliveries[0].id
                                                })

                                                setSelectedConsigneeCustomerContact((newDeliveries[0].customer?.contacts || []).find(c => c.is_primary === 1) || {})
                                            }
                                        }

                                        let panel = {
                                            panelName: `${props.panelName}-customer`,
                                            component: (
                                                <Customers
                                                    pageName={"Customer"}
                                                    title={"Consignee Company"}
                                                    panelName={`${props.panelName}-customer`}
                                                    tabTimes={300043 + props.tabTimes}
                                                    componentId={moment().format("x")}
                                                    isOnPanel={true}
                                                    isAdmin={props.isAdmin}
                                                    origin={props.origin}
                                                    suborigin='dispatch consignee'
                                                    onCustomerChangeCallback={(customer) => {
                                                        // checking if the order is already created
                                                        if ((selectedOrder?.id || 0) > 0) {
                                                            // checking if the customer is already selected as consignee
                                                            if ((selectedConsigneeCustomer?.id || 0) === customer.id) {
                                                                setSelectedOrder(prev => {
                                                                    return {
                                                                        ...prev,
                                                                        deliveries: (prev?.deliveries || []).map((p, i) => {
                                                                            if (p.id === (selectedConsigneeCustomer?.delivery_id || 0)) {
                                                                                p.customer = customer;
                                                                                p.customer_id = customer.id;

                                                                                setSelectedConsigneeCustomer(prev => {
                                                                                    return {
                                                                                        ...prev,
                                                                                        ...customer,
                                                                                        customer: {}
                                                                                    }
                                                                                })

                                                                                setSelectedConsigneeCustomerContact((customer.contacts || []).find((c) => c.is_primary === 1) || {})
                                                                            }
                                                                            return p;
                                                                        })
                                                                    }
                                                                })

                                                                setIsShowingConsigneeSecondPage(true);
                                                            } else { // if the customer is not selected as shipper, is added as new delivery and selected as consignee
                                                                setSelectedOrder((prev) => {
                                                                    return {
                                                                        ...prev,
                                                                        deliveries: [
                                                                            ...(prev?.deliveries || []),
                                                                            {
                                                                                id: 0,
                                                                                order_id: prev.id,
                                                                                customer: { ...customer },
                                                                                customer_id: customer.id
                                                                            }
                                                                        ]
                                                                    };
                                                                });

                                                                setSelectedConsigneeCustomer({
                                                                    ...customer,
                                                                    customer: {},
                                                                    delivery_id: 0
                                                                })

                                                                setSelectedConsigneeCustomerContact((customer?.contacts || []).find((c) => c.is_primary === 1) || {})
                                                                setIsShowingConsigneeSecondPage(true)
                                                                setIsSavingDeliveryId(0)
                                                            }
                                                        } else {
                                                            // if the order is not created yet, check if the customer has the Bill To Code filled
                                                            // and proceed to create the order with the selected customer as bill to customer and consignee
                                                            if ((customer?.bill_to_code || '') !== '') {
                                                                setIsLoading(true);
                                                                const billToCode = (customer?.bill_to_code || '') + ((customer?.bill_to_code_number || 0) === 0 ? '' : customer.bill_to_code_number)

                                                                axios.post(props.serverUrl + "/getCustomerByCode", {
                                                                    code: billToCode,
                                                                    user_code: (props.user?.user_code?.type || 'employee') === 'agent' ? props.user.user_code.code : ''
                                                                }).then((res) => {
                                                                    if (res.data.result === "OK") {
                                                                        setSelectedBillToCustomer(res.data.customer);
                                                                        setSelectedBillToCustomerContact((res.data.customer.contacts || []).find((c) => c.is_primary === 1) || {});

                                                                        setSelectedConsigneeCustomer({
                                                                            ...customer,
                                                                            customer: {},
                                                                            pickup_id: 0
                                                                        })

                                                                        setSelectedConsigneeCustomerContact((customer?.contacts || []).find((c) => c.is_primary === 1) || {})

                                                                        setSelectedOrder((selectedOrder) => {
                                                                            return {
                                                                                ...selectedOrder,
                                                                                bill_to_customer_id: res.data.customer.id,
                                                                                deliveries: [
                                                                                    {
                                                                                        toSave: true,
                                                                                        id: 0,
                                                                                        customer: { ...customer },
                                                                                        customer_id: customer.id
                                                                                    }
                                                                                ]
                                                                            };
                                                                        });

                                                                        validateOrderForSaving({ keyCode: 9 });

                                                                        setIsShowingConsigneeSecondPage(true);
                                                                    }
                                                                    setIsLoading(false);
                                                                }).catch((e) => {
                                                                    console.log("error getting customers", e);
                                                                    setIsLoading(false);
                                                                });
                                                            }
                                                        }
                                                    }}
                                                    closingCallback={() => {
                                                        closePanel(`${props.panelName}-customer`, props.origin);
                                                        (props.isOnPanel ? refOrderNumber : props.refOrderNumber).current.focus({ preventScroll: true });
                                                    }}
                                                />
                                            ),
                                        };

                                        openPanel(panel, props.origin);
                                    }} />
                                </div>
                                <div className="top-border top-border-middle"></div>
                                <div className="form-buttons">
                                    <div className="mochi-button" onClick={() => {
                                        if ((selectedOrder?.id || 0) === 0) {
                                            window.alert("You must create or load an order first!")
                                            return
                                        }

                                        if ((selectedOrder?.deliveries || []).find(x => x.id === 0)) {
                                            const newDeliveries = (selectedOrder?.deliveries || []).filter(x => x.id !== 0);

                                            setSelectedOrder(prev => {
                                                return {
                                                    ...prev,
                                                    deliveries: newDeliveries
                                                }
                                            })

                                            if (newDeliveries.length > 0) {
                                                setSelectedConsigneeCustomer({
                                                    ...newDeliveries[0].customer,
                                                    delivery_id: newDeliveries[0].id
                                                })

                                                setSelectedConsigneeCustomerContact((newDeliveries[0].customer?.contacts || []).find(c => c.is_primary === 1) || {})
                                            }
                                        }

                                        let panel = {
                                            panelName: `${props.panelName}-customer`,
                                            component: (
                                                <Customers
                                                    pageName={"Customer"}
                                                    title={"Consignee Company"}
                                                    panelName={`${props.panelName}-customer`}
                                                    tabTimes={30056440 + props.tabTimes}
                                                    componentId={moment().format("x")}
                                                    isOnPanel={true}
                                                    isAdmin={props.isAdmin}
                                                    origin={props.origin}
                                                    suborigin='dispatch consignee'
                                                    onCustomerChangeCallback={(customer) => {
                                                        // checking if the customer is already selected as consignee
                                                        if ((selectedConsigneeCustomer?.id || 0) === customer.id) {
                                                            setSelectedOrder(prev => {
                                                                return {
                                                                    ...prev,
                                                                    deliveries: (prev?.deliveries || []).map((p, i) => {
                                                                        if (p.id === (selectedConsigneeCustomer?.delivery_id || 0)) {
                                                                            p.customer = customer;
                                                                            p.customer_id = customer.id;

                                                                            setSelectedConsigneeCustomer(prev => {
                                                                                return {
                                                                                    ...prev,
                                                                                    ...customer,
                                                                                    customer: {}
                                                                                }
                                                                            })

                                                                            setSelectedConsigneeCustomerContact((customer.contacts || []).find((c) => c.is_primary === 1) || {})
                                                                        }
                                                                        return p;
                                                                    })
                                                                }
                                                            })

                                                            setIsShowingConsigneeSecondPage(true);
                                                        } else { // if the customer is not selected as consignee, is added as new delivery and selected as consignee
                                                            setSelectedOrder((prev) => {
                                                                return {
                                                                    ...prev,
                                                                    deliveries: [
                                                                        ...(prev?.deliveries || []),
                                                                        {
                                                                            id: 0,
                                                                            order_id: prev.id,
                                                                            customer: { ...customer },
                                                                            customer_id: customer.id
                                                                        }
                                                                    ]
                                                                };
                                                            });

                                                            setSelectedConsigneeCustomer({
                                                                ...customer,
                                                                customer: {},
                                                                delivery_id: 0
                                                            })

                                                            setSelectedConsigneeCustomerContact((customer?.contacts || []).find((c) => c.is_primary === 1) || {})
                                                            setIsShowingConsigneeSecondPage(true)
                                                            setIsSavingDeliveryId(0)
                                                        }
                                                    }}
                                                    closingCallback={() => {
                                                        closePanel(`${props.panelName}-customer`, props.origin);
                                                        (props.isOnPanel ? refOrderNumber : props.refOrderNumber).current.focus({ preventScroll: true });
                                                    }}
                                                />
                                            ),
                                        };

                                        openPanel(panel, props.origin);
                                    }}>
                                        <div className="mochi-button-decorator mochi-button-decorator-left">(</div>
                                        <div className="mochi-button-base">Add New Consignee</div>
                                        <div className="mochi-button-decorator mochi-button-decorator-right">)</div>
                                    </div>
                                    <div className="mochi-button" onClick={consigneeCompanySearch} style={{
                                        pointerEvents: (selectedOrder?.is_cancelled || 0) === 0 ? 'all' : 'none'
                                    }}>
                                        <div className="mochi-button-decorator mochi-button-decorator-left">(</div>
                                        <div className="mochi-button-base" style={{
                                            color: (selectedOrder?.is_cancelled || 0) === 0 ? 'black' : 'rgba(0,0,0,0.4)'
                                        }}>Search
                                        </div>
                                        <div className="mochi-button-decorator mochi-button-decorator-right">)</div>
                                    </div>
                                    <div className="mochi-button" onClick={() => {
                                        if ((selectedConsigneeCustomer.id || 0) === 0) {
                                            window.alert("You must select a customer first!");
                                            return;
                                        }

                                        let panel = {
                                            panelName: `${props.panelName}-customer`,
                                            component: (
                                                <Customers
                                                    pageName={"Customer"}
                                                    title={"Consignee Company"}
                                                    panelName={`${props.panelName}-customer`}
                                                    tabTimes={4000 + props.tabTimes}
                                                    componentId={moment().format("x")}
                                                    isOnPanel={true}
                                                    isAdmin={props.isAdmin}
                                                    origin={props.origin}
                                                    closingCallback={() => {
                                                        closePanel(`${props.panelName}-customer`, props.origin);
                                                        (props.isOnPanel ? refOrderNumber : props.refOrderNumber).current.focus({ preventScroll: true });
                                                    }}

                                                    customer_id={selectedConsigneeCustomer.id}
                                                />
                                            ),
                                        };

                                        openPanel(panel, props.origin);
                                    }}
                                    >
                                        <div className="mochi-button-decorator mochi-button-decorator-left">(</div>
                                        <div className="mochi-button-base">Company info</div>
                                        <div className="mochi-button-decorator mochi-button-decorator-right">)</div>
                                    </div>
                                    {isShowingConsigneeSecondPage && (
                                        <div className="mochi-button" onClick={() => {
                                            setIsShowingConsigneeSecondPage(false);
                                        }}
                                        >
                                            <div className="mochi-button-decorator mochi-button-decorator-left">(</div>
                                            <div className="mochi-button-base">1st Page</div>
                                            <div className="mochi-button-decorator mochi-button-decorator-right">)</div>
                                        </div>
                                    )}
                                    {!isShowingConsigneeSecondPage && (
                                        <div className="mochi-button" onClick={() => {
                                            setIsShowingConsigneeSecondPage(true);
                                        }}
                                        >
                                            <div className="mochi-button-decorator mochi-button-decorator-left">(</div>
                                            <div className="mochi-button-base">2nd Page</div>
                                            <div className="mochi-button-decorator mochi-button-decorator-right">)</div>
                                        </div>
                                    )}
                                </div>
                                <div className="top-border top-border-right"></div>
                            </div>

                            <div className="form-wrapper">
                                <div className="form-row">
                                    <div className="input-box-container input-code">
                                        <input
                                            tabIndex={35 + props.tabTimes}
                                            type="text"
                                            placeholder="Code"
                                            maxLength="8"
                                            ref={refConsigneeCompanyCode}
                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                            onKeyDown={(e) => {
                                                if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                    getConsigneeCompanyByCode(e);
                                                }
                                            }}
                                            onInput={(e) => {
                                                if ((selectedConsigneeCustomer?.id || 0) === 0) {
                                                    if (
                                                        (selectedOrder?.deliveries || []).find(
                                                            (p) => p.id === 0
                                                        ) === undefined
                                                    ) {
                                                        setSelectedOrder({
                                                            ...selectedOrder,
                                                            deliveries: [
                                                                ...(selectedOrder?.deliveries || []),
                                                                {
                                                                    id: 0,
                                                                    customer: {
                                                                        id: 0,
                                                                        code: e.target.value.toUpperCase(),
                                                                    },
                                                                },
                                                            ],
                                                        });
                                                    } else {
                                                        setSelectedOrder({
                                                            ...selectedOrder,
                                                            deliveries: (selectedOrder?.deliveries || []).map(
                                                                (p, i) => {
                                                                    if (p.id === 0) {
                                                                        p.customer = {
                                                                            ...p.customer,
                                                                            code: e.target.value.toUpperCase(),
                                                                        };
                                                                    }
                                                                    return p;
                                                                }
                                                            ),
                                                        });
                                                    }

                                                    setSelectedConsigneeCustomer({
                                                        ...selectedConsigneeCustomer,
                                                        code: e.target.value.toUpperCase(),
                                                        code_number: 0,
                                                    });
                                                } else {
                                                    setSelectedOrder({
                                                        ...selectedOrder,
                                                        deliveries: (selectedOrder?.deliveries || []).map(
                                                            (p, i) => {
                                                                if (p.id === selectedConsigneeCustomer?.id) {
                                                                    p.customer = {
                                                                        ...p.customer,
                                                                        code: e.target.value.toUpperCase(),
                                                                    };
                                                                }
                                                                return p;
                                                            }
                                                        ),
                                                    });

                                                    setSelectedConsigneeCustomer({
                                                        ...selectedConsigneeCustomer,
                                                        code: e.target.value.toUpperCase(),
                                                        code_number: 0,
                                                    });
                                                }
                                            }}
                                            onChange={(e) => {
                                                if ((selectedConsigneeCustomer?.id || 0) === 0) {
                                                    if (
                                                        (selectedOrder?.deliveries || []).find(
                                                            (p) => p.id === 0
                                                        ) === undefined
                                                    ) {
                                                        setSelectedOrder({
                                                            ...selectedOrder,
                                                            deliveries: [
                                                                ...(selectedOrder?.deliveries || []),
                                                                {
                                                                    id: 0,
                                                                    customer: {
                                                                        id: 0,
                                                                        code: e.target.value.toUpperCase(),
                                                                    },
                                                                },
                                                            ],
                                                        });
                                                    } else {
                                                        setSelectedOrder({
                                                            ...selectedOrder,
                                                            deliveries: (selectedOrder?.deliveries || []).map(
                                                                (p, i) => {
                                                                    if (p.id === 0) {
                                                                        p.customer = {
                                                                            ...p.customer,
                                                                            code: e.target.value.toUpperCase(),
                                                                        };
                                                                    }
                                                                    return p;
                                                                }
                                                            ),
                                                        });
                                                    }

                                                    setSelectedConsigneeCustomer({
                                                        ...selectedConsigneeCustomer,
                                                        code: e.target.value.toUpperCase(),
                                                        code_number: 0,
                                                    });
                                                } else {
                                                    setSelectedOrder({
                                                        ...selectedOrder,
                                                        deliveries: (selectedOrder?.deliveries || []).map(
                                                            (p, i) => {
                                                                if (p.id === selectedConsigneeCustomer?.id) {
                                                                    p.customer = {
                                                                        ...p.customer,
                                                                        code: e.target.value.toUpperCase(),
                                                                    };
                                                                }
                                                                return p;
                                                            }
                                                        ),
                                                    });

                                                    setSelectedConsigneeCustomer({
                                                        ...selectedConsigneeCustomer,
                                                        code: e.target.value.toUpperCase(),
                                                        code_number: 0,
                                                    });
                                                }
                                            }}
                                            value={
                                                (selectedConsigneeCustomer.code_number || 0) === 0
                                                    ? selectedConsigneeCustomer.code || ""
                                                    : selectedConsigneeCustomer.code +
                                                    selectedConsigneeCustomer.code_number
                                            }
                                        />
                                    </div>
                                    <div className="form-h-sep"></div>
                                    <div className="input-box-container grow">
                                        <input
                                            tabIndex={36 + props.tabTimes}
                                            type="text"
                                            placeholder="Name"
                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                            onInput={(e) => {
                                                if ((selectedConsigneeCustomer?.id || 0) === 0) {
                                                    if (
                                                        (selectedOrder?.deliveries || []).find(
                                                            (p) => p.id === 0
                                                        ) === undefined
                                                    ) {
                                                        setSelectedOrder({
                                                            ...selectedOrder,
                                                            deliveries: [
                                                                ...(selectedOrder?.deliveries || []),
                                                                {
                                                                    id: 0,
                                                                    customer: {
                                                                        id: 0,
                                                                        name: e.target.value,
                                                                    },
                                                                },
                                                            ],
                                                        });
                                                    } else {
                                                        setSelectedOrder({
                                                            ...selectedOrder,
                                                            deliveries: (selectedOrder?.deliveries || []).map(
                                                                (p, i) => {
                                                                    if (p.id === 0) {
                                                                        p.customer = {
                                                                            ...p.customer,
                                                                            name: e.target.value,
                                                                        };
                                                                    }
                                                                    return p;
                                                                }
                                                            ),
                                                        });
                                                    }

                                                    setSelectedConsigneeCustomer({
                                                        ...selectedConsigneeCustomer,
                                                        id: 0,
                                                        name: e.target.value,
                                                    });
                                                } else {
                                                    setSelectedOrder({
                                                        ...selectedOrder,
                                                        deliveries: (selectedOrder?.deliveries || []).map(
                                                            (p, i) => {
                                                                if (p.id === selectedConsigneeCustomer?.id) {
                                                                    p.customer = {
                                                                        ...p.customer,
                                                                        name: e.target.value,
                                                                    };
                                                                }
                                                                return p;
                                                            }
                                                        ),
                                                    });

                                                    setSelectedConsigneeCustomer({
                                                        ...selectedConsigneeCustomer,
                                                        name: e.target.value,
                                                    });
                                                }
                                            }}
                                            onChange={(e) => {
                                                if ((selectedConsigneeCustomer?.id || 0) === 0) {
                                                    if (
                                                        (selectedOrder?.deliveries || []).find(
                                                            (p) => p.id === 0
                                                        ) === undefined
                                                    ) {
                                                        setSelectedOrder({
                                                            ...selectedOrder,
                                                            deliveries: [
                                                                ...(selectedOrder?.deliveries || []),
                                                                {
                                                                    id: 0,
                                                                    customer: {
                                                                        id: 0,
                                                                        name: e.target.value,
                                                                    },
                                                                },
                                                            ],
                                                        });
                                                    } else {
                                                        setSelectedOrder({
                                                            ...selectedOrder,
                                                            deliveries: (selectedOrder?.deliveries || []).map(
                                                                (p, i) => {
                                                                    if (p.id === 0) {
                                                                        p.customer = {
                                                                            ...p.customer,
                                                                            name: e.target.value,
                                                                        };
                                                                    }
                                                                    return p;
                                                                }
                                                            ),
                                                        });
                                                    }

                                                    setSelectedConsigneeCustomer({
                                                        ...selectedConsigneeCustomer,
                                                        id: 0,
                                                        name: e.target.value,
                                                    });
                                                } else {
                                                    setSelectedOrder({
                                                        ...selectedOrder,
                                                        deliveries: (selectedOrder?.deliveries || []).map(
                                                            (p, i) => {
                                                                if (p.id === selectedConsigneeCustomer.id) {
                                                                    p.customer = {
                                                                        ...p.customer,
                                                                        name: e.target.value,
                                                                    };
                                                                }
                                                                return p;
                                                            }
                                                        ),
                                                    });

                                                    setSelectedConsigneeCustomer({
                                                        ...selectedConsigneeCustomer,
                                                        name: e.target.value,
                                                    });
                                                }
                                            }}
                                            value={selectedConsigneeCustomer?.name || ""}
                                        />
                                    </div>
                                </div>

                                {consigneeFirstPageTransition(
                                    (style, item) =>
                                        item && (
                                            <animated.div
                                                className="form-page first-page consignee-first-page"
                                                style={{
                                                    ...style,
                                                }}
                                            >
                                                <div className="form-row">
                                                    <div className="input-box-container grow">
                                                        <input
                                                            tabIndex={37 + props.tabTimes}
                                                            type="text"
                                                            style={{ textTransform: 'capitalize' }}
                                                            placeholder="Address 1"
                                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                            onInput={(e) => {
                                                                if (
                                                                    (selectedConsigneeCustomer?.id || 0) === 0
                                                                ) {
                                                                    if (
                                                                        (selectedOrder?.deliveries || []).find(
                                                                            (p) => p.id === 0
                                                                        ) === undefined
                                                                    ) {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            deliveries: [
                                                                                ...(selectedOrder?.deliveries || []),
                                                                                {
                                                                                    id: 0,
                                                                                    customer: {
                                                                                        id: 0,
                                                                                        address1: e.target.value,
                                                                                    },
                                                                                },
                                                                            ],
                                                                        });
                                                                    } else {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            deliveries: (
                                                                                selectedOrder?.deliveries || []
                                                                            ).map((p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.customer = {
                                                                                        ...p.customer,
                                                                                        address1: e.target.value,
                                                                                    };
                                                                                }
                                                                                return p;
                                                                            }),
                                                                        });
                                                                    }

                                                                    setSelectedConsigneeCustomer({
                                                                        ...selectedConsigneeCustomer,
                                                                        id: 0,
                                                                        address1: e.target.value,
                                                                    });
                                                                } else {
                                                                    setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        deliveries: (
                                                                            selectedOrder?.deliveries || []
                                                                        ).map((p, i) => {
                                                                            if (
                                                                                p.id === selectedConsigneeCustomer?.id
                                                                            ) {
                                                                                p.customer = {
                                                                                    ...p.customer,
                                                                                    address1: e.target.value,
                                                                                };
                                                                            }
                                                                            return p;
                                                                        }),
                                                                    });

                                                                    setSelectedConsigneeCustomer({
                                                                        ...selectedConsigneeCustomer,
                                                                        address1: e.target.value,
                                                                    });
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                if (
                                                                    (selectedConsigneeCustomer?.id || 0) === 0
                                                                ) {
                                                                    if (
                                                                        (selectedOrder?.deliveries || []).find(
                                                                            (p) => p.id === 0
                                                                        ) === undefined
                                                                    ) {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            deliveries: [
                                                                                ...(selectedOrder?.deliveries || []),
                                                                                {
                                                                                    id: 0,
                                                                                    customer: {
                                                                                        id: 0,
                                                                                        address1: e.target.value,
                                                                                    },
                                                                                },
                                                                            ],
                                                                        });
                                                                    } else {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            deliveries: (
                                                                                selectedOrder?.deliveries || []
                                                                            ).map((p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.customer = {
                                                                                        ...p.customer,
                                                                                        address1: e.target.value,
                                                                                    };
                                                                                }
                                                                                return p;
                                                                            }),
                                                                        });
                                                                    }

                                                                    setSelectedConsigneeCustomer({
                                                                        ...selectedConsigneeCustomer,
                                                                        id: 0,
                                                                        address1: e.target.value,
                                                                    });
                                                                } else {
                                                                    setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        deliveries: (
                                                                            selectedOrder?.deliveries || []
                                                                        ).map((p, i) => {
                                                                            if (p.id === 0) {
                                                                                p.customer = {
                                                                                    ...p.customer,
                                                                                    address1: e.target.value,
                                                                                };
                                                                            }
                                                                            return p;
                                                                        }),
                                                                    });

                                                                    setSelectedConsigneeCustomer({
                                                                        ...selectedConsigneeCustomer,
                                                                        address1: e.target.value,
                                                                    });
                                                                }
                                                            }}
                                                            value={selectedConsigneeCustomer?.address1 || ""}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="input-box-container grow">
                                                        <input
                                                            tabIndex={38 + props.tabTimes}
                                                            type="text"
                                                            style={{ textTransform: 'capitalize' }}
                                                            placeholder="Address 2"
                                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                            onInput={(e) => {
                                                                if (
                                                                    (selectedConsigneeCustomer?.id || 0) === 0
                                                                ) {
                                                                    if (
                                                                        (selectedOrder?.deliveries || []).find(
                                                                            (p) => p.id === 0
                                                                        ) === undefined
                                                                    ) {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            deliveries: [
                                                                                ...(selectedOrder?.deliveries || []),
                                                                                {
                                                                                    id: 0,
                                                                                    customer: {
                                                                                        id: 0,
                                                                                        address2: e.target.value,
                                                                                    },
                                                                                },
                                                                            ],
                                                                        });
                                                                    } else {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            deliveries: (
                                                                                selectedOrder?.deliveries || []
                                                                            ).map((p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.customer = {
                                                                                        ...p.customer,
                                                                                        address2: e.target.value,
                                                                                    };
                                                                                }
                                                                                return p;
                                                                            }),
                                                                        });
                                                                    }

                                                                    setSelectedConsigneeCustomer({
                                                                        ...selectedConsigneeCustomer,
                                                                        id: 0,
                                                                        address2: e.target.value,
                                                                    });
                                                                } else {
                                                                    setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        deliveries: (
                                                                            selectedOrder?.deliveries || []
                                                                        ).map((p, i) => {
                                                                            if (
                                                                                p.id === selectedConsigneeCustomer?.id
                                                                            ) {
                                                                                p.customer = {
                                                                                    ...p.customer,
                                                                                    address2: e.target.value,
                                                                                };
                                                                            }
                                                                            return p;
                                                                        }),
                                                                    });

                                                                    setSelectedConsigneeCustomer({
                                                                        ...selectedConsigneeCustomer,
                                                                        address2: e.target.value,
                                                                    });
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                if (
                                                                    (selectedConsigneeCustomer?.id || 0) === 0
                                                                ) {
                                                                    if (
                                                                        (selectedOrder?.deliveries || []).find(
                                                                            (p) => p.id === 0
                                                                        ) === undefined
                                                                    ) {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            deliveries: [
                                                                                ...(selectedOrder?.deliveries || []),
                                                                                {
                                                                                    id: 0,
                                                                                    customer: {
                                                                                        id: 0,
                                                                                        address2: e.target.value,
                                                                                    },
                                                                                },
                                                                            ],
                                                                        });
                                                                    } else {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            deliveries: (
                                                                                selectedOrder?.deliveries || []
                                                                            ).map((p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.customer = {
                                                                                        ...p.customer,
                                                                                        address2: e.target.value,
                                                                                    };
                                                                                }
                                                                                return p;
                                                                            }),
                                                                        });
                                                                    }

                                                                    setSelectedConsigneeCustomer({
                                                                        ...selectedConsigneeCustomer,
                                                                        id: 0,
                                                                        address2: e.target.value,
                                                                    });
                                                                } else {
                                                                    setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        deliveries: (
                                                                            selectedOrder?.deliveries || []
                                                                        ).map((p, i) => {
                                                                            if (p.id === 0) {
                                                                                p.customer = {
                                                                                    ...p.customer,
                                                                                    address2: e.target.value,
                                                                                };
                                                                            }
                                                                            return p;
                                                                        }),
                                                                    });

                                                                    setSelectedConsigneeCustomer({
                                                                        ...selectedConsigneeCustomer,
                                                                        address2: e.target.value,
                                                                    });
                                                                }
                                                            }}
                                                            value={selectedConsigneeCustomer?.address2 || ""}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="input-box-container grow">
                                                        <input
                                                            tabIndex={39 + props.tabTimes}
                                                            type="text"
                                                            style={{ textTransform: 'capitalize' }}
                                                            placeholder="City"
                                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                            onInput={(e) => {
                                                                if (
                                                                    (selectedConsigneeCustomer?.id || 0) === 0
                                                                ) {
                                                                    if (
                                                                        (selectedOrder?.deliveries || []).find(
                                                                            (p) => p.id === 0
                                                                        ) === undefined
                                                                    ) {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            deliveries: [
                                                                                ...(selectedOrder?.deliveries || []),
                                                                                {
                                                                                    id: 0,
                                                                                    customer: {
                                                                                        id: 0,
                                                                                        city: e.target.value,
                                                                                    },
                                                                                },
                                                                            ],
                                                                        });
                                                                    } else {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            deliveries: (
                                                                                selectedOrder?.deliveries || []
                                                                            ).map((p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.customer = {
                                                                                        ...p.customer,
                                                                                        city: e.target.value,
                                                                                    };
                                                                                }
                                                                                return p;
                                                                            }),
                                                                        });
                                                                    }

                                                                    setSelectedConsigneeCustomer({
                                                                        ...selectedConsigneeCustomer,
                                                                        id: 0,
                                                                        city: e.target.value,
                                                                    });
                                                                } else {
                                                                    setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        deliveries: (
                                                                            selectedOrder?.deliveries || []
                                                                        ).map((p, i) => {
                                                                            if (p.id === 0) {
                                                                                p.customer = {
                                                                                    ...p.customer,
                                                                                    city: e.target.value,
                                                                                };
                                                                            }
                                                                            return p;
                                                                        }),
                                                                    });

                                                                    setSelectedConsigneeCustomer({
                                                                        ...selectedConsigneeCustomer,
                                                                        city: e.target.value,
                                                                    });
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                if (
                                                                    (selectedConsigneeCustomer?.id || 0) === 0
                                                                ) {
                                                                    if (
                                                                        (selectedOrder?.deliveries || []).find(
                                                                            (p) => p.id === 0
                                                                        ) === undefined
                                                                    ) {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            deliveries: [
                                                                                ...(selectedOrder?.deliveries || []),
                                                                                {
                                                                                    id: 0,
                                                                                    customer: {
                                                                                        id: 0,
                                                                                        city: e.target.value,
                                                                                    },
                                                                                },
                                                                            ],
                                                                        });
                                                                    } else {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            deliveries: (
                                                                                selectedOrder?.deliveries || []
                                                                            ).map((p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.customer = {
                                                                                        ...p.customer,
                                                                                        city: e.target.value,
                                                                                    };
                                                                                }
                                                                                return p;
                                                                            }),
                                                                        });
                                                                    }

                                                                    setSelectedConsigneeCustomer({
                                                                        ...selectedConsigneeCustomer,
                                                                        id: 0,
                                                                        city: e.target.value,
                                                                    });
                                                                } else {
                                                                    setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        deliveries: (
                                                                            selectedOrder?.deliveries || []
                                                                        ).map((p, i) => {
                                                                            if (p.id === 0) {
                                                                                p.customer = {
                                                                                    ...p.customer,
                                                                                    city: e.target.value,
                                                                                };
                                                                            }
                                                                            return p;
                                                                        }),
                                                                    });

                                                                    setSelectedConsigneeCustomer({
                                                                        ...selectedConsigneeCustomer,
                                                                        city: e.target.value,
                                                                    });
                                                                }
                                                            }}
                                                            value={selectedConsigneeCustomer?.city || ""}
                                                        />
                                                    </div>
                                                    <div className="form-h-sep"></div>
                                                    <div className="input-box-container input-state">
                                                        <input
                                                            tabIndex={40 + props.tabTimes}
                                                            type="text"
                                                            placeholder="State"
                                                            maxLength="2"
                                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                            onInput={(e) => {
                                                                if (
                                                                    (selectedConsigneeCustomer?.id || 0) === 0
                                                                ) {
                                                                    if (
                                                                        (selectedOrder?.deliveries || []).find(
                                                                            (p) => p.id === 0
                                                                        ) === undefined
                                                                    ) {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            deliveries: [
                                                                                ...(selectedOrder?.deliveries || []),
                                                                                {
                                                                                    id: 0,
                                                                                    customer: {
                                                                                        id: 0,
                                                                                        state: e.target.value.toUpperCase(),
                                                                                    },
                                                                                },
                                                                            ],
                                                                        });
                                                                    } else {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            deliveries: (
                                                                                selectedOrder?.deliveries || []
                                                                            ).map((p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.customer = {
                                                                                        ...p.customer,
                                                                                        state: e.target.value.toUpperCase(),
                                                                                    };
                                                                                }
                                                                                return p;
                                                                            }),
                                                                        });
                                                                    }

                                                                    setSelectedConsigneeCustomer({
                                                                        ...selectedConsigneeCustomer,
                                                                        id: 0,
                                                                        state: e.target.value.toUpperCase(),
                                                                    });
                                                                } else {
                                                                    setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        deliveries: (
                                                                            selectedOrder?.deliveries || []
                                                                        ).map((p, i) => {
                                                                            if (p.id === 0) {
                                                                                p.customer = {
                                                                                    ...p.customer,
                                                                                    state: e.target.value.toUpperCase(),
                                                                                };
                                                                            }
                                                                            return p;
                                                                        }),
                                                                    });

                                                                    setSelectedConsigneeCustomer({
                                                                        ...selectedConsigneeCustomer,
                                                                        state: e.target.value.toUpperCase(),
                                                                    });
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                if (
                                                                    (selectedConsigneeCustomer?.id || 0) === 0
                                                                ) {
                                                                    if (
                                                                        (selectedOrder?.deliveries || []).find(
                                                                            (p) => p.id === 0
                                                                        ) === undefined
                                                                    ) {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            deliveries: [
                                                                                ...(selectedOrder?.deliveries || []),
                                                                                {
                                                                                    id: 0,
                                                                                    customer: {
                                                                                        id: 0,
                                                                                        state: e.target.value.toUpperCase(),
                                                                                    },
                                                                                },
                                                                            ],
                                                                        });
                                                                    } else {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            deliveries: (
                                                                                selectedOrder?.deliveries || []
                                                                            ).map((p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.customer = {
                                                                                        ...p.customer,
                                                                                        state: e.target.value.toUpperCase(),
                                                                                    };
                                                                                }
                                                                                return p;
                                                                            }),
                                                                        });
                                                                    }

                                                                    setSelectedConsigneeCustomer({
                                                                        ...selectedConsigneeCustomer,
                                                                        id: 0,
                                                                        state: e.target.value.toUpperCase(),
                                                                    });
                                                                } else {
                                                                    setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        deliveries: (
                                                                            selectedOrder?.deliveries || []
                                                                        ).map((p, i) => {
                                                                            if (p.id === 0) {
                                                                                p.customer = {
                                                                                    ...p.customer,
                                                                                    state: e.target.value.toUpperCase(),
                                                                                };
                                                                            }
                                                                            return p;
                                                                        }),
                                                                    });

                                                                    setSelectedConsigneeCustomer({
                                                                        ...selectedConsigneeCustomer,
                                                                        state: e.target.value.toUpperCase(),
                                                                    });
                                                                }
                                                            }}
                                                            value={selectedConsigneeCustomer?.state || ""}
                                                        />
                                                    </div>
                                                    <div className="form-h-sep"></div>
                                                    <div className="input-box-container input-zip-code">
                                                        <input
                                                            tabIndex={41 + props.tabTimes}
                                                            type="text"
                                                            placeholder="Postal Code"
                                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                            onInput={(e) => {
                                                                if (
                                                                    (selectedConsigneeCustomer?.id || 0) === 0
                                                                ) {
                                                                    if (
                                                                        (selectedOrder?.deliveries || []).find(
                                                                            (p) => p.id === 0
                                                                        ) === undefined
                                                                    ) {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            deliveries: [
                                                                                ...(selectedOrder?.deliveries || []),
                                                                                {
                                                                                    id: 0,
                                                                                    customer: {
                                                                                        id: 0,
                                                                                        zip: e.target.value,
                                                                                    },
                                                                                },
                                                                            ],
                                                                        });
                                                                    } else {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            deliveries: (
                                                                                selectedOrder?.deliveries || []
                                                                            ).map((p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.customer = {
                                                                                        ...p.customer,
                                                                                        zip: e.target.value,
                                                                                    };
                                                                                }
                                                                                return p;
                                                                            }),
                                                                        });
                                                                    }

                                                                    setSelectedConsigneeCustomer({
                                                                        ...selectedConsigneeCustomer,
                                                                        id: 0,
                                                                        zip: e.target.value,
                                                                    });
                                                                } else {
                                                                    setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        deliveries: (
                                                                            selectedOrder?.deliveries || []
                                                                        ).map((p, i) => {
                                                                            if (p.id === 0) {
                                                                                p.customer = {
                                                                                    ...p.customer,
                                                                                    zip: e.target.value,
                                                                                };
                                                                            }
                                                                            return p;
                                                                        }),
                                                                    });

                                                                    setSelectedConsigneeCustomer({
                                                                        ...selectedConsigneeCustomer,
                                                                        zip: e.target.value,
                                                                    });
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                if (
                                                                    (selectedConsigneeCustomer?.id || 0) === 0
                                                                ) {
                                                                    if (
                                                                        (selectedOrder?.deliveries || []).find(
                                                                            (p) => p.id === 0
                                                                        ) === undefined
                                                                    ) {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            deliveries: [
                                                                                ...(selectedOrder?.deliveries || []),
                                                                                {
                                                                                    id: 0,
                                                                                    customer: {
                                                                                        id: 0,
                                                                                        zip: e.target.value,
                                                                                    },
                                                                                },
                                                                            ],
                                                                        });
                                                                    } else {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            deliveries: (
                                                                                selectedOrder?.deliveries || []
                                                                            ).map((p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.customer = {
                                                                                        ...p.customer,
                                                                                        zip: e.target.value,
                                                                                    };
                                                                                }
                                                                                return p;
                                                                            }),
                                                                        });
                                                                    }

                                                                    setSelectedConsigneeCustomer({
                                                                        ...selectedConsigneeCustomer,
                                                                        id: 0,
                                                                        zip: e.target.value,
                                                                    });
                                                                } else {
                                                                    setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        deliveries: (
                                                                            selectedOrder?.deliveries || []
                                                                        ).map((p, i) => {
                                                                            if (p.id === 0) {
                                                                                p.customer = {
                                                                                    ...p.customer,
                                                                                    zip: e.target.value,
                                                                                };
                                                                            }
                                                                            return p;
                                                                        }),
                                                                    });

                                                                    setSelectedConsigneeCustomer({
                                                                        ...selectedConsigneeCustomer,
                                                                        zip: e.target.value,
                                                                    });
                                                                }
                                                            }}
                                                            value={selectedConsigneeCustomer?.zip || ""}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="select-box-container" style={{ flexGrow: 1 }}>
                                                        <div className="select-box-wrapper">
                                                            <input
                                                                tabIndex={42 + props.tabTimes}
                                                                type="text"
                                                                placeholder="Contact Name"
                                                                style={{ textTransform: 'capitalize' }}
                                                                ref={refConsigneeContactName}
                                                                readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                                onKeyDown={async (e) => {
                                                                    if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                        let key = e.keyCode || e.which;

                                                                        switch (key) {
                                                                            case 37:
                                                                            case 38: // arrow left | arrow up
                                                                                if (showConsigneeContactNames) {
                                                                                    let selectedIndex = consigneeContactNameItems.findIndex(item => item.selected);

                                                                                    if (selectedIndex === -1) {
                                                                                        await setConsigneeContactNameItems(consigneeContactNameItems.map((item, index) => {
                                                                                            item.selected = index === 0;
                                                                                            return item;
                                                                                        }))
                                                                                    } else {
                                                                                        await setConsigneeContactNameItems(consigneeContactNameItems.map((item, index) => {
                                                                                            if (selectedIndex === 0) {
                                                                                                item.selected = index === (consigneeContactNameItems.length - 1);
                                                                                            } else {
                                                                                                item.selected = index === (selectedIndex - 1)
                                                                                            }
                                                                                            return item;
                                                                                        }))
                                                                                    }

                                                                                    refConsigneeContactNamePopupItems.current.map((r, i) => {
                                                                                        if (r && r.classList.contains('selected')) {
                                                                                            r.scrollIntoView({
                                                                                                behavior: 'auto',
                                                                                                block: 'center',
                                                                                                inline: 'nearest'
                                                                                            })
                                                                                        }
                                                                                        return true;
                                                                                    });
                                                                                } else {
                                                                                    if ((selectedConsigneeCustomer?.contacts || []).length > 0) {
                                                                                        await setConsigneeContactNameItems((selectedConsigneeCustomer?.contacts || []).map((item, index) => {
                                                                                            item.selected = index === 0
                                                                                            return item;
                                                                                        }))

                                                                                        setShowConsigneeContactNames(true);

                                                                                        refConsigneeContactNamePopupItems.current.map((r, i) => {
                                                                                            if (r && r.classList.contains('selected')) {
                                                                                                r.scrollIntoView({
                                                                                                    behavior: 'auto',
                                                                                                    block: 'center',
                                                                                                    inline: 'nearest'
                                                                                                })
                                                                                            }
                                                                                            return true;
                                                                                        });
                                                                                    }
                                                                                }
                                                                                break;

                                                                            case 39:
                                                                            case 40: // arrow right | arrow down

                                                                                if (showConsigneeContactNames) {
                                                                                    let selectedIndex = consigneeContactNameItems.findIndex(item => item.selected);

                                                                                    if (selectedIndex === -1) {
                                                                                        await setConsigneeContactNameItems(consigneeContactNameItems.map((item, index) => {
                                                                                            item.selected = index === 0;
                                                                                            return item;
                                                                                        }))
                                                                                    } else {
                                                                                        await setConsigneeContactNameItems(consigneeContactNameItems.map((item, index) => {
                                                                                            if (selectedIndex === (consigneeContactNameItems.length - 1)) {
                                                                                                item.selected = index === 0;
                                                                                            } else {
                                                                                                item.selected = index === (selectedIndex + 1)
                                                                                            }
                                                                                            return item;
                                                                                        }))
                                                                                    }

                                                                                    refConsigneeContactNamePopupItems.current.map((r, i) => {
                                                                                        if (r && r.classList.contains('selected')) {
                                                                                            r.scrollIntoView({
                                                                                                behavior: 'auto',
                                                                                                block: 'center',
                                                                                                inline: 'nearest'
                                                                                            })
                                                                                        }
                                                                                        return true;
                                                                                    });
                                                                                } else {
                                                                                    if ((selectedConsigneeCustomer?.contacts || []).length > 0) {
                                                                                        await setConsigneeContactNameItems((selectedConsigneeCustomer?.contacts || []).map((item, index) => {
                                                                                            item.selected = index === 0
                                                                                            return item;
                                                                                        }))

                                                                                        setShowConsigneeContactNames(true);

                                                                                        refConsigneeContactNamePopupItems.current.map((r, i) => {
                                                                                            if (r && r.classList.contains('selected')) {
                                                                                                r.scrollIntoView({
                                                                                                    behavior: 'auto',
                                                                                                    block: 'center',
                                                                                                    inline: 'nearest'
                                                                                                })
                                                                                            }
                                                                                            return true;
                                                                                        });
                                                                                    }
                                                                                }
                                                                                break;

                                                                            case 27: // escape
                                                                                setShowConsigneeContactNames(false);
                                                                                break;

                                                                            case 13: // enter
                                                                                if (showConsigneeContactNames && consigneeContactNameItems.findIndex(item => item.selected) > -1) {
                                                                                    let _contact_id = consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].id;
                                                                                    let _contact_name = ((consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].first_name || '')
                                                                                        + ' '
                                                                                        + (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].last_name || '')).trim();

                                                                                    let _contact_phone = (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].primary_phone || '') === 'work'
                                                                                        ? (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].phone_work || '')
                                                                                        : (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].primary_phone || '') === 'fax'
                                                                                            ? (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].phone_work_fax || '')
                                                                                            : (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].primary_phone || '') === 'mobile'
                                                                                                ? (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].phone_mobile || '')
                                                                                                : (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].primary_phone || '') === 'direct'
                                                                                                    ? (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].phone_direct || '')
                                                                                                    : (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].primary_phone || '') === 'other'
                                                                                                        ? (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].phone_other || '')
                                                                                                        : '';
                                                                                    let _contact_phone_ext = (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].phone_ext || '');

                                                                                    let _contact_primary_phone = (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].phone_work || '') !== ''
                                                                                        ? 'work'
                                                                                        : (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].phone_work_fax || '') !== ''
                                                                                            ? 'fax'
                                                                                            : (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].phone_mobile || '') !== ''
                                                                                                ? 'mobile'
                                                                                                : (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].phone_direct || '') !== ''
                                                                                                    ? 'direct'
                                                                                                    : (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].phone_other || '') !== ''
                                                                                                        ? 'other' :
                                                                                                        ''


                                                                                    if ((selectedConsigneeCustomer?.id || 0) === 0) {
                                                                                        if ((selectedOrder?.deliveries || []).find((p) => p.id === 0) === undefined) {
                                                                                            setSelectedOrder({
                                                                                                ...selectedOrder,
                                                                                                deliveries: [
                                                                                                    ...(selectedOrder?.deliveries || []),
                                                                                                    {
                                                                                                        id: 0,
                                                                                                        contact_id: _contact_id,
                                                                                                        contact_name: _contact_name,
                                                                                                        contact_phone: _contact_phone,
                                                                                                        contact_phone_ext: _contact_phone_ext,
                                                                                                        contact_primary_phone: _contact_primary_phone
                                                                                                    },
                                                                                                ],
                                                                                            });
                                                                                        } else {
                                                                                            setSelectedOrder({
                                                                                                ...selectedOrder,
                                                                                                deliveries: (selectedOrder?.deliveries || []).map((p, i) => {
                                                                                                    if (p.id === 0) {
                                                                                                        p.contact_id = _contact_id;
                                                                                                        p.contact_name = _contact_name;
                                                                                                        p.contact_phone = _contact_phone;
                                                                                                        p.contact_phone_ext = _contact_phone_ext;
                                                                                                        p.contact_primary_phone = _contact_primary_phone;
                                                                                                    }
                                                                                                    return p;
                                                                                                }),
                                                                                            });
                                                                                        }
                                                                                    } else {
                                                                                        setSelectedOrder(_selectedOrder => {
                                                                                            return {
                                                                                                ..._selectedOrder,
                                                                                                deliveries: (_selectedOrder?.deliveries || []).map((p, i) => {
                                                                                                    if (p.id === selectedConsigneeCustomer.delivery_id) {
                                                                                                        p.contact_id = _contact_id;
                                                                                                        p.contact_name = _contact_name;
                                                                                                        p.contact_phone = _contact_phone;
                                                                                                        p.contact_phone_ext = _contact_phone_ext;
                                                                                                        p.contact_primary_phone = _contact_primary_phone;
                                                                                                    }
                                                                                                    return p;
                                                                                                }),
                                                                                            }
                                                                                        });
                                                                                    }

                                                                                    setSelectedConsigneeCustomer({
                                                                                        ...selectedConsigneeCustomer,
                                                                                        contact_id: _contact_id,
                                                                                        contact_name: _contact_name,
                                                                                        contact_phone: _contact_phone,
                                                                                        contact_phone_ext: _contact_phone_ext,
                                                                                        contact_primary_phone: _contact_primary_phone
                                                                                    });

                                                                                    setShowConsigneeContactNames(false);
                                                                                    refConsigneeContactPhone.current.inputElement.focus();
                                                                                }
                                                                                break;

                                                                            case 9: // tab
                                                                                if (showConsigneeContactNames && consigneeContactNameItems.findIndex(item => item.selected) > -1) {
                                                                                    e.preventDefault();
                                                                                    let _contact_id = consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].id;
                                                                                    let _contact_name = ((consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].first_name || '')
                                                                                        + ' '
                                                                                        + (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].last_name || '')).trim();
                                                                                    let _contact_phone = (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].primary_phone || '') === 'work'
                                                                                        ? (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].phone_work || '')
                                                                                        : (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].primary_phone || '') === 'fax'
                                                                                            ? (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].phone_work_fax || '')
                                                                                            : (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].primary_phone || '') === 'mobile'
                                                                                                ? (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].phone_mobile || '')
                                                                                                : (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].primary_phone || '') === 'direct'
                                                                                                    ? (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].phone_direct || '')
                                                                                                    : (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].primary_phone || '') === 'other'
                                                                                                        ? (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].phone_other || '')
                                                                                                        : '';
                                                                                    let _contact_phone_ext = (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].phone_ext || '');
                                                                                    let _email = (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].primary_email || '') === 'work'
                                                                                        ? (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].email_work || '')
                                                                                        : (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].primary_email || '') === 'personal'
                                                                                            ? (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].email_personal || '')
                                                                                            : (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].primary_email || '') === 'other'
                                                                                                ? (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].email_other || '')
                                                                                                : '';
                                                                                    let _contact_primary_phone = (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].phone_work || '') !== ''
                                                                                        ? 'work'
                                                                                        : (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].phone_work_fax || '') !== ''
                                                                                            ? 'fax'
                                                                                            : (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].phone_mobile || '') !== ''
                                                                                                ? 'mobile'
                                                                                                : (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].phone_direct || '') !== ''
                                                                                                    ? 'direct'
                                                                                                    : (consigneeContactNameItems[consigneeContactNameItems.findIndex(item => item.selected)].phone_other || '') !== ''
                                                                                                        ? 'other' :
                                                                                                        ''


                                                                                    if ((selectedConsigneeCustomer?.id || 0) === 0) {
                                                                                        if ((selectedOrder?.deliveries || []).find((p) => p.id === 0) === undefined) {
                                                                                            setSelectedOrder({
                                                                                                ...selectedOrder,
                                                                                                deliveries: [
                                                                                                    ...(selectedOrder?.deliveries || []),
                                                                                                    {
                                                                                                        id: 0,
                                                                                                        contact_id: _contact_id,
                                                                                                        contact_name: _contact_name,
                                                                                                        contact_phone: _contact_phone,
                                                                                                        contact_phone_ext: _contact_phone_ext,
                                                                                                        contact_primary_phone: _contact_primary_phone
                                                                                                    },
                                                                                                ],
                                                                                            });
                                                                                        } else {
                                                                                            setSelectedOrder({
                                                                                                ...selectedOrder,
                                                                                                deliveries: (selectedOrder?.deliveries || []).map((p, i) => {
                                                                                                    if (p.id === 0) {
                                                                                                        p.contact_id = _contact_id;
                                                                                                        p.contact_name = _contact_name;
                                                                                                        p.contact_phone = _contact_phone;
                                                                                                        p.contact_phone_ext = _contact_phone_ext;
                                                                                                        p.contact_primary_phone = _contact_primary_phone;
                                                                                                    }
                                                                                                    return p;
                                                                                                }),
                                                                                            });
                                                                                        }
                                                                                    } else {
                                                                                        setSelectedOrder(_selectedOrder => {
                                                                                            return {
                                                                                                ..._selectedOrder,
                                                                                                deliveries: (_selectedOrder?.deliveries || []).map((p, i) => {
                                                                                                    if (p.id === selectedConsigneeCustomer.delivery_id) {
                                                                                                        p.contact_id = _contact_id;
                                                                                                        p.contact_name = _contact_name;
                                                                                                        p.contact_phone = _contact_phone;
                                                                                                        p.contact_phone_ext = _contact_phone_ext;
                                                                                                        p.contact_primary_phone = _contact_primary_phone;
                                                                                                    }
                                                                                                    return p;
                                                                                                }),
                                                                                            }
                                                                                        });
                                                                                    }

                                                                                    setSelectedConsigneeCustomer({
                                                                                        ...selectedConsigneeCustomer,
                                                                                        contact_id: _contact_id,
                                                                                        contact_name: _contact_name,
                                                                                        contact_phone: _contact_phone,
                                                                                        contact_phone_ext: _contact_phone_ext,
                                                                                        contact_primary_phone: _contact_primary_phone
                                                                                    });

                                                                                    setShowConsigneeContactNames(false);
                                                                                    refConsigneeContactPhone.current.inputElement.focus();
                                                                                } else {

                                                                                }
                                                                                break;
                                                                            default:
                                                                                break;
                                                                        }
                                                                    }
                                                                }}
                                                                onBlur={e => {
                                                                    if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                        let contact = (selectedConsigneeCustomer?.contacts || []).find(x => (x.first_name + ' ' + x.last_name).toLowerCase() === e.target.value.toLowerCase());

                                                                        if (contact) {
                                                                            if ((selectedConsigneeCustomer?.id || 0) === 0) {
                                                                                if ((selectedOrder?.deliveries || []).find((p) => p.id === 0) === undefined) {
                                                                                    setSelectedOrder({
                                                                                        ...selectedOrder,
                                                                                        deliveries: [
                                                                                            ...(selectedOrder?.deliveries || []),
                                                                                            {
                                                                                                id: 0,
                                                                                                contact_id: contact.id,
                                                                                                contact_phone: (contact.primary_phone || '') === 'work'
                                                                                                    ? (contact.phone_work || '')
                                                                                                    : (contact.primary_phone || '') === 'fax'
                                                                                                        ? (contact.phone_work_fax || '')
                                                                                                        : (contact.primary_phone || '') === 'mobile'
                                                                                                            ? (contact.phone_mobile || '')
                                                                                                            : (contact.primary_phone || '') === 'direct'
                                                                                                                ? (contact.phone_direct || '')
                                                                                                                : (contact.primary_phone || '') === 'other'
                                                                                                                    ? (contact.phone_other || '')
                                                                                                                    : '',
                                                                                                contact_primary_phone: contact.primary_phone || 'work',
                                                                                                contact_phone_ext: (contact.phone_ext || '')
                                                                                            }
                                                                                        ],
                                                                                    });
                                                                                } else {
                                                                                    setSelectedOrder({
                                                                                        ...selectedOrder,
                                                                                        deliveries: (selectedOrder?.deliveries || []).map((p, i) => {
                                                                                            if (p.id === 0) {
                                                                                                p.contact_phone = (contact.primary_phone || '') === 'work'
                                                                                                    ? (contact.phone_work || '')
                                                                                                    : (contact.primary_phone || '') === 'fax'
                                                                                                        ? (contact.phone_work_fax || '')
                                                                                                        : (contact.primary_phone || '') === 'mobile'
                                                                                                            ? (contact.phone_mobile || '')
                                                                                                            : (contact.primary_phone || '') === 'direct'
                                                                                                                ? (contact.phone_direct || '')
                                                                                                                : (contact.primary_phone || '') === 'other'
                                                                                                                    ? (contact.phone_other || '')
                                                                                                                    : '';
                                                                                                p.contact_primary_phone = contact.primary_phone || 'work';
                                                                                                p.contact_phone_ext = (contact.phone_ext || '');
                                                                                            }
                                                                                            return p;
                                                                                        }),
                                                                                    });
                                                                                }
                                                                            } else {
                                                                                setSelectedOrder(_selectedOrder => {
                                                                                    return {
                                                                                        ..._selectedOrder,
                                                                                        deliveries: (_selectedOrder?.deliveries || []).map((p, i) => {
                                                                                            if (p.id === selectedConsigneeCustomer.delivery_id) {
                                                                                                p.contact_phone = (contact.primary_phone || '') === 'work'
                                                                                                    ? (contact.phone_work || '')
                                                                                                    : (contact.primary_phone || '') === 'fax'
                                                                                                        ? (contact.phone_work_fax || '')
                                                                                                        : (contact.primary_phone || '') === 'mobile'
                                                                                                            ? (contact.phone_mobile || '')
                                                                                                            : (contact.primary_phone || '') === 'direct'
                                                                                                                ? (contact.phone_direct || '')
                                                                                                                : (contact.primary_phone || '') === 'other'
                                                                                                                    ? (contact.phone_other || '')
                                                                                                                    : '';
                                                                                                p.contact_primary_phone = contact.primary_phone || 'work';
                                                                                                p.contact_phone_ext = (contact.phone_ext || '');
                                                                                            }
                                                                                            return p;
                                                                                        }),
                                                                                    }
                                                                                });
                                                                            }

                                                                            setSelectedConsigneeCustomer(_selectedConsigneeCustomer => {
                                                                                return {
                                                                                    ..._selectedConsigneeCustomer,
                                                                                    contact_phone: (contact.primary_phone || '') === 'work'
                                                                                        ? (contact.phone_work || '')
                                                                                        : (contact.primary_phone || '') === 'fax'
                                                                                            ? (contact.phone_work_fax || '')
                                                                                            : (contact.primary_phone || '') === 'mobile'
                                                                                                ? (contact.phone_mobile || '')
                                                                                                : (contact.primary_phone || '') === 'direct'
                                                                                                    ? (contact.phone_direct || '')
                                                                                                    : (contact.primary_phone || '') === 'other'
                                                                                                        ? (contact.phone_other || '')
                                                                                                        : '',
                                                                                    contact_primary_phone: contact.primary_phone || 'work',
                                                                                    contact_phone_ext: (contact.phone_ext || '')
                                                                                }
                                                                            })
                                                                        } else {
                                                                            if ((selectedConsigneeCustomer?.id || 0) === 0) {
                                                                                if ((selectedOrder?.deliveries || []).find((p) => p.id === 0) === undefined) {
                                                                                    setSelectedOrder({
                                                                                        ...selectedOrder,
                                                                                        deliveries: [
                                                                                            ...(selectedOrder?.deliveries || []),
                                                                                            {
                                                                                                id: 0,
                                                                                                contact_id: null,
                                                                                                contact_primary_phone: ''
                                                                                            }
                                                                                        ],
                                                                                    });
                                                                                } else {
                                                                                    setSelectedOrder({
                                                                                        ...selectedOrder,
                                                                                        deliveries: (selectedOrder?.deliveries || []).map((p, i) => {
                                                                                            if (p.id === 0) {
                                                                                                p.contact_id = null;
                                                                                                p.contact_primary_phone = '';
                                                                                            }
                                                                                            return p;
                                                                                        }),
                                                                                    });
                                                                                }
                                                                            } else {
                                                                                setSelectedOrder(_selectedOrder => {
                                                                                    return {
                                                                                        ..._selectedOrder,
                                                                                        deliveries: (_selectedOrder?.deliveries || []).map((p, i) => {
                                                                                            if (p.id === selectedConsigneeCustomer.delivery_id) {
                                                                                                p.contact_id = null;
                                                                                                p.contact_primary_phone = '';
                                                                                            }
                                                                                            return p;
                                                                                        }),
                                                                                    }
                                                                                });
                                                                            }

                                                                            setSelectedConsigneeCustomer(_selectedConsigneeCustomer => {
                                                                                return {
                                                                                    ..._selectedConsigneeCustomer,
                                                                                    contact_id: null,
                                                                                    contact_primary_phone: ''
                                                                                }
                                                                            })
                                                                        }
                                                                    }
                                                                }}
                                                                onChange={(e) => {
                                                                    if ((selectedConsigneeCustomer?.id || 0) === 0) {
                                                                        if ((selectedOrder?.deliveries || []).find((p) => p.id === 0) === undefined) {
                                                                            setSelectedOrder({
                                                                                ...selectedOrder,
                                                                                deliveries: [
                                                                                    ...(selectedOrder?.deliveries || []),
                                                                                    {
                                                                                        id: 0,
                                                                                        contact_name: e.target.value,
                                                                                    },
                                                                                ],
                                                                            });
                                                                        } else {
                                                                            setSelectedOrder({
                                                                                ...selectedOrder,
                                                                                deliveries: (selectedOrder?.deliveries || []).map((p, i) => {
                                                                                    if (p.id === 0) {
                                                                                        p.contact_name = e.target.value
                                                                                    }
                                                                                    return p;
                                                                                }),
                                                                            });
                                                                        }
                                                                    } else {
                                                                        setSelectedOrder(_selectedOrder => {
                                                                            return {
                                                                                ..._selectedOrder,
                                                                                deliveries: (_selectedOrder?.deliveries || []).map((p, i) => {
                                                                                    if (p.id === selectedConsigneeCustomer.delivery_id) {

                                                                                        p.contact_name = e.target.value
                                                                                    }
                                                                                    return p;
                                                                                }),
                                                                            }
                                                                        });
                                                                    }

                                                                    setSelectedConsigneeCustomer({
                                                                        ...selectedConsigneeCustomer,
                                                                        contact_name: e.target.value,
                                                                    });
                                                                }}
                                                                onInput={(e) => {
                                                                    if ((selectedConsigneeCustomer?.id || 0) === 0) {
                                                                        if ((selectedOrder?.deliveries || []).find((p) => p.id === 0) === undefined) {
                                                                            setSelectedOrder({
                                                                                ...selectedOrder,
                                                                                deliveries: [
                                                                                    ...(selectedOrder?.deliveries || []),
                                                                                    {
                                                                                        id: 0,
                                                                                        contact_name: e.target.value,
                                                                                    },
                                                                                ],
                                                                            });
                                                                        } else {
                                                                            setSelectedOrder({
                                                                                ...selectedOrder,
                                                                                deliveries: (selectedOrder?.deliveries || []).map((p, i) => {
                                                                                    if (p.id === 0) {
                                                                                        p.contact_name = e.target.value
                                                                                    }
                                                                                    return p;
                                                                                }),
                                                                            });
                                                                        }
                                                                    } else {
                                                                        setSelectedOrder(_selectedOrder => {
                                                                            return {
                                                                                ..._selectedOrder,
                                                                                deliveries: (_selectedOrder?.deliveries || []).map((p, i) => {
                                                                                    if (p.id === selectedConsigneeCustomer.delivery_id) {

                                                                                        p.contact_name = e.target.value
                                                                                    }
                                                                                    return p;
                                                                                }),
                                                                            }
                                                                        });
                                                                    }

                                                                    setSelectedConsigneeCustomer({
                                                                        ...selectedConsigneeCustomer,
                                                                        contact_name: e.target.value,
                                                                    });
                                                                }}
                                                                value={(selectedConsigneeCustomer?.contact_id || 0) > 0
                                                                    ? (((selectedConsigneeCustomer?.contacts || []).find(x => x.id === selectedConsigneeCustomer.contact_id)?.first_name || '') + ' ' +
                                                                        ((selectedConsigneeCustomer?.contacts || []).find(x => x.id === selectedConsigneeCustomer.contact_id)?.last_name || '')).trim()
                                                                    : (selectedConsigneeCustomer?.contacts || []).find(x => x.is_primary === 1)
                                                                        ? ((selectedConsigneeCustomer.contacts.find(x => x.is_primary === 1)?.first_name || '') + ' ' +
                                                                            (selectedConsigneeCustomer.contacts.find(x => x.is_primary === 1)?.last_name || '')).trim()
                                                                        : (selectedConsigneeCustomer?.contact_name || '')
                                                                }
                                                            />

                                                            {
                                                                (selectedOrder?.is_cancelled || 0) === 0 &&
                                                                <FontAwesomeIcon className="dropdown-button"
                                                                    icon={faCaretDown}
                                                                    onClick={async () => {
                                                                        if (showConsigneeContactNames) {
                                                                            setShowConsigneeContactNames(false);
                                                                        } else {
                                                                            if ((selectedConsigneeCustomer?.contacts || []).length > 0) {
                                                                                await setConsigneeContactNameItems((selectedConsigneeCustomer?.contacts || []).map((item, index) => {
                                                                                    item.selected = index === 0
                                                                                    return item;
                                                                                }))

                                                                                window.setTimeout(async () => {
                                                                                    await setShowConsigneeContactNames(true);

                                                                                    refConsigneeContactNamePopupItems.current.map((r, i) => {
                                                                                        if (r && r.classList.contains('selected')) {
                                                                                            r.scrollIntoView({
                                                                                                behavior: 'auto',
                                                                                                block: 'center',
                                                                                                inline: 'nearest'
                                                                                            })
                                                                                        }
                                                                                        return true;
                                                                                    });
                                                                                }, 0)
                                                                            }
                                                                        }

                                                                        refConsigneeContactName.current.focus();
                                                                    }} />
                                                            }
                                                        </div>
                                                        {
                                                            consigneeContactNamesTransition((style, item) => item && (
                                                                <animated.div
                                                                    className="mochi-contextual-container"
                                                                    id="mochi-contextual-container-contact-names"
                                                                    style={{
                                                                        ...style,
                                                                        left: '0',
                                                                        display: 'block'
                                                                    }}
                                                                    ref={refConsigneeContactNameDropDown}
                                                                >
                                                                    <div
                                                                        className="mochi-contextual-popup vertical below right"
                                                                        style={{ height: 150 }}>
                                                                        <div className="mochi-contextual-popup-content">
                                                                            <div className="mochi-contextual-popup-wrapper">
                                                                                {
                                                                                    consigneeContactNameItems.map((item, index) => {
                                                                                        const mochiItemClasses = classnames({
                                                                                            'mochi-item': true,
                                                                                            'selected': item.selected
                                                                                        });

                                                                                        return (
                                                                                            <div
                                                                                                key={index}
                                                                                                className={mochiItemClasses}
                                                                                                id={item.id}
                                                                                                onClick={() => {
                                                                                                    let _contact_id = (item.id)
                                                                                                    let _contact_name = ((item.first_name || '')
                                                                                                        + ' '
                                                                                                        + (item.last_name || '')).trim();

                                                                                                    let _contact_phone = (item.primary_phone || '') === 'work'
                                                                                                        ? (item.phone_work || '')
                                                                                                        : (item.primary_phone || '') === 'fax'
                                                                                                            ? (item.phone_work_fax || '')
                                                                                                            : (item.primary_phone || '') === 'mobile'
                                                                                                                ? (item.phone_mobile || '')
                                                                                                                : (item.primary_phone || '') === 'direct'
                                                                                                                    ? (item.phone_direct || '')
                                                                                                                    : (item.primary_phone || '') === 'other'
                                                                                                                        ? (item.phone_other || '')
                                                                                                                        : '';
                                                                                                    let _contact_phone_ext = (item.phone_ext || '');

                                                                                                    let _contact_primary_phone = (item.phone_work || '') !== ''
                                                                                                        ? 'work'
                                                                                                        : (item.phone_work_fax || '') !== ''
                                                                                                            ? 'fax'
                                                                                                            : (item.phone_mobile || '') !== ''
                                                                                                                ? 'mobile'
                                                                                                                : (item.phone_direct || '') !== ''
                                                                                                                    ? 'direct'
                                                                                                                    : (item.phone_other || '') !== ''
                                                                                                                        ? 'other' :
                                                                                                                        ''


                                                                                                    if ((selectedConsigneeCustomer?.id || 0) === 0) {
                                                                                                        if ((selectedOrder?.deliveries || []).find((p) => p.id === 0) === undefined) {
                                                                                                            setSelectedOrder({
                                                                                                                ...selectedOrder,
                                                                                                                deliveries: [
                                                                                                                    ...(selectedOrder?.deliveries || []),
                                                                                                                    {
                                                                                                                        id: 0,
                                                                                                                        contact_id: _contact_id,
                                                                                                                        contact_name: _contact_name,
                                                                                                                        contact_phone: _contact_phone,
                                                                                                                        contact_phone_ext: _contact_phone_ext,
                                                                                                                        contact_primary_phone: _contact_primary_phone
                                                                                                                    },
                                                                                                                ],
                                                                                                            });
                                                                                                        } else {
                                                                                                            setSelectedOrder({
                                                                                                                ...selectedOrder,
                                                                                                                deliveries: (selectedOrder?.deliveries || []).map((p, i) => {
                                                                                                                    if (p.id === 0) {
                                                                                                                        p.contact_id = _contact_id;
                                                                                                                        p.contact_name = _contact_name;
                                                                                                                        p.contact_phone = _contact_phone;
                                                                                                                        p.contact_phone_ext = _contact_phone_ext;
                                                                                                                        p.contact_primary_phone = _contact_primary_phone;
                                                                                                                    }
                                                                                                                    return p;
                                                                                                                }),
                                                                                                            });
                                                                                                        }
                                                                                                    } else {
                                                                                                        setSelectedOrder(_selectedOrder => {
                                                                                                            return {
                                                                                                                ..._selectedOrder,
                                                                                                                deliveries: (_selectedOrder?.deliveries || []).map((p, i) => {
                                                                                                                    if (p.id === selectedConsigneeCustomer.delivery_id) {
                                                                                                                        p.contact_id = _contact_id;
                                                                                                                        p.contact_name = _contact_name;
                                                                                                                        p.contact_phone = _contact_phone;
                                                                                                                        p.contact_phone_ext = _contact_phone_ext;
                                                                                                                        p.contact_primary_phone = _contact_primary_phone;
                                                                                                                    }
                                                                                                                    return p;
                                                                                                                }),
                                                                                                            }
                                                                                                        });
                                                                                                    }

                                                                                                    setSelectedConsigneeCustomer({
                                                                                                        ...selectedConsigneeCustomer,
                                                                                                        contact_id: _contact_id,
                                                                                                        contact_name: _contact_name,
                                                                                                        contact_phone: _contact_phone,
                                                                                                        contact_phone_ext: _contact_phone_ext,
                                                                                                        contact_primary_phone: _contact_primary_phone
                                                                                                    });

                                                                                                    setShowConsigneeContactNames(false);
                                                                                                    refConsigneeContactPhone.current.inputElement.focus();
                                                                                                }}
                                                                                                ref={ref => refConsigneeContactNamePopupItems.current.push(ref)}
                                                                                            >
                                                                                                {
                                                                                                    item.first_name + ((item.last_name || '') === '' ? '' : ' ' + item.last_name)
                                                                                                }

                                                                                                {
                                                                                                    item.selected &&
                                                                                                    <FontAwesomeIcon
                                                                                                        className="dropdown-selected"
                                                                                                        icon={faCaretRight} />
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </animated.div>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className="form-h-sep"></div>
                                                    <div className="select-box-container input-phone"
                                                        style={{ width: '10.3rem' }}>
                                                        <div className="select-box-wrapper">
                                                            <MaskedInput
                                                                tabIndex={43 + props.tabTimes}
                                                                mask={[/[0-9]/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/,]}
                                                                guide={true}
                                                                type="text"
                                                                placeholder="Contact Phone"
                                                                ref={refConsigneeContactPhone}
                                                                readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                                onKeyDown={async (e) => {
                                                                    if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                        let key = e.keyCode || e.which;

                                                                        switch (key) {
                                                                            case 37:
                                                                            case 38: // arrow left | arrow up
                                                                                if (showConsigneeContactPhones) {
                                                                                    let selectedIndex = consigneeContactPhoneItems.findIndex(item => item.selected);

                                                                                    if (selectedIndex === -1) {
                                                                                        await setConsigneeContactPhoneItems(consigneeContactPhoneItems.map((item, index) => {
                                                                                            item.selected = index === 0;
                                                                                            return item;
                                                                                        }))
                                                                                    } else {
                                                                                        await setConsigneeContactPhoneItems(consigneeContactPhoneItems.map((item, index) => {
                                                                                            if (selectedIndex === 0) {
                                                                                                item.selected = index === (consigneeContactPhoneItems.length - 1);
                                                                                            } else {
                                                                                                item.selected = index === (selectedIndex - 1)
                                                                                            }
                                                                                            return item;
                                                                                        }))
                                                                                    }

                                                                                    refConsigneeContactPhonePopupItems.current.map((r, i) => {
                                                                                        if (r && r.classList.contains('selected')) {
                                                                                            r.scrollIntoView({
                                                                                                behavior: 'auto',
                                                                                                block: 'center',
                                                                                                inline: 'nearest'
                                                                                            })
                                                                                        }
                                                                                        return true;
                                                                                    });
                                                                                } else {
                                                                                    if (consigneeContactPhoneItems.length > 0) {
                                                                                        await setConsigneeContactPhoneItems(consigneeContactPhoneItems.map((item, index) => {
                                                                                            item.selected = index === 0;
                                                                                            return item;
                                                                                        }))

                                                                                        setShowConsigneeContactPhones(true);

                                                                                        refConsigneeContactPhonePopupItems.current.map((r, i) => {
                                                                                            if (r && r.classList.contains('selected')) {
                                                                                                r.scrollIntoView({
                                                                                                    behavior: 'auto',
                                                                                                    block: 'center',
                                                                                                    inline: 'nearest'
                                                                                                })
                                                                                            }
                                                                                            return true;
                                                                                        });
                                                                                    }
                                                                                }
                                                                                break;

                                                                            case 39:
                                                                            case 40: // arrow right | arrow down
                                                                                if (showConsigneeContactPhones) {
                                                                                    let selectedIndex = consigneeContactPhoneItems.findIndex(item => item.selected);

                                                                                    if (selectedIndex === -1) {
                                                                                        await setConsigneeContactPhoneItems(consigneeContactPhoneItems.map((item, index) => {
                                                                                            item.selected = index === 0;
                                                                                            return item;
                                                                                        }))
                                                                                    } else {
                                                                                        await setConsigneeContactPhoneItems(consigneeContactPhoneItems.map((item, index) => {
                                                                                            if (selectedIndex === (consigneeContactPhoneItems.length - 1)) {
                                                                                                item.selected = index === 0;
                                                                                            } else {
                                                                                                item.selected = index === (selectedIndex + 1)
                                                                                            }
                                                                                            return item;
                                                                                        }))
                                                                                    }

                                                                                    refConsigneeContactPhonePopupItems.current.map((r, i) => {
                                                                                        if (r && r.classList.contains('selected')) {
                                                                                            r.scrollIntoView({
                                                                                                behavior: 'auto',
                                                                                                block: 'center',
                                                                                                inline: 'nearest'
                                                                                            })
                                                                                        }
                                                                                        return true;
                                                                                    });
                                                                                } else {
                                                                                    if (consigneeContactPhoneItems.length > 0) {
                                                                                        await setConsigneeContactPhoneItems(consigneeContactPhoneItems.map((item, index) => {
                                                                                            item.selected = index === 0;
                                                                                            return item;
                                                                                        }))

                                                                                        setShowConsigneeContactPhones(true);

                                                                                        refConsigneeContactPhonePopupItems.current.map((r, i) => {
                                                                                            if (r && r.classList.contains('selected')) {
                                                                                                r.scrollIntoView({
                                                                                                    behavior: 'auto',
                                                                                                    block: 'center',
                                                                                                    inline: 'nearest'
                                                                                                })
                                                                                            }
                                                                                            return true;
                                                                                        });
                                                                                    }
                                                                                }
                                                                                break;

                                                                            case 27: // escape
                                                                                setShowConsigneeContactPhones(false);
                                                                                break;

                                                                            case 13: // enter
                                                                                if (showConsigneeContactPhones && consigneeContactPhoneItems.findIndex(item => item.selected) > -1) {
                                                                                    let _contact_phone = consigneeContactPhoneItems[consigneeContactPhoneItems.findIndex(item => item.selected)].phone;
                                                                                    let _contact_primary_phone = consigneeContactPhoneItems[consigneeContactPhoneItems.findIndex(item => item.selected)].type;

                                                                                    if ((selectedConsigneeCustomer?.id || 0) === 0) {
                                                                                        if ((selectedOrder?.deliveries || []).find((p) => p.id === 0) === undefined) {
                                                                                            setSelectedOrder({
                                                                                                ...selectedOrder,
                                                                                                deliveries: [
                                                                                                    ...(selectedOrder?.deliveries || []),
                                                                                                    {
                                                                                                        id: 0,
                                                                                                        contact_phone: _contact_phone,
                                                                                                        contact_primary_phone: _contact_primary_phone
                                                                                                    },
                                                                                                ],
                                                                                            });
                                                                                        } else {
                                                                                            setSelectedOrder({
                                                                                                ...selectedOrder,
                                                                                                deliveries: (selectedOrder?.deliveries || []).map((p, i) => {
                                                                                                    if (p.id === 0) {
                                                                                                        p.contact_phone = _contact_phone;
                                                                                                        p.contact_primary_phone = _contact_primary_phone;
                                                                                                    }
                                                                                                    return p;
                                                                                                }),
                                                                                            });
                                                                                        }
                                                                                    } else {
                                                                                        setSelectedOrder(_selectedOrder => {
                                                                                            return {
                                                                                                ..._selectedOrder,
                                                                                                deliveries: (_selectedOrder?.deliveries || []).map((p, i) => {
                                                                                                    if (p.id === selectedConsigneeCustomer.delivery_id) {
                                                                                                        p.contact_phone = _contact_phone;
                                                                                                        p.contact_primary_phone = _contact_primary_phone;
                                                                                                    }
                                                                                                    return p;
                                                                                                }),
                                                                                            }
                                                                                        });
                                                                                    }

                                                                                    setSelectedConsigneeCustomer({
                                                                                        ...selectedConsigneeCustomer,
                                                                                        contact_phone: _contact_phone,
                                                                                        contact_primary_phone: _contact_primary_phone
                                                                                    });

                                                                                    setShowConsigneeContactPhones(false);
                                                                                    refConsigneeContactPhone.current.inputElement.focus();
                                                                                }
                                                                                break;

                                                                            case 9: // tab
                                                                                if (showConsigneeContactPhones && consigneeContactPhoneItems.findIndex(item => item.selected) > -1) {
                                                                                    e.preventDefault();
                                                                                    let _contact_phone = consigneeContactPhoneItems[consigneeContactPhoneItems.findIndex(item => item.selected)].phone;
                                                                                    let _contact_primary_phone = consigneeContactPhoneItems[consigneeContactPhoneItems.findIndex(item => item.selected)].type;

                                                                                    if ((selectedConsigneeCustomer?.id || 0) === 0) {
                                                                                        if ((selectedOrder?.deliveries || []).find((p) => p.id === 0) === undefined) {
                                                                                            setSelectedOrder({
                                                                                                ...selectedOrder,
                                                                                                deliveries: [
                                                                                                    ...(selectedOrder?.deliveries || []),
                                                                                                    {
                                                                                                        id: 0,
                                                                                                        contact_phone: _contact_phone,
                                                                                                        contact_primary_phone: _contact_primary_phone
                                                                                                    },
                                                                                                ],
                                                                                            });
                                                                                        } else {
                                                                                            setSelectedOrder({
                                                                                                ...selectedOrder,
                                                                                                deliveries: (selectedOrder?.deliveries || []).map((p, i) => {
                                                                                                    if (p.id === 0) {
                                                                                                        p.contact_phone = _contact_phone;
                                                                                                        p.contact_primary_phone = _contact_primary_phone;
                                                                                                    }
                                                                                                    return p;
                                                                                                }),
                                                                                            });
                                                                                        }
                                                                                    } else {
                                                                                        setSelectedOrder(_selectedOrder => {
                                                                                            return {
                                                                                                ..._selectedOrder,
                                                                                                deliveries: (_selectedOrder?.deliveries || []).map((p, i) => {
                                                                                                    if (p.id === selectedConsigneeCustomer.delivery_id) {
                                                                                                        p.contact_phone = _contact_phone;
                                                                                                        p.contact_primary_phone = _contact_primary_phone;
                                                                                                    }
                                                                                                    return p;
                                                                                                }),
                                                                                            }
                                                                                        });
                                                                                    }

                                                                                    setSelectedConsigneeCustomer({
                                                                                        ...selectedConsigneeCustomer,
                                                                                        contact_phone: _contact_phone,
                                                                                        contact_primary_phone: _contact_primary_phone
                                                                                    });

                                                                                    setShowConsigneeContactPhones(false);
                                                                                    refConsigneeContactPhone.current.inputElement.focus();
                                                                                }
                                                                                break;
                                                                            default:
                                                                                break;
                                                                        }
                                                                    }
                                                                }}
                                                                onInput={(e) => {
                                                                    if ((selectedConsigneeCustomer?.id || 0) === 0) {
                                                                        if ((selectedOrder?.deliveries || []).find((p) => p.id === 0) === undefined) {
                                                                            setSelectedOrder({
                                                                                ...selectedOrder,
                                                                                deliveries: [
                                                                                    ...(selectedOrder?.deliveries || []),
                                                                                    {
                                                                                        id: 0,
                                                                                        contact_phone: e.target.value
                                                                                    },
                                                                                ],
                                                                            });
                                                                        } else {
                                                                            setSelectedOrder({
                                                                                ...selectedOrder,
                                                                                deliveries: (selectedOrder?.deliveries || []).map((p, i) => {
                                                                                    if (p.id === 0) {
                                                                                        p.contact_phone = e.target.value;
                                                                                    }
                                                                                    return p;
                                                                                }),
                                                                            });
                                                                        }
                                                                    } else {
                                                                        setSelectedOrder(_selectedOrder => {
                                                                            return {
                                                                                ..._selectedOrder,
                                                                                deliveries: (_selectedOrder?.deliveries || []).map((p, i) => {
                                                                                    if (p.id === selectedConsigneeCustomer.delivery_id) {
                                                                                        p.contact_phone = e.target.value;
                                                                                    }
                                                                                    return p;
                                                                                }),
                                                                            }
                                                                        });
                                                                    }

                                                                    setSelectedConsigneeCustomer(_selectedConsigneeCustomer => {
                                                                        return {
                                                                            ..._selectedConsigneeCustomer,
                                                                            contact_phone: e.target.value
                                                                        }
                                                                    })
                                                                }}
                                                                onChange={(e) => {
                                                                    if ((selectedConsigneeCustomer?.id || 0) === 0) {
                                                                        if ((selectedOrder?.deliveries || []).find((p) => p.id === 0) === undefined) {
                                                                            setSelectedOrder({
                                                                                ...selectedOrder,
                                                                                deliveries: [
                                                                                    ...(selectedOrder?.deliveries || []),
                                                                                    {
                                                                                        id: 0,
                                                                                        contact_phone: e.target.value
                                                                                    },
                                                                                ],
                                                                            });
                                                                        } else {
                                                                            setSelectedOrder({
                                                                                ...selectedOrder,
                                                                                deliveries: (selectedOrder?.deliveries || []).map((p, i) => {
                                                                                    if (p.id === 0) {
                                                                                        p.contact_phone = e.target.value;
                                                                                    }
                                                                                    return p;
                                                                                }),
                                                                            });
                                                                        }
                                                                    } else {
                                                                        setSelectedOrder(_selectedOrder => {
                                                                            return {
                                                                                ..._selectedOrder,
                                                                                deliveries: (_selectedOrder?.deliveries || []).map((p, i) => {
                                                                                    if (p.id === selectedConsigneeCustomer.delivery_id) {
                                                                                        p.contact_phone = e.target.value;
                                                                                    }
                                                                                    return p;
                                                                                }),
                                                                            }
                                                                        });
                                                                    }

                                                                    setSelectedConsigneeCustomer(_selectedConsigneeCustomer => {
                                                                        return {
                                                                            ..._selectedConsigneeCustomer,
                                                                            contact_phone: e.target.value
                                                                        }
                                                                    })
                                                                }}
                                                                value={(selectedConsigneeCustomer?.contact_id || 0) > 0
                                                                    ? (selectedConsigneeCustomer?.contact_primary_phone || 'work') === 'work'
                                                                        ? ((selectedConsigneeCustomer?.contacts || []).find(x => x.id === selectedConsigneeCustomer.contact_id)?.phone_work || '')
                                                                        : (selectedConsigneeCustomer?.contact_primary_phone || 'work') === 'fax'
                                                                            ? ((selectedConsigneeCustomer?.contacts || []).find(x => x.id === selectedConsigneeCustomer.contact_id)?.phone_work_fax || '')
                                                                            : (selectedConsigneeCustomer?.contact_primary_phone || 'work') === 'mobile'
                                                                                ? ((selectedConsigneeCustomer?.contacts || []).find(x => x.id === selectedConsigneeCustomer.contact_id)?.phone_mobile || '')
                                                                                : (selectedConsigneeCustomer?.contact_primary_phone || 'work') === 'direct'
                                                                                    ? ((selectedConsigneeCustomer?.contacts || []).find(x => x.id === selectedConsigneeCustomer.contact_id)?.phone_direct || '')
                                                                                    : (selectedConsigneeCustomer?.contact_primary_phone || 'work') === 'other'
                                                                                        ? ((selectedConsigneeCustomer?.contacts || []).find(x => x.id === selectedConsigneeCustomer.contact_id)?.phone_other || '')
                                                                                        : ''
                                                                    : (selectedConsigneeCustomer?.contacts || []).find(x => x.is_primary === 1)
                                                                        ? (selectedConsigneeCustomer?.contact_primary_phone || 'work') === 'work'
                                                                            ? (selectedConsigneeCustomer.contacts.find(x => x.is_primary === 1)?.phone_work || '')
                                                                            : (selectedConsigneeCustomer?.contact_primary_phone || 'work') === 'fax'
                                                                                ? (selectedConsigneeCustomer.contacts.find(x => x.is_primary === 1)?.phone_work_fax || '')
                                                                                : (selectedConsigneeCustomer?.contact_primary_phone || 'work') === 'mobile'
                                                                                    ? (selectedConsigneeCustomer.contacts.find(x => x.is_primary === 1)?.phone_mobile || '')
                                                                                    : (selectedConsigneeCustomer?.contact_primary_phone || 'work') === 'direct'
                                                                                        ? (selectedConsigneeCustomer.contacts.find(x => x.is_primary === 1)?.phone_direct || '')
                                                                                        : (selectedConsigneeCustomer?.contact_primary_phone || 'work') === 'other'
                                                                                            ? (selectedConsigneeCustomer.contacts.find(x => x.is_primary === 1)?.phone_other || '')
                                                                                            : ''
                                                                        : (selectedConsigneeCustomer?.contact_phone || '')
                                                                }
                                                            />
                                                            {((selectedConsigneeCustomer?.id || 0) > 0 && (selectedConsigneeCustomer?.id !== undefined)) && (
                                                                <div className={classnames({
                                                                    "selected-customer-contact-primary-phone": true,
                                                                    'pushed': (consigneeContactPhoneItems.length > 0)
                                                                })}>
                                                                    {selectedConsigneeCustomer?.contact_primary_phone || ''}
                                                                </div>
                                                            )}

                                                            {
                                                                (consigneeContactPhoneItems.length > 0 && (selectedOrder?.is_cancelled || 0) === 0) &&
                                                                (<FontAwesomeIcon
                                                                    className="dropdown-button" icon={faCaretDown}
                                                                    onClick={async () => {
                                                                        if (showConsigneeContactPhones) {
                                                                            setShowConsigneeContactPhones(false);
                                                                        } else {
                                                                            if (consigneeContactPhoneItems.length > 0) {
                                                                                await setConsigneeContactPhoneItems(consigneeContactPhoneItems.map((item, index) => {
                                                                                    item.selected = index === 0;
                                                                                    return item;
                                                                                }))

                                                                                window.setTimeout(async () => {
                                                                                    await setShowConsigneeContactPhones(true);

                                                                                    refConsigneeContactPhonePopupItems.current.map((r, i) => {
                                                                                        if (r && r.classList.contains('selected')) {
                                                                                            r.scrollIntoView({
                                                                                                behavior: 'auto',
                                                                                                block: 'center',
                                                                                                inline: 'nearest'
                                                                                            })
                                                                                        }
                                                                                        return true;
                                                                                    });
                                                                                }, 0)
                                                                            }
                                                                        }

                                                                        refConsigneeContactPhone.current.inputElement.focus();
                                                                    }}
                                                                />)
                                                            }
                                                        </div>
                                                        {
                                                            consigneeContactPhonesTransition((style, item) => item && (
                                                                <animated.div
                                                                    className="mochi-contextual-container"
                                                                    id="mochi-contextual-container-contact-phone"
                                                                    style={{
                                                                        ...style,
                                                                        left: '0',
                                                                        display: 'block'
                                                                    }}
                                                                    ref={refConsigneeContactPhoneDropDown}
                                                                >
                                                                    <div
                                                                        className="mochi-contextual-popup vertical below right"
                                                                        style={{ height: 150 }}>
                                                                        <div className="mochi-contextual-popup-content">
                                                                            <div className="mochi-contextual-popup-wrapper">
                                                                                {
                                                                                    consigneeContactPhoneItems.map((item, index) => {
                                                                                        const mochiItemClasses = classnames({
                                                                                            'mochi-item': true,
                                                                                            'selected': item.selected
                                                                                        });

                                                                                        return (
                                                                                            <div
                                                                                                key={index}
                                                                                                className={mochiItemClasses}
                                                                                                id={item.id}
                                                                                                onClick={() => {
                                                                                                    let _contact_phone = item.phone;
                                                                                                    let _contact_primary_phone = item.type;

                                                                                                    if ((selectedConsigneeCustomer?.id || 0) === 0) {
                                                                                                        if ((selectedOrder?.deliveries || []).find((p) => p.id === 0) === undefined) {
                                                                                                            setSelectedOrder({
                                                                                                                ...selectedOrder,
                                                                                                                deliveries: [
                                                                                                                    ...(selectedOrder?.deliveries || []),
                                                                                                                    {
                                                                                                                        id: 0,
                                                                                                                        contact_phone: _contact_phone,
                                                                                                                        contact_primary_phone: _contact_primary_phone
                                                                                                                    },
                                                                                                                ],
                                                                                                            });
                                                                                                        } else {
                                                                                                            setSelectedOrder({
                                                                                                                ...selectedOrder,
                                                                                                                deliveries: (selectedOrder?.deliveries || []).map((p, i) => {
                                                                                                                    if (p.id === 0) {
                                                                                                                        p.contact_phone = _contact_phone;
                                                                                                                        p.contact_primary_phone = _contact_primary_phone;
                                                                                                                    }
                                                                                                                    return p;
                                                                                                                }),
                                                                                                            });
                                                                                                        }
                                                                                                    } else {
                                                                                                        setSelectedOrder(_selectedOrder => {
                                                                                                            return {
                                                                                                                ..._selectedOrder,
                                                                                                                deliveries: (_selectedOrder?.deliveries || []).map((p, i) => {
                                                                                                                    if (p.id === selectedConsigneeCustomer.delivery_id) {
                                                                                                                        p.contact_phone = _contact_phone;
                                                                                                                        p.contact_primary_phone = _contact_primary_phone;
                                                                                                                    }
                                                                                                                    return p;
                                                                                                                }),
                                                                                                            }
                                                                                                        });
                                                                                                    }

                                                                                                    setSelectedConsigneeCustomer({
                                                                                                        ...selectedConsigneeCustomer,
                                                                                                        contact_phone: _contact_phone,
                                                                                                        contact_primary_phone: _contact_primary_phone
                                                                                                    });

                                                                                                    setShowConsigneeContactPhones(false);
                                                                                                    refConsigneeContactPhone.current.inputElement.focus();
                                                                                                }}
                                                                                                ref={ref => refConsigneeContactPhonePopupItems.current.push(ref)}
                                                                                            >
                                                                                                {
                                                                                                    item.type === 'work' ? `Phone Work `
                                                                                                        : item.type === 'fax' ? `Phone Work Fax `
                                                                                                            : item.type === 'mobile' ? `Phone Mobile `
                                                                                                                : item.type === 'direct' ? `Phone Direct `
                                                                                                                    : item.type === 'other' ? `Phone Other ` : ''
                                                                                                }

                                                                                                (<b>
                                                                                                    {
                                                                                                        item.type === 'work' ? item.phone
                                                                                                            : item.type === 'fax' ? item.phone
                                                                                                                : item.type === 'mobile' ? item.phone
                                                                                                                    : item.type === 'direct' ? item.phone
                                                                                                                        : item.type === 'other' ? item.phone : ''
                                                                                                    }
                                                                                                </b>)

                                                                                                {
                                                                                                    item.selected &&
                                                                                                    <FontAwesomeIcon
                                                                                                        className="dropdown-selected"
                                                                                                        icon={faCaretRight} />
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </animated.div>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className="form-h-sep"></div>
                                                    <div className="input-box-container input-phone-ext">
                                                        <input
                                                            tabIndex={44 + props.tabTimes}
                                                            type="text"
                                                            placeholder="Ext"
                                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                            onKeyDown={e => {
                                                                if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                    let key = e.keyCode || e.which;

                                                                    if (key === 9) {
                                                                        setIsSavingDeliveryId(selectedConsigneeCustomer?.delivery_id || 0);
                                                                    }
                                                                }
                                                            }}
                                                            onInput={e => {
                                                                if ((selectedConsigneeCustomer?.id || 0) === 0) {
                                                                    if ((selectedOrder?.deliveries || []).find((p) => p.id === 0) === undefined) {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            deliveries: [
                                                                                ...(selectedOrder?.deliveries || []),
                                                                                {
                                                                                    id: 0,
                                                                                    contact_phone_ext: e.target.value
                                                                                },
                                                                            ],
                                                                        });
                                                                    } else {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            deliveries: (selectedOrder?.deliveries || []).map((p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.contact_phone_ext = e.target.value;
                                                                                }
                                                                                return p;
                                                                            }),
                                                                        });
                                                                    }
                                                                } else {
                                                                    setSelectedOrder(_selectedOrder => {
                                                                        return {
                                                                            ..._selectedOrder,
                                                                            deliveries: (_selectedOrder?.deliveries || []).map((p, i) => {
                                                                                if (p.id === selectedConsigneeCustomer.delivery_id) {
                                                                                    p.contact_phone_ext = e.target.value;
                                                                                }
                                                                                return p;
                                                                            }),
                                                                        }
                                                                    });
                                                                }

                                                                setSelectedConsigneeCustomer(_selectedConsigneeCustomer => {
                                                                    return {
                                                                        ..._selectedConsigneeCustomer,
                                                                        contact_phone_ext: e.target.value
                                                                    }
                                                                })
                                                            }}
                                                            onChange={e => {
                                                                if ((selectedConsigneeCustomer?.id || 0) === 0) {
                                                                    if ((selectedOrder?.deliveries || []).find((p) => p.id === 0) === undefined) {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            deliveries: [
                                                                                ...(selectedOrder?.deliveries || []),
                                                                                {
                                                                                    id: 0,
                                                                                    contact_phone_ext: e.target.value
                                                                                },
                                                                            ],
                                                                        });
                                                                    } else {
                                                                        setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            deliveries: (selectedOrder?.deliveries || []).map((p, i) => {
                                                                                if (p.id === 0) {
                                                                                    p.contact_phone_ext = e.target.value;
                                                                                }
                                                                                return p;
                                                                            }),
                                                                        });
                                                                    }
                                                                } else {
                                                                    setSelectedOrder(_selectedOrder => {
                                                                        return {
                                                                            ..._selectedOrder,
                                                                            deliveries: (_selectedOrder?.deliveries || []).map((p, i) => {
                                                                                if (p.id === selectedConsigneeCustomer.delivery_id) {
                                                                                    p.contact_phone_ext = e.target.value;
                                                                                }
                                                                                return p;
                                                                            }),
                                                                        }
                                                                    });
                                                                }

                                                                setSelectedConsigneeCustomer(_selectedConsigneeCustomer => {
                                                                    return {
                                                                        ..._selectedConsigneeCustomer,
                                                                        contact_phone_ext: e.target.value
                                                                    }
                                                                })
                                                            }}
                                                            value={
                                                                (selectedConsigneeCustomer?.contact_id || 0) > 0
                                                                    ? (selectedConsigneeCustomer?.contact_primary_phone || '') === 'work'
                                                                        ? (selectedConsigneeCustomer?.contacts || []).find(x => x.id === selectedConsigneeCustomer.contact_id)?.phone_ext || ''
                                                                        : ''
                                                                    : (selectedConsigneeCustomer?.contact_primary_phone || '') === 'work'
                                                                        ? (selectedConsigneeCustomer?.contacts || []).find(x => x.is_primary === 1)
                                                                            ? selectedConsigneeCustomer.contacts.find(x => x.is_primary === 1)?.phone_ext || ''
                                                                            : selectedConsigneeCustomer?.contact_phone_ext || ''
                                                                        : ''
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </animated.div>
                                        )
                                )}

                                {consigneeSecondPageTransition(
                                    (style, item) =>
                                        item && (
                                            <animated.div className="form-page second-page consignee-second-page"
                                                style={{ ...style }}>
                                                <div className="form-row" style={{ alignItems: "center" }}>
                                                    <div className="select-box-container"
                                                        style={{ flexGrow: 1, position: "relative" }}>
                                                        <div className="select-box-wrapper">
                                                            <MaskedInput
                                                                tabIndex={46 + props.tabTimes}
                                                                ref={refDeliveryDate1}
                                                                mask={[/[0-9]/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                                                                guide={false}
                                                                type="text"
                                                                placeholder="Delivery Date 1"
                                                                autoFocus={true}
                                                                readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                                onKeyDown={async (e) => {
                                                                    if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                        e.stopPropagation();
                                                                        let key = e.keyCode || e.which;
                                                                        await setDeliveryDate1KeyCode(key);

                                                                        let deliveryDate1 = e.target.value.trim() === "" ? moment() : moment(getFormattedDates(selectedConsigneeCustomer?.delivery_date1 || ""), "MM/DD/YYYY");

                                                                        await setPreSelectedDeliveryDate1(deliveryDate1);

                                                                        if (key === 13) {
                                                                            if (isDeliveryDate1CalendarShown) {
                                                                                await setSelectedConsigneeCustomer({
                                                                                    ...selectedConsigneeCustomer,
                                                                                    delivery_date1: preSelectedDeliveryDate1.clone().format("MM/DD/YYYY"),
                                                                                    delivery_date2: preSelectedDeliveryDate1.clone().format("MM/DD/YYYY"),
                                                                                });

                                                                                await setSelectedOrder({
                                                                                    ...selectedOrder,
                                                                                    deliveries: (selectedOrder?.deliveries || []).map((delivery, i) => {
                                                                                        if (delivery.id === (selectedConsigneeCustomer?.delivery_id || 0)) {
                                                                                            delivery.delivery_date1 = preSelectedDeliveryDate1.clone().format("MM/DD/YYYY");
                                                                                            delivery.delivery_date2 = preSelectedDeliveryDate1.clone().format("MM/DD/YYYY");
                                                                                            // setIsSavingDeliveryId(-1);
                                                                                            // setIsSavingDeliveryId(delivery.id);
                                                                                        }
                                                                                        return delivery;
                                                                                    }),
                                                                                });

                                                                                await setIsPickupDate1CalendarShown(false);
                                                                                await setIsPickupDate2CalendarShown(false);
                                                                                await setIsDeliveryDate1CalendarShown(false);
                                                                                await setIsDeliveryDate2CalendarShown(false);

                                                                                refDeliveryTime1.current.focus();
                                                                            }
                                                                        }

                                                                        if (key >= 37 && key <= 40) {
                                                                            if (isDeliveryDate1CalendarShown) {
                                                                                e.preventDefault();

                                                                                if (key === 37) {
                                                                                    // left - minus 1
                                                                                    setPreSelectedDeliveryDate1(preSelectedDeliveryDate1.clone().subtract(1, "day"));
                                                                                }

                                                                                if (key === 38) {
                                                                                    // up - minus 7
                                                                                    setPreSelectedDeliveryDate1(preSelectedDeliveryDate1.clone().subtract(7, "day"));
                                                                                }

                                                                                if (key === 39) {
                                                                                    // right - plus 1
                                                                                    setPreSelectedDeliveryDate1(preSelectedDeliveryDate1.clone().add(1, "day"));
                                                                                }

                                                                                if (key === 40) {
                                                                                    // down - plus 7
                                                                                    setPreSelectedDeliveryDate1(preSelectedDeliveryDate1.clone().add(7, "day"));
                                                                                }

                                                                                await setSelectedOrder({
                                                                                    ...selectedOrder,
                                                                                    deliveries: (
                                                                                        selectedOrder?.deliveries || []
                                                                                    ).map((delivery, i) => {
                                                                                        if (delivery.id === (selectedConsigneeCustomer?.delivery_id || 0)) {
                                                                                            delivery.delivery_date1 = preSelectedDeliveryDate1.clone().format("MM/DD/YYYY");
                                                                                        }
                                                                                        return delivery;
                                                                                    }),
                                                                                });
                                                                                // await validateOrderForSaving({ keyCode: 9 });
                                                                            } else {
                                                                                if (key === 38 || key === 40) {
                                                                                    setIsDeliveryDate1CalendarShown(true);
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }}
                                                                onBlur={async (e) => {
                                                                    if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                        if (deliveryDate1KeyCode === 9) {
                                                                            let formatted = getFormattedDates(
                                                                                e.target.value
                                                                            );

                                                                            await setSelectedConsigneeCustomer({
                                                                                ...selectedConsigneeCustomer,
                                                                                delivery_date1: formatted,
                                                                                delivery_date2: formatted,
                                                                            });

                                                                            let deliveries = (
                                                                                selectedOrder?.deliveries || []
                                                                            ).map((delivery, i) => {
                                                                                if (
                                                                                    delivery.id ===
                                                                                    (selectedConsigneeCustomer?.delivery_id ||
                                                                                        0)
                                                                                ) {
                                                                                    delivery.delivery_date1 = formatted;
                                                                                    delivery.delivery_date2 = formatted;
                                                                                    // setIsSavingDeliveryId(-1);
                                                                                    // setIsSavingDeliveryId(delivery.id);
                                                                                }
                                                                                return delivery;
                                                                            });

                                                                            await setSelectedOrder({
                                                                                ...selectedOrder,
                                                                                deliveries: deliveries,
                                                                            });
                                                                        }
                                                                    }
                                                                }}
                                                                onInput={(e) => {
                                                                    setSelectedConsigneeCustomer({
                                                                        ...selectedConsigneeCustomer,
                                                                        delivery_date1: e.target.value,
                                                                    });

                                                                    setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        deliveries: (
                                                                            selectedOrder?.deliveries || []
                                                                        ).map((delivery, i) => {
                                                                            if (
                                                                                delivery.id ===
                                                                                (selectedConsigneeCustomer?.delivery_id ||
                                                                                    0)
                                                                            ) {
                                                                                delivery.delivery_date1 =
                                                                                    e.target.value;
                                                                            }
                                                                            return delivery;
                                                                        }),
                                                                    });
                                                                }}
                                                                onChange={(e) => {
                                                                    setSelectedConsigneeCustomer({
                                                                        ...selectedConsigneeCustomer,
                                                                        delivery_date1: e.target.value,
                                                                    });

                                                                    setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        deliveries: (
                                                                            selectedOrder?.deliveries || []
                                                                        ).map((delivery, i) => {
                                                                            if (
                                                                                delivery.id ===
                                                                                (selectedConsigneeCustomer?.delivery_id ||
                                                                                    0)
                                                                            ) {
                                                                                delivery.delivery_date1 =
                                                                                    e.target.value;
                                                                            }
                                                                            return delivery;
                                                                        }),
                                                                    });
                                                                }}
                                                                value={
                                                                    selectedConsigneeCustomer?.delivery_date1 ||
                                                                    ""
                                                                }
                                                            />

                                                            {
                                                                (selectedOrder?.is_cancelled || 0) === 0 &&
                                                                <FontAwesomeIcon
                                                                    className="dropdown-button calendar"
                                                                    icon={faCalendarAlt}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setIsDeliveryDate2CalendarShown(false);
                                                                        setIsPickupDate1CalendarShown(false);
                                                                        setIsPickupDate2CalendarShown(false);

                                                                        if (moment((selectedConsigneeCustomer?.delivery_date1 || "").trim(), "MM/DD/YYYY").format("MM/DD/YYYY") ===
                                                                            (selectedConsigneeCustomer?.delivery_date1 || "").trim()) {
                                                                            setPreSelectedDeliveryDate1(moment(selectedConsigneeCustomer?.delivery_date1, "MM/DD/YYYY"));
                                                                        } else {
                                                                            setPreSelectedDeliveryDate1(moment());
                                                                        }

                                                                        window.setTimeout(async () => {
                                                                            await setIsDeliveryDate1CalendarShown(true);
                                                                            refDeliveryDate1.current.inputElement.focus();
                                                                        }, 0);
                                                                    }}
                                                                />
                                                            }
                                                        </div>
                                                        {deliveryDate1Transition(
                                                            (style, item) =>
                                                                item && (
                                                                    <animated.div
                                                                        className="mochi-contextual-container"
                                                                        id="mochi-contextual-container-delivery-date1"
                                                                        style={{
                                                                            ...style,
                                                                            left: "0%",
                                                                            display: "block",
                                                                        }}
                                                                        ref={refDeliveryDate1CalendarDropDown}
                                                                    >
                                                                        <div
                                                                            className="mochi-contextual-popup vertical below right"
                                                                            style={{ height: 275 }}
                                                                        >
                                                                            <div className="mochi-contextual-popup-content">
                                                                                <div className="mochi-contextual-popup-wrapper">
                                                                                    <Calendar
                                                                                        value={moment((selectedConsigneeCustomer?.delivery_date1 || "").trim(), "MM/DD/YYYY").format("MM/DD/YYYY") === (selectedConsigneeCustomer?.delivery_date1 || "").trim()
                                                                                            ? moment(selectedConsigneeCustomer?.delivery_date1, "MM/DD/YYYY")
                                                                                            : moment()
                                                                                        }
                                                                                        onChange={(day) => {
                                                                                            setSelectedConsigneeCustomer(
                                                                                                (selectedConsigneeCustomer) => {
                                                                                                    return {
                                                                                                        ...selectedConsigneeCustomer,
                                                                                                        delivery_date1: day.format("MM/DD/YYYY"),
                                                                                                        delivery_date2: day.format("MM/DD/YYYY"),
                                                                                                    };
                                                                                                }
                                                                                            );

                                                                                            setSelectedOrder(
                                                                                                (selectedOrder) => {
                                                                                                    return {
                                                                                                        ...selectedOrder,
                                                                                                        deliveries: (selectedOrder?.deliveries || []).map((delivery, i) => {
                                                                                                            if (delivery.id === (selectedConsigneeCustomer?.delivery_id || 0)) {
                                                                                                                delivery.delivery_date1 = day.format("MM/DD/YYYY");
                                                                                                                delivery.delivery_date2 = day.format("MM/DD/YYYY");
                                                                                                                // setIsSavingDeliveryId(-1);
                                                                                                                // setIsSavingDeliveryId(delivery.id);
                                                                                                            }
                                                                                                            return delivery;
                                                                                                        }),
                                                                                                    };
                                                                                                }
                                                                                            );

                                                                                            setIsPickupDate1CalendarShown(false);
                                                                                            setIsPickupDate2CalendarShown(false);
                                                                                            setIsDeliveryDate1CalendarShown(false);
                                                                                            setIsDeliveryDate2CalendarShown(false);

                                                                                            refDeliveryTime1.current.focus();
                                                                                        }}
                                                                                        closeCalendar={() => {
                                                                                            setIsDeliveryDate1CalendarShown(false);
                                                                                        }}
                                                                                        preDay={preSelectedDeliveryDate1}
                                                                                        onChangePreDay={(preDay) => {
                                                                                            setPreSelectedDeliveryDate1(preDay);
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </animated.div>
                                                                )
                                                        )}
                                                    </div>
                                                    <div className="form-h-sep"></div>
                                                    <div className="input-box-container grow">
                                                        <input
                                                            tabIndex={46 + props.tabTimes}
                                                            type="text"
                                                            placeholder="Delivery Time 1"
                                                            maxLength={4}
                                                            ref={refDeliveryTime1}
                                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                            onKeyDown={(e) => {
                                                                if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                    e.stopPropagation();
                                                                    setDeliveryTime1KeyCode(e.keyCode || e.which);
                                                                }
                                                            }}
                                                            onBlur={async (e) => {
                                                                if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                    if (deliveryTime1KeyCode === 9) {
                                                                        let formatted = getFormattedHours(
                                                                            e.target.value
                                                                        );

                                                                        await setSelectedConsigneeCustomer({
                                                                            ...selectedConsigneeCustomer,
                                                                            delivery_time1: formatted,
                                                                        });

                                                                        let deliveries = (selectedOrder?.deliveries || []).map((delivery, i) => {
                                                                            if (delivery.id === (selectedConsigneeCustomer?.delivery_id || 0)) {
                                                                                delivery.delivery_time1 = formatted;
                                                                                // setIsSavingDeliveryId(-1);
                                                                                // setIsSavingDeliveryId(delivery.id);
                                                                            }
                                                                            return delivery;
                                                                        });

                                                                        await setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            deliveries: deliveries,
                                                                        });
                                                                    }
                                                                }
                                                            }}
                                                            onInput={(e) => {
                                                                setSelectedConsigneeCustomer({
                                                                    ...selectedConsigneeCustomer,
                                                                    delivery_time1: e.target.value,
                                                                });

                                                                setSelectedOrder({
                                                                    ...selectedOrder,
                                                                    deliveries: (
                                                                        selectedOrder?.deliveries || []
                                                                    ).map((delivery, i) => {
                                                                        if (
                                                                            delivery.id ===
                                                                            (selectedConsigneeCustomer?.delivery_id ||
                                                                                0)
                                                                        ) {
                                                                            delivery.delivery_time1 = e.target.value;
                                                                        }
                                                                        return delivery;
                                                                    }),
                                                                });
                                                            }}
                                                            onChange={(e) => {
                                                                setSelectedConsigneeCustomer({
                                                                    ...selectedConsigneeCustomer,
                                                                    delivery_time1: e.target.value,
                                                                });

                                                                setSelectedOrder({
                                                                    ...selectedOrder,
                                                                    deliveries: (
                                                                        selectedOrder?.deliveries || []
                                                                    ).map((delivery, i) => {
                                                                        if (
                                                                            delivery.id ===
                                                                            (selectedConsigneeCustomer?.delivery_id ||
                                                                                0)
                                                                        ) {
                                                                            delivery.delivery_time1 = e.target.value;
                                                                        }
                                                                        return delivery;
                                                                    }),
                                                                });
                                                            }}
                                                            value={selectedConsigneeCustomer?.delivery_time1 || ""}
                                                        />
                                                    </div>
                                                    <div style={{
                                                        minWidth: "1.8rem",
                                                        fontSize: "1rem",
                                                        textAlign: "center"
                                                    }}>To
                                                    </div>
                                                    <div className="select-box-container"
                                                        style={{ flexGrow: 1, position: "relative" }}>
                                                        <div className="select-box-wrapper">
                                                            <MaskedInput
                                                                tabIndex={47 + props.tabTimes}
                                                                ref={refDeliveryDate2}
                                                                mask={[/[0-9]/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                                                                guide={false}
                                                                type="text"
                                                                placeholder="Delivery Date 2"
                                                                readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                                onKeyDown={async (e) => {
                                                                    if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                        e.stopPropagation();
                                                                        let key = e.keyCode || e.which;
                                                                        await setDeliveryDate2KeyCode(key);

                                                                        let deliveryDate2 = e.target.value.trim() === ""
                                                                            ? moment()
                                                                            : moment(getFormattedDates(selectedConsigneeCustomer?.delivery_date2 || ""), "MM/DD/YYYY");
                                                                        await setPreSelectedDeliveryDate1(deliveryDate2);

                                                                        if (key === 13) {
                                                                            if (isDeliveryDate2CalendarShown) {
                                                                                await setSelectedConsigneeCustomer({
                                                                                    ...selectedConsigneeCustomer,
                                                                                    delivery_date2: preSelectedDeliveryDate2.clone().format("MM/DD/YYYY")
                                                                                });

                                                                                await setSelectedOrder({
                                                                                    ...selectedOrder,
                                                                                    deliveries: (selectedOrder?.deliveries || []).map((delivery, i) => {
                                                                                        if (delivery.id === (selectedConsigneeCustomer?.delivery_id || 0)) {
                                                                                            delivery.delivery_date2 = preSelectedDeliveryDate2.clone().format("MM/DD/YYYY");
                                                                                            // setIsSavingDeliveryId(-1);
                                                                                            // setIsSavingDeliveryId(delivery.id);
                                                                                        }
                                                                                        return delivery;
                                                                                    }),
                                                                                });

                                                                                await setIsPickupDate1CalendarShown(false);
                                                                                await setIsPickupDate2CalendarShown(false);
                                                                                await setIsDeliveryDate1CalendarShown(false);
                                                                                await setIsDeliveryDate2CalendarShown(false);

                                                                                refDeliveryTime2.current.focus();
                                                                            }
                                                                        }

                                                                        if (key >= 37 && key <= 40) {
                                                                            if (isDeliveryDate2CalendarShown) {
                                                                                e.preventDefault();

                                                                                if (key === 37) {
                                                                                    // left - minus 1
                                                                                    setPreSelectedDeliveryDate2(preSelectedDeliveryDate2.clone().subtract(1, "day"));
                                                                                }

                                                                                if (key === 38) {
                                                                                    // up - minus 7
                                                                                    setPreSelectedDeliveryDate2(preSelectedDeliveryDate2.clone().subtract(7, "day"));
                                                                                }

                                                                                if (key === 39) {
                                                                                    // right - plus 1
                                                                                    setPreSelectedDeliveryDate2(preSelectedDeliveryDate2.clone().add(1, "day"));
                                                                                }

                                                                                if (key === 40) {
                                                                                    // down - plus 7
                                                                                    setPreSelectedDeliveryDate2(preSelectedDeliveryDate2.clone().add(7, "day"));
                                                                                }

                                                                                await setSelectedOrder({
                                                                                    ...selectedOrder,
                                                                                    deliveries: (selectedOrder?.deliveries || []).map((delivery, i) => {
                                                                                        if (delivery.id === (selectedConsigneeCustomer?.delivery_id || 0)) {
                                                                                            delivery.delivery_date2 = preSelectedDeliveryDate2.clone().format("MM/DD/YYYY");
                                                                                        }
                                                                                        return delivery;
                                                                                    }),
                                                                                });
                                                                                // await validateOrderForSaving({ keyCode: 9 });
                                                                            } else {
                                                                                if (key === 38 || key === 40) {
                                                                                    setIsDeliveryDate2CalendarShown(true);
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }}
                                                                onBlur={async (e) => {
                                                                    if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                        if (deliveryDate1KeyCode === 9) {
                                                                            let formatted = getFormattedDates(
                                                                                e.target.value
                                                                            );

                                                                            await setSelectedConsigneeCustomer({
                                                                                ...selectedConsigneeCustomer,
                                                                                delivery_date2: formatted,
                                                                            });

                                                                            let deliveries = (selectedOrder?.deliveries || []).map((delivery, i) => {
                                                                                if (delivery.id === (selectedConsigneeCustomer?.delivery_id || 0)) {
                                                                                    delivery.delivery_date2 = formatted;
                                                                                    // setIsSavingDeliveryId(-1);
                                                                                    // setIsSavingDeliveryId(delivery.id);
                                                                                }
                                                                                return delivery;
                                                                            });

                                                                            await setSelectedOrder({
                                                                                ...selectedOrder,
                                                                                deliveries: deliveries,
                                                                            });
                                                                        }
                                                                    }
                                                                }}
                                                                onInput={(e) => {
                                                                    setSelectedConsigneeCustomer({
                                                                        ...selectedConsigneeCustomer,
                                                                        delivery_date2: e.target.value,
                                                                    });

                                                                    setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        deliveries: (selectedOrder?.deliveries || []).map((delivery, i) => {
                                                                            if (delivery.id === (selectedConsigneeCustomer?.delivery_id || 0)) {
                                                                                delivery.delivery_date2 = e.target.value;
                                                                            }
                                                                            return delivery;
                                                                        }),
                                                                    });
                                                                }}
                                                                onChange={(e) => {
                                                                    setSelectedConsigneeCustomer({
                                                                        ...selectedConsigneeCustomer,
                                                                        delivery_date2: e.target.value,
                                                                    });

                                                                    setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        deliveries: (selectedOrder?.deliveries || []).map((delivery, i) => {
                                                                            if (delivery.id === (selectedConsigneeCustomer?.delivery_id || 0)) {
                                                                                delivery.delivery_date2 = e.target.value;
                                                                            }
                                                                            return delivery;
                                                                        }),
                                                                    });
                                                                }}
                                                                value={selectedConsigneeCustomer?.delivery_date2 || ""}
                                                            />

                                                            {
                                                                (selectedOrder?.is_cancelled || 0) === 0 &&
                                                                <FontAwesomeIcon
                                                                    className="dropdown-button calendar"
                                                                    icon={faCalendarAlt}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setIsDeliveryDate1CalendarShown(false);
                                                                        setIsPickupDate1CalendarShown(false);
                                                                        setIsPickupDate2CalendarShown(false);

                                                                        if (moment((selectedConsigneeCustomer?.delivery_date2 || "").trim(), "MM/DD/YYYY").format("MM/DD/YYYY") === (selectedConsigneeCustomer?.delivery_date2 || "").trim()) {
                                                                            setPreSelectedDeliveryDate2(moment(selectedConsigneeCustomer?.delivery_date2, "MM/DD/YYYY"));
                                                                        } else {
                                                                            setPreSelectedDeliveryDate2(moment());
                                                                        }

                                                                        window.setTimeout(async () => {
                                                                            await setIsDeliveryDate2CalendarShown(true);
                                                                            refDeliveryDate2.current.inputElement.focus();
                                                                        }, 0);
                                                                    }}
                                                                />
                                                            }
                                                        </div>
                                                        {deliveryDate2Transition(
                                                            (style, item) =>
                                                                item && (
                                                                    <animated.div
                                                                        className="mochi-contextual-container"
                                                                        id="mochi-contextual-container-delivery-date2"
                                                                        style={{
                                                                            ...style,
                                                                            left: "0%",
                                                                            display: "block",
                                                                        }}
                                                                        ref={refDeliveryDate2CalendarDropDown}
                                                                    >
                                                                        <div
                                                                            className="mochi-contextual-popup vertical below right"
                                                                            style={{ height: 275 }}>
                                                                            <div className="mochi-contextual-popup-content">
                                                                                <div className="mochi-contextual-popup-wrapper">
                                                                                    <Calendar
                                                                                        value={moment((selectedConsigneeCustomer?.delivery_date2 || "").trim(), "MM/DD/YYYY").format("MM/DD/YYYY") === (selectedConsigneeCustomer?.delivery_date2 || "").trim()
                                                                                            ? moment(selectedConsigneeCustomer?.delivery_date2, "MM/DD/YYYY")
                                                                                            : moment()
                                                                                        }
                                                                                        onChange={(day) => {
                                                                                            setSelectedConsigneeCustomer((selectedConsigneeCustomer) => {
                                                                                                return {
                                                                                                    ...selectedConsigneeCustomer,
                                                                                                    delivery_date2: day.format("MM/DD/YYYY"),
                                                                                                };
                                                                                            }
                                                                                            );

                                                                                            setSelectedOrder((selectedOrder) => {
                                                                                                return {
                                                                                                    ...selectedOrder,
                                                                                                    deliveries: (selectedOrder?.deliveries || []).map((delivery, i) => {
                                                                                                        if (delivery.id === (selectedConsigneeCustomer?.delivery_id || 0)) {
                                                                                                            delivery.delivery_date2 = day.format("MM/DD/YYYY");
                                                                                                            // setIsSavingDeliveryId(-1);
                                                                                                            // setIsSavingDeliveryId(delivery.id);
                                                                                                        }
                                                                                                        return delivery;
                                                                                                    }),
                                                                                                };
                                                                                            }
                                                                                            );

                                                                                            setIsPickupDate1CalendarShown(false);
                                                                                            setIsPickupDate2CalendarShown(false);
                                                                                            setIsDeliveryDate1CalendarShown(false);
                                                                                            setIsDeliveryDate2CalendarShown(false);

                                                                                            refDeliveryTime2.current.focus();
                                                                                        }}
                                                                                        closeCalendar={() => {
                                                                                            setIsDeliveryDate2CalendarShown(false);
                                                                                        }}
                                                                                        preDay={preSelectedDeliveryDate2}
                                                                                        onChangePreDay={(preDay) => {
                                                                                            setPreSelectedDeliveryDate2(preDay);
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </animated.div>
                                                                )
                                                        )}
                                                    </div>
                                                    <div className="form-h-sep"></div>
                                                    <div className="input-box-container grow">
                                                        <input
                                                            tabIndex={48 + props.tabTimes}
                                                            type="text"
                                                            placeholder="Delivery Time 2"
                                                            maxLength={4}
                                                            ref={refDeliveryTime2}
                                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                            onKeyDown={(e) => {
                                                                if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                    e.stopPropagation();
                                                                    setDeliveryTime2KeyCode(e.keyCode || e.which);
                                                                }
                                                            }}
                                                            onBlur={async (e) => {
                                                                if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                    if (deliveryTime2KeyCode === 9) {
                                                                        let formatted = getFormattedHours(
                                                                            e.target.value
                                                                        );

                                                                        await setSelectedConsigneeCustomer({
                                                                            ...selectedConsigneeCustomer,
                                                                            delivery_time2: formatted,
                                                                        });

                                                                        let deliveries = (
                                                                            selectedOrder?.deliveries || []
                                                                        ).map((delivery, i) => {
                                                                            if (
                                                                                delivery.id ===
                                                                                (selectedConsigneeCustomer?.delivery_id ||
                                                                                    0)
                                                                            ) {
                                                                                delivery.delivery_time2 = formatted;
                                                                                // setIsSavingDeliveryId(-1);
                                                                                // setIsSavingDeliveryId(delivery.id);
                                                                            }
                                                                            return delivery;
                                                                        });

                                                                        await setSelectedOrder({
                                                                            ...selectedOrder,
                                                                            deliveries: deliveries,
                                                                        });
                                                                    }
                                                                }
                                                            }}
                                                            onInput={(e) => {
                                                                setSelectedConsigneeCustomer({
                                                                    ...selectedConsigneeCustomer,
                                                                    delivery_time2: e.target.value,
                                                                });

                                                                setSelectedOrder({
                                                                    ...selectedOrder,
                                                                    deliveries: (
                                                                        selectedOrder?.deliveries || []
                                                                    ).map((delivery, i) => {
                                                                        if (
                                                                            delivery.id ===
                                                                            (selectedConsigneeCustomer?.delivery_id ||
                                                                                0)
                                                                        ) {
                                                                            delivery.delivery_time2 = e.target.value;
                                                                        }
                                                                        return delivery;
                                                                    }),
                                                                });
                                                            }}
                                                            onChange={(e) => {
                                                                setSelectedConsigneeCustomer({
                                                                    ...selectedConsigneeCustomer,
                                                                    delivery_time2: e.target.value,
                                                                });

                                                                setSelectedOrder({
                                                                    ...selectedOrder,
                                                                    deliveries: (
                                                                        selectedOrder?.deliveries || []
                                                                    ).map((delivery, i) => {
                                                                        if (
                                                                            delivery.id ===
                                                                            (selectedConsigneeCustomer?.delivery_id ||
                                                                                0)
                                                                        ) {
                                                                            delivery.delivery_time2 = e.target.value;
                                                                        }
                                                                        return delivery;
                                                                    }),
                                                                });
                                                            }}
                                                            value={
                                                                selectedConsigneeCustomer?.delivery_time2 || ""
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-row" style={{ alignItems: "center" }}>
                                                    <div className="input-box-container grow"
                                                        style={{ flexGrow: 1, flexBasis: "100%" }}>
                                                        {
                                                            (selectedConsigneeCustomer?.bol_numbers || "").split("|").map((item, index) => {
                                                                if (item.trim() !== "") {
                                                                    return (
                                                                        <div key={index} style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            fontSize: "0.7rem",
                                                                            backgroundColor: "rgba(0,0,0,0.2)",
                                                                            padding: "2px 10px",
                                                                            borderRadius: "10px",
                                                                            marginRight: "2px",
                                                                            cursor: "default",
                                                                        }}
                                                                            title={item}
                                                                        >
                                                                            {
                                                                                (selectedOrder?.is_cancelled || 0) === 0 &&
                                                                                <span className="fas fa-trash-alt" style={{
                                                                                    marginRight: "5px",
                                                                                    cursor: "pointer"
                                                                                }} onClick={() => {
                                                                                    setSelectedConsigneeCustomer({
                                                                                        ...selectedConsigneeCustomer,
                                                                                        bol_numbers: (selectedConsigneeCustomer?.bol_numbers || "").replace(item, "").trim(),
                                                                                    });

                                                                                    setSelectedOrder({
                                                                                        ...selectedOrder,
                                                                                        deliveries: (selectedOrder?.deliveries || []).map((delivery, i) => {
                                                                                            if (delivery.id === (selectedConsigneeCustomer?.delivery_id || 0)) {
                                                                                                delivery.bol_numbers = (selectedConsigneeCustomer?.bol_numbers || "").replace(item, "").trim();
                                                                                            }
                                                                                            return delivery;
                                                                                        }),
                                                                                    });

                                                                                    setIsSavingDeliveryId(selectedConsigneeCustomer?.delivery_id || 0);
                                                                                }}
                                                                                ></span>
                                                                            }

                                                                            <span className="automatic-email-inputted"
                                                                                style={{ whiteSpace: "nowrap" }}>{item}</span>
                                                                        </div>
                                                                    );
                                                                } else {
                                                                    return false;
                                                                }
                                                            })
                                                        }

                                                        <input
                                                            tabIndex={49 + props.tabTimes}
                                                            type="text"
                                                            placeholder="BOL Numbers"
                                                            ref={refDeliveryBolNumbers}
                                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                            onKeyDown={(e) => {
                                                                if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                    deliveryBolNumbersOnKeydown(e);
                                                                }
                                                            }}
                                                            onInput={(e) => {
                                                                setConsigneeBolNumber(e.target.value);
                                                            }}
                                                            onChange={(e) => {
                                                                setConsigneeBolNumber(e.target.value);
                                                            }}
                                                            value={consigneeBolNumber || ""}
                                                        />
                                                    </div>
                                                    <div style={{
                                                        minWidth: "1.8rem",
                                                        fontSize: "1rem",
                                                        textAlign: "center"
                                                    }}></div>
                                                    <div className="input-box-container grow"
                                                        style={{ flexGrow: 1, flexBasis: "100%" }}>

                                                        {(selectedConsigneeCustomer?.po_numbers || "").split("|").map((item, index) => {
                                                            if (item.trim() !== "") {
                                                                return (
                                                                    <div key={index} style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        fontSize: "0.7rem",
                                                                        backgroundColor: "rgba(0,0,0,0.2)",
                                                                        padding: "2px 10px",
                                                                        borderRadius: "10px",
                                                                        marginRight: "2px",
                                                                        cursor: "default",
                                                                    }}
                                                                        title={item}
                                                                    >
                                                                        {
                                                                            (selectedOrder?.is_cancelled || 0) === 0 &&
                                                                            <span className="fas fa-trash-alt" style={{
                                                                                marginRight: "5px",
                                                                                cursor: "pointer"
                                                                            }} onClick={() => {
                                                                                setSelectedConsigneeCustomer({
                                                                                    ...selectedConsigneeCustomer,
                                                                                    po_numbers: (selectedConsigneeCustomer?.po_numbers || "").replace(item, "").trim()
                                                                                });

                                                                                setSelectedOrder({
                                                                                    ...selectedOrder,
                                                                                    deliveries: (selectedOrder?.deliveries || []).map((delivery, i) => {
                                                                                        if (delivery.id === (selectedConsigneeCustomer?.delivery_id || 0)) {
                                                                                            delivery.po_numbers = (selectedConsigneeCustomer?.po_numbers || "").replace(item, "").trim();
                                                                                        }
                                                                                        return delivery;
                                                                                    }),
                                                                                });

                                                                                setIsSavingDeliveryId(selectedConsigneeCustomer?.delivery_id || 0);
                                                                            }}
                                                                            ></span>
                                                                        }
                                                                        <span className="automatic-email-inputted"
                                                                            style={{ whiteSpace: "nowrap" }}>{item}</span>
                                                                    </div>
                                                                );
                                                            } else {
                                                                return false;
                                                            }
                                                        })}
                                                        <input
                                                            tabIndex={50 + props.tabTimes}
                                                            type="text"
                                                            placeholder="PO Numbers"
                                                            ref={refDeliveryPoNumbers}
                                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                            onKeyDown={(e) => {
                                                                if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                    deliveryPoNumbersOnKeydown(e);
                                                                }
                                                            }}
                                                            onInput={(e) => {
                                                                setConsigneePoNumber(e.target.value);
                                                            }}
                                                            onChange={(e) => {
                                                                setConsigneePoNumber(e.target.value);
                                                            }}
                                                            value={consigneePoNumber || ""}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-row" style={{ alignItems: "center" }}>
                                                    <div className="input-box-container grow"
                                                        style={{ flexGrow: 1, flexBasis: "100%" }}>
                                                        {(selectedConsigneeCustomer?.ref_numbers || "").split("|").map((item, index) => {
                                                            if (item.trim() !== "") {
                                                                return (
                                                                    <div key={index} style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        fontSize: "0.7rem",
                                                                        backgroundColor: "rgba(0,0,0,0.2)",
                                                                        padding: "2px 10px",
                                                                        borderRadius: "10px",
                                                                        marginRight: "2px",
                                                                        cursor: "default",
                                                                    }}
                                                                        title={item}
                                                                    >
                                                                        {
                                                                            (selectedOrder?.is_cancelled || 0) === 0 &&
                                                                            <span className="fas fa-trash-alt" style={{
                                                                                marginRight: "5px",
                                                                                cursor: "pointer"
                                                                            }}
                                                                                onClick={() => {
                                                                                    setSelectedConsigneeCustomer({
                                                                                        ...selectedConsigneeCustomer,
                                                                                        ref_numbers: (selectedConsigneeCustomer?.ref_numbers || "").replace(item, "").trim()
                                                                                    });

                                                                                    setSelectedOrder({
                                                                                        ...selectedOrder,
                                                                                        deliveries: (selectedOrder?.deliveries || []).map((delivery, i) => {
                                                                                            if (delivery.id === selectedConsigneeCustomer.delivery_id) {
                                                                                                delivery.ref_numbers = (selectedConsigneeCustomer?.ref_numbers || "").replace(item, "").trim();
                                                                                            }
                                                                                            return delivery;
                                                                                        }),
                                                                                    });

                                                                                    setIsSavingDeliveryId(selectedConsigneeCustomer?.delivery_id || 0);
                                                                                }}
                                                                            ></span>
                                                                        }
                                                                        <span className="automatic-email-inputted"
                                                                            style={{ whiteSpace: "nowrap" }}>{item}</span>
                                                                    </div>
                                                                );
                                                            } else {
                                                                return false;
                                                            }
                                                        })}
                                                        <input
                                                            tabIndex={51 + props.tabTimes}
                                                            type="text"
                                                            placeholder="REF Numbers"
                                                            ref={refDeliveryRefNumbers}
                                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                            onKeyDown={(e) => {
                                                                if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                    deliveryRefNumbersOnKeydown(e);
                                                                }
                                                            }}
                                                            onInput={(e) => {
                                                                setConsigneeRefNumber(e.target.value);
                                                            }}
                                                            onChange={(e) => {
                                                                setConsigneeRefNumber(e.target.value);
                                                            }}
                                                            value={consigneeRefNumber || ""}
                                                        />
                                                    </div>
                                                    <div style={{
                                                        minWidth: "1.8rem",
                                                        fontSize: "1rem",
                                                        textAlign: "center"
                                                    }}></div>
                                                    <div className="input-box-container grow"
                                                        style={{ flexGrow: 1, flexBasis: "100%" }}>
                                                        <input
                                                            tabIndex={52 + props.tabTimes}
                                                            type="text"
                                                            placeholder="SEAL Number"
                                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                            onKeyDown={(e) => {
                                                                if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                    let key = e.keyCode || e.which;

                                                                    if (key === 9) {
                                                                        // setIsSavingDeliveryId(selectedConsigneeCustomer?.delivery_id || 0);
                                                                    }
                                                                }
                                                            }}
                                                            onInput={(e) => {
                                                                setSelectedOrder({
                                                                    ...selectedOrder,
                                                                    deliveries: (selectedOrder?.deliveries || []).map((delivery, i) => {
                                                                        if (delivery.id === (selectedConsigneeCustomer?.delivery_id || 0)) {
                                                                            delivery.seal_number = e.target.value;
                                                                        }
                                                                        return delivery;
                                                                    })
                                                                });

                                                                setSelectedConsigneeCustomer({
                                                                    ...selectedConsigneeCustomer,
                                                                    seal_number: e.target.value,
                                                                });
                                                            }}
                                                            onChange={(e) => {
                                                                setSelectedOrder({
                                                                    ...selectedOrder,
                                                                    deliveries: (selectedOrder?.deliveries || []).map((delivery, i) => {
                                                                        if (delivery.id === (selectedConsigneeCustomer?.delivery_id || 0)) {
                                                                            delivery.seal_number = e.target.value;
                                                                        }
                                                                        return delivery;
                                                                    })
                                                                });

                                                                setSelectedConsigneeCustomer({
                                                                    ...selectedConsigneeCustomer,
                                                                    seal_number: e.target.value,
                                                                });
                                                            }}
                                                            value={selectedConsigneeCustomer?.seal_number || ""}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-row" style={{ alignItems: "center" }}>
                                                    <div className="input-box-container grow">
                                                        <input
                                                            tabIndex={53 + props.tabTimes}
                                                            type="text"
                                                            placeholder="Special Instructions"
                                                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                                            onKeyDown={(e) => {
                                                                if ((selectedOrder?.is_cancelled || 0) === 0) {
                                                                    let key = e.keyCode || e.which;

                                                                    if (key === 9) {
                                                                        e.preventDefault();
                                                                        setIsSavingDeliveryId(selectedConsigneeCustomer?.delivery_id || 0);
                                                                        goToTabindex(
                                                                            (54 + props.tabTimes).toString()
                                                                        );

                                                                        if ((selectedOrder?.load_type_id || 0) === 2) {
                                                                            let panel = {
                                                                                panelName: `${props.panelName}-carrier-list`,
                                                                                component: (
                                                                                    <CarrierList
                                                                                        title="Carriers"
                                                                                        tabTimes={154648410 + props.tabTimes}
                                                                                        panelName={`${props.panelName}-carrier-list`}
                                                                                        origin={props.origin}
                                                                                        componentId={moment().format("x")}
                                                                                        selectedOrderId={selectedOrder.id}
                                                                                        setSelectedOrder={setSelectedOrder}
                                                                                        closingCallback={() => {
                                                                                            closePanel(`${props.panelName}-carrier-list`, props.origin);
                                                                                            refCarrierCode.current.focus({ preventScroll: true })
                                                                                            setIsShowingConsigneeSecondPage(false);
                                                                                        }}
                                                                                    />
                                                                                ),
                                                                            };

                                                                            openPanel(panel, props.origin);
                                                                        } else {
                                                                            refCarrierCode.current.focus({ preventScroll: true });
                                                                            setIsShowingConsigneeSecondPage(false);
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                            onInput={(e) => {
                                                                setSelectedOrder({
                                                                    ...selectedOrder,
                                                                    deliveries: (selectedOrder?.deliveries || []).map((delivery, i) => {
                                                                        if (delivery.id === (selectedConsigneeCustomer?.delivery_id || 0)) {
                                                                            delivery.special_instructions = e.target.value;
                                                                        }
                                                                        return delivery;
                                                                    })
                                                                });

                                                                setSelectedConsigneeCustomer({
                                                                    ...selectedConsigneeCustomer,
                                                                    special_instructions: e.target.value,
                                                                });
                                                            }}
                                                            onChange={(e) => {
                                                                setSelectedOrder({
                                                                    ...selectedOrder,
                                                                    deliveries: (selectedOrder?.deliveries || []).map((delivery, i) => {
                                                                        if (delivery.id === (selectedConsigneeCustomer?.delivery_id || 0)) {
                                                                            delivery.special_instructions = e.target.value;
                                                                        }
                                                                        return delivery;
                                                                    })
                                                                });

                                                                setSelectedConsigneeCustomer({
                                                                    ...selectedConsigneeCustomer,
                                                                    special_instructions: e.target.value,
                                                                });
                                                            }}
                                                            value={selectedConsigneeCustomer?.special_instructions || ""}
                                                        />
                                                    </div>
                                                </div>
                                            </animated.div>
                                        )
                                )}
                            </div>
                        </div>

                        <div className="form-borderless-box"
                            style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
                            <div className="form-row" style={{ flexGrow: 1, display: "flex" }}>
                                <div className="form-bordered-box">
                                    <div className="form-header">
                                        <div className="top-border top-border-left"></div>
                                        <div className="form-title">Internal Notes</div>
                                        <div className="top-border top-border-middle"></div>
                                        <div className="form-buttons">
                                            <div className="mochi-button" style={{
                                                pointerEvents: (selectedOrder?.is_cancelled || 0) === 0 ? 'all' : 'none'
                                            }} onClick={() => {
                                                if ((selectedOrder?.order_number || 0) === 0) {
                                                    window.alert("You must select or create an order first!");
                                                    return;
                                                }
                                                setSelectedInternalNote({ id: 0 });
                                            }}
                                            >
                                                <div className="mochi-button-decorator mochi-button-decorator-left">(
                                                </div>
                                                <div className="mochi-button-base" style={{
                                                    color: (selectedOrder?.is_cancelled || 0) === 0 ? 'black' : 'rgba(0,0,0,0.4)'
                                                }}>Add note
                                                </div>
                                                <div className="mochi-button-decorator mochi-button-decorator-right">)
                                                </div>
                                            </div>
                                        </div>
                                        <div className="top-border top-border-right"></div>
                                    </div>

                                    <div className="internal-notes-container">
                                        <div className="internal-notes-wrapper">
                                            {(selectedOrder?.internal_notes || []).map(
                                                (note, index) => {
                                                    return (
                                                        <div
                                                            className="internal-notes-item"
                                                            key={index}
                                                            onClick={() => setSelectedInternalNote(note)}
                                                        >
                                                            {note.text}
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="fields-container-col"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "10%",
                        padding: "0 0 10px 0",
                    }}
                >
                    <div
                        className="form-bordered-box"
                        style={{ flexGrow: 1, justifyContent: "space-evenly" }}
                    >
                        <div className="form-header">
                            <div className="top-border top-border-left"></div>
                            <div className="form-title">Commisions</div>
                            <div className="top-border top-border-middle"></div>
                            <div className="top-border top-border-right"></div>
                        </div>

                        <div className="select-box-container">
                            <div className="select-box-wrapper">
                                <input
                                    type="text"
                                    // tabIndex={4 + props.tabTimes}
                                    placeholder="Agent Code"
                                    ref={refAgentCode}
                                    readOnly={(selectedOrder?.is_cancelled || 0) === 1 ||
                                        (selectedOrder?.id || 0) === 0 ||
                                        (selectedOrder?.order_invoiced || 0) === 1 ||
                                        !(selectedOrder?.bill_to_company?.agent)
                                    }
                                    onKeyDown={(e) => {
                                        if ((selectedOrder?.is_cancelled || 0) === 0) {
                                            let key = e.keyCode || e.which;

                                            switch (key) {
                                                case 37:
                                                case 38: // arrow left | arrow up
                                                    e.preventDefault();
                                                    if (!((selectedOrder?.is_cancelled || 0) === 1 ||
                                                        (selectedOrder?.id || 0) === 0 ||
                                                        (selectedOrder?.order_invoiced || 0) === 1 ||
                                                        !(selectedOrder?.bill_to_company?.agent))) {
                                                        if (agentCodeItems.length > 0) {
                                                            let selectedIndex = agentCodeItems.findIndex(
                                                                (item) => item.selected
                                                            );

                                                            if (selectedIndex === -1) {
                                                                setAgentCodeItems(agentCodeItems.map((item, index) => {
                                                                    item.selected = index === 0;
                                                                    return item;
                                                                }));
                                                            } else {
                                                                setAgentCodeItems(agentCodeItems.map((item, index) => {
                                                                    if (selectedIndex === 0) {
                                                                        item.selected = index === agentCodeItems.length - 1;
                                                                    } else {
                                                                        item.selected = index === selectedIndex - 1;
                                                                    }
                                                                    return item;
                                                                }));
                                                            }

                                                            refAgentCodePopupItems.current.map((r, i) => {
                                                                if (r && r.classList.contains("selected")) {
                                                                    r.scrollIntoView({
                                                                        behavior: "auto",
                                                                        block: "center",
                                                                        inline: "nearest",
                                                                    });
                                                                }
                                                                return true;
                                                            });
                                                        } else {
                                                            setAgentCodeItems([
                                                                {
                                                                    id: 1,
                                                                    name: 'Not Assigned',
                                                                    selected: (selectedOrder?.agent_code || '') === '' || (selectedOrder?.agent_code || '') === 'Not Assigned'
                                                                },
                                                                {
                                                                    id: 2,
                                                                    name: (selectedOrder?.bill_to_company?.agent?.code || '') !== ''
                                                                        ? selectedOrder.bill_to_company.agent.code
                                                                        : '',
                                                                    selected: (selectedOrder?.agent_code || '') !== '' &&
                                                                        (selectedOrder?.agent_code || '') === (selectedOrder?.bill_to_company?.agent?.code || '')
                                                                }
                                                            ])

                                                            refAgentCodePopupItems.current.map((r, i) => {
                                                                if (r && r.classList.contains("selected")) {
                                                                    r.scrollIntoView({
                                                                        behavior: "auto",
                                                                        block: "center",
                                                                        inline: "nearest",
                                                                    });
                                                                }
                                                                return true;
                                                            });
                                                        }
                                                    }

                                                    break;

                                                case 39:
                                                case 40: // arrow right | arrow down
                                                    e.preventDefault();
                                                    if (!((selectedOrder?.is_cancelled || 0) === 1 ||
                                                        (selectedOrder?.id || 0) === 0 ||
                                                        (selectedOrder?.order_invoiced || 0) === 1 ||
                                                        !(selectedOrder?.bill_to_company?.agent))) {
                                                        if (agentCodeItems.length > 0) {
                                                            let selectedIndex = agentCodeItems.findIndex(
                                                                (item) => item.selected
                                                            );

                                                            if (selectedIndex === -1) {
                                                                setAgentCodeItems(agentCodeItems.map((item, index) => {
                                                                    item.selected = index === 0;
                                                                    return item;
                                                                }));
                                                            } else {
                                                                setAgentCodeItems(agentCodeItems.map((item, index) => {
                                                                    if (selectedIndex === agentCodeItems.length - 1) {
                                                                        item.selected = index === 0;
                                                                    } else {
                                                                        item.selected = index === selectedIndex + 1;
                                                                    }
                                                                    return item;
                                                                }));
                                                            }

                                                            refAgentCodePopupItems.current.map((r, i) => {
                                                                if (r && r.classList.contains("selected")) {
                                                                    r.scrollIntoView({
                                                                        behavior: "auto",
                                                                        block: "center",
                                                                        inline: "nearest",
                                                                    });
                                                                }
                                                                return true;
                                                            });
                                                        } else {
                                                            setAgentCodeItems([
                                                                {
                                                                    id: 1,
                                                                    name: 'Not Assigned',
                                                                    selected: (selectedOrder?.agent_code || '') === '' || (selectedOrder?.agent_code || '') === 'Not Assigned'
                                                                },
                                                                {
                                                                    id: 2,
                                                                    name: (selectedOrder?.bill_to_company?.agent?.code || '') !== ''
                                                                        ? selectedOrder.bill_to_company.agent.code
                                                                        : '',
                                                                    selected: (selectedOrder?.agent_code || '') !== '' &&
                                                                        (selectedOrder?.agent_code || '') === (selectedOrder?.bill_to_company?.agent?.code || '')
                                                                }
                                                            ])

                                                            refAgentCodePopupItems.current.map((r, i) => {
                                                                if (r && r.classList.contains("selected")) {
                                                                    r.scrollIntoView({
                                                                        behavior: "auto",
                                                                        block: "center",
                                                                        inline: "nearest",
                                                                    });
                                                                }
                                                                return true;
                                                            });
                                                        }
                                                    }

                                                    break;

                                                case 27: // escape
                                                    e.preventDefault();
                                                    setAgentCodeItems([]);
                                                    break;

                                                case 13: // enter
                                                    if (agentCodeItems.length > 0 && agentCodeItems.findIndex((item) => item.selected) > -1) {
                                                        setSelectedOrder(prev => {
                                                            return {
                                                                ...prev,
                                                                agent_code: agentCodeItems[agentCodeItems.findIndex((item) => item.selected)].name,
                                                                agent_commission: (agentCodeItems[agentCodeItems.findIndex((item) => item.selected)].name === 'Not Assigned'
                                                                    ? 0
                                                                    : (selectedOrder?.user_code?.code || '') === agentCodeItems[agentCodeItems.findIndex((item) => item.selected)].name
                                                                        ? (selectedOrder?.bill_to_company?.agent?.agent_pay_brokerage || 0)
                                                                        : (selectedOrder?.bill_to_company?.agent?.agent_pay_et3 || 0) > 0
                                                                            ? 100 - selectedOrder?.bill_to_company?.agent?.agent_pay_et3
                                                                            : 0)
                                                            }
                                                        })

                                                        validateOrderForSaving({ keyCode: 9 });
                                                        setAgentCodeItems([]);
                                                        refAgentCode.current.focus();
                                                    }
                                                    break;

                                                case 9: // tab
                                                    if (agentCodeItems.length > 0) {
                                                        e.preventDefault();
                                                        setSelectedOrder(prev => {
                                                            return {
                                                                ...prev,
                                                                agent_code: agentCodeItems[agentCodeItems.findIndex((item) => item.selected)].name,
                                                                agent_commission: (agentCodeItems[agentCodeItems.findIndex((item) => item.selected)].name === 'Not Assigned'
                                                                    ? 0
                                                                    : (selectedOrder?.user_code?.code || '') === agentCodeItems[agentCodeItems.findIndex((item) => item.selected)].name
                                                                        ? (selectedOrder?.bill_to_company?.agent?.agent_pay_brokerage || 0)
                                                                        : (selectedOrder?.bill_to_company?.agent?.agent_pay_et3 || 0) > 0
                                                                            ? 100 - selectedOrder?.bill_to_company?.agent?.agent_pay_et3
                                                                            : 0)
                                                            }
                                                        })
                                                        validateOrderForSaving({ keyCode: 9 });
                                                        setAgentCodeItems([]);
                                                        refAgentCode.current.focus();
                                                    }
                                                    break;

                                                default:
                                                    break;
                                            }
                                        }
                                    }}

                                    onChange={(e) => { }}
                                    value={selectedOrder?.agent_code || ""}
                                />
                                {
                                    ((selectedOrder?.is_cancelled || 0) === 0 &&
                                        (selectedOrder?.order_invoiced || 0) === 0 &&
                                        (selectedOrder?.id || 0) > 0 &&
                                        (selectedOrder?.bill_to_company?.agent?.code || '') !== '') &&
                                    <FontAwesomeIcon
                                        className="dropdown-button"
                                        icon={faCaretDown}
                                        onClick={() => {
                                            if (agentCodeItems.length > 0) {
                                                setAgentCodeItems([]);
                                            } else {
                                                window.setTimeout(() => {
                                                    setAgentCodeItems([
                                                        {
                                                            id: 1,
                                                            name: 'Not Assigned',
                                                            selected: (selectedOrder?.agent_code || '') === '' || (selectedOrder?.agent_code || '') === 'Not Assigned'
                                                        },
                                                        {
                                                            id: 2,
                                                            name: (selectedOrder?.bill_to_company?.agent?.code || '') !== ''
                                                                ? selectedOrder.bill_to_company.agent.code
                                                                : '',
                                                            selected: (selectedOrder?.agent_code || '') !== '' &&
                                                                (selectedOrder?.agent_code || '') === (selectedOrder?.bill_to_company?.agent?.code || '')
                                                        }
                                                    ])

                                                    refAgentCodePopupItems.current.map((r, i) => {
                                                        if (r && r.classList.contains("selected")) {
                                                            r.scrollIntoView({
                                                                behavior: "auto",
                                                                block: "center",
                                                                inline: "nearest",
                                                            });
                                                        }
                                                        return true;
                                                    });
                                                }, 10)
                                            }

                                            refAgentCode.current.focus();
                                        }}
                                    />
                                }
                            </div>

                            {agentCodeTransition(
                                (style, item) =>
                                    item && (
                                        <animated.div
                                            className="mochi-contextual-container"
                                            id="mochi-contextual-container-agent-code"
                                            style={{
                                                ...style,
                                                left: "calc(0% - 250px)",
                                                display: "block",
                                            }}
                                            ref={refAgentCodeDropDown}
                                        >
                                            <div
                                                className="mochi-contextual-popup vertical left below corner"
                                                style={{ height: 150 }}
                                            >
                                                <div className="mochi-contextual-popup-content">
                                                    <div className="mochi-contextual-popup-wrapper">
                                                        {agentCodeItems.map((item, index) => {
                                                            const mochiItemClasses = classnames({
                                                                "mochi-item": true,
                                                                selected: item.selected,
                                                            });

                                                            const searchValue = undefined;

                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className={mochiItemClasses}
                                                                    id={item.id}
                                                                    onClick={() => {
                                                                        setSelectedOrder(prev => {
                                                                            return {
                                                                                ...prev,
                                                                                agent_code: item.name,
                                                                                agent_commission: (item.name === 'Not Assigned'
                                                                                    ? 0
                                                                                    : (selectedOrder?.user_code?.code || '') === item.name
                                                                                        ? (selectedOrder?.bill_to_company?.agent?.agent_pay_brokerage || 0)
                                                                                        : (selectedOrder?.bill_to_company?.agent?.agent_pay_et3 || 0) > 0
                                                                                            ? 100 - selectedOrder?.bill_to_company?.agent?.agent_pay_et3
                                                                                            : 0)
                                                                            }
                                                                        })

                                                                        window.setTimeout(() => {
                                                                            validateOrderForSaving({ keyCode: 9 });
                                                                            setAgentCodeItems([]);
                                                                            refAgentCode.current.focus();
                                                                        }, 0);
                                                                    }}
                                                                    ref={(ref) => refAgentCodePopupItems.current.push(ref)}
                                                                >
                                                                    {searchValue === undefined ? (
                                                                        item.name
                                                                    ) : (
                                                                        <Highlighter
                                                                            highlightClassName="mochi-item-highlight-text"
                                                                            searchWords={[searchValue]}
                                                                            autoEscape={true}
                                                                            textToHighlight={item.name}
                                                                        />
                                                                    )}
                                                                    {item.selected && (
                                                                        <FontAwesomeIcon
                                                                            className="dropdown-selected"
                                                                            icon={faCaretRight}
                                                                        />
                                                                    )}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </animated.div>
                                    )
                            )}
                        </div>
                        <div className="input-box-container grow">
                            <NumberFormat
                                style={{ fontSize: "0.7rem", textAlign: "left" }}
                                value={
                                    selectedOrder?.bill_to_company?.agent
                                        ? new Intl.NumberFormat("en-US", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }).format(
                                            (Number((
                                                (selectedOrder?.order_customer_ratings || []).reduce((a, b) => {
                                                    return {
                                                        total_charges:
                                                            Number((a.total_charges || "").toString().replace(",", "")) +
                                                            Number((b.total_charges || "").toString().replace(",", "")),
                                                    };
                                                }, { total_charges: "" })?.total_charges || "").toString().replace(",", "")) -
                                                Number(((selectedOrder?.order_carrier_ratings || []).reduce((a, b) => {
                                                    return {
                                                        total_charges:
                                                            Number((a.total_charges || "").toString().replace(",", "")) +
                                                            Number((b.total_charges || "").toString().replace(",", "")),
                                                    };
                                                }, { total_charges: "" })?.total_charges || "").toString().replace(",", ""))) *
                                            ((selectedOrder?.agent_commission || 0) > 0 ? selectedOrder.agent_commission / 100 : 0)
                                        )
                                        : '' // not agent owner
                                }
                                thousandsGroupStyle="thousand"
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix={"$ "}
                                type="text"
                                onValueChange={(values) => {
                                }}
                                displayType={"input"}
                                placeholder="Agent Commission"
                                readOnly={true}
                            />

                            {
                                (selectedOrder?.agent_date_paid || '') !== '' &&
                                <div className="agent-paid-btn" onClick={() => {
                                    let panel = {
                                        panelName: `${props.panelName}-invoice`,
                                        component: <Invoice
                                            pageName={'Invoice'}
                                            title={'Invoice'}
                                            panelName={`${props.panelName}-invoice`}
                                            tabTimes={500560 + props.tabTimes}
                                            screenFocused={props.invoiceScreenFocused}
                                            componentId={moment().format('x')}
                                            isOnPanel={true}
                                            origin={props.origin}
                                            closingCallback={() => {
                                                closePanel(`${props.panelName}-invoice`, props.origin);
                                                (props.isOnPanel ? refOrderNumber : props.refOrderNumber).current.focus({ preventScroll: true });
                                            }}

                                            order_id={(selectedOrder?.id || 0)}
                                        />
                                    }

                                    openPanel(panel, props.origin);
                                }}>Paid</div>
                            }
                        </div>
                        <div className="input-box-container grow">
                            <input type="text" placeholder="Salesman Code" readOnly={true} />
                        </div>
                        <div className="input-box-container grow">
                            <input type="text" placeholder="Salesman Commission" readOnly={true} onKeyDown={(e) => {
                                let key = e.keyCode || e.which;

                                if (key === 9) {
                                    e.preventDefault();
                                    if (props.isOnPanel) {
                                        if (refOrderNumber?.current) {
                                            refOrderNumber.current.focus({
                                                preventScroll: true,
                                            });
                                        }
                                    } else {
                                        if (props.refOrderNumber?.current) {
                                            props.refOrderNumber.current.focus({
                                                preventScroll: true,
                                            });
                                        }
                                    }
                                }
                            }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="fields-container-row" style={{ marginBottom: 10 }}>
                <div
                    style={{
                        minWidth: "70%",
                        maxWidth: "70%",
                        display: "flex",
                        alignItems: "center",
                        marginRight: 10,
                    }}
                >
                    <div
                        className="select-box-container"
                        style={{ width: "10rem", position: "relative" }}
                    >
                        <div className="select-box-wrapper">
                            <input
                                tabIndex={71 + props.tabTimes}
                                type="text"
                                placeholder="Event"
                                ref={refDispatchEvents}
                                readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                onKeyDown={(e) => {
                                    if ((selectedOrder?.is_cancelled || 0) === 0) {
                                        let key = e.keyCode || e.which;

                                        switch (key) {
                                            case 37:
                                            case 38: // arrow left | arrow up
                                                e.preventDefault();

                                                if ((selectedOrder?.id || 0) === 0) {
                                                    // window.alert('You must create or load an order first!');
                                                    return;
                                                }

                                                if (dispatchEventItems.length > 0) {
                                                    if (showDispatchEventSecondPageItems) {
                                                        let selectedIndex = dispatchEventSecondPageItems.findIndex((item) => item.selected);

                                                        if (selectedIndex === -1) {
                                                            setDispatchEventSecondPageItems(dispatchEventSecondPageItems.map((item, index) => {
                                                                item.selected = index === 0;
                                                                return item;
                                                            }));
                                                        } else {
                                                            setDispatchEventSecondPageItems(
                                                                dispatchEventSecondPageItems.map((item, index) => {
                                                                    if (selectedIndex === 0) {
                                                                        item.selected = index === dispatchEventSecondPageItems.length - 1;
                                                                    } else {
                                                                        item.selected = index === selectedIndex - 1;
                                                                    }
                                                                    return item;
                                                                })
                                                            );
                                                        }

                                                        refDispatchEventSecondPagePopupItems.current.map(
                                                            (r, i) => {
                                                                if (r && r.classList.contains("selected")) {
                                                                    r.scrollIntoView({
                                                                        behavior: "auto",
                                                                        block: "center",
                                                                        inline: "nearest",
                                                                    });
                                                                }
                                                                return true;
                                                            }
                                                        );
                                                    } else {
                                                        let selectedIndex = dispatchEventItems.findIndex(
                                                            (item) => item.selected
                                                        );

                                                        if (selectedIndex === -1) {
                                                            setDispatchEventItems(
                                                                dispatchEventItems.map((item, index) => {
                                                                    item.selected = index === 0;
                                                                    return item;
                                                                })
                                                            );
                                                        } else {
                                                            setDispatchEventItems(
                                                                dispatchEventItems.map((item, index) => {
                                                                    if (selectedIndex === 0) {
                                                                        item.selected = index === dispatchEventItems.length - 1;
                                                                    } else {
                                                                        item.selected = index === selectedIndex - 1;
                                                                    }
                                                                    return item;
                                                                })
                                                            );
                                                        }

                                                        refDispatchEventPopupItems.current.map((r, i) => {
                                                            if (r && r.classList.contains("selected")) {
                                                                r.scrollIntoView({
                                                                    behavior: "auto",
                                                                    block: "center",
                                                                    inline: "nearest",
                                                                });
                                                            }
                                                            return true;
                                                        });
                                                    }
                                                } else {
                                                    axios.post(props.serverUrl + "/getEventTypes").then((res) => {
                                                        if (res.data.result === "OK") {
                                                            setDispatchEventItems(
                                                                res.data.event_types.map((item, index) => {
                                                                    item.selected = (dispatchEvent?.id || 0) === 0 ? index === 0 : item.id === dispatchEvent.id;
                                                                    return item;
                                                                })
                                                            );

                                                            refDispatchEventPopupItems.current.map((r, i) => {
                                                                if (r && r.classList.contains("selected")) {
                                                                    r.scrollIntoView({
                                                                        behavior: "auto",
                                                                        block: "center",
                                                                        inline: "nearest",
                                                                    });
                                                                }
                                                                return true;
                                                            });
                                                        }
                                                    }).catch((e) => {
                                                        console.log("error getting event types", e);
                                                    });
                                                }
                                                break;

                                            case 39:
                                            case 40: // arrow right | arrow down
                                                e.preventDefault();

                                                if ((selectedOrder?.id || 0) === 0) {
                                                    // window.alert('You must create or load an order first!');
                                                    return;
                                                }

                                                if (dispatchEventItems.length > 0) {
                                                    if (showDispatchEventSecondPageItems) {
                                                        let selectedIndex = dispatchEventSecondPageItems.findIndex((item) => item.selected);

                                                        if (selectedIndex === -1) {
                                                            setDispatchEventSecondPageItems(
                                                                dispatchEventSecondPageItems.map(
                                                                    (item, index) => {
                                                                        item.selected = index === 0;
                                                                        return item;
                                                                    }
                                                                )
                                                            );
                                                        } else {
                                                            setDispatchEventSecondPageItems(
                                                                dispatchEventSecondPageItems.map((item, index) => {
                                                                    if (selectedIndex === dispatchEventSecondPageItems.length - 1) {
                                                                        item.selected = index === 0;
                                                                    } else {
                                                                        item.selected = index === selectedIndex + 1;
                                                                    }
                                                                    return item;
                                                                }
                                                                )
                                                            );
                                                        }

                                                        refDispatchEventSecondPagePopupItems.current.map(
                                                            (r, i) => {
                                                                if (r && r.classList.contains("selected")) {
                                                                    r.scrollIntoView({
                                                                        behavior: "auto",
                                                                        block: "center",
                                                                        inline: "nearest",
                                                                    });
                                                                }
                                                                return true;
                                                            }
                                                        );
                                                    } else {
                                                        let selectedIndex = dispatchEventItems.findIndex((item) => item.selected);

                                                        if (selectedIndex === -1) {
                                                            setDispatchEventItems(
                                                                dispatchEventItems.map((item, index) => {
                                                                    item.selected = index === 0;
                                                                    return item;
                                                                })
                                                            );
                                                        } else {
                                                            setDispatchEventItems(
                                                                dispatchEventItems.map((item, index) => {
                                                                    if (selectedIndex === dispatchEventItems.length - 1) {
                                                                        item.selected = index === 0;
                                                                    } else {
                                                                        item.selected = index === selectedIndex + 1;
                                                                    }
                                                                    return item;
                                                                })
                                                            );
                                                        }

                                                        refDispatchEventPopupItems.current.map((r, i) => {
                                                            if (r && r.classList.contains("selected")) {
                                                                r.scrollIntoView({
                                                                    behavior: "auto",
                                                                    block: "center",
                                                                    inline: "nearest",
                                                                });
                                                            }
                                                            return true;
                                                        });
                                                    }
                                                } else {
                                                    axios.post(props.serverUrl + "/getEventTypes").then((res) => {
                                                        if (res.data.result === "OK") {
                                                            setDispatchEventItems(
                                                                res.data.event_types.map((item, index) => {
                                                                    item.selected = (dispatchEvent?.id || 0) === 0 ? index === 0 : item.id === dispatchEvent.id;
                                                                    return item;
                                                                })
                                                            );

                                                            refDispatchEventPopupItems.current.map((r, i) => {
                                                                if (r && r.classList.contains("selected")) {
                                                                    r.scrollIntoView({
                                                                        behavior: "auto",
                                                                        block: "center",
                                                                        inline: "nearest",
                                                                    });
                                                                }
                                                                return true;
                                                            });
                                                        }
                                                    }).catch((e) => {
                                                        console.log("error getting event types", e);
                                                    });
                                                }
                                                break;

                                            case 27: // escape
                                                e.preventDefault();
                                                if (showDispatchEventSecondPageItems) {
                                                    setShowDispatchEventSecondPageItems(false);
                                                } else {
                                                    setDispatchEventItems([]);
                                                }

                                                // refDispatchEvents.current.focus();
                                                break;

                                            case 13: // enter
                                                if (dispatchEventItems.length > 0 && dispatchEventItems.findIndex((item) => item.selected) > -1) {
                                                    if (showDispatchEventSecondPageItems) {
                                                        let item = dispatchEventSecondPageItems.find((el) => el.selected);

                                                        if (item !== undefined) {
                                                            let eventItem = dispatchEventItems.find((el) => el.selected);

                                                            setSelectedOrderEvent(item);

                                                            setDispatchEvent(eventItem);
                                                            setDispatchEventLocation(item.customer.city + ", " + item.customer.state);

                                                            if ((eventItem?.name || "").toLowerCase() === "arrived") {
                                                                setDispatchEventNotes("Arrived at " + item.customer.code + (item.customer.code_number === 0 ? "" : item.customer.code_number) + " - " + item.customer.name);
                                                            }

                                                            if ((eventItem?.name || "").toLowerCase() === "loaded") {
                                                                setDispatchEventNotes("Loaded at Shipper " + item.customer.code + (item.customer.code_number === 0 ? "" : item.customer.code_number) + " - " + item.customer.name);
                                                            }

                                                            if ((eventItem?.name || "").toLowerCase() === "delivered") {
                                                                setDispatchEventNotes("Delivered at Consignee " + item.customer.code + (item.customer.code_number === 0 ? "" : item.customer.code_number) + " - " + item.customer.name);
                                                            }

                                                            window.setTimeout(() => {
                                                                setShowDispatchEventSecondPageItems(false);
                                                                setDispatchEventItems([]);
                                                                refEventDate.current.inputElement.focus();
                                                            }, 0);
                                                        }
                                                    } else {
                                                        let item = dispatchEventItems[dispatchEventItems.findIndex((item) => item.selected)];

                                                        if ((item?.name || "").toLowerCase() === "arrived") {
                                                            if (getPickupsOnRouting().length > 0 || getDeliveriesOnRouting().length > 0) {
                                                                setDispatchEventItems(dispatchEventItems.map((item, index) => {
                                                                    item.selected = (item?.name || "").toLowerCase() === "arrived";
                                                                    return item;
                                                                }));

                                                                let arriveIndex = -1;
                                                                let departureIndex = -1;

                                                                for (let i = 0; i < (selectedOrder?.events || []).length; i++) {
                                                                    let event = (selectedOrder?.events || [])[i];

                                                                    if ((event.event_type?.name || "").toLowerCase() === "arrived") {
                                                                        arriveIndex = i;
                                                                        break;
                                                                    }
                                                                }

                                                                for (let i = 0; i < (selectedOrder?.events || []).length; i++) {
                                                                    let event = (selectedOrder?.events || [])[i];

                                                                    if ((event.event_type?.name || "").toLowerCase() === "departed") {
                                                                        departureIndex = i;
                                                                        break;
                                                                    }
                                                                }

                                                                if ((arriveIndex === -1 && departureIndex === -1) || (departureIndex > -1 && departureIndex < arriveIndex)) {
                                                                    let items = [
                                                                        ...getPickupsOnRouting().filter((pu) => {
                                                                            return (selectedOrder?.events.find((el) => (el.event_type?.name || "").toLowerCase() === "arrived" && el.shipper_id === (pu.customer?.id || 0)) === undefined);
                                                                        }),
                                                                        ...getDeliveriesOnRouting().filter((delivery) => {
                                                                            return (selectedOrder?.events.find((el) => (el.event_type?.name || "").toLowerCase() === "arrived" && el.consignee_id === (delivery?.customer.id || 0)) === undefined);
                                                                        }),
                                                                    ];

                                                                    if (items.length > 0) {
                                                                        if (items.length === 1) {
                                                                            setDispatchEvent(item);
                                                                            setDispatchEventLocation(items[0].customer.city + ", " + items[0].customer.state);
                                                                            setDispatchEventNotes("Arrived at " + items[0].customer.code + (items[0].customer.code_number === 0 ? "" : items[0].customer.code_number) + " - " + items[0].customer.name);
                                                                            setSelectedOrderEvent(items[0]);

                                                                            window.setTimeout(() => {
                                                                                setDispatchEventItems([]);
                                                                                setDispatchEventSecondPageItems([]);
                                                                                setShowDispatchEventSecondPageItems(false);
                                                                                refEventDate.current.inputElement.focus();
                                                                            }, 0);
                                                                        } else {
                                                                            items = items.map((x, i) => {
                                                                                x.selected = i === 0;
                                                                                return x;
                                                                            });
                                                                        }
                                                                    } else {
                                                                        window.alert("No shippers or consignees available!");
                                                                        refDispatchEvents.current.focus();
                                                                        return;
                                                                    }

                                                                    window.setTimeout(async () => {
                                                                        setDispatchEventSecondPageItems(items);
                                                                        setShowDispatchEventSecondPageItems(true);
                                                                    }, 0);
                                                                } else {
                                                                    window.alert("You must enter a 'departed' event for the last 'arrived' event first!");
                                                                    refDispatchEvents.current.focus();
                                                                    return;
                                                                }
                                                            } else {
                                                                window.alert("No shippers or consignees available!");
                                                                refDispatchEvents.current.focus();
                                                                return;
                                                            }
                                                        } else if (item.type === "departed") {
                                                            setDispatchEventItems(dispatchEventItems.map((item, index) => {
                                                                item.selected = (item?.name || "").toLowerCase() === "arrived";
                                                                return item;
                                                            }));

                                                            let arriveIndex = -1;
                                                            let departureIndex = -1;
                                                            let arrived_customer = {};

                                                            for (let i = 0; i < (selectedOrder?.events || []).length; i++) {
                                                                let event = (selectedOrder?.events || [])[i];

                                                                if ((event.event_type?.name || "").toLowerCase() === "arrived") {
                                                                    arrived_customer = {
                                                                        ...event.arrived_customer,
                                                                    };
                                                                    arriveIndex = i;
                                                                    break;
                                                                }
                                                            }

                                                            for (let i = 0; i < (selectedOrder?.events || []).length; i++) {
                                                                let event = (selectedOrder?.events || [])[i];

                                                                if ((event.event_type?.name || "").toLowerCase() === "departed") {
                                                                    departureIndex = i;
                                                                    break;
                                                                }
                                                            }

                                                            if ((arriveIndex === -1 && departureIndex === -1) || (departureIndex > -1 && departureIndex < arriveIndex)) {
                                                                window.alert("You must enter an 'arrived' event first!");
                                                                refDispatchEvents.current.focus();
                                                                return;
                                                            } else {
                                                                setDispatchEvent(item);
                                                                setDispatchEventLocation(arrived_customer.city + ", " + arrived_customer.state);
                                                                setDispatchEventNotes("Departed at " + arrived_customer.code + (arrived_customer.code_number === 0 ? "" : arrived_customer.code_number) + " - " + arrived_customer.name);
                                                                setSelectedOrderEvent(arrived_customer);

                                                                window.setTimeout(() => {
                                                                    setDispatchEventItems([]);
                                                                    setDispatchEventSecondPageItems([]);
                                                                    setShowDispatchEventSecondPageItems(false);
                                                                    refEventDate.current.inputElement.focus();
                                                                }, 0);
                                                            }
                                                        } else if ((item?.name || "").toLowerCase() === "loaded") {
                                                            if (getPickupsOnRouting().length > 0) {
                                                                setDispatchEventItems(dispatchEventItems.map((item, index) => {
                                                                    item.selected = (item?.name || "").toLowerCase() === "loaded";
                                                                    return item;
                                                                }));

                                                                let items = [
                                                                    ...getPickupsOnRouting().filter((pu) => {
                                                                        return (selectedOrder?.events.find((el) => (el.event_type?.name || "").toLowerCase() === "loaded" && el.shipper_id === pu.customer.id) === undefined);
                                                                    }),
                                                                ];

                                                                if (items.length > 0) {
                                                                    if (items.length === 1) {
                                                                        setDispatchEvent(item);
                                                                        setDispatchEventLocation(items[0].customer.city + ", " + items[0].customer.state);
                                                                        setDispatchEventNotes("Loaded at Shipper " + items[0].customer.code + (items[0].customer.code_number === 0 ? "" : items[0].customer.code_number) + " - " + items[0].customer.name);
                                                                        setSelectedOrderEvent(items[0]);

                                                                        window.setTimeout(() => {
                                                                            setDispatchEventItems([]);
                                                                            setDispatchEventSecondPageItems([]);
                                                                            setShowDispatchEventSecondPageItems(false);
                                                                            refEventDate.current.inputElement.focus();
                                                                        }, 0);
                                                                    } else {
                                                                        items = items.map((x, i) => {
                                                                            x.selected = i === 0;
                                                                            return x;
                                                                        });
                                                                    }
                                                                } else {
                                                                    window.alert("No shippers available!");
                                                                    refDispatchEvents.current.focus();
                                                                    return;
                                                                }

                                                                window.setTimeout(async () => {
                                                                    setDispatchEventSecondPageItems(items);
                                                                    setShowDispatchEventSecondPageItems(true);
                                                                }, 0);
                                                            } else {
                                                                window.alert("No shippers available!");
                                                                refDispatchEvents.current.focus();
                                                                return;
                                                            }
                                                        } else if ((item?.name || "").toLowerCase() === "delivered") {
                                                            if (getDeliveriesOnRouting().length > 0) {
                                                                setDispatchEventItems(dispatchEventItems.map((item, index) => {
                                                                    item.selected = (item?.name || "").toLowerCase() === "delivered";
                                                                    return item;
                                                                }));

                                                                let items = [
                                                                    ...getDeliveriesOnRouting().filter(
                                                                        (delivery) => {
                                                                            return (selectedOrder?.events.find((el) => (el.event_type?.name || "").toLowerCase() === "delivered" && el.consignee_id === delivery.customer.id) === undefined);
                                                                        }
                                                                    ),
                                                                ];

                                                                if (items.length > 0) {
                                                                    if (items.length === 1) {
                                                                        setDispatchEvent(item);
                                                                        setDispatchEventLocation(items[0].customer.city + ", " + items[0].customer.state);
                                                                        setDispatchEventNotes("Delivered at Consignee " + items[0].customer.code + (items[0].customer.code_number === 0 ? "" : items[0].customer.code_number) + " - " + items[0].customer.name);
                                                                        setSelectedOrderEvent(items[0]);

                                                                        window.setTimeout(() => {
                                                                            setDispatchEventItems([]);
                                                                            setDispatchEventSecondPageItems([]);
                                                                            setShowDispatchEventSecondPageItems(false);
                                                                            refEventDate.current.inputElement.focus();
                                                                        }, 0);
                                                                    } else {
                                                                        items = items.map((x, i) => {
                                                                            x.selected = i === 0;
                                                                            return x;
                                                                        });
                                                                    }
                                                                } else {
                                                                    window.alert("No consignees available!");
                                                                    refDispatchEvents.current.focus();
                                                                    return;
                                                                }

                                                                window.setTimeout(async () => {
                                                                    setDispatchEventSecondPageItems(items);
                                                                    setShowDispatchEventSecondPageItems(true);
                                                                }, 0);
                                                            } else {
                                                                window.alert("No consignees available!");
                                                                refDispatchEvents.current.focus();
                                                                return;
                                                            }
                                                        } else {
                                                            setDispatchEvent(item);
                                                            setDispatchEventLocation("");
                                                            setDispatchEventNotes("");
                                                            setDispatchEventItems([]);
                                                            refDispatchEventLocation.current.focus();
                                                        }
                                                    }
                                                }
                                                break;

                                            case 9: // tab
                                                if (dispatchEventItems.length > 0 || showDispatchEventSecondPageItems) {
                                                    e.preventDefault();
                                                    if (dispatchEventItems.length > 0 && dispatchEventItems.findIndex((item) => item.selected) > -1) {
                                                        if (showDispatchEventSecondPageItems) {
                                                            let item = dispatchEventSecondPageItems.find((el) => el.selected);

                                                            if (item !== undefined) {
                                                                let eventItem = dispatchEventItems.find((el) => el.selected);

                                                                setSelectedOrderEvent(item);

                                                                setDispatchEvent(eventItem);
                                                                setDispatchEventLocation(item.customer.city + ", " + item.customer.state);

                                                                if ((eventItem?.name || "").toLowerCase() === "arrived") {
                                                                    setDispatchEventNotes("Arrived at " + item.customer.code + (item.customer.code_number === 0 ? "" : item.customer.code_number) + " - " + item.customer.name);
                                                                }

                                                                if ((eventItem?.name || "").toLowerCase() === "loaded") {
                                                                    setDispatchEventNotes("Loaded at Shipper " + item.customer.code + (item.customer.code_number === 0 ? "" : item.customer.code_number) + " - " + item.customer.name);
                                                                }

                                                                if ((eventItem?.name || "").toLowerCase() === "delivered") {
                                                                    setDispatchEventNotes("Delivered at Consignee " + item.customer.code + (item.customer.code_number === 0 ? "" : item.customer.code_number) + " - " + item.customer.name);
                                                                }

                                                                window.setTimeout(() => {
                                                                    setShowDispatchEventSecondPageItems(false);
                                                                    setDispatchEventItems([]);
                                                                    refEventDate.current.inputElement.focus();
                                                                }, 0);
                                                            }
                                                        } else {
                                                            let item = dispatchEventItems[dispatchEventItems.findIndex((item) => item.selected)];

                                                            if ((item?.name || "").toLowerCase() === "arrived") {
                                                                if (getPickupsOnRouting().length > 0 || getDeliveriesOnRouting().length > 0) {
                                                                    setDispatchEventItems(dispatchEventItems.map((item, index) => {
                                                                        item.selected = (item?.name || "").toLowerCase() === "arrived";
                                                                        return item;
                                                                    }));

                                                                    let arriveIndex = -1;
                                                                    let departureIndex = -1;

                                                                    for (let i = 0; i < (selectedOrder?.events || []).length; i++) {
                                                                        let event = (selectedOrder?.events || [])[i];

                                                                        if ((event.event_type?.name || "").toLowerCase() === "arrived") {
                                                                            arriveIndex = i;
                                                                            break;
                                                                        }
                                                                    }

                                                                    for (let i = 0; i < (selectedOrder?.events || []).length; i++) {
                                                                        let event = (selectedOrder?.events || [])[i];

                                                                        if ((event.event_type?.name || "").toLowerCase() === "departed") {
                                                                            departureIndex = i;
                                                                            break;
                                                                        }
                                                                    }

                                                                    if ((arriveIndex === -1 && departureIndex === -1) || (departureIndex > -1 && departureIndex < arriveIndex)) {
                                                                        let items = [
                                                                            ...getPickupsOnRouting().filter((pu) => {
                                                                                return (selectedOrder?.events.find((el) => (el.event_type?.name || "").toLowerCase() === "arrived" && el.shipper_id === (pu.customer?.id || 0)) === undefined);
                                                                            }),
                                                                            ...getDeliveriesOnRouting().filter((delivery) => {
                                                                                return (selectedOrder?.events.find((el) => (el.event_type?.name || "").toLowerCase() === "arrived" && el.consignee_id === (delivery?.customer.id || 0)) === undefined);
                                                                            }),
                                                                        ];

                                                                        if (items.length > 0) {
                                                                            if (items.length === 1) {
                                                                                setDispatchEvent(item);
                                                                                setDispatchEventLocation(items[0].customer.city + ", " + items[0].customer.state);
                                                                                setDispatchEventNotes("Arrived at " + items[0].customer.code + (items[0].customer.code_number === 0 ? "" : items[0].customer.code_number) + " - " + items[0].customer.name);
                                                                                setSelectedOrderEvent(items[0]);

                                                                                window.setTimeout(() => {
                                                                                    setDispatchEventItems([]);
                                                                                    setDispatchEventSecondPageItems([]);
                                                                                    setShowDispatchEventSecondPageItems(false);
                                                                                    refEventDate.current.inputElement.focus();
                                                                                }, 0);
                                                                            } else {
                                                                                items = items.map((x, i) => {
                                                                                    x.selected = i === 0;
                                                                                    return x;
                                                                                });
                                                                            }
                                                                        } else {
                                                                            window.alert("No shippers or consignees available!");
                                                                            refDispatchEvents.current.focus();
                                                                            return;
                                                                        }

                                                                        window.setTimeout(async () => {
                                                                            setDispatchEventSecondPageItems(items);
                                                                            setShowDispatchEventSecondPageItems(true);
                                                                        }, 0);
                                                                    } else {
                                                                        window.alert("You must enter a 'departed' event for the last 'arrived' event first!");
                                                                        refDispatchEvents.current.focus();
                                                                        return;
                                                                    }
                                                                } else {
                                                                    window.alert("No shippers or consignees available!");
                                                                    refDispatchEvents.current.focus();
                                                                    return;
                                                                }
                                                            } else if (item.type === "departed") {
                                                                setDispatchEventItems(dispatchEventItems.map((item, index) => {
                                                                    item.selected = (item?.name || "").toLowerCase() === "arrived";
                                                                    return item;
                                                                }));

                                                                let arriveIndex = -1;
                                                                let departureIndex = -1;
                                                                let arrived_customer = {};

                                                                for (let i = 0; i < (selectedOrder?.events || []).length; i++) {
                                                                    let event = (selectedOrder?.events || [])[i];

                                                                    if ((event.event_type?.name || "").toLowerCase() === "arrived") {
                                                                        arrived_customer = {
                                                                            ...event.arrived_customer,
                                                                        };
                                                                        arriveIndex = i;
                                                                        break;
                                                                    }
                                                                }

                                                                for (let i = 0; i < (selectedOrder?.events || []).length; i++) {
                                                                    let event = (selectedOrder?.events || [])[i];

                                                                    if ((event.event_type?.name || "").toLowerCase() === "departed") {
                                                                        departureIndex = i;
                                                                        break;
                                                                    }
                                                                }

                                                                if ((arriveIndex === -1 && departureIndex === -1) || (departureIndex > -1 && departureIndex < arriveIndex)) {
                                                                    window.alert("You must enter an 'arrived' event first!");
                                                                    refDispatchEvents.current.focus();
                                                                    return;
                                                                } else {
                                                                    setDispatchEvent(item);
                                                                    setDispatchEventLocation(arrived_customer.city + ", " + arrived_customer.state);
                                                                    setDispatchEventNotes("Departed at " + arrived_customer.code + (arrived_customer.code_number === 0 ? "" : arrived_customer.code_number) + " - " + arrived_customer.name);
                                                                    setSelectedOrderEvent(arrived_customer);

                                                                    window.setTimeout(() => {
                                                                        setDispatchEventItems([]);
                                                                        setDispatchEventSecondPageItems([]);
                                                                        setShowDispatchEventSecondPageItems(false);
                                                                        refEventDate.current.inputElement.focus();
                                                                    }, 0);
                                                                }
                                                            } else if ((item?.name || "").toLowerCase() === "loaded") {
                                                                if (getPickupsOnRouting().length > 0) {
                                                                    setDispatchEventItems(dispatchEventItems.map((item, index) => {
                                                                        item.selected = (item?.name || "").toLowerCase() === "loaded";
                                                                        return item;
                                                                    }));

                                                                    let items = [
                                                                        ...getPickupsOnRouting().filter((pu) => {
                                                                            return (selectedOrder?.events.find((el) => (el.event_type?.name || "").toLowerCase() === "loaded" && el.shipper_id === pu.customer.id) === undefined);
                                                                        }),
                                                                    ];

                                                                    if (items.length > 0) {
                                                                        if (items.length === 1) {
                                                                            setDispatchEvent(item);
                                                                            setDispatchEventLocation(items[0].customer.city + ", " + items[0].customer.state);
                                                                            setDispatchEventNotes("Loaded at Shipper " + items[0].customer.code + (items[0].customer.code_number === 0 ? "" : items[0].customer.code_number) + " - " + items[0].customer.name);
                                                                            setSelectedOrderEvent(items[0]);

                                                                            window.setTimeout(() => {
                                                                                setDispatchEventItems([]);
                                                                                setDispatchEventSecondPageItems([]);
                                                                                setShowDispatchEventSecondPageItems(false);
                                                                                refEventDate.current.inputElement.focus();
                                                                            }, 0);
                                                                        } else {
                                                                            items = items.map((x, i) => {
                                                                                x.selected = i === 0;
                                                                                return x;
                                                                            });
                                                                        }
                                                                    } else {
                                                                        window.alert("No shippers available!");
                                                                        refDispatchEvents.current.focus();
                                                                        return;
                                                                    }

                                                                    window.setTimeout(async () => {
                                                                        setDispatchEventSecondPageItems(items);
                                                                        setShowDispatchEventSecondPageItems(true);
                                                                    }, 0);
                                                                } else {
                                                                    window.alert("No shippers available!");
                                                                    refDispatchEvents.current.focus();
                                                                    return;
                                                                }
                                                            } else if ((item?.name || "").toLowerCase() === "delivered") {
                                                                if (getDeliveriesOnRouting().length > 0) {
                                                                    setDispatchEventItems(dispatchEventItems.map((item, index) => {
                                                                        item.selected = (item?.name || "").toLowerCase() === "delivered";
                                                                        return item;
                                                                    }));

                                                                    let items = [
                                                                        ...getDeliveriesOnRouting().filter(
                                                                            (delivery) => {
                                                                                return (selectedOrder?.events.find((el) => (el.event_type?.name || "").toLowerCase() === "delivered" && el.consignee_id === delivery.customer.id) === undefined);
                                                                            }
                                                                        ),
                                                                    ];

                                                                    if (items.length > 0) {
                                                                        if (items.length === 1) {
                                                                            setDispatchEvent(item);
                                                                            setDispatchEventLocation(items[0].customer.city + ", " + items[0].customer.state);
                                                                            setDispatchEventNotes("Delivered at Consignee " + items[0].customer.code + (items[0].customer.code_number === 0 ? "" : items[0].customer.code_number) + " - " + items[0].customer.name);
                                                                            setSelectedOrderEvent(items[0]);

                                                                            window.setTimeout(() => {
                                                                                setDispatchEventItems([]);
                                                                                setDispatchEventSecondPageItems([]);
                                                                                setShowDispatchEventSecondPageItems(false);
                                                                                refEventDate.current.inputElement.focus();
                                                                            }, 0);
                                                                        } else {
                                                                            items = items.map((x, i) => {
                                                                                x.selected = i === 0;
                                                                                return x;
                                                                            });
                                                                        }
                                                                    } else {
                                                                        window.alert("No consignees available!");
                                                                        refDispatchEvents.current.focus();
                                                                        return;
                                                                    }

                                                                    window.setTimeout(async () => {
                                                                        setDispatchEventSecondPageItems(items);
                                                                        setShowDispatchEventSecondPageItems(true);
                                                                    }, 0);
                                                                } else {
                                                                    window.alert("No consignees available!");
                                                                    refDispatchEvents.current.focus();
                                                                    return;
                                                                }
                                                            } else {
                                                                setDispatchEvent(item);
                                                                setDispatchEventLocation("");
                                                                setDispatchEventNotes("");
                                                                setDispatchEventItems([]);
                                                                refDispatchEventLocation.current.focus();
                                                            }
                                                        }
                                                    }
                                                }
                                                break;
                                            default:
                                                break;
                                        }
                                    }
                                }}
                                onBlur={(e) => {
                                    // if ((dispatchEvent?.id || 0) === 0) {
                                    //     setDispatchEvent({});
                                    // }
                                }}
                                onInput={(e) => {
                                    if (!showDispatchEventSecondPageItems) {
                                        setDispatchEvent({
                                            ...dispatchEvent,
                                            name: e.target.value,
                                            id: 0,
                                        });

                                        if (e.target.value.trim() === "") {
                                            setDispatchEventItems([]);
                                        } else {
                                            axios
                                                .post(props.serverUrl + "/getEventTypes", {
                                                    name: e.target.value.trim(),
                                                })
                                                .then((res) => {
                                                    if (res.data.result === "OK") {
                                                        setDispatchEventItems(
                                                            res.data.event_types.map((item, index) => {
                                                                item.selected =
                                                                    (dispatchEvent?.id || 0) === 0
                                                                        ? index === 0
                                                                        : item.id === dispatchEvent.id;
                                                                return item;
                                                            })
                                                        );

                                                        refDispatchEventPopupItems.current.map((r, i) => {
                                                            if (r && r.classList.contains("selected")) {
                                                                r.scrollIntoView({
                                                                    behavior: "auto",
                                                                    block: "center",
                                                                    inline: "nearest",
                                                                });
                                                            }
                                                            return true;
                                                        });
                                                    }
                                                })
                                                .catch((e) => {
                                                    console.log("error getting event types", e);
                                                });
                                        }
                                    }
                                }}
                                onChange={(e) => {
                                    if (!showDispatchEventSecondPageItems) {
                                        setDispatchEvent({
                                            ...dispatchEvent,
                                            name: e.target.value,
                                            id: 0,
                                        });
                                    }
                                }}
                                value={(dispatchEvent.name || "").toUpperCase()}
                            />

                            {
                                (selectedOrder?.is_cancelled || 0) === 0 &&
                                <FontAwesomeIcon
                                    className="dropdown-button"
                                    icon={faCaretDown}
                                    onClick={async () => {
                                        if (dispatchEventItems.length > 0) {
                                            setDispatchEventItems([]);
                                        } else {
                                            if ((selectedOrder?.id || 0) === 0) {
                                                window.alert("You must create or load an order first!");
                                                return;
                                            }

                                            axios.post(props.serverUrl + "/getEventTypes", {
                                                name: dispatchEvent?.name || "",
                                            }).then((res) => {
                                                if (res.data.result === "OK") {
                                                    setDispatchEventItems(
                                                        res.data.event_types.map((item, index) => {
                                                            item.selected = (dispatchEvent?.id || 0) === 0 ? index === 0 : item.id === dispatchEvent.id;
                                                            return item;
                                                        })
                                                    );

                                                    refDispatchEventPopupItems.current.map((r, i) => {
                                                        if (r && r.classList.contains("selected")) {
                                                            r.scrollIntoView({
                                                                behavior: "auto",
                                                                block: "center",
                                                                inline: "nearest",
                                                            });
                                                        }
                                                        return true;
                                                    });
                                                }
                                            }).catch((e) => {
                                                console.log("error getting event types", e);
                                            });
                                        }

                                        refDispatchEvents.current.focus();
                                    }}
                                />
                            }
                        </div>

                        {eventTransition(
                            (style, item) =>
                                item && (
                                    <animated.div
                                        className="mochi-contextual-container"
                                        id="mochi-contextual-container-dispatch-event"
                                        style={{
                                            ...style,
                                            left: "0",
                                            display: "block",
                                        }}
                                        ref={refDispatchEventDropDown}
                                    >
                                        <div
                                            className="mochi-contextual-popup vertical below right"
                                            style={{ height: 150 }}
                                        >
                                            <div className="mochi-contextual-popup-content">
                                                <div className="mochi-contextual-popup-wrapper multipage">
                                                    <div
                                                        className="mochi-contextual-popup-slider"
                                                        style={{
                                                            transform: `translateX(${showDispatchEventSecondPageItems ? "-50%" : "0"
                                                                })`,
                                                        }}
                                                    >
                                                        <div className="first-page">
                                                            <div className="page-wrapper">
                                                                {dispatchEventItems.map((item, index) => {
                                                                    const mochiItemClasses = classnames({
                                                                        "mochi-item": true,
                                                                        selected: item.selected,
                                                                        hidden: item.hidden,
                                                                    });

                                                                    const searchValue =
                                                                        (dispatchEvent?.id || 0) === 0 &&
                                                                            (dispatchEvent?.name || "") !== ""
                                                                            ? dispatchEvent?.name
                                                                            : undefined;

                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            className={mochiItemClasses}
                                                                            id={item.id}
                                                                            onMouseOver={() => {
                                                                                setDispatchEventItems(
                                                                                    dispatchEventItems.map((i, index) => {
                                                                                        i.selected = i.id === item.id;
                                                                                        return i;
                                                                                    })
                                                                                );
                                                                            }}
                                                                            onClick={() => {
                                                                                if ((item?.name || "").toLowerCase() === "arrived") {
                                                                                    if (getPickupsOnRouting().length > 0 || getDeliveriesOnRouting().length > 0) {
                                                                                        setDispatchEventItems(dispatchEventItems.map((item, index) => {
                                                                                            item.selected = (item?.name || "").toLowerCase() === "arrived";
                                                                                            return item;
                                                                                        }));

                                                                                        let arriveIndex = -1;
                                                                                        let departureIndex = -1;

                                                                                        for (let i = 0; i < (selectedOrder?.events || []).length; i++) {
                                                                                            let event = (selectedOrder?.events || [])[i];

                                                                                            if ((event.event_type?.name || "").toLowerCase() === "arrived") {
                                                                                                arriveIndex = i;
                                                                                                break;
                                                                                            }
                                                                                        }

                                                                                        for (let i = 0; i < (selectedOrder?.events || []).length; i++) {
                                                                                            let event = (selectedOrder?.events || [])[i];

                                                                                            if ((event.event_type?.name || "").toLowerCase() === "departed") {
                                                                                                departureIndex = i;
                                                                                                break;
                                                                                            }
                                                                                        }

                                                                                        if ((arriveIndex === -1 && departureIndex === -1) || (departureIndex > -1 && departureIndex < arriveIndex)) {
                                                                                            let items = [
                                                                                                ...getPickupsOnRouting().filter((pu) => {
                                                                                                    return (selectedOrder?.events.find((el) => (el.event_type?.name || "").toLowerCase() === "arrived" && el.shipper_id === (pu.customer?.id || 0)) === undefined);
                                                                                                }),
                                                                                                ...getDeliveriesOnRouting().filter((delivery) => {
                                                                                                    return (selectedOrder?.events.find((el) => (el.event_type?.name || "").toLowerCase() === "arrived" && el.consignee_id === (delivery?.id || 0)) === undefined);
                                                                                                }),
                                                                                            ];

                                                                                            if (items.length > 0) {
                                                                                                if (items.length === 1) {
                                                                                                    setDispatchEvent(item);
                                                                                                    setDispatchEventLocation(items[0].customer.city + ", " + items[0].customer.state);
                                                                                                    setDispatchEventNotes("Arrived at " + items[0].customer.code + (items[0].customer.code_number === 0 ? "" : items[0].customer.code_number) + " - " + items[0].customer.name);
                                                                                                    setSelectedOrderEvent(items[0]);

                                                                                                    window.setTimeout(() => {
                                                                                                        setDispatchEventItems([]);
                                                                                                        setDispatchEventSecondPageItems([]);
                                                                                                        setShowDispatchEventSecondPageItems(false);
                                                                                                        refEventDate.current.inputElement.focus();
                                                                                                    }, 0);
                                                                                                } else {
                                                                                                    items = items.map((x, i) => {
                                                                                                        x.selected = i === 0;
                                                                                                        return x;
                                                                                                    });
                                                                                                }
                                                                                            } else {
                                                                                                window.alert("No shippers or consignees available!");
                                                                                                refDispatchEvents.current.focus();
                                                                                                return;
                                                                                            }

                                                                                            window.setTimeout(() => {
                                                                                                setDispatchEventSecondPageItems(items);
                                                                                                setShowDispatchEventSecondPageItems(true);
                                                                                            }, 0);
                                                                                        } else {
                                                                                            window.alert("You must enter a 'departed' event for the last 'arrived' event first!");
                                                                                            refDispatchEvents.current.focus();
                                                                                            return;
                                                                                        }
                                                                                    } else {
                                                                                        window.alert("No shippers or consignees available!");
                                                                                        refDispatchEvents.current.focus();
                                                                                        return;
                                                                                    }
                                                                                } else if ((item?.name || "").toLowerCase() === "departed") {
                                                                                    setDispatchEventItems(dispatchEventItems.map((item, index) => {
                                                                                        item.selected = (item?.name || "").toLowerCase() === "arrived";
                                                                                        return item;
                                                                                    }));

                                                                                    let arriveIndex = -1;
                                                                                    let departureIndex = -1;
                                                                                    let arrived_customer = {};

                                                                                    for (let i = 0; i < (selectedOrder?.events || []).length; i++) {
                                                                                        let event = (selectedOrder?.events || [])[i];

                                                                                        if ((event.event_type?.name || "").toLowerCase() === "arrived") {
                                                                                            arrived_customer = {
                                                                                                ...event.arrived_customer,
                                                                                            };
                                                                                            arriveIndex = i;
                                                                                            break;
                                                                                        }
                                                                                    }

                                                                                    for (let i = 0; i < (selectedOrder?.events || []).length; i++) {
                                                                                        let event = (selectedOrder?.events || [])[i];

                                                                                        if ((event.event_type?.name || "").toLowerCase() === "departed") {
                                                                                            departureIndex = i;
                                                                                            break;
                                                                                        }
                                                                                    }

                                                                                    if ((arriveIndex === -1 && departureIndex === -1) || (departureIndex > -1 && departureIndex < arriveIndex)) {
                                                                                        window.alert("You must enter an 'arrived' event first!");
                                                                                        refDispatchEvents.current.focus();
                                                                                        return;
                                                                                    } else {
                                                                                        setDispatchEvent(item);
                                                                                        setDispatchEventLocation(arrived_customer.city + ", " + arrived_customer.state);
                                                                                        setDispatchEventNotes("Departed at " + arrived_customer.code + (arrived_customer.code_number === 0 ? "" : arrived_customer.code_number) + " - " + arrived_customer.name);
                                                                                        setSelectedOrderEvent(arrived_customer);

                                                                                        window.setTimeout(() => {
                                                                                            setDispatchEventItems([]);
                                                                                            setDispatchEventSecondPageItems([]);
                                                                                            setShowDispatchEventSecondPageItems(false);
                                                                                            refEventDate.current.inputElement.focus();
                                                                                        }, 0);
                                                                                    }
                                                                                } else if ((item?.name || "").toLowerCase() === "loaded") {
                                                                                    if (getPickupsOnRouting().length > 0) {
                                                                                        setDispatchEventItems(dispatchEventItems.map((item, index) => {
                                                                                            item.selected = (item?.name || "").toLowerCase() === "loaded";
                                                                                            return item;
                                                                                        }));

                                                                                        let items = [
                                                                                            ...getPickupsOnRouting().filter((pu) => {
                                                                                                return (selectedOrder?.events.find((el) => (el.event_type?.name || "").toLowerCase() === "loaded" && el.shipper_id === (pu.customer?.id || 0)) === undefined);
                                                                                            }),
                                                                                        ];

                                                                                        if (items.length > 0) {
                                                                                            if (items.length === 1) {
                                                                                                setDispatchEvent(item);
                                                                                                setDispatchEventLocation(items[0].customer.city + ", " + items[0].customer.state);
                                                                                                setDispatchEventNotes("Loaded at Shipper " + items[0].customer.code + (items[0].customer.code_number === 0 ? "" : items[0].customer.code_number) + " - " + items[0].customer.name);
                                                                                                setSelectedOrderEvent(items[0]);

                                                                                                window.setTimeout(() => {
                                                                                                    setDispatchEventItems([]);
                                                                                                    setDispatchEventSecondPageItems([]);
                                                                                                    setShowDispatchEventSecondPageItems(false);
                                                                                                    refEventDate.current.inputElement.focus();
                                                                                                }, 0);
                                                                                            } else {
                                                                                                items = items.map((x, i) => {
                                                                                                    x.selected = i === 0;
                                                                                                    return x;
                                                                                                });
                                                                                            }
                                                                                        } else {
                                                                                            window.alert("No shippers available!");
                                                                                            refDispatchEvents.current.focus();
                                                                                            return;
                                                                                        }

                                                                                        window.setTimeout(() => {
                                                                                            setDispatchEventSecondPageItems(items);
                                                                                            setShowDispatchEventSecondPageItems(true);
                                                                                        }, 0);
                                                                                    } else {
                                                                                        window.alert("No shippers available!");
                                                                                        refDispatchEvents.current.focus();
                                                                                        return;
                                                                                    }
                                                                                } else if ((item?.name || "").toLowerCase() === "delivered") {
                                                                                    if (getDeliveriesOnRouting().length > 0) {
                                                                                        setDispatchEventItems(dispatchEventItems.map((item, index) => {
                                                                                            item.selected = (item?.name || "").toLowerCase() === "delivered";
                                                                                            return item;
                                                                                        }));

                                                                                        let items = [
                                                                                            ...getDeliveriesOnRouting().filter((delivery) => {
                                                                                                return (selectedOrder?.events.find((el) => (el.event_type?.name || "").toLowerCase() === "delivered" && el.consignee_id === (delivery.customer?.id || 0)) === undefined);
                                                                                            }),
                                                                                        ];

                                                                                        if (items.length > 0) {
                                                                                            if (items.length === 1) {
                                                                                                setDispatchEvent(item);
                                                                                                setDispatchEventLocation(items[0].customer.city + ", " + items[0].customer.state);
                                                                                                setDispatchEventNotes("Delivered at Consignee " + items[0].customer.code + (items[0].customer.code_number === 0 ? "" : items[0].customer.code_number) + " - " + items[0].customer.name);
                                                                                                setSelectedOrderEvent(items[0]);

                                                                                                window.setTimeout(() => {
                                                                                                    setDispatchEventItems([]);
                                                                                                    setDispatchEventSecondPageItems([]);
                                                                                                    setShowDispatchEventSecondPageItems(false);
                                                                                                    refEventDate.current.inputElement.focus();
                                                                                                }, 0);
                                                                                            } else {
                                                                                                items = items.map((x, i) => {
                                                                                                    x.selected = i === 0;
                                                                                                    return x;
                                                                                                });
                                                                                            }
                                                                                        } else {
                                                                                            window.alert("No consignees available!");
                                                                                            refDispatchEvents.current.focus();
                                                                                            return;
                                                                                        }

                                                                                        window.setTimeout(() => {
                                                                                            setDispatchEventSecondPageItems(items);
                                                                                            setShowDispatchEventSecondPageItems(true);
                                                                                        }, 0);
                                                                                    } else {
                                                                                        window.alert("No consignees available!");
                                                                                        refDispatchEvents.current.focus();
                                                                                        return;
                                                                                    }
                                                                                } else {
                                                                                    setDispatchEvent(item);
                                                                                    setDispatchEventLocation("");
                                                                                    setDispatchEventNotes("");
                                                                                    setDispatchEventItems([]);
                                                                                    refDispatchEventLocation.current.focus();
                                                                                }
                                                                            }}
                                                                            ref={(ref) => refDispatchEventPopupItems.current.push(ref)}
                                                                        >
                                                                            {searchValue === undefined ? (
                                                                                item.name
                                                                            ) : (
                                                                                <Highlighter
                                                                                    highlightClassName="mochi-item-highlight-text"
                                                                                    searchWords={[searchValue]}
                                                                                    autoEscape={true}
                                                                                    textToHighlight={item.name}
                                                                                />
                                                                            )}
                                                                            {item.selected && (
                                                                                <FontAwesomeIcon
                                                                                    className="dropdown-selected"
                                                                                    icon={faCaretRight}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                        <div className="second-page">
                                                            <div className="page-wrapper">
                                                                {dispatchEventSecondPageItems.map(
                                                                    (item, index) => {
                                                                        const mochiItemClasses = classnames({
                                                                            "mochi-item": true,
                                                                            selected: item.selected,
                                                                        });

                                                                        return (
                                                                            <div
                                                                                key={index}
                                                                                className={mochiItemClasses}
                                                                                id={item.id}
                                                                                onClick={() => {
                                                                                    let eventItem = dispatchEventItems.find((el) => el.selected);

                                                                                    setSelectedOrderEvent(item);

                                                                                    setDispatchEvent(eventItem);
                                                                                    setDispatchEventLocation(item.customer.city + ", " + item.customer.state);

                                                                                    if ((eventItem?.name || "").toLowerCase() === "arrived") {
                                                                                        setDispatchEventNotes("Arrived at " + item.customer.code + (item.customer.code_number === 0 ? "" : item.customer.code_number) + " - " + item.customer.name);
                                                                                    }

                                                                                    if ((eventItem?.name || "").toLowerCase() === "loaded") {
                                                                                        setDispatchEventNotes("Loaded at Shipper " + item.customer.code + (item.customer.code_number === 0 ? "" : item.customer.code_number) + " - " + item.customer.name);
                                                                                    }

                                                                                    if ((eventItem?.name || "").toLowerCase() === "delivered") {
                                                                                        setDispatchEventNotes("Delivered at Consignee " + item.customer.code + (item.customer.code_number === 0 ? "" : item.customer.code_number) + " - " + item.customer.name);
                                                                                    }

                                                                                    window.setTimeout(() => {
                                                                                        setShowDispatchEventSecondPageItems(false);
                                                                                        setDispatchEventItems([]);
                                                                                        refEventDate.current.inputElement.focus();
                                                                                    }, 0);
                                                                                }}
                                                                                onMouseOver={() => {
                                                                                    setDispatchEventSecondPageItems(dispatchEventSecondPageItems.map((i, index) => {
                                                                                        i.selected = i.id === item.id;
                                                                                        return i;
                                                                                    }));
                                                                                }}
                                                                                ref={(ref) => refDispatchEventSecondPagePopupItems.current.push(ref)}
                                                                            >
                                                                                <Highlighter
                                                                                    highlightClassName="mochi-item-highlight-text"
                                                                                    searchWords={[]}
                                                                                    autoEscape={true}
                                                                                    textToHighlight={
                                                                                        item.customer.code + (item.customer.code_number === 0 ? "" : item.customer.code_number) + " - " + item.customer.city + ", " + item.customer.state}
                                                                                />
                                                                                {item.selected && (
                                                                                    <FontAwesomeIcon
                                                                                        className="dropdown-selected"
                                                                                        icon={faCaretRight}
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </animated.div>
                                )
                        )}
                    </div>
                    <div className="form-h-sep"></div>
                    <div className="input-box-container" style={{ width: "10rem" }}>
                        <input
                            tabIndex={72 + props.tabTimes}
                            ref={refDispatchEventLocation}
                            type="text"
                            placeholder="Event Location"
                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                            onInput={(e) => {
                                if (
                                    (dispatchEvent?.type || "") !== "arrived" &&
                                    (dispatchEvent?.type || "") !== "departed" &&
                                    (dispatchEvent?.type || "") !== "loaded" &&
                                    (dispatchEvent?.type || "") !== "delivered"
                                )
                                    setDispatchEventLocation(e.target.value);
                            }}
                            onChange={(e) => {
                                if (
                                    (dispatchEvent?.type || "") !== "arrived" &&
                                    (dispatchEvent?.type || "") !== "departed" &&
                                    (dispatchEvent?.type || "") !== "loaded" &&
                                    (dispatchEvent?.type || "") !== "delivered"
                                )
                                    setDispatchEventLocation(e.target.value);
                            }}
                            value={dispatchEventLocation || ""}
                        />
                    </div>
                    <div className="form-h-sep"></div>
                    <div className="input-box-container grow">
                        <input
                            tabIndex={73 + props.tabTimes}
                            type="text"
                            placeholder="Event Notes"
                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                            onKeyDown={(e) => {
                                if ((selectedOrder?.is_cancelled || 0) === 0) {
                                    let key = e.keyCode || e.which;

                                    if (key === 9) {
                                    }
                                }
                            }}
                            onInput={(e) => {
                                if (
                                    (dispatchEvent?.type || "") !== "arrived" &&
                                    (dispatchEvent?.type || "") !== "departed" &&
                                    (dispatchEvent?.type || "") !== "loaded" &&
                                    (dispatchEvent?.type || "") !== "delivered"
                                )
                                    setDispatchEventNotes(e.target.value);
                            }}
                            onChange={(e) => {
                                if (
                                    (dispatchEvent?.type || "") !== "arrived" &&
                                    (dispatchEvent?.type || "") !== "departed" &&
                                    (dispatchEvent?.type || "") !== "loaded" &&
                                    (dispatchEvent?.type || "") !== "delivered"
                                )
                                    setDispatchEventNotes(e.target.value);
                            }}
                            value={dispatchEventNotes || ""}
                        />
                    </div>
                    <div className="form-h-sep"></div>
                    <div className="select-box-container" style={{ width: "8rem" }}>
                        <div className="select-box-wrapper">
                            <MaskedInput
                                tabIndex={74 + props.tabTimes}
                                mask={[/[0-9]/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/,]}
                                guide={false}
                                type="text"
                                placeholder="Event Date"
                                readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                                onKeyDown={async (e) => {
                                    if ((selectedOrder?.is_cancelled || 0) === 0) {
                                        let key = e.keyCode || e.which;

                                        if (key >= 37 && key <= 40) {
                                            let event_date =
                                                e.target.value.trim() === ""
                                                    ? moment()
                                                    : moment(
                                                        getFormattedDates(dispatchEventDate || ""),
                                                        "MM/DD/YYYY"
                                                    );
                                            await setPreSelectedDispatchEventDate(event_date);

                                            if (isDispatchEventDateCalendarShown) {
                                                e.preventDefault();

                                                if (key === 37) {
                                                    // left - minus 1
                                                    setPreSelectedDispatchEventDate(
                                                        preSelectedDispatchEventDate
                                                            .clone()
                                                            .subtract(1, "day")
                                                    );
                                                }

                                                if (key === 38) {
                                                    // up - minus 7
                                                    setPreSelectedDispatchEventDate(
                                                        preSelectedDispatchEventDate
                                                            .clone()
                                                            .subtract(7, "day")
                                                    );
                                                }

                                                if (key === 39) {
                                                    // right - plus 1
                                                    setPreSelectedDispatchEventDate(
                                                        preSelectedDispatchEventDate.clone().add(1, "day")
                                                    );
                                                }

                                                if (key === 40) {
                                                    // down - plus 7
                                                    setPreSelectedDispatchEventDate(
                                                        preSelectedDispatchEventDate.clone().add(7, "day")
                                                    );
                                                }
                                            } else {
                                                await setIsDispatchEventDateCalendarShown(true);
                                            }
                                        }

                                        if (key === 13) {
                                            let event_date =
                                                e.target.value.trim() === ""
                                                    ? moment()
                                                    : moment(
                                                        getFormattedDates(dispatchEventDate || ""),
                                                        "MM/DD/YYYY"
                                                    );
                                            await setPreSelectedDispatchEventDate(event_date);

                                            if (isDispatchEventDateCalendarShown) {
                                                event_date = preSelectedDispatchEventDate
                                                    .clone()
                                                    .format("MM/DD/YYYY");

                                                await setDispatchEventDate(event_date);

                                                await setIsDispatchEventDateCalendarShown(false);
                                            }
                                        }

                                        if (key === 9) {
                                            let event_date =
                                                e.target.value.trim() === ""
                                                    ? moment()
                                                    : moment(
                                                        getFormattedDates(dispatchEventDate || ""),
                                                        "MM/DD/YYYY"
                                                    );
                                            await setPreSelectedDispatchEventDate(event_date);

                                            if (isDispatchEventDateCalendarShown) {
                                                event_date = preSelectedDispatchEventDate
                                                    .clone()
                                                    .format("MM/DD/YYYY");

                                                await setDispatchEventDate(event_date);

                                                await setIsDispatchEventDateCalendarShown(false);
                                            }
                                        }
                                    }
                                }}
                                onBlur={(e) => {
                                    if ((selectedOrder?.is_cancelled || 0) === 0) {
                                        setDispatchEventDate(getFormattedDates(dispatchEventDate));
                                    }
                                }}
                                onInput={(e) => {
                                    setDispatchEventDate(e.target.value);
                                }}
                                onChange={(e) => {
                                    setDispatchEventDate(e.target.value);
                                }}
                                value={dispatchEventDate || ""}
                                ref={refEventDate}
                            />

                            {
                                (selectedOrder?.is_cancelled || 0) === 0 &&
                                <FontAwesomeIcon
                                    className="dropdown-button calendar"
                                    icon={faCalendarAlt}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsDispatchEventDateCalendarShown(true);

                                        if (
                                            moment(
                                                (dispatchEventDate || "").trim(),
                                                "MM/DD/YYYY"
                                            ).format("MM/DD/YYYY") === (dispatchEventDate || "").trim()
                                        ) {
                                            setPreSelectedDispatchEventDate(
                                                moment(dispatchEventDate, "MM/DD/YYYY")
                                            );
                                        } else {
                                            setPreSelectedDispatchEventDate(moment());
                                        }

                                        refEventDate.current.inputElement.focus();
                                    }}
                                />
                            }
                        </div>
                        {dateEventTransition(
                            (style, item) =>
                                item && (
                                    <animated.div
                                        className="mochi-contextual-container"
                                        id="mochi-contextual-container-dispatch-event-date"
                                        style={{
                                            ...style,
                                            left: "-100px",
                                            display: "block",
                                        }}
                                        ref={refDispatchEventDateCalendarDropDown}
                                    >
                                        <div
                                            className="mochi-contextual-popup vertical below"
                                            style={{ height: 275 }}
                                        >
                                            <div className="mochi-contextual-popup-content">
                                                <div className="mochi-contextual-popup-wrapper">
                                                    <Calendar
                                                        value={
                                                            moment(
                                                                (dispatchEventDate || "").trim(),
                                                                "MM/DD/YYYY"
                                                            ).format("MM/DD/YYYY") ===
                                                                (dispatchEventDate || "").trim()
                                                                ? moment(dispatchEventDate, "MM/DD/YYYY")
                                                                : moment()
                                                        }
                                                        onChange={(day) => {
                                                            setDispatchEventDate(day.format("MM/DD/YYYY"));
                                                        }}
                                                        closeCalendar={() => {
                                                            setIsDispatchEventDateCalendarShown(false);
                                                        }}
                                                        preDay={preSelectedDispatchEventDate}
                                                        onChangePreDay={(preDay) => {
                                                            setPreSelectedDispatchEventDate(preDay);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </animated.div>
                                )
                        )}
                    </div>
                    <div className="form-h-sep"></div>
                    <div className="input-box-container" style={{ width: "6rem" }}>
                        <input
                            tabIndex={75 + props.tabTimes}
                            type="text"
                            placeholder="Event Time"
                            ref={refEventTime}
                            readOnly={(selectedOrder?.is_cancelled || 0) === 1}
                            onKeyDown={async (e) => {
                                if ((selectedOrder?.is_cancelled || 0) === 0) {
                                    let key = e.keyCode || e.which;

                                    e.stopPropagation();

                                    if (key === 9) {
                                        e.preventDefault();

                                        let formatted = getFormattedHours(e.target.value);

                                        await setDispatchEventTime(formatted);

                                        if ((dispatchEvent?.name || "") === "") {
                                            if (props.isOnPanel) {
                                                if (refOrderNumber?.current) {
                                                    refOrderNumber.current.focus({
                                                        preventScroll: true,
                                                    });
                                                }
                                            } else {
                                                if (props.refOrderNumber?.current) {
                                                    props.refOrderNumber.current.focus({
                                                        preventScroll: true,
                                                    });
                                                }
                                            }
                                        } else {
                                            if ((selectedOrder?.id || 0) === 0) {
                                                if (props.isOnPanel) {
                                                    if (refOrderNumber?.current) {
                                                        refOrderNumber.current.focus({
                                                            preventScroll: true,
                                                        });
                                                    }
                                                } else {
                                                    if (props.refOrderNumber?.current) {
                                                        props.refOrderNumber.current.focus({
                                                            preventScroll: true,
                                                        });
                                                    }
                                                }
                                                return;
                                            }

                                            let event_parameters = {
                                                order_id: selectedOrder.id,
                                                time: moment().format("HHmm"),
                                                event_time: formatted,
                                                date: moment().format("MM/DD/YYYY"),
                                                event_date: dispatchEventDate,
                                                user_code_id: props.user.user_code.id || null,
                                                event_location: dispatchEventLocation,
                                                event_notes: dispatchEventNotes,
                                                event_type_id: dispatchEvent.id,
                                            };

                                            if (dispatchEvent.id === 1) {
                                                event_parameters.arrived_customer_id = selectedOrderEvent.customer.id;
                                                event_parameters.shipper_id = (selectedOrderEvent.customer.type || "") === "pickup"
                                                    ? selectedOrderEvent.customer.id
                                                    : null;
                                                event_parameters.consignee_id = (selectedOrderEvent.customer.type || "") === "delivery"
                                                    ? selectedOrderEvent.customer.id
                                                    : null;
                                            } else if (dispatchEvent.id === 7) {
                                                event_parameters.departed_customer_id = selectedOrderEvent.id;
                                            } else if (dispatchEvent.id === 9) {
                                                event_parameters.shipper_id = selectedOrderEvent.customer.id;
                                            } else if (dispatchEvent.id === 6) {
                                                event_parameters.consignee_id = selectedOrderEvent.customer.id;
                                            } else {
                                                if (event_parameters.event_notes.trim() === "") {
                                                    window.alert("You must include some notes!");
                                                    refEventTime.current.focus();
                                                    return;
                                                }

                                                if (event_parameters.event_date.trim() === "") {
                                                    window.alert("You must include the event date!");
                                                    refEventTime.current.focus();
                                                    return;
                                                }

                                                if (event_parameters.event_time.trim() === "") {
                                                    window.alert("You must include the event time!");
                                                    refEventTime.current.focus();
                                                    return;
                                                }
                                            }

                                            if (window.confirm("Save this event?")) {
                                                e.preventDefault();
                                                axios.post(props.serverUrl + "/saveOrderEvent", event_parameters).then(async (res) => {
                                                    if (res.data.result === "OK") {
                                                        await setSelectedOrder({
                                                            ...selectedOrder,
                                                            events: res.data.order_events,
                                                        });

                                                        setDispatchEvent({});
                                                        setDispatchEventLocation("");
                                                        setDispatchEventNotes("");
                                                        setDispatchEventDate("");
                                                        setDispatchEventTime("");

                                                        setDispatchEventItems([]);
                                                        setShowDispatchEventSecondPageItems(false);
                                                        setDispatchEventSecondPageItems([]);

                                                        refDispatchEvents.current.focus();

                                                        let user_first_name = (selectedOrder?.user_code?.type || '') === 'agent'
                                                            ? (((selectedOrder.user_code?.agent?.contacts || []).find(x => x.id === (selectedOrder.user_code?.agent_contact_id || 0))?.first_name || '')).trim()
                                                            : (selectedOrder?.user_code?.type || '') === 'employee'
                                                                ? ((selectedOrder.user_code?.employee?.first_name || '')).trim()
                                                                : '';

                                                        let user_last_name = (selectedOrder?.user_code?.type || '') === 'agent'
                                                            ? (((selectedOrder.user_code?.agent?.contacts || []).find(x => x.id === (selectedOrder.user_code?.agent_contact_id || 0))?.last_name || '')).trim()
                                                            : (selectedOrder?.user_code?.type || '') === 'employee'
                                                                ? ((selectedOrder.user_code?.employee?.last_name || '')).trim()
                                                                : '';

                                                        let recipient_to = [];
                                                        let recipient_cc = [];
                                                        let recipient_bcc = [];
                                                        let emailUrl = '';
                                                        let customer_id = null;
                                                        let event_location = null;

                                                        if ((event_parameters?.event_type_id || 0) === 1) { // Arrived
                                                            if ((selectedOrderEvent?.type || '') === 'pickup') {
                                                                emailUrl = '/sendCarrierArrivedShipperEmail';
                                                                customer_id = selectedOrderEvent.customer.id;

                                                                (selectedOrder?.bill_to_company?.automatic_emails || []).map(item => {

                                                                    if ((item?.carrier_arrival_shipper || 0) === 1) {
                                                                        if ((item?.type || '') === 'to') {
                                                                            recipient_to.push(item.email);
                                                                        }

                                                                        if ((item?.type || '') === 'cc') {
                                                                            recipient_cc.push(item.email);
                                                                        }

                                                                        if ((item?.type || '') === 'bcc') {
                                                                            recipient_bcc.push(item.email);
                                                                        }
                                                                    }

                                                                    return true;
                                                                });

                                                                getLoadBoardOrders()
                                                            } else if ((selectedOrderEvent?.type || '') === 'delivery') {
                                                                emailUrl = '/sendCarrierArrivedConsigneeEmail';
                                                                customer_id = selectedOrderEvent.customer.id;

                                                                (selectedOrder?.bill_to_company?.automatic_emails || []).map(item => {

                                                                    if ((item?.carrier_arrival_consignee || 0) === 1) {
                                                                        if ((item?.type || '') === 'to') {
                                                                            recipient_to.push(item.email);
                                                                        }

                                                                        if ((item?.type || '') === 'cc') {
                                                                            recipient_cc.push(item.email);
                                                                        }

                                                                        if ((item?.type || '') === 'bcc') {
                                                                            recipient_bcc.push(item.email);
                                                                        }
                                                                    }

                                                                    return true;
                                                                });

                                                                getLoadBoardOrders()
                                                            }
                                                        }

                                                        if ((event_parameters?.event_type_id || 0) === 9) { // Loaded at shipper
                                                            emailUrl = '/sendCarrierLoadedShipperEmail';
                                                            customer_id = event_parameters.shipper_id;

                                                            (selectedOrder?.bill_to_company?.automatic_emails || []).map(item => {

                                                                if ((item?.loaded || 0) === 1) {
                                                                    if ((item?.type || '') === 'to') {
                                                                        recipient_to.push(item.email);
                                                                    }

                                                                    if ((item?.type || '') === 'cc') {
                                                                        recipient_cc.push(item.email);
                                                                    }

                                                                    if ((item?.type || '') === 'bcc') {
                                                                        recipient_bcc.push(item.email);
                                                                    }
                                                                }

                                                                return true;
                                                            });

                                                            getLoadBoardOrders()
                                                        }

                                                        if ((event_parameters?.event_type_id || 0) === 6) { // Delivered at consignee
                                                            if ((res.data.order_events || []).filter(x => x.event_type_id === 6).length === (selectedOrder?.routing || []).filter(x => x.type === 'delivery').length) {
                                                                emailUrl = '/sendCarrierUnloadedConsigneeEmail';
                                                                customer_id = event_parameters.consignee_id;


                                                                (selectedOrder?.bill_to_company?.automatic_emails || []).map(item => {

                                                                    if ((item?.empty || 0) === 1) {
                                                                        if ((item?.type || '') === 'to') {
                                                                            recipient_to.push(item.email);
                                                                        }

                                                                        if ((item?.type || '') === 'cc') {
                                                                            recipient_cc.push(item.email);
                                                                        }

                                                                        if ((item?.type || '') === 'bcc') {
                                                                            recipient_bcc.push(item.email);
                                                                        }
                                                                    }

                                                                    return true;
                                                                });
                                                            }

                                                            getLoadBoardOrders()
                                                        }

                                                        if ((event_parameters?.event_type_id || 0) === 4) { // Check Calls
                                                            emailUrl = '/sendCarrierCheckCallsEmail';

                                                            let lastDelivery = (selectedOrder?.routing || []).findLast(x => x.type === 'delivery');

                                                            if (lastDelivery) {
                                                                customer_id = (selectedOrder?.deliveries || []).find(x => x.id === lastDelivery.delivery_id)?.customer_id;
                                                                event_location = event_parameters?.event_location;

                                                                (selectedOrder?.bill_to_company?.automatic_emails || []).map(item => {

                                                                    if ((item?.check_calls || 0) === 1) {
                                                                        if ((item?.type || '') === 'to') {
                                                                            recipient_to.push(item.email);
                                                                        }

                                                                        if ((item?.type || '') === 'cc') {
                                                                            recipient_cc.push(item.email);
                                                                        }

                                                                        if ((item?.type || '') === 'bcc') {
                                                                            recipient_bcc.push(item.email);
                                                                        }
                                                                    }

                                                                    return true;
                                                                });
                                                            }

                                                            getLoadBoardOrders()
                                                        }

                                                        if (recipient_to.length > 0) {
                                                            axios.post(props.serverUrl + emailUrl, {
                                                                order_number: selectedOrder?.order_number,
                                                                customer_id,
                                                                event_location,
                                                                user_first_name,
                                                                user_last_name,
                                                                recipient_to,
                                                                recipient_cc,
                                                                recipient_bcc
                                                            }).then(res => {
                                                                console.log(res.data.result);
                                                            }).catch(e => {
                                                                console.log(e);
                                                            })
                                                        }

                                                    } else if (res.data.result === "ORDER ID NOT VALID") {
                                                        window.alert("The order number is not valid!");
                                                        refEventTime.current.focus();
                                                    }
                                                }).catch((e) => {
                                                    console.log("error saving order event", e);
                                                });
                                            } else {
                                                e.preventDefault();

                                                setDispatchEventItems([]);
                                                setShowDispatchEventSecondPageItems(false);
                                                setDispatchEventSecondPageItems([]);

                                                refEventTime.current.focus();
                                            }
                                        }
                                    }
                                }
                            }}
                            onInput={(e) => {
                                setDispatchEventTime(e.target.value);
                            }}
                            onChange={(e) => {
                                setDispatchEventTime(e.target.value);
                            }}
                            value={dispatchEventTime || ""}
                        />
                    </div>
                    <div className="form-h-sep"></div>
                    <div
                        style={{
                            borderRight: "solid 1px rgba(0,0,0,0.5)",
                            width: 1,
                            height: "100%",
                            marginLeft: 8,
                        }}
                    ></div>
                </div>

                <div
                    style={{
                        flexGrow: 1,
                        display: "grid",
                        gridTemplateColumns:
                            ".8fr 0.1fr 1.3fr 0.1fr 1.3fr 0.1fr 1.3fr 0.1fr .9fr",
                    }}
                >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                            style={{
                                fontSize: "0.7rem",
                                textDecoration: "underline",
                                fontWeight: "bold",
                                whiteSpace: "nowrap",
                                textAlign: "center",
                                marginBottom: 1,
                            }}
                        >
                            Miles
                        </div>
                        <div
                            style={{
                                fontSize: "0.7rem",
                                position: "relative",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexGrow: 1,
                                pointerEvents: ((selectedOrder?.miles || 0) / 1609.34) > 0 ? 'all' : 'none',
                                cursor: 'pointer'
                            }}

                            onClick={() => {
                                let panel = {
                                    panelName: `${props.panelName}-routing-map`,
                                    component: <RoutingMap
                                        title='Routing Map'
                                        tabTimes={484000 + props.tabTimes}
                                        panelName={`${props.panelName}-routing-map`}
                                        componentId={moment().format('x')}
                                        origin={props.origin}
                                        closingCallback={() => {
                                            closePanel(`${props.panelName}-routing-map`, props.origin);
                                            (props.isOnPanel ? refOrderNumber : props.refOrderNumber).current.focus({ preventScroll: true });
                                        }}

                                        selectedOrder={selectedOrder}
                                    />
                                }

                                openPanel(panel, props.origin);
                            }}
                        >
                            {mileageLoaderVisible ? (
                                <div className="loading-container" style={{ right: "initial", left: "50%" }}>
                                    <Loader
                                        type="ThreeDots"
                                        color="#333738"
                                        height={20}
                                        width={20}
                                        visible={mileageLoaderVisible}
                                    />
                                </div>
                            ) : (
                                ((selectedOrder?.miles || 0) / 1609.34).toFixed(0)
                            )}
                        </div>
                    </div>

                    <div
                        style={{
                            fontSize: "0.8rem",
                            display: "flex",
                            alignItems: "center",
                            color: "rgba(0,0,0,0.5)",
                        }}
                    >
                        |
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                            style={{
                                fontSize: "0.7rem",
                                textDecoration: "underline",
                                fontWeight: "bold",
                                textAlign: "center",
                                whiteSpace: "nowrap",
                                marginBottom: 1,
                            }}
                        >
                            Customer Charges
                        </div>
                        <div
                            style={{
                                fontSize: "0.7rem",
                                position: "relative",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexGrow: 1,
                            }}
                        >
                            <NumberFormat
                                className={classnames({
                                    "negative-number":
                                        Number(
                                            (
                                                (selectedOrder?.order_customer_ratings || []).reduce(
                                                    (a, b) => {
                                                        return {
                                                            total_charges:
                                                                Number(a.total_charges) +
                                                                Number(b.total_charges),
                                                        };
                                                    },
                                                    { total_charges: "" }
                                                )?.total_charges || ""
                                            )
                                                .toString()
                                                .replace(",", "")
                                        ) < 0,
                                })}
                                style={{ fontSize: "0.7rem", textAlign: "center" }}
                                value={new Intl.NumberFormat("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }).format(
                                    Number(
                                        (
                                            (selectedOrder?.order_customer_ratings || []).reduce(
                                                (a, b) => {
                                                    return {
                                                        total_charges:
                                                            Number(a.total_charges) + Number(b.total_charges),
                                                    };
                                                },
                                                { total_charges: "" }
                                            )?.total_charges || ""
                                        )
                                            .toString()
                                            .replace(",", "")
                                    )
                                )}
                                thousandsGroupStyle="thousand"
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix={"$ "}
                                type="text"
                                onValueChange={(values) => {
                                }}
                                displayType={"text"}
                                readOnly={true}
                            />
                        </div>
                    </div>

                    <div
                        style={{
                            fontSize: "0.8rem",
                            display: "flex",
                            alignItems: "center",
                            color: "rgba(0,0,0,0.5)",
                        }}
                    >
                        |
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                            style={{
                                fontSize: "0.7rem",
                                textDecoration: "underline",
                                fontWeight: "bold",
                                textAlign: "center",
                                whiteSpace: "nowrap",
                                marginBottom: 1,
                            }}
                        >
                            Carrier Costs
                        </div>
                        <div
                            style={{
                                fontSize: "0.7rem",
                                position: "relative",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexGrow: 1,
                            }}
                        >
                            <NumberFormat
                                className={classnames({
                                    "negative-number":
                                        Number(
                                            (
                                                (selectedOrder?.order_carrier_ratings || []).reduce(
                                                    (a, b) => {
                                                        return {
                                                            total_charges:
                                                                Number(a.total_charges) +
                                                                Number(b.total_charges),
                                                        };
                                                    },
                                                    { total_charges: "" }
                                                )?.total_charges || ""
                                            )
                                                .toString()
                                                .replace(",", "")
                                        ) < 0,
                                })}
                                style={{ fontSize: "0.7rem", textAlign: "center" }}
                                value={new Intl.NumberFormat("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }).format(
                                    Number(
                                        (
                                            (selectedOrder?.order_carrier_ratings || []).reduce(
                                                (a, b) => {
                                                    return {
                                                        total_charges:
                                                            Number(a.total_charges) + Number(b.total_charges),
                                                    };
                                                },
                                                { total_charges: "" }
                                            )?.total_charges || ""
                                        )
                                            .toString()
                                            .replace(",", "")
                                    )
                                )}
                                thousandsGroupStyle="thousand"
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix={"$ "}
                                type="text"
                                onValueChange={(values) => {
                                }}
                                displayType={"text"}
                                readOnly={true}
                            />
                        </div>
                    </div>

                    <div
                        style={{
                            fontSize: "0.8rem",
                            display: "flex",
                            alignItems: "center",
                            color: "rgba(0,0,0,0.5)",
                        }}
                    >
                        |
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", position: 'relative' }}>
                        <div>
                            <div
                                style={{
                                    fontSize: "0.7rem",
                                    textDecoration: "underline",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    whiteSpace: "nowrap",
                                    marginBottom: 1,
                                    pointerEvents: (selectedOrder?.agent_code || '') === '' ? 'none' : 'all',
                                    cursor: (selectedOrder?.agent_code || '') === '' ? 'default' : 'pointer'
                                }}
                                ref={refProfits}
                                tabIndex={76 + props.tabTimes}
                                onKeyDown={(e) => {
                                    const key = e.keyCode || e.which;

                                    if (key === 27) { // escape
                                        e.stopPropagation()
                                        setShowProfitItems(false);
                                        refDispatchEvents.current.focus();
                                    }
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if ((selectedOrder?.agent_code || '') !== '') {
                                        window.setTimeout(() => {
                                            setShowProfitItems(true);
                                            refProfitDropDown.current.focus();
                                        }, 10)
                                    }
                                }}
                            >
                                Profit
                            </div>
                            {profitTransition(
                                (style, item) =>
                                    item && (
                                        <animated.div
                                            className="mochi-contextual-container"
                                            id="mochi-contextual-container-profits"
                                            style={{
                                                ...style,
                                                left: "-195px",
                                                display: "block",
                                            }}
                                            ref={refProfitDropDown}
                                        >
                                            <div
                                                className="mochi-contextual-popup vertical below left"
                                                style={{ height: 150 }}
                                            >
                                                <div className="mochi-contextual-popup-content">
                                                    <div className="mochi-contextual-popup-wrapper" style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        gap: 15
                                                    }}>
                                                        <div className='mochi-item profit' style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between'
                                                        }}>
                                                            <div className="mochi-item-title" style={{ fontWeight: 'bold' }}>Agent Commission</div>
                                                            <div className="mochi-item-value">
                                                                {`$ ${selectedOrder?.bill_to_company?.agent
                                                                    ? new Intl.NumberFormat("en-US", {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                    }).format(
                                                                        (Number((
                                                                            (selectedOrder?.order_customer_ratings || []).reduce((a, b) => {
                                                                                return {
                                                                                    total_charges:
                                                                                        Number((a.total_charges || "").toString().replace(",", "")) +
                                                                                        Number((b.total_charges || "").toString().replace(",", "")),
                                                                                };
                                                                            }, { total_charges: "" })?.total_charges || "").toString().replace(",", "")) -
                                                                            Number(((selectedOrder?.order_carrier_ratings || []).reduce((a, b) => {
                                                                                return {
                                                                                    total_charges:
                                                                                        Number((a.total_charges || "").toString().replace(",", "")) +
                                                                                        Number((b.total_charges || "").toString().replace(",", "")),
                                                                                };
                                                                            }, { total_charges: "" })?.total_charges || "").toString().replace(",", ""))) *
                                                                        ((selectedOrder?.agent_commission || 0) > 0 ? selectedOrder.agent_commission / 100 : 0)
                                                                    )
                                                                    : ''}`}
                                                            </div>
                                                        </div>

                                                        <div className='mochi-item profit' style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between'
                                                        }}>
                                                            <div className="mochi-item-title" style={{ fontWeight: 'bold' }}>Company Profit</div>
                                                            <div className="mochi-item-value">
                                                                {
                                                                    `$ ${new Intl.NumberFormat("en-US", {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                    }).format((Number(
                                                                        (
                                                                            (selectedOrder?.order_customer_ratings || []).reduce(
                                                                                (a, b) => {
                                                                                    return {
                                                                                        total_charges:
                                                                                            Number(
                                                                                                (a.total_charges || "")
                                                                                                    .toString()
                                                                                                    .replace(",", "")
                                                                                            ) +
                                                                                            Number(
                                                                                                (b.total_charges || "")
                                                                                                    .toString()
                                                                                                    .replace(",", "")
                                                                                            ),
                                                                                    };
                                                                                },
                                                                                { total_charges: "" }
                                                                            )?.total_charges || ""
                                                                        )
                                                                            .toString()
                                                                            .replace(",", "")
                                                                    ) -
                                                                        Number(
                                                                            (
                                                                                (selectedOrder?.order_carrier_ratings || []).reduce(
                                                                                    (a, b) => {
                                                                                        return {
                                                                                            total_charges:
                                                                                                Number(
                                                                                                    (a.total_charges || "")
                                                                                                        .toString()
                                                                                                        .replace(",", "")
                                                                                                ) +
                                                                                                Number(
                                                                                                    (b.total_charges || "")
                                                                                                        .toString()
                                                                                                        .replace(",", "")
                                                                                                ),
                                                                                        };
                                                                                    },
                                                                                    { total_charges: "" }
                                                                                )?.total_charges || ""
                                                                            )
                                                                                .toString()
                                                                                .replace(",", "")
                                                                        )) - ((Number((
                                                                            (selectedOrder?.order_customer_ratings || []).reduce((a, b) => {
                                                                                return {
                                                                                    total_charges:
                                                                                        Number((a.total_charges || "").toString().replace(",", "")) +
                                                                                        Number((b.total_charges || "").toString().replace(",", "")),
                                                                                };
                                                                            }, { total_charges: "" })?.total_charges || "").toString().replace(",", "")) -
                                                                            Number(((selectedOrder?.order_carrier_ratings || []).reduce((a, b) => {
                                                                                return {
                                                                                    total_charges:
                                                                                        Number((a.total_charges || "").toString().replace(",", "")) +
                                                                                        Number((b.total_charges || "").toString().replace(",", "")),
                                                                                };
                                                                            }, { total_charges: "" })?.total_charges || "").toString().replace(",", ""))) *
                                                                            ((selectedOrder?.agent_commission || 0) > 0 ? selectedOrder.agent_commission / 100 : 0)))}`
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className='mochi-item profit' style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between'
                                                        }}>
                                                            <div className="mochi-item-title" style={{ fontWeight: 'bold' }}>Total Profit</div>
                                                            <div className="mochi-item-value">
                                                                {
                                                                    `$ ${new Intl.NumberFormat("en-US", {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                    }).format(Number(
                                                                        (
                                                                            (selectedOrder?.order_customer_ratings || []).reduce(
                                                                                (a, b) => {
                                                                                    return {
                                                                                        total_charges:
                                                                                            Number(
                                                                                                (a.total_charges || "")
                                                                                                    .toString()
                                                                                                    .replace(",", "")
                                                                                            ) +
                                                                                            Number(
                                                                                                (b.total_charges || "")
                                                                                                    .toString()
                                                                                                    .replace(",", "")
                                                                                            ),
                                                                                    };
                                                                                },
                                                                                { total_charges: "" }
                                                                            )?.total_charges || ""
                                                                        )
                                                                            .toString()
                                                                            .replace(",", "")
                                                                    ) -
                                                                        Number(
                                                                            (
                                                                                (selectedOrder?.order_carrier_ratings || []).reduce(
                                                                                    (a, b) => {
                                                                                        return {
                                                                                            total_charges:
                                                                                                Number(
                                                                                                    (a.total_charges || "")
                                                                                                        .toString()
                                                                                                        .replace(",", "")
                                                                                                ) +
                                                                                                Number(
                                                                                                    (b.total_charges || "")
                                                                                                        .toString()
                                                                                                        .replace(",", "")
                                                                                                ),
                                                                                        };
                                                                                    },
                                                                                    { total_charges: "" }
                                                                                )?.total_charges || ""
                                                                            )
                                                                                .toString()
                                                                                .replace(",", "")
                                                                        ))}`
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </animated.div>
                                    )
                            )}
                        </div>
                        <div
                            style={{
                                fontSize: "0.7rem",
                                position: "relative",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexGrow: 1,
                            }}
                        >
                            <NumberFormat
                                className={classnames({
                                    "negative-number":
                                        Number(
                                            (
                                                (selectedOrder?.order_customer_ratings || []).reduce(
                                                    (a, b) => {
                                                        return {
                                                            total_charges:
                                                                Number(
                                                                    (a.total_charges || "")
                                                                        .toString()
                                                                        .replace(",", "")
                                                                ) +
                                                                Number(
                                                                    (b.total_charges || "")
                                                                        .toString()
                                                                        .replace(",", "")
                                                                ),
                                                        };
                                                    },
                                                    { total_charges: "" }
                                                )?.total_charges || ""
                                            )
                                                .toString()
                                                .replace(",", "")
                                        ) -
                                        Number(
                                            (
                                                (selectedOrder?.order_carrier_ratings || []).reduce(
                                                    (a, b) => {
                                                        return {
                                                            total_charges:
                                                                Number(
                                                                    (a.total_charges || "")
                                                                        .toString()
                                                                        .replace(",", "")
                                                                ) +
                                                                Number(
                                                                    (b.total_charges || "")
                                                                        .toString()
                                                                        .replace(",", "")
                                                                ),
                                                        };
                                                    },
                                                    { total_charges: "" }
                                                )?.total_charges || ""
                                            )
                                                .toString()
                                                .replace(",", "")
                                        ) <
                                        0,
                                })}
                                style={{ fontSize: "0.7rem", textAlign: "center" }}
                                value={new Intl.NumberFormat("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }).format(
                                    Number(
                                        (
                                            (selectedOrder?.order_customer_ratings || []).reduce(
                                                (a, b) => {
                                                    return {
                                                        total_charges:
                                                            Number(
                                                                (a.total_charges || "")
                                                                    .toString()
                                                                    .replace(",", "")
                                                            ) +
                                                            Number(
                                                                (b.total_charges || "")
                                                                    .toString()
                                                                    .replace(",", "")
                                                            ),
                                                    };
                                                },
                                                { total_charges: "" }
                                            )?.total_charges || ""
                                        )
                                            .toString()
                                            .replace(",", "")
                                    ) -
                                    Number(
                                        (
                                            (selectedOrder?.order_carrier_ratings || []).reduce(
                                                (a, b) => {
                                                    return {
                                                        total_charges:
                                                            Number(
                                                                (a.total_charges || "")
                                                                    .toString()
                                                                    .replace(",", "")
                                                            ) +
                                                            Number(
                                                                (b.total_charges || "")
                                                                    .toString()
                                                                    .replace(",", "")
                                                            ),
                                                    };
                                                },
                                                { total_charges: "" }
                                            )?.total_charges || ""
                                        )
                                            .toString()
                                            .replace(",", "")
                                    )
                                )}
                                thousandsGroupStyle="thousand"
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix={"$ "}
                                type="text"
                                onValueChange={(values) => {
                                }}
                                displayType={"text"}
                                readOnly={true}
                            />
                        </div>
                    </div>

                    <div
                        style={{
                            fontSize: "0.8rem",
                            display: "flex",
                            alignItems: "center",
                            color: "rgba(0,0,0,0.5)",
                        }}
                    >
                        |
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                            style={{
                                fontSize: "0.7rem",
                                textDecoration: "underline",
                                fontWeight: "bold",
                                textAlign: "center",
                                whiteSpace: "nowrap",
                                marginBottom: 1,
                            }}
                        >
                            Percentage
                        </div>
                        <div
                            style={{
                                fontSize: "0.7rem",
                                position: "relative",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexGrow: 1,
                            }}
                        >
                            <NumberFormat
                                className={classnames({
                                    "negative-number":
                                        (Number(
                                            (
                                                (selectedOrder?.order_customer_ratings || []).reduce(
                                                    (a, b) => {
                                                        return {
                                                            total_charges:
                                                                Number(
                                                                    (a.total_charges || "")
                                                                        .toString()
                                                                        .replace(",", "")
                                                                ) +
                                                                Number(
                                                                    (b.total_charges || "")
                                                                        .toString()
                                                                        .replace(",", "")
                                                                ),
                                                        };
                                                    },
                                                    { total_charges: "" }
                                                )?.total_charges || ""
                                            )
                                                .toString()
                                                .replace(",", "")
                                        ) > 0 ||
                                            Number(
                                                (
                                                    (selectedOrder?.order_carrier_ratings || []).reduce(
                                                        (a, b) => {
                                                            return {
                                                                total_charges:
                                                                    Number(
                                                                        (a.total_charges || "")
                                                                            .toString()
                                                                            .replace(",", "")
                                                                    ) +
                                                                    Number(
                                                                        (b.total_charges || "")
                                                                            .toString()
                                                                            .replace(",", "")
                                                                    ),
                                                            };
                                                        },
                                                        { total_charges: "" }
                                                    )?.total_charges || ""
                                                )
                                                    .toString()
                                                    .replace(",", "")
                                            ) > 0
                                            ? ((Number(
                                                (
                                                    (
                                                        selectedOrder?.order_customer_ratings || []
                                                    ).reduce(
                                                        (a, b) => {
                                                            return {
                                                                total_charges:
                                                                    Number(
                                                                        (a.total_charges || "")
                                                                            .toString()
                                                                            .replace(",", "")
                                                                    ) +
                                                                    Number(
                                                                        (b.total_charges || "")
                                                                            .toString()
                                                                            .replace(",", "")
                                                                    ),
                                                            };
                                                        },
                                                        { total_charges: "" }
                                                    )?.total_charges || ""
                                                )
                                                    .toString()
                                                    .replace(",", "")
                                            ) -
                                                Number(
                                                    (
                                                        (
                                                            selectedOrder?.order_carrier_ratings || []
                                                        ).reduce(
                                                            (a, b) => {
                                                                return {
                                                                    total_charges:
                                                                        Number(
                                                                            (a.total_charges || "")
                                                                                .toString()
                                                                                .replace(",", "")
                                                                        ) +
                                                                        Number(
                                                                            (b.total_charges || "")
                                                                                .toString()
                                                                                .replace(",", "")
                                                                        ),
                                                                };
                                                            },
                                                            { total_charges: "" }
                                                        )?.total_charges || ""
                                                    )
                                                        .toString()
                                                        .replace(",", "")
                                                )) *
                                                100) /
                                            (Number(
                                                (
                                                    (
                                                        selectedOrder?.order_customer_ratings || []
                                                    ).reduce(
                                                        (a, b) => {
                                                            return {
                                                                total_charges:
                                                                    Number(
                                                                        (a.total_charges || "")
                                                                            .toString()
                                                                            .replace(",", "")
                                                                    ) +
                                                                    Number(
                                                                        (b.total_charges || "")
                                                                            .toString()
                                                                            .replace(",", "")
                                                                    ),
                                                            };
                                                        },
                                                        { total_charges: "" }
                                                    )?.total_charges || ""
                                                )
                                                    .toString()
                                                    .replace(",", "")
                                            ) > 0
                                                ? Number(
                                                    (
                                                        (
                                                            selectedOrder?.order_customer_ratings || []
                                                        ).reduce(
                                                            (a, b) => {
                                                                return {
                                                                    total_charges:
                                                                        Number(
                                                                            (a.total_charges || "")
                                                                                .toString()
                                                                                .replace(",", "")
                                                                        ) +
                                                                        Number(
                                                                            (b.total_charges || "")
                                                                                .toString()
                                                                                .replace(",", "")
                                                                        ),
                                                                };
                                                            },
                                                            { total_charges: "" }
                                                        )?.total_charges || ""
                                                    )
                                                        .toString()
                                                        .replace(",", "")
                                                )
                                                : Number(
                                                    (
                                                        (
                                                            selectedOrder?.order_carrier_ratings || []
                                                        ).reduce(
                                                            (a, b) => {
                                                                return {
                                                                    total_charges:
                                                                        Number(
                                                                            (a.total_charges || "")
                                                                                .toString()
                                                                                .replace(",", "")
                                                                        ) +
                                                                        Number(
                                                                            (b.total_charges || "")
                                                                                .toString()
                                                                                .replace(",", "")
                                                                        ),
                                                                };
                                                            },
                                                            { total_charges: "" }
                                                        )?.total_charges || ""
                                                    )
                                                        .toString()
                                                        .replace(",", "")
                                                ))
                                            : 0) < 0,
                                })}
                                style={{ fontSize: "0.7rem", textAlign: "center" }}
                                value={
                                    Number(
                                        (
                                            (selectedOrder?.order_customer_ratings || []).reduce(
                                                (a, b) => {
                                                    return {
                                                        total_charges:
                                                            Number(
                                                                (a.total_charges || "")
                                                                    .toString()
                                                                    .replace(",", "")
                                                            ) +
                                                            Number(
                                                                (b.total_charges || "")
                                                                    .toString()
                                                                    .replace(",", "")
                                                            ),
                                                    };
                                                },
                                                { total_charges: "" }
                                            )?.total_charges || ""
                                        )
                                            .toString()
                                            .replace(",", "")
                                    ) > 0 ||
                                        Number(
                                            (
                                                (selectedOrder?.order_carrier_ratings || []).reduce(
                                                    (a, b) => {
                                                        return {
                                                            total_charges:
                                                                Number(
                                                                    (a.total_charges || "")
                                                                        .toString()
                                                                        .replace(",", "")
                                                                ) +
                                                                Number(
                                                                    (b.total_charges || "")
                                                                        .toString()
                                                                        .replace(",", "")
                                                                ),
                                                        };
                                                    },
                                                    { total_charges: "" }
                                                )?.total_charges || ""
                                            )
                                                .toString()
                                                .replace(",", "")
                                        ) > 0
                                        ? ((Number(
                                            (
                                                (selectedOrder?.order_customer_ratings || []).reduce(
                                                    (a, b) => {
                                                        return {
                                                            total_charges:
                                                                Number(
                                                                    (a.total_charges || "")
                                                                        .toString()
                                                                        .replace(",", "")
                                                                ) +
                                                                Number(
                                                                    (b.total_charges || "")
                                                                        .toString()
                                                                        .replace(",", "")
                                                                ),
                                                        };
                                                    },
                                                    { total_charges: "" }
                                                )?.total_charges || ""
                                            )
                                                .toString()
                                                .replace(",", "")
                                        ) -
                                            Number(
                                                (
                                                    (selectedOrder?.order_carrier_ratings || []).reduce(
                                                        (a, b) => {
                                                            return {
                                                                total_charges:
                                                                    Number(
                                                                        (a.total_charges || "")
                                                                            .toString()
                                                                            .replace(",", "")
                                                                    ) +
                                                                    Number(
                                                                        (b.total_charges || "")
                                                                            .toString()
                                                                            .replace(",", "")
                                                                    ),
                                                            };
                                                        },
                                                        { total_charges: "" }
                                                    )?.total_charges || ""
                                                )
                                                    .toString()
                                                    .replace(",", "")
                                            )) *
                                            100) /
                                        (Number(
                                            (
                                                (selectedOrder?.order_customer_ratings || []).reduce(
                                                    (a, b) => {
                                                        return {
                                                            total_charges:
                                                                Number(
                                                                    (a.total_charges || "")
                                                                        .toString()
                                                                        .replace(",", "")
                                                                ) +
                                                                Number(
                                                                    (b.total_charges || "")
                                                                        .toString()
                                                                        .replace(",", "")
                                                                ),
                                                        };
                                                    },
                                                    { total_charges: "" }
                                                )?.total_charges || ""
                                            )
                                                .toString()
                                                .replace(",", "")
                                        ) > 0
                                            ? Number(
                                                (
                                                    (
                                                        selectedOrder?.order_customer_ratings || []
                                                    ).reduce(
                                                        (a, b) => {
                                                            return {
                                                                total_charges:
                                                                    Number(
                                                                        (a.total_charges || "")
                                                                            .toString()
                                                                            .replace(",", "")
                                                                    ) +
                                                                    Number(
                                                                        (b.total_charges || "")
                                                                            .toString()
                                                                            .replace(",", "")
                                                                    ),
                                                            };
                                                        },
                                                        { total_charges: "" }
                                                    )?.total_charges || ""
                                                )
                                                    .toString()
                                                    .replace(",", "")
                                            )
                                            : Number(
                                                (
                                                    (
                                                        selectedOrder?.order_carrier_ratings || []
                                                    ).reduce(
                                                        (a, b) => {
                                                            return {
                                                                total_charges:
                                                                    Number(
                                                                        (a.total_charges || "")
                                                                            .toString()
                                                                            .replace(",", "")
                                                                    ) +
                                                                    Number(
                                                                        (b.total_charges || "")
                                                                            .toString()
                                                                            .replace(",", "")
                                                                    ),
                                                            };
                                                        },
                                                        { total_charges: "" }
                                                    )?.total_charges || ""
                                                )
                                                    .toString()
                                                    .replace(",", "")
                                            ))
                                        : 0
                                }
                                thousandsGroupStyle="thousand"
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix={""}
                                suffix={"%"}
                                type="text"
                                displayType={"text"}
                                readOnly={true}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="fields-container-row" style={{ flexGrow: 1 }}>
                <div className="form-bordered-box">
                    <div className="form-header">
                        <div className="top-border top-border-left"></div>
                        <div className="form-title">Events</div>
                        <div className="top-border top-border-middle"></div>
                        <div className="form-buttons">
                            <div className="mochi-button" onClick={() => {
                                if ((selectedOrder?.id || 0) === 0 || selectedOrder.events.length === 0) {
                                    window.alert("There is nothing to print!");
                                    return;
                                }

                                let html = `<h2>Order Number: ${selectedOrder.order_number} - Events</h2></br></br>`;

                                html += `
                                    <div style="display:flex;align-items:center;font-size: 0.9rem;font-weight:bold;margin-bottom:10px;color: rgba(0,0,0,0.8)">
                                        <div style="min-width:15%;max-width:15%;text-decoration:underline">Date & Time</div>
                                        <div style="min-width:10%;max-width:10%;text-decoration:underline">User ID</div>
                                        <div style="min-width:15%;max-width:15%;text-decoration:underline">Event</div>
                                        <div style="min-width:20%;max-width:20%;text-decoration:underline">Location</div>
                                        <div style="min-width:40%;max-width:40%;text-decoration:underline">Notes</div>
                                        
                                    </div>
                                    `;

                                selectedOrder.events.map((item, index) => {
                                    html += `
                                    <div style="padding: 5px 0;display:flex;align-items:center;font-size: 0.7rem;font-weight:normal;margin-bottom:15px;color: rgba(0,0,0,1); borderTop:1px solid rgba(0,0,0,0.1);border-bottom:1px solid rgba(0,0,0,0.1)">
                                        <div style="min-width:15%;max-width:15%;">${item.event_date
                                        }@${item.event_time}</div>
                                        <div style="min-width:10%;max-width:10%;">${item?.user_code?.code || ''}</div>
                                        <div style="min-width:15%;max-width:15%;">${item.event_type.name.toUpperCase()}</div>
                                        <div style="min-width:20%;max-width:20%;">${item.event_location || ""
                                        }</div>
                                        <div style="min-width:40%;max-width:40%;">${item.event_notes || ""
                                        }</div> 
                                    </div>
                                    `;

                                    return true;
                                });

                                printWindow(html);
                            }}
                            >
                                <div className="mochi-button-decorator mochi-button-decorator-left">
                                    (
                                </div>
                                <div className="mochi-button-base">Print</div>
                                <div className="mochi-button-decorator mochi-button-decorator-right">
                                    )
                                </div>
                            </div>
                        </div>
                        <div className="top-border top-border-right"></div>
                    </div>

                    <div className="order-events-container" tabIndex={-1}>
                        {(selectedOrder?.events || []).length > 0 && (
                            <div className="order-events-item">
                                <div className="event-date">Date</div>
                                <div className="event-time">Time</div>
                                <div className="event-user-id">User ID</div>
                                <div className="event-type">Event</div>
                                <div className="event-location">Location</div>
                                <div className="event-notes">Notes</div>
                                <div className="event-date">Event Date</div>
                                <div className="event-time">Event Time</div>
                            </div>
                        )}

                        {
                            (selectedOrder?.is_cancelled || 0) === 1 &&
                            <div className="order-cancelled-tag">CANCELLED</div>
                        }

                        <div className="order-events-wrapper" tabIndex={-1}>
                            {(selectedOrder?.events || []).map((item, index) => (
                                <div className="order-events-item" key={index}>
                                    <div className="event-date">{item.date}</div>
                                    <div className="event-time">{item.time}</div>
                                    <div className="event-user-id">{item?.user_code?.code || ''}</div>
                                    <div className="event-type">
                                        {item.event_type.name.toUpperCase()}
                                    </div>
                                    <div className="event-location">{item.event_location}</div>
                                    <div className="event-notes">{item.event_notes}</div>
                                    <div className="event-date">{item.event_date}</div>
                                    <div className="event-time">{item.event_time}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {changeCarrierTransition(
                (style, item) =>
                    item && (
                        <animated.div
                            className="change-carrier-main-container"
                            style={{
                                ...style,
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                top: 0,
                                left: 0,
                                backgroundColor: "rgba(0,0,0,0.3)",
                            }}
                        >
                            <div
                                className="change-carrier-wrapper"
                                style={{
                                    position: "relative",
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <ChangeCarrier
                                    panelName={`${props.panelName}-change-carrier`}
                                    tabTimes={props.tabTimes}
                                    componentId={moment().format("x")}
                                    origin={props.origin}
                                    closeModal={() => {
                                        setShowingChangeCarrier(false);
                                    }}
                                    updateCarrier={() => {
                                    }}
                                    selectedOrder={selectedOrder}
                                />
                            </div>
                        </animated.div>
                    )
            )}

            {loadingTransition(
                (style, item) =>
                    item && (
                        <animated.div className="loading-container" style={style}>
                            <div className="loading-container-wrapper">
                                <Loader
                                    type="Circles"
                                    color="#009bdd"
                                    height={40}
                                    width={40}
                                    visible={item}
                                />
                            </div>
                        </animated.div>
                    )
            )}

            {noteForDriverTransition(
                (style, item) =>
                    item && (
                        <animated.div style={{ ...style }}>
                            <DispatchModal
                                selectedData={selectedNoteForDriver}
                                setSelectedData={setSelectedNoteForDriver}
                                selectedParent={selectedOrder}
                                setSelectedParent={(data) => {
                                    setSelectedOrder((selectedOrder) => {
                                        return { ...selectedOrder, notes_for_driver: data.notes };
                                    });
                                }}
                                savingDataUrl="/saveNotesForDriver"
                                deletingDataUrl="/deleteNotesForDriver"
                                type="note"
                                isEditable={true}
                                isDeletable={true}
                                isAdding={selectedNoteForDriver?.id === 0}
                            />
                        </animated.div>
                    )
            )}

            {noteForCarrierTransition(
                (style, item) =>
                    item && (
                        <animated.div style={{ ...style }}>
                            <DispatchModal
                                selectedData={selectedNoteForCarrier}
                                setSelectedData={setSelectedNoteForCarrier}
                                selectedParent={selectedOrder}
                                setSelectedParent={(data) => {
                                    setSelectedOrder((selectedOrder) => {
                                        return { ...selectedOrder, notes_for_carrier: data.notes };
                                    });
                                }}
                                savingDataUrl="/saveNotesForCarrier"
                                deletingDataUrl="/deleteNotesForCarrier"
                                type="note"
                                isEditable={true}
                                isDeletable={true}
                                isAdding={selectedNoteForCarrier?.id === 0}
                            />
                        </animated.div>
                    )
            )}

            {internalNoteTransition(
                (style, item) =>
                    item && (
                        <animated.div style={{ ...style }}>
                            <DispatchModal
                                selectedData={selectedInternalNote}
                                setSelectedData={setSelectedInternalNote}
                                selectedParent={selectedOrder}
                                setSelectedParent={(data) => {
                                    setSelectedOrder((selectedOrder) => {
                                        return { ...selectedOrder, internal_notes: data.notes };
                                    });
                                }}
                                savingDataUrl="/saveInternalNotes"
                                deletingDataUrl="/deleteInternalNotes"
                                type="note"
                                isEditable={props.isAdmin}
                                isDeletable={props.isAdmin}
                                isAdding={selectedInternalNote?.id === 0}
                            />
                        </animated.div>
                    )
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        scale: state.systemReducers.scale,
        user: state.systemReducers.user,
        serverUrl: state.systemReducers.serverUrl,

        adminHomePanels: state.adminReducers.adminHomePanels,
        companyHomePanels: state.companyReducers.companyHomePanels,
        adminCompanySetupPanels: state.companySetupReducers.adminCompanySetupPanels,
        companyCompanySetupPanels: state.companySetupReducers.companyCompanySetupPanels,
        adminCarrierPanels: state.carrierReducers.adminCarrierPanels,
        companyCarrierPanels: state.carrierReducers.companyCarrierPanels,
        adminCustomerPanels: state.customerReducers.adminCustomerPanels,
        companyCustomerPanels: state.customerReducers.companyCustomerPanels,
        adminDispatchPanels: state.dispatchReducers.adminDispatchPanels,
        companyDispatchPanels: state.dispatchReducers.companyDispatchPanels,
        adminInvoicePanels: state.invoiceReducers.adminInvoicePanels,
        companyInvoicePanels: state.invoiceReducers.companyInvoicePanels,
        adminLoadBoardPanels: state.loadBoardReducers.adminLoadBoardPanels,
        companyLoadBoardPanels: state.loadBoardReducers.companyLoadBoardPanels,
        adminReportPanels: state.reportReducers.adminReportPanels,
        companyReportPanels: state.reportReducers.companyReportPanels,

        selectedOrder: state.dispatchReducers.selected_order,
        selectedCustomer: state.customerReducers.selectedCustomer,
        selectedCustomerContact: state.customerReducers.selectedContact,
        selectedCarrier: state.carrierReducers.selectedCarrier,
        selectedCarrierContact: state.carrierReducers.selectedContact,
        selectedCarrierDriver: state.carrierReducers.selectedDriver,
        selectedCarrieInsurance: state.carrierReducers.selectedInsurance,
    };
};

export default connect(mapStateToProps, {
    setSelectedOrder,
    setSelectedCustomer,
    setSelectedContact,
    setSelectedCarrier,
    setSelectedCarrierContact,
    setSelectedCarrierDriver,
    setSelectedCarrierInsurance,
    setAdminHomePanels,
    setCompanyHomePanels,
    setAdminCarrierPanels,
    setCompanyCarrierPanels,
    setAdminCompanySetupPanels,
    setCompanyCompanySetupPanels,
    setAdminCustomerPanels,
    setCompanyCustomerPanels,
    setAdminDispatchPanels,
    setCompanyDispatchPanels,
    setAdminInvoicePanels,
    setCompanyInvoicePanels,
    setAdminLoadBoardPanels,
    setCompanyLoadBoardPanels,
    setAdminReportPanels,
    setCompanyReportPanels,
    setAvailableOrders,
    setBookedOrders,
    setInTransitOrders,
    setDeliveredNotInvoiced,
    setIsLoadingWidget
})(Dispatch);
